import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Mutation from "../apollo/mutations";
import { Theme, UserIcon } from "../theme";
import { useMutation } from "@apollo/react-hooks";
import { isSamePassword } from "../helpers/Validation";
import { PasswordField } from "../components/InputFields";
import { ShowDialog } from "../components/DialogsComponent";
import { ResetPassword } from "../apollo/mutations/Variables";
import { Box, Button, Heading, ResponsiveContext } from "grommet";

const RootContainer = styled(Box)`
  background-color: ${Theme.global.colors["white"]};
  padding-bottom: ${(props) => (props.size === "small" ? "0.5rem" : "5.6rem")};
  padding-left: ${(props) => (props.size === "small" ? "0.5rem" : "3rem")};
  padding-right: ${(props) => (props.size === "small" ? "0.5rem" : "3rem")};
  padding-top: ${(props) => (props.size === "small" ? "0.5rem" : "2rem")};
  @media (max-width: 1280px) {
    padding-bottom: 2rem;
  }
`;
const InformationFileds = styled(Box)`
  @media (min-width: 1280px) {
    align-items: center;
  }
`;
const InputContainer = styled(Box)`
  width: 100%;
  @media (min-width: 1280px) {
    width: 26rem;
  }
  ::placeholder {
    font-weight: lighter;
  }
`;
const EmailButton = styled(Button)`
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1.2rem;
  padding-left: 1.3rem;
  border-radius: 10rem;
  background-color: ${Theme.global.colors["brand"]};
  color: ${Theme.global.colors["white"]};
`;
const EmailVerify = (props) => {
    const getInitialState = () => {
        return {
            password: "",
            confirmPassword: "",
        };
    };
    const [userInfo, setUserInfo] = useState(getInitialState());
    const [dialogState, setDialogState] = useState({
        show: false,
        message: "",
        type: "",
    });
    const getTokenFromUrl = () => {
        const query = new URLSearchParams(window.location.search);
        const token = query.get("token");
        return token;
    };

    const [
        resetPassword,
        { data: resetPasswordResponse, error: resetPasswordErrorResponse },
    ] = useMutation(
        Mutation.RESET_PASSWORD_MUTATION,
        ResetPassword({ ...userInfo, token: getTokenFromUrl() })
    );

    const navToSignInPage = () => {
        let path = `/signin`;
        props.history.push(path);
    };
    const onSubmit = () => {
        if (userInfo.password && userInfo.confirmPassword) {
            if (isSamePassword(userInfo.password, userInfo.confirmPassword)) {
                setDialogState({
                    show: true,
                    message: "Please wait...",
                    type: "LOADING",
                });
                resetPassword();
            } else {
                setDialogState({
                    show: true,
                    message: "Please make sure passwords are same.",
                    type: "FAILED",
                });
            }
        } else {
            setDialogState({
                show: true,
                message: "Please fill all require fields.",
                type: "FAILED",
            });
        }
    };
    useEffect(() => {
        if (resetPasswordResponse) {
            setDialogState({
                show: true,
                message: "password successfully updated",
                type: "SUCCESS",
            });
        }
        if (resetPasswordErrorResponse) {
            setDialogState({
                show: true,
                message: "Session expired",
                type: "FAILED",
            });
        }
    }, [
        resetPasswordResponse,
        resetPasswordErrorResponse
    ]);

    const handleCloseAlertDialog = () => {
        setDialogState({
            ...dialogState,
            show: false,
            message: "",
        });
        if (dialogState.type === "SUCCESS") {
            navToSignInPage();
        }
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <React.Fragment>
                    {dialogState.show && (
                        <ShowDialog
                            title={dialogState.message}
                            onClose={handleCloseAlertDialog}
                            type={dialogState.type}
                        />
                    )}

                    <RootContainer elevation={"medium"}>
                        <Box align={"center"}>
                            <Heading
                                color={Theme.global.colors.textBlack}
                                style={{ fontSize: "2.2rem" }}
                                margin={{
                                    bottom: "xsmall",
                                }}
                            >
                                Change your password here
                            </Heading>
                        </Box>
                        <InformationFileds>
                            <InputContainer margin={{ top: size }} size={size}>
                                <PasswordField
                                    size={size}
                                    title={"New Password"}
                                    value={userInfo.password}
                                    onValueChange={(event) =>
                                        setUserInfo({ ...userInfo, password: event })
                                    }
                                />
                            </InputContainer>
                            <InputContainer margin={{ top: size }} size={size}>
                                <PasswordField
                                    size={size}
                                    title={"Confirm  Password"}
                                    value={userInfo.password}
                                    onValueChange={(event) =>
                                        setUserInfo({ ...userInfo, confirmPassword: event })
                                    }
                                />
                            </InputContainer>
                            <InputContainer margin={{ top: size }} size={size}>
                                <EmailButton
                                    ariaLabel="click on sign button"
                                    tabIndex="0"
                                    role="link"
                                    icon={<UserIcon color={Theme.global.colors.white}/>}
                                    alignSelf={"left"}
                                    label="Submit"
                                    onClick={() => {
                                        onSubmit();
                                    }}
                                />
                            </InputContainer>
                        </InformationFileds>
                    </RootContainer>
                </React.Fragment>
            )}
        </ResponsiveContext.Consumer>
    );
};
export default EmailVerify;
