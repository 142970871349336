import React, { useContext } from "react";
import styled from "styled-components";
import AppContext from "../appContext";
import List from "../components/List";
import FAQ from "../components/FAQComponent";
import ESFLogo from "../assets/images/esf_logo.png";
import DECLogo from "../assets/images/dec_fat.png";
import HWSLogo from "../assets/images/HWS_logo.png"
import NYNHPLogoResize from "../assets/images/NYNHP_colorHiRes_resize.png"
import { Brand1Logo, Brand2Logo, Theme } from "../theme";
import ContactComponent from "../components/ContactComponent";
import { Anchor, Box, ResponsiveContext, Text } from "grommet";

const RootContainer = styled(Box)`
  background: ${Theme.global.colors.white};

  .image-container-reptiles {
    flex-direction: row;
    @media (max-width: 1050px) {
      flex-direction: column;
    }
  }

  .image-container-mammals {
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 1050px) {
      flex-direction: column;
    }
    img {
      max-width: 140px;
    }
    svg {
      max-width: 140px;
    }
  }
`;

const BrandLogoWrapper = styled.div`
  background-color: transparent;
  margin-bottom: 2rem;
  margin-top: 1rem;
  left: 0;
  right: 0;

  svg {
    height: 105px;
    width: 235px;
  }

  png {
    height: 105px;
    width: 235px;
  }
`;

const MammalsAboutContainer = () => {
    return (
        <div>
            <div
                style={{ marginBottom: "1rem" }}>
                <Text>
                    <b> The New
                        York
                        Mammal
                        Survey
                        (NYMS) </b>
                    <Text
                        weight="normal"
                        style={{ fontSize: "18px" }}>
                        is the
                        first
                        statewide
                        assessment
                        of the
                        distribution
                        and
                        status
                        of
                        New
                        York’s
                        terrestrial
                        and
                        freshwater
                        mammals.
                    </Text>
                </Text>
            </div>
            <FAQ
                style={{ marginBottom: 0 }}
                question="What is NYMS?"
                answer="While mammals capture our attention and we see them everywhere, data on
their occurrence and abundance has become increasingly rare and targeted
approaches to collecting wildlife information are often very time consuming
and expensive. At the same time technological advances (such as smart
phones and trail cameras) are making casual observations of wildlife
increasingly common. These advances have provided a fantastic and
underused source of information."
            ></FAQ>
            <br/>
            <FAQ
                question=""
                answer="The New York Mammal Survey (NYMS) is the first comprehensive
assessment of NY State’s mammal species. By combining these new sources
of data with historical mammal records, NYMS will">

            </FAQ>

            <List
                itemStyle={{ fontSize: "18px" }}
                items={[
                    "Guide environmental planning and conservation for New York's\
                  mammals, some of which are endangered or declining",
                    "Provide a framework for detecting changes in species’ ranges\
                  around the state due to habitat loss, climate change, and other\
                  factors",
                    "Let us learn more about the natural history and habitat\
                  requirements of New York’s mammals",
                ]}
            />

            <FAQ
                style={{ marginBottom: 0 }}
                question="What can you do with NYMS?"
                answer=""
            ></FAQ>
            <List
                itemStyle={{ fontSize: "18px" }}
                items={[
                    "Find out what mammals others are reporting in New York State",
                    "Explore where mammals occur through maps, graphs, and charts by\
          time period, environment, or location",
                    "Learn more about New York’s mammals through the NYMS mammal\n" +
                    "guide",
                    "Contribute your own observations using iNaturalist",
                ]}
            />

            <FAQ
                question="What is the goal of NYMS?"
                answer="NYMS’s primary goal is to determine where mammal species occur in New
York State and, when possible, estimate their abundance. To do so we are seeking any
and all observations of mammals (sighted, heard, caught on trail cameras,
roadkill, etc.) to combine with our structured sampling efforts and data from published literature."
            />

            <FAQ
                question="Who oversees NYMS?"
                answer="NYMS is an institutional collaboration among the New York State Department
of Environmental Conservation’s Bureau of Wildlife, which funds the work and
whose staff are conducting mammal surveys in the field, Cornell University’s
New York Cooperative Fish and Wildlife Research Unit, which is contributing
camera trap data and analytical support, the New York Natural Heritage
Program, which conducts specialized surveys for rare species and assists
with project oversight, and the Roosevelt Wild Life Station at SUNY-ESF,
which oversees the project, maintains the data, and provides models of
species distribution and trends. To see the current members of the steering
committee visit us "
                linkText="here"
                link="https://www.rooseveltwildlife.org/new-york-mammal-survey"
                secondPartOfText="."
            />
            <FAQ
                question="Who contributes?"
                answer="You! In addition to your important observations, we are soliciting information
from other mammal experts working in the region and from public databases
like GBIF and eMammal. NYMS also relies on assistance from
students, volunteers, and interns who help to collect, verify, and process these
data. We are grateful to NY zoologist and conservation photographer Larry
Master ("
                linkText="masterimages.org"
                link="https://www.masterimages.org"
                secondPartOfText=") for contributing many of the beautiful images used here. The NYMS website was created by Woodbury Shortridge of First Turn Media.">

            </FAQ>
            <FAQ
                question="Who funds NYMS?"
                answer="NYMS is sponsored by the New York State Department of Environmental Conservation 
                  via the Pittman-Robertson Wildlife Restoration Act, and is overseen by the Roosevelt 
                  Wild Life Station at the State University of New York’s College of Environmental Science 
                  and Forestry in Syracuse."
            />
            <FAQ
                question="NYMS updates"
                answer="Please connect to NYMS’ "
                link="https://twitter.com/NYMammals"
                linkText="Twitter"
                link2="https://www.facebook.com/NYMammals"
                linkText2="Facebook"
                secondPartOfText=" and "
                thirdPartOfText=" project feeds that contain information
                on project progress, species identification tips, survey techniques, priority search areas,
                and other items of interest."
            />
        </div>
    );
};
const ReptilesAboutContainer = () => {
    return (
        <div>
            <div
                style={{ marginBottom: "1rem" }}>
                <Text>
                    <b>New
                        York
                        Amphibian
                        &
                        Reptile
                        Survey
                        (NYARS) </b>

                    <Text
                        weight="normal"
                        style={{ fontSize: "18px" }}>
                        is a
                        statewide
                        assessment
                        of
                        species
                        distribution
                        and
                        status.
                    </Text>
                </Text>
            </div>
            <FAQ
                style={{ marginBottom: 0 }}
                question="What is NYARS?"
                answer="NYARS is a collaborative effort to document distribution, abundance, habitat use, and trends of
             amphibians and reptiles in New York State. Gathering historic and new records on where amphibians
            and reptiles occur in New York State will"
            ></FAQ>

            <List
                itemStyle={{ fontSize: "18px" }}
                items={[
                    "Guide environmental planning and conservation for New York’s amphibians and reptiles,\
                   some of which are endangered or declining",
                    "Provide a scientific framework for detecting changes in species’\
                   ranges around the state due to habitat loss, climate change, and other factors",
                    "Let us learn more about the natural history and habitat requirements of New York’s\
                   amphibians and reptiles",
                ]}
            />

            <FAQ
                style={{ marginBottom: 0 }}
                question="What can you do with NYARS?"
                answer=""
            ></FAQ>
            <List
                itemStyle={{ fontSize: "18px" }}
                items={[
                    "Maintain a list, store your data, and manage, edit, and download records\
                   of the amphibians and reptiles you see",
                    "Find out what amphibians and reptiles others are reporting in New York State",
                    "Explore through maps, graphs, and charts by time period, environment,\
                   or location where amphibians and reptiles occur",
                    "Learn more about New York’s amphibians and reptiles through the NY Amphibian and Reptile guide",
                ]}
            />

            <FAQ
                question="What is the goal of NYARS?"
                answer="NYARS’s primary goal is to determine where amphibians and reptile species
              occur in New York State and, when possible, their abundance. Every time you
              notice an amphibian or reptile, you are holding a key piece of information.
              By sharing your sightings with others through NYARS, you make it possible to
              improve our collective understanding of the natural history, distribution, and
              status of New York’s amphibians and reptiles and protect them well into the future.
              Your contributions are welcome regardless of your experience."
            />
            <Box
                style={{ marginBottom: "1rem" }}>
                <Text
                    style={{ fontWeight: "bold" }}>Who
                    oversees
                    NYARS </Text>
                <Text
                    weight={"normal"}
                    style={{
                        display: "block",
                        fontSize: "18px"
                    }}>
                    The
                    project
                    represents
                    the
                    continuation
                    of the
                    Amphibian
                    and
                    Reptile
                    Atlas of
                    New York
                    State,
                    originally
                    conducted
                    by the
                    Department
                    of
                    Environmental
                    Conservation
                    from
                    1990-1999.
                    The
                    original
                    atlas
                    compiled
                    over
                    70,000
                    reports of
                    amphibians
                    and
                    reptiles
                    by nearly
                    4,000
                    volunteer
                    citizens.
                    The
                    original
                    Atlas was
                    created by
                    Alvin R.
                    "Al"
                    Breisch
                    along with
                    John Ozard
                    of the New
                    York State
                    Department
                    of
                    Environmental
                    Conservation.
                    This new
                    digital
                    edition
                    has been
                    developed
                    by
                    <Anchor
                        href="http://people.hws.edu/cosentino/home.html"
                        label=" Dr. Bradley J. Cosentino"
                    />
                    , a
                    Professor
                    of Biology
                    at
                    <Anchor
                        href="http://www.hws.edu/"
                        label=" Hobart and William Smith Colleges in Geneva "
                    />
                    and
                    <Anchor
                        href="http://www.esf.edu/EFB/gibbs/"
                        label=" Dr. James P. Gibbs"
                    />
                    , a
                    Professor
                    of
                    Conservation
                    Biology at
                    <Anchor
                        href="http://www.esf.edu"
                        label=" SUNY-ESF"/>.
                    NYARS is
                    in
                    the
                    process of
                    establishing
                    a
                    taxonomic
                    review
                    working
                    group (to
                    check
                    records)
                    and a
                    scientific
                    advisory
                    committee.
                </Text>
                <Text
                    weight={"normal"}
                    style={{
                        display: "block",
                        fontSize: "18px"
                    }}>
                    Most of
                    the
                    spectacular
                    amphibian
                    and
                    reptile
                    imagery
                    here is
                    the work
                    of
                    collaborator
                    <Anchor
                        href="http://rtpi.org/biology-without-borders-photography-by-rtpi-president-twan-leenders/"
                        label=" Twan Leenders"
                    />
                    ,
                    President
                    of the
                    Roger Tory
                    Peterson
                    Institute
                    of Natural
                    History in
                    Jamestown.
                    Other
                    images are
                    used with
                    permission
                    of the
                    photographers
                    who
                    contributed
                    them to
                    the
                    <Anchor
                        label=' "The Amphibians and Reptiles of New York State: Identification, Natural History, and Conservation" '
                        href="https://www.amazon.com/Amphibians-Reptiles-York-State-Identification/dp/0195304446"
                    />
                    field
                    guide.
                </Text>
            </Box>
            <FAQ
                question="Who funds NYARS?"
                answer="Supporters of NYARS include the Roosevelt Wild Life Station at the State University of New York’s
            College of Environmental Science and Forestry, The Wetland Trust, and New York State Water Resources
            Institute. Our partners include the New York Department of Environmental Conservation and the New York
            Natural Heritage Program. The NYARS website was created by Woodbury Shortridge of First Turn Media. "
            />

            <Box
                style={{ marginBottom: "1rem" }}>
                <Text
                    style={{ fontWeight: "bold" }}>Help
                    sustain
                    NYARS </Text>
                <Text
                    weight={"normal"}
                    style={{
                        display: "block",
                        fontSize: "18px"
                    }}>
                    You can
                    contribute
                    directly
                    to the
                    project
                    at:
                    <br/>
                    SUNY-ESF -
                    A state
                    university
                    that
                    focuses on
                    the study
                    of the
                    environment.
                    <List
                        isOrdered
                        itemStyle={{ fontSize: "18px" }}
                        items={[
                            <>
                                Visit
                                <Anchor
                                    href="https://wwwinfo-2.esf.edu/donate/"
                                    label=" ESF donation webpage"
                                />
                            </>,
                            'Click "Gift"',
                            'Under "Gift Designation" choose "Amphibian and Reptile Atlas"',
                        ]}
                    />
                    Hobart &
                    William
                    Smith
                    Colleges -
                    A small
                    liberal
                    arts
                    college in
                    the
                    Finger
                    Lakes
                    region of
                    upstate
                    New York.
                    <List
                        isOrdered
                        itemStyle={{ fontSize: "18px" }}
                        items={[
                            <>
                                Visit
                                <Anchor
                                    href="https://www.hws.edu/onlinegiving"
                                    label="  HWS donation webpage "
                                />
                                and
                                fill
                                out
                                information
                                about
                                you.
                            </>,
                            'Under "Designation", select “Other” and type "NY Amphibian and Reptile Survey".',
                        ]}
                    />
                    Thank you
                    for your
                    support.
                </Text>
            </Box>
            <FAQ
                question="NYARS updates"
                answer="Please connect to NYARS’ Twitter and Facebook project feeds that contain information on project progress,
             species identification tips, survey techniques, priority search areas, and other items of interest."
            />
        </div>
    );
};

const AboutPage = (props) => {
    const context = useContext(AppContext);

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <React.Fragment>
                    <RootContainer
                        elevation={size === "small" ? "none" : "medium"}
                        round="0.25rem"
                        margin={{
                            bottom: size === "small" ? "small" : "medium",
                        }}
                        pad={size === "small" ? "0px" : size === "medium" ? "1rem" : "3rem"}
                        size={size}
                    >
                        {context.isMammals ? (
                            <Box
                                className={'image-container-mammals'}
                                align="center"
                                margin={{ bottom: "2rem" }}
                            >
                                <img
                                    height={"56px"}
                                    width={"140px"}
                                    src={ESFLogo}/>
                                <BrandLogoWrapper>
                                    <Brand1Logo/>
                                </BrandLogoWrapper>
                                <img
                                    height={"61px"}
                                    width={"140px"}
                                    src={NYNHPLogoResize}
                                />
                                <img
                                    height={"140px"}
                                    width={"140px"}
                                    src={DECLogo}/>
                            </Box>
                        ) : (<Box
                                className={'image-container-reptiles'}
                                justify="between"
                                align="center"
                                margin={{ bottom: "2rem" }}
                            >
                                <img
                                    height={"80px"}
                                    width={"200px"}
                                    src={ESFLogo}/>
                                <BrandLogoWrapper>
                                    <Brand2Logo
                                        width={"400px"}
                                        height={"124px"}/>
                                </BrandLogoWrapper>
                                <img height={"80px"} width={"200px"} src={HWSLogo}/>
                            </Box>
                        )}
                        {context.isMammals ? (
                            <MammalsAboutContainer/>
                        ) : (
                            <ReptilesAboutContainer/>
                        )}

                        <ContactComponent
                            position="center"
                            size="medium"/>
                    </RootContainer>
                </React.Fragment>
            )}
        </ResponsiveContext.Consumer>
    );
};

export default AboutPage;
