import S3FileUpload from "react-s3";
import { Config } from "./index";

export default async function uploadFileToS3(file) {
    return new Promise(async function (resolve, reject) {
        S3FileUpload.uploadFile(file, Config.S3)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
                throw err;
            });
    });
}
