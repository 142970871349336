import React, { useState } from "react";
import { Box, Button, CheckBox, Image } from "grommet";
import styled from "styled-components";
import { CloseCustom, Theme, UploadIcon } from "../../theme";
import { UploadFileToS3 } from "../../helpers";
import { ShowDialog } from "../../components/DialogsComponent";
import Loader from "../../components/Loader";
import { TextField } from "../InputFields";

const Root = styled.div`
  text-align: center;
  position: relative;
  z-index: 2;
`;
const DilogIndex = styled.div`
  position: relative;
  z-index: 1;
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;
const Dialog = styled.div`
  background-color: ${Theme.global.colors.white};
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  input {
    font-size: 1rem;
    font-weight: 400;
  }
`;

const ActionButton = styled(Button)`
  height: 2.5rem;
  color: ${Theme.global.colors.active};
  font-size: 1rem;
`;

const ImageCaptionDialog = (props) => {
    const image = URL.createObjectURL(props.file);
    const [captionInput, setCaptionInput] = useState("");
    const [creditsInput, setCreditsInput] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [isSensitiveImage, setIsSensitiveImage] = useState(false);
    const [dialogState, setDialogState] = useState({
        show: false,
        message: "",
        type: "",
    });
    const convertFileToDataURLviaFileReader = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
    };

    const base64toFile = async (base64, filename) => {
        var arr = base64.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    const handleCloseAlertDialog = () => {
        setDialogState({
            ...dialogState,
            show: false,
            message: "",
        });
    };
    const UploadImage = () => {
        let key = props.Key.replace(/[^A-Z0-9]/gi, "_").toLowerCase();
        const newName = `observations/${key}_${new Date().getTime()}.jpg`;

        convertFileToDataURLviaFileReader(image, async function (base64_data) {
            var file = await base64toFile(base64_data, newName);
            setIsUploading(true);
            try {
                const data = await UploadFileToS3(file);
                props.onImageUploaded(data, captionInput, creditsInput);
            } catch (err) {
                throw err;
            }
        });
    };

    return (
        <Root>
            <DilogIndex>
                {dialogState.show && (
                    <ShowDialog
                        title={dialogState.message}
                        onClose={handleCloseAlertDialog}
                        type={dialogState.type}
                    />
                )}
            </DilogIndex>
            <Overlay>
                <Dialog>
                    {isUploading ? (
                        <Loader/>
                    ) : (
                        <Box pad="xsmall" gap="small" direction="column">
                            <Image
                                style={{
                                    width: "22rem",
                                    height: "14rem",
                                    borderRadius: "0.25rem",
                                }}
                                src={image}
                            />
                            <TextField
                                size={"medium"}
                                isRequired={false}
                                value={captionInput}
                                placeholder={"Add a caption"}
                                title={"Caption"}
                                onValueChange={(value) => setCaptionInput(value)}
                            />
                            <TextField
                                size={"medium"}
                                isRequired={false}
                                value={creditsInput}
                                placeholder={"Add credits"}
                                title={"Credits"}
                                onValueChange={(value) => setCreditsInput(value)}
                            />
                            <CheckBox
                                toggle
                                size={"medium"}
                                label={"Sensitive image"}
                                style={{ fontSize: 3 }}
                                checked={isSensitiveImage}
                                onChange={(isChecked) => {
                                    setIsSensitiveImage(isChecked.target.checked);
                                }}
                            ></CheckBox>
                            <Box direction="row" justify="between" margin={{ top: "0.5rem" }}>
                                <ActionButton
                                    icon={<CloseCustom color={Theme.global.colors.active}/>}
                                    label="Cancel"
                                    onClick={() => {
                                        props.onCancel();
                                    }}
                                />

                                <ActionButton
                                    icon={<UploadIcon color={Theme.global.colors.active}/>}
                                    label="Upload"
                                    onClick={() => {
                                        UploadImage();
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </Dialog>
            </Overlay>
        </Root>
    );
};

export default ImageCaptionDialog;
