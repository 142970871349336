import React, { useContext } from 'react';
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import AppContext from '../../appContext';

const MR = 211;
const MG = 159;
const MB = 60;

const HR = 0;
const HG = 125;
const HB = 223;


const MAMMALS_COLORS = [
    `rgb(${MR}, ${MG}, ${MB}, 0.1)`,
    `rgb(${MR}, ${MG}, ${MB}, 0.3)`,
    `rgb(${MR}, ${MG}, ${MB}, 0.55)`,
    `rgb(${MR}, ${MG}, ${MB}, 0.8)`,
    `rgb(${MR}, ${MG}, ${MB}, 1.2)`,
];

const HERPS_COLORS = [
    `rgb(${HR}, ${HG}, ${HB}, 0.1)`,
    `rgb(${HR}, ${HG}, ${HB}, 0.3)`,
    `rgb(${HR}, ${HG}, ${HB}, 0.55)`,
    `rgb(${HR}, ${HG}, ${HB}, 0.8)`,
    `rgb(${HR}, ${HG}, ${HB}, 1.2)`,
];

const PieChartGraph = (props) => {
    const context = useContext(AppContext);


    const getGraphData = () => {
        return [
            { name: "Open water", value: props.graphData.water },
            { name: "Wetland", value: props.graphData.wetland },
            { name: "Developed", value: props.graphData.urban },
            { name: "Agriculture", value: props.graphData.agriculture },
            { name: "Forest", value: props.graphData.forest },
        ];
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active) {
            return (
                <div>
                    <p style={{ fontSize: "1rem" }}>{payload[0].name}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <PieChart
            width={props.size === "small" ? 120 : 200}
            height={props.size === "small" ? 120 : 200}
        >
            <Pie
                data={getGraphData()}
                labelLine={false}
                outerRadius={props.size === "small" ? 50 : 80}
                fill="#fff"
                dataKey="value"
            >
                {getGraphData().map((entry, index) => (
                    <Cell key={`cell-${index}`}
                          fill={context.isMammals ? MAMMALS_COLORS[index % MAMMALS_COLORS.length] : HERPS_COLORS[index % HERPS_COLORS.length]}/>
                ))}
            </Pie>
            <Tooltip content={<CustomTooltip/>}/>
        </PieChart>
    );
};

export default PieChartGraph;
