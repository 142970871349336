import GuidePage from "./../pages/Guide";
import HomePage from "./../pages/Home";
import GuideDetailPage from "./../pages/GuideDetail";
import NotFoundPage from "../pages/NotFound";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import Profile from "../pages/UserProfile";
import Record from "../pages/Record";
import BulkRecord from "../pages/BulkRecord";
import AddRecordSuccess from "../pages/AddRecordSuccess";
import EmailVerify from "../pages/EmailVarification";
import PasswordReset from "../pages/ResetPassword";
import Explore from "../pages/Explore";
import Observation from "../pages/Observation";
import AboutPage from "../pages/About";
import GettingStarted from "../pages/GettingStarted";
import Contact from "../pages/Contact";

const Route = [
    {

        path: "/",
        name: "home",
        exact: true,
        component: HomePage
    },
    {
        path: "/about",
        name: "about",
        exact: true,
        component: AboutPage
    },
    {
        path: "/guide",
        name: "guide",
        exact: true,
        component: GuidePage
    },
    {
        path: "/guide/:id",
        name: "guide",
        exact: true,
        component: GuideDetailPage
    },
    {
        path: "/signup",
        name: "signup",
        exact: true,
        component: SignUp
    },
    {
        path: "/profile/:id",
        name: "editProfile",
        exact: true,
        component: SignUp
    },
    {
        path: "/signin",
        name: "signin",
        exact: true,
        component: SignIn
    },
    {
        path: "/profile",
        name: "profile",
        exact: true,
        component: Profile
    },
    {
        path: "/record",
        name: "record",
        exact: true,
        component: Record
    },
    {
        path: "/record/:id",
        name: "record",
        exact: true,
        component: Record
    },
    {
        path: "/bulkrecord",
        name: "bulkrecord",
        exact: true,
        component: BulkRecord
    },
    {
        path: "/success",
        name: "AddRecordSuccess",
        exact: true,
        component: AddRecordSuccess
    },
    {
        path: "/explore",
        name: "Explore",
        exact: true,
        component: Explore
    },
    {
        path: "/observation/:id",
        name: "Observation",
        exact: true,
        component: Observation
    },
    {
        path: "/verify-email",
        name: "Email",
        exact: true,
        component: EmailVerify
    },
    {
        path: "/resetpassword",
        name: "resetpassword",
        exact: true,
        component: PasswordReset
    },
    {
        path: "/getting-started",
        name: "gettingStarted",
        exact: true,
        component: GettingStarted
    },
    {
        path: "/contact",
        name: "contact",
        exact: true,
        component: Contact
    },
    {
        component: NotFoundPage
    }
];

export default Route;
