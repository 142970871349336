const MammalsHomeData = {
    logo: "Brand1Logo",
    info:
        "NYMS – the New York Mammal Survey – is the first statewide assessment of the distribution and status of New York’s terrestrial and freshwater mammals.",
    infoButton1: "Getting started guide",
    infoButton2: "More about the Mammal Survey",
    headingTopContributors: "Top mammal survey contributors this month",
    headingLatestObservations: "Public observations",
    latestObservationInfo:
        "See what mammals have been sighted by the public and shared online across New York State.",
    topContributorsDescription: "",
    headingSurveyGuide: "The mammal survey guide",
    surveyGuideInfo:
        "Interested in learning more about New York’s mammals? Explore the species guide.",
};

const AmphibiansReptilesHomeData = {
    logo: "Brand2Logo",
    info:
        "NYARS – the New York Amphibian & Reptile Survey – a statewide assessment of species distribution and status.",
    infoButton1: "Getting started guide",
    infoButton2: "More about the Amphibians and Reptiles Survey",
    headingLatestObservations: "Public observations",
    latestObservationInfo:
        "See what amphibians and reptiles have been sighted in particular areas and across New York State.",
    headingTopContributors: "Top Amphibians and Reptiles survey contributors",
    topContributorsDescription: "",
    headingSurveyGuide: "The Amphibians and Reptiles survey guide",
    surveyGuideInfo:
        "Interested in learning more about New York’s amphibians and reptiles? Explore the species guide.",
};

export { MammalsHomeData, AmphibiansReptilesHomeData };
