import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import Mutation from "../apollo/mutations";
import styled from "styled-components";
import { Errors } from "../constants";
import { Theme, UserCustom } from "../theme";
import { EmailField } from "../components/InputFields";
import { ShowDialog } from "../components/DialogsComponent";
import { getEmailForReset } from "../apollo/mutations/Variables";
import { Anchor, Box, Button, Heading, ResponsiveContext, Text } from "grommet";

const RootContainer = styled(Box)`
  background-color: ${Theme.global.colors["white"]};
  padding-bottom: ${(props) => (props.size === "small" ? "0.5rem" : "5.6rem")};
  padding-left: ${(props) => (props.size === "small" ? "0.5rem" : "3rem")};
  padding-right: ${(props) => (props.size === "small" ? "0.5rem" : "3rem")};
  padding-top: ${(props) => (props.size === "small" ? "0.5rem" : "2rem")};
  @media (max-width: 1280px) {
    padding-bottom: 2rem;
  }
`;
const InformationFileds = styled(Box)`
  @media (min-width: 1280px) {
    align-items: center;
  }
`;
const InputContainer = styled(Box)`
  width: 100%;
  @media (min-width: 1280px) {
    width: 27.5rem;
  }
  ::placeholder {
    font-weight: lighter;
  }
`;
const EmailButton = styled(Button)`
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1.2rem;
  padding-left: 1.3rem;
  border-radius: 10rem;
  background-color: ${Theme.global.colors["brand"]};
  color: ${Theme.global.colors["white"]};
`;
const EmailVerify = (props) => {
    const [userInfo, setUserInfo] = useState("");
    const [dialogState, setDialogState] = useState({
        show: false,
        message: "",
        type: "",
    });
    const [
        userEmailForReset,
        { data: networkCallResponseData, error: networkCallErrorResponse },
    ] = useMutation(Mutation.USER_EMAIL_FOR_RESET, getEmailForReset(userInfo));
    const onSubmit = () => {
        if (userInfo) {
            setDialogState({
                show: true,
                message: Errors.PLEASE_WAIT,
                type: "LOADING",
            });

            userEmailForReset();
        } else {
            setDialogState({
                show: true,
                message: Errors.ENTER_EMAIL,
                type: "FAILED",
            });
        }
    };
    useEffect(() => {
        if (networkCallResponseData) {
            setDialogState({
                show: true,
                message: Errors.CHECK_EMAIL,
                type: "SUCCESS",
            });
        }
        if (networkCallErrorResponse) {
            setDialogState({
                show: true,
                message: Errors.USER_NOT_FOUND,
                type: "FAILED",
            });
        }
    }, [
        networkCallResponseData,
        networkCallErrorResponse
    ]);
    const navToSignUpPage = () => {
        let path;
        path = `/signup`;
        props.history.push(path);
    };
    const handleCloseAlertDialog = () => {
        setDialogState({
            ...dialogState,
            show: false,
            message: "",
        });
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <React.Fragment>
                    {dialogState.show && (
                        <ShowDialog
                            title={dialogState.message}
                            onClose={handleCloseAlertDialog}
                            type={dialogState.type}
                        />
                    )}
                    <RootContainer elevation={"medium"}>
                        <Box align={"center"}>
                            <Heading
                                color={Theme.global.colors.textBlack}
                                level={2}
                                margin={{
                                    bottom: "xsmall",
                                }}
                            >
                                Did you forget your password?
                            </Heading>
                            <Text
                                style={{ display: "block" }}
                                color={Theme.global.colors.textBlack}
                            >
                                Enetr your email you're using for your accout below
                            </Text>
                            <Text color={Theme.global.colors.textBlack}>
                                and we will sent you password reset link
                            </Text>
                        </Box>
                        <InformationFileds>
                            <InputContainer margin={{ top: size }} size={size}>
                                <EmailField
                                    size={size}
                                    value={userInfo}
                                    onValueChange={(event) => {
                                        setUserInfo(event);
                                    }}
                                />
                                <EmailButton
                                    ariaLabel="click on sign button"
                                    tabIndex="0"
                                    role="link"
                                    icon={<UserCustom color={Theme.global.colors.white}/>}
                                    alignSelf={"left"}
                                    label="Request reset link"
                                    onClick={() => {
                                        onSubmit();
                                    }}
                                />
                                <Box align={"start"}>
                                    <Text
                                        style={{ fontSize: "17px" }}
                                        color={Theme.global.colors.grey}
                                        margin={{
                                            top: "0.9rem",
                                            bottom: "xsmall",
                                        }}
                                    >
                                        You don't have an account ?
                                        <Anchor
                                            label=" Sign up"
                                            onClick={() => {
                                                navToSignUpPage();
                                            }}
                                        />
                                    </Text>
                                </Box>
                            </InputContainer>
                        </InformationFileds>
                    </RootContainer>
                </React.Fragment>
            )}
        </ResponsiveContext.Consumer>
    );
};
export default EmailVerify;
