import React from "react";
import styled from "styled-components";
import { Theme } from "../../theme";
import { Box, Image, Text } from "grommet";

const RootContainer = styled(Box)`
  display: flex;
  border-radius: 5px;
  border-color: ${Theme.global.colors.lightGrey};
  padding: 1rem;
  flex-direction: row;
  background-color: white;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    .contributor-item {
      width: 100% !important;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;
    }
  }
`;

const UserImage = styled(Image)`
  max-width: 3rem;
  min-height: 3rem;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
`;

const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const ContributorsCard = (props) => {
    return (
        <RootContainer
            ariaLabel={"contributorsCard"}
            tabIndex="1"
            role="link"
            round="0.25rem"
            elevation="medium"
            align={"center"}
            background={Theme.global.colors.white}
        >
            <TextWrapper
                className={"contributor-item"}
                style={{ alignItems: "center", width: "50%", height: "100%" }}
            >
                <Box>
                    <UserImage fit="cover" src={props.image} margin={{ right: "1rem" }}/>
                </Box>
                <Box alignSelf={"start"} justify={"center"}>
                    <Text weight={"bold"} size={props.size}>
                        {props.name}
                    </Text>
                    <Text
                        style={{
                            color: Theme.global.colors.grey,
                            fontSize: "0.8rem",
                            alignItems: "center",
                        }}
                        size={"xsmall"}
                    >
                        {props.city}
                    </Text>
                </Box>
            </TextWrapper>
            <Box
                className={"contributor-item"}
                style={{ width: "25%", height: "100%" }}
                justify={"center"}
            >
                <Text weight={"bold"} size={props.size}>
                    {props.totalObservations}
                </Text>
                <Text
                    style={{
                        color: Theme.global.colors.grey,
                        fontSize: "0.8rem",
                        alignItems: "center",
                    }}
                    size={"xsmall"}
                >
                    Observations
                </Text>
            </Box>
            <Box
                className={"contributor-item"}
                align={"center"}
                style={{ width: "25%", height: "100%" }}
                justify={"center"}
            >
                <Text weight={"bold"} size={props.size}>
                    +{props.currMonthObservations}
                </Text>
                <Text
                    style={{
                        color: Theme.global.colors.grey,
                        fontSize: "0.8rem",
                        alignItems: "center",
                    }}
                    size={"xsmall"}
                >
                    This month
                </Text>
            </Box>
        </RootContainer>
    );
};

export default ContributorsCard;
