import React, { Component } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Theme } from "../../theme";
import { Circle, GeoJSON, LayersControl, Map, Marker, TileLayer, } from "react-leaflet";
import StateGeoJson from "../../helpers/GeoJSONs/State.json";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

class PickLocationDialog extends Component {
    constructor(props) {
        super(props);

        let lat = 0;
        let lng = 0;
        if (this.props.lat && this.props.lng) {
            lat = this.props.lat;
            lng = this.props.lng;
        }
        let zoom = this.props.zoom;
        if (this.props.isViewOnly && this.props.isEndangered) {
            zoom = 11;
        }
        this.state = {
            zoom: zoom,
            currentPos: { lat: lat, lng: lng },
            layerData: StateGeoJson,
        };
    }

    drawLayer = () => {
        if (this.state.layerData) {
            let data = this.state.layerData;
            data.features.forEach((x, i) => {
                data.features[i].properties.stroke = Theme.global.colors.lightGreen;
            });
            this.setState({ layerData: data });
        }
    };

    addMarker = (e) => {
        if (!this.props.isViewOnly) {
            this.setState({ currentPos: e.latlng });
            this.props.onLatLngPick(e.latlng.lat, e.latlng.lng);
        }
    };

    componentDidUpdate() {
        if (
            this.props.lat &&
            this.props.lng &&
            (this.props.lat !== this.state.currentPos.lat ||
                this.props.lng !== this.state.currentPos.lng)
        ) {
            this.setState({
                currentPos: { lat: this.props.lat, lng: this.props.lng },
                zoom: this.props.zoom,
            });
        }
    }

    render() {
        return (
            <Map
                center={
                    this.state.currentPos.lat === 0 || this.state.currentPos.lng === 0
                        ? {
                            lat: 43.01519122050245,
                            lng: -75.27832031250001,
                        }
                        : this.state.currentPos
                }
                onZoomEnd={(obj) => {
                    obj?.target?._zoom && this.setState({ zoom: obj.target._zoom });
                }}
                onClick={this.addMarker}
                zoom={this.state.zoom}
                scrollWheelZoom={true}
                zoomControl={true}
                dragging={true}
                doubleClickZoom={false}
                style={{ height: this.props.height, marginBottom: "1rem", zIndex: "1" }}
            >
                <LayersControl position="topleft">
                    <LayersControl.BaseLayer name="Satellite">
                        <TileLayer
                            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked name="Street">
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
                            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>
                {this.state.currentPos &&
                this.state.currentPos.lat !== 0 &&
                this.state.currentPos.lng !== 0 &&
                (this.props.isViewOnly && this.props.isEndangered ? (
                    <Circle
                        center={{
                            lat: this.state.currentPos.lat,
                            lng: this.state.currentPos.lng,
                        }}
                        fillOpacity={0.1}
                        fillColor="blue"
                        radius={10000}
                    />
                ) : (
                    <Marker position={this.state.currentPos}/>
                ))}

                {!this.props.isViewOnly && (
                    <GeoJSON
                        data={this.state.layerData}
                        weight={1}
                        style={(feature) => {
                            return {
                                color: Theme.global.colors.brand,
                                fillOpacity: feature.properties["fill-opacity"],
                            };
                        }}
                    />
                )}
            </Map>
        );
    }
}

export default PickLocationDialog;
