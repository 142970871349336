import { getToken } from "../../helpers";
import ApolloClient from "apollo-boost";
import resolvers from "../resolvers";

const client = new ApolloClient({
    request: (operation) => {
        const token = getToken();
        operation.setContext({
            uri: process.env.REACT_APP_URL,
            headers: {
                authorization: token ? `Bearer ${token}` : "",
                clientType:
                    process.env.REACT_APP_TYPE_CODE === "2" ? "AMPHREP" : "MAMMALS",
            },
        });
    },
    resolvers,
});

client.cache.writeData({
    data: {
        selectedZoneData: {
            name: "",
            climate: "",
            landscape: "",
            location: "",
            __typename: "selectedZoneData",
        },
    },
});

const clearClientCache = () => {
    client.cache.reset();
};

export { client, clearClientCache };
