import React from "react";
import { Anchor, Box, ResponsiveContext, Text } from "grommet";
import styled from "styled-components";
import { Theme } from "../theme";

const ContactPageRootContainer = styled(Box)`
  background: ${Theme.global.colors.white};
`;

const Contact = (props) => {
    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <ContactPageRootContainer
                    elevation="medium"
                    round="0.25rem"
                    margin={{
                        top: size === "small" ? "small" : "medium",
                        bottom: size === "small" ? "small" : "medium",
                    }}
                    pad={size === "small" ? "1.5rem" : "3rem"}
                    direction="column"
                    gap="small"
                    size={size}
                >
                    <Text>
                        Questions? Contact the current project manager <Anchor label="here"
                                                                               href="mailto:nyms@esf.edu"/> or visit us
                        at the <Anchor label="Roosevelt Wild Life Station"
                                       href="https://www.rooseveltwildlife.org/new-york-mammal-survey"
                                       target="_blank"/> to learn more about the current NYMS personnel.
                    </Text>

                </ContactPageRootContainer>

            )}
        </ResponsiveContext.Consumer>
    )
}

export default Contact;