import { gql } from "apollo-boost";

//SERVER SIDE MUTAIONS BELOW
const CREATE_USER_MUTATION = gql`
  mutation createUser($createUserInput: CreateUserInput!) {
    createUser(data: $createUserInput) {
      token
      user {
        id
        bio
        name
        city
        type
        email
        state
        image
        privacy
        education
      }
    }
  }
`;

const USER_LOGIN_MUTATION = gql`
  mutation userLogin($loginUserInput: LoginUserInput!) {
    login(data: $loginUserInput) {
      token
      user {
        id
        name
        email
        type
        password
        education
        city
        state
        image
        bio
        privacy
      }
    }
  }
`;

const CREATE_SPECIE_MUTATION = gql`
  mutation createSpecieMutation($specieCreateInput: SpecieCreateInput!) {
    createSpecie(data: $specieCreateInput) {
      id
    }
  }
`;
const UPDATE_USER_PROFILE = gql`
  mutation updateUser($updateUserInput: UserUpdateInput!) {
    updateUser(data: $updateUserInput) {
      id
      bio
      name
      city
      type
      email
      state
      image
      privacy
      education
    }
  }
`;

const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPasswordWithToken(token: $token, password: $password)
  }
`;

const CREATE_OBSERVATION_MUTATION = gql`
  mutation createObservation($observationCreateInput: ObservationCreateInput!) {
    createObservation(data: $observationCreateInput) {
      id
    }
  }
`;

const DELETE_OBSERVATION_MUTATION = gql`
  mutation deleteObservation(
    $observationWhereUniqueInput: ObservationWhereUniqueInput!
  ) {
    deleteObservation(where: $observationWhereUniqueInput) {
      id
    }
  }
`;

const USER_EMAIL_FOR_RESET = gql`
  mutation UserEmail($email: String!) {
    passwordResetTokenRequest(email: $email)
  }
`;

const UPDATE_OBSERVATION_MUTATION = gql`
  mutation updateObservation(
    $observationUpdateInput: ObservationUpdateInput!
    $observationId: ObservationWhereUniqueInput!
  ) {
    updateObservation(data: $observationUpdateInput, where: $observationId) {
      id
    }
  }
`;

const CREATE_JOB_RECORD = gql`
  mutation createJobRecord($jobRecordCreateInput: JobRecordCreateInput!) {
    createJobRecord(data: $jobRecordCreateInput) {
      id
      type
      status
      externalResLink
    }
  }
`;

//CLIENT SIDE MUTATIONS BELOW
const CLIENT_SET_SELECTED_ZONE_MUTATION = gql`
  mutation setSelectedZone($selectedZoneData: SelectedZoneData!) {
    setSelectedZone(selectedZoneData: $selectedZoneData) @client
  }
`;

export default {
    //SERVER SIDE
    CREATE_SPECIE_MUTATION,
    USER_LOGIN_MUTATION,
    CREATE_USER_MUTATION,
    UPDATE_USER_PROFILE,
    CREATE_OBSERVATION_MUTATION,
    DELETE_OBSERVATION_MUTATION,
    USER_EMAIL_FOR_RESET,
    UPDATE_OBSERVATION_MUTATION,
    RESET_PASSWORD_MUTATION,
    CREATE_JOB_RECORD,
    //CLIENT SIDE
    CLIENT_SET_SELECTED_ZONE_MUTATION,
};
