import React from "react";
import { Box, Text } from "grommet";
import styled from "styled-components";
import { Theme } from "../../theme";

const DesignColorContainer = styled.div`
  margin-top: 0.7rem;
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: row;
  }
`;

const ColorContainerStyle = styled(Box)`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ColorContainer = ({
                            minZoneCount = 0,
                            maxZoneCount = 0,
                            colorRanges = [],
                            ...props
                        }) => {
    const formatIntervalString = (interval) => {
        if (interval === maxZoneCount || interval === minZoneCount) {
            return "<= " + interval.toString();
        } else {
            return "< " + interval.toString();
        }
    };

    const InfoView = () => (
        <Box
            elevation='medium'
            pad='small'
            margin={"small"}
            style={{ borderRadius: "0.5rem" }}
            background={Theme.global.colors.white}
            direction='column'
        >
            <Text
                size={"xsmall"}
                weight={"bold"}
                style={{
                    whiteSpace: "pre-line",
                    lineHeight: "1.2",
                    marginBottom: "0.5rem",
                }}
            >
                {props.titleFlag ? "Order richness" : "Observations"}
            </Text>
            <DesignColorContainer direction='column' gap={"medium"}>
                {colorRanges.map((interval, index) => {
                    return (
                        <ColorContainerStyle
                            direction='row'
                            align='center'
                            margin={{ right: "0.5rem" }}
                            key={index}
                        >
                            <div
                                style={{
                                    width: "0.8rem",
                                    height: "0.8rem",
                                    marginRight: "0.3rem",
                                    backgroundColor: interval.color,
                                }}
                            />
                            <Text size={"small"}>{formatIntervalString(interval.val)}</Text>
                        </ColorContainerStyle>
                    );
                })}
            </DesignColorContainer>
        </Box>
    );
    return <div>{props.observationsCount && <InfoView/>}</div>;
};
export default ColorContainer;
