import React, { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import styled from 'styled-components';

const StyledList = styled(List)`
  &::-webkit-scrollbar {
      display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export default function InfiniteLoaderList({
                                               // Are there more items to load?
                                               // (This information comes from the most recent API request.)
                                               hasNextPage,
                                               // Are we currently loading a page of items?
                                               // (This may be an in-flight flag in your Redux store for example.)
                                               isNextPageLoading,

                                               // Array of items loaded so far.
                                               items,
                                               renderItem,
                                               // Callback function responsible for loading the next page of items.
                                               loadNextPage,
                                           }) {
    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = hasNextPage ? items.length + 1 : items.length;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = isNextPageLoading ? () => {
    } : loadNextPage;

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = (index) => !hasNextPage || index < items.length;

    // Render an item or a loading indicator.
    const Item = memo(({ index, style }) => {
        const item = items[index];
        return (
            <div style={style}>
                {!isItemLoaded(index) ? <Skeleton count={5}/> : renderItem(item)}
            </div>
        );
    });

    return (
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
        >
            {({ onItemsRendered, ref }) => (
                <StyledList
                    overscanCount={10}
                    className="List"
                    height={350}
                    itemCount={itemCount}
                    itemSize={100}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    width={300}
                >
                    {Item}
                </StyledList>
            )}
        </InfiniteLoader>
    );
}
