const resolvers = {
    Mutation: {
        setSelectedZone: (_, args, { cache }) => {
            cache.writeData({
                data: {
                    selectedZoneData: {
                        name: args.selectedZoneData.name,
                        climate: args.selectedZoneData.climate,
                        landscape: args.selectedZoneData.landscape,
                        location: args.selectedZoneData.location,
                        __typename: "selectedZoneData"
                    }
                }
            });
            return null;
        }
    }
};
export default resolvers;
