import React, { Component } from "react";
import { Box, Button, Text } from "grommet";
import styled from "styled-components";
import { Download, Theme } from "../../theme";
import Dropzone from "react-dropzone";

const ImageSelector = styled(Box)`
  box-sizing: border-box;
  height: 13.438rem;
  text-align: center;
  align-content: center;
  border: 1px dashed #c9ced8;
  padding-top: 3rem;
  ::placeholder {
    font-weight: lighter;
  }
`;

const acceptedImagesFileType =
    "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
const acceptedCSVFileType = ".csv";
let acceptedFileTypes = "";
const imageMaxSize = 1000000000;

export default class DragAndDropComponent extends Component {
    verifyFile = (files) => {
        const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
            return item.trim();
        });
        if (files && files.length > 0) {
            const currentFile = files[0];
            const currentFileType = currentFile.type;
            const currentFileSize = currentFile.size;
            if (currentFileSize > imageMaxSize) {
                alert(
                    "This file is not allowed. " + currentFileSize + " size is too large"
                );
                return false;
            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                alert("This file is not allowed. Only images are allowed.");
                return false;
            }
            return true;
        }
    };

    handleOnClickBrowse = () => {
        document.getElementById("inputBtn").click();
    };

    handleDrop = (files) => {
        if (files && files.length > 0) {
            acceptedFileTypes =
                this.props.fileType === "image"
                    ? acceptedImagesFileType
                    : acceptedCSVFileType;
            if (this.props.fileType === "image" && this.verifyFile(files)) {
                const currentFile = files[0];
                this.props.onBrowse(currentFile);
            } else {
                this.props.onBrowse(files[0]);
            }
        }
    };

    render() {
        return (
            <div>
                <Dropzone
                    onDrop={this.handleDrop}
                    multiple={false}
                    accept={
                        this.props.fileType === "image"
                            ? acceptedImagesFileType
                            : acceptedCSVFileType
                    }
                >
                    {({ getRootProps }) => (
                        <ImageSelector direction="column" gap="small" {...getRootProps()}>
                            <div>
                                <Download color={Theme.global.colors.active}/>
                            </div>
                            <Text
                                size="small"
                                margin={{ "margin-top": "4rem" }}
                                color={Theme.global.colors.textBlack}
                            >
                                Drag and drop
                                <br/>
                                or
                            </Text>
                            <div>
                                <Button
                                    plain
                                    ariaLabel="Drag And Drop"
                                    tabIndex="1"
                                    role="link"
                                    style={{ fontSize: "1.1rem" }}
                                    margin={{ "margin-top": "2rem" }}
                                    color={Theme.global.colors.active}
                                    onClick={() => {
                                        this.handleOnClickBrowse();
                                    }}
                                >
                                    Browse
                                </Button>
                                <input
                                    id="inputBtn"
                                    type="file"
                                    multiple={false}
                                    value=""
                                    accept={
                                        this.props.fileType === "image"
                                            ? acceptedImagesFileType
                                            : acceptedCSVFileType
                                    }
                                    style={{ display: "none" }}
                                    onChange={(event) => {
                                        this.handleDrop(event.target.files);
                                    }}
                                />
                            </div>
                        </ImageSelector>
                    )}
                </Dropzone>
            </div>
        );
    }
}
