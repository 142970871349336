import React from "react";
import { isUserLogIn } from "../../helpers";
import { Route } from "react-router-dom";
import SignIn from "../../pages/SignIn";
import NotFound from "../../pages/NotFound";

const ProvideRoutes = ({ component, ...options }) => {
    const isAuthanticated = isUserLogIn() ? component : SignIn;
    const isAlreadyLoggedIn = isUserLogIn() ? NotFound : component;
    const isMammals = process.env.REACT_APP_TYPE_CODE == 1 ? component : NotFound;
    const getComponent = () => {
        if (options.path === "/bulkrecord") {
            return isAuthanticated;
        } else if (options.path === "/success") {
            return isAuthanticated;
        } else if (options.path === "/profile") {
            return isAuthanticated;
        } else if (options.path === "/profile/:id") {
            return isAuthanticated;
        } else if (options.path === "/signin") {
            return isAlreadyLoggedIn;
        } else if (options.path === "/signup") {
            return isAlreadyLoggedIn;
        } else if (options.path === "/record") {
            return isAuthanticated;
        } else if (options.path === "/contact") {
            return isMammals;
        } else {
            return component;
        }
    };
    return <Route {...options} component={getComponent()}/>;
};

export { ProvideRoutes };
