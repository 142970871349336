import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Button, Text } from "grommet";
import { deleteSession, getToken } from "../../helpers";
import { clearClientCache } from "../../apollo";
import styled from "styled-components";
import { AppContextConsumer } from "../../appContext";
import {
    Add,
    Brand1,
    Brand2,
    ChartIcon,
    HomeIcon,
    InfoIcon,
    SearchIcon,
    Theme,
    UploadIcon,
    UserIcon,
} from "../../theme";

const MenuWrapper = styled.div`
  min-height: 100vh;
  margin-left: 2.5rem;
  margin-top: 0.5rem;
  a {
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 1.5rem;
    text-decoration: none;
    margin-bottom: 1rem;
    color: ${Theme.global.colors.inactive};
    &.active {
      .icon {
        svg.home polygon,
        svg.guide path,
        svg.signin path,
        svg.signup path,
        svg.profile path,
        svg.signout path,
        svg.explore path,
        svg.about path {
          width: 24px;
          height: 24px;
          fill: ${Theme.global.colors.active};
        }
      }
    }
    &:hover,
    &:focus {
      .icon {
        svg.home polygon,
        svg.guide path,
        svg.signin path,
        svg.signup path,
        svg.profile path,
        svg.signout path,
        svg.explore path,
        svg.about path {
          fill: ${Theme.global.colors.active};
        }
      }
    }
    .icon {
      display: flex;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      svg.home polygon,
      svg.signin path,
      svg.signup path,
      svg.profile path,
      svg.signout path,
      svg.guide path,
      svg.guide path svg.explore path,
      svg.about path {
        fill: ${Theme.global.colors.inactive};
      }
    }
  }
`;

const Separator = styled.hr`
  display: block;
  height: 2px;
  border: 0;
  border-top: 1px solid ${Theme.global.colors.inactive};
  margin: 2rem 2.5rem 2rem -0.5rem;
  opacity: 0.25;
`;

const BrandLogoWrapper = styled.div`
  background-color: transparent;
  height: 4.3rem;
  width: 13.1rem;
  margin: 3rem 0;
  svg {
  }
`;

const pickIcon = (item) => {
    let Icon;
    item = item.replace("/", "");
    switch (item) {
        case "profile":
            Icon = UserIcon;
            break;
        case "signup":
            Icon = UserIcon;
            break;
        case "home":
            Icon = HomeIcon;
            break;
        case "explore":
            Icon = ChartIcon;
            break;
        case "guide":
            Icon = SearchIcon;
            break;
        case "about":
            Icon = InfoIcon;
            break;
        case "signin":
            Icon = UserIcon;
            break;
        case "signout":
            Icon = UserIcon;
            break;
        case "record":
            Icon = "none";
            break;
        case "bulkrecord":
            Icon = "none";
            break;
        default:
            Icon = HomeIcon;
    }
    return (
        <Icon
            aria-hidden={"true"}
            width={"20px"}
            height={"20px"}
            className={item === "" ? "home" : item}
        />
    );
};

class Menu extends Component {
    handleClick = (path) => {
        if (path) {
            this.props.history.push(path);
        } else {
            clearClientCache();
            deleteSession();
            this.props.history.push("/signin");
        }
    };

    renderMenuItem = (item, path) => {
        return (
            <NavLink to={path} exact={true} activeClassName="active">
                {<div className={"icon"}>{pickIcon(path)}</div>}
                <Text style={{ display: "flex", alignItems: "center" }}>{item}</Text>
            </NavLink>
        );
    };

    renderSeparator = () => {
        return <Separator/>;
    };

    render() {
        return (
            <AppContextConsumer>
                {(props) => {
                    return (
                        <MenuWrapper>
                            <BrandLogoWrapper
                                style={{ cursor: "pointer" }}
                                onClick={() => this.props.history.push("/")}
                            >
                                {props.isMammals ? (
                                    <Brand1/>
                                ) : (
                                    <Brand2 width={"201px"} height={"61"}/>
                                )}
                            </BrandLogoWrapper>
                            {this.renderMenuItem("Home", "/")}
                            {this.renderMenuItem("Explore", "/explore")}
                            {this.renderMenuItem("Guide", "/guide")}
                            {this.renderMenuItem("About", "/about")}
                            {this.renderSeparator()}
                            {getToken() ? (
                                <div>
                                    <Button
                                        label="Add records"
                                        icon={<Add color={Theme.global.colors.active}/>}
                                        style={{ color: Theme.global.colors.active, left: "1rem" }}
                                        margin={{ bottom: "1rem" }}
                                        onClick={() => this.handleClick("/record")}
                                    />
                                    <Button
                                        label="Bulk records"
                                        icon={<UploadIcon color={Theme.global.colors.active}/>}
                                        style={{
                                            color: Theme.global.colors.active,
                                            left: "2rem",
                                            bottom: "1rem",
                                        }}
                                        margin={{ bottom: "1rem" }}
                                        onClick={() => this.handleClick("/bulkrecord")}
                                    />
                                    {this.renderMenuItem("Profile", "/profile")}
                                    <div
                                        onClick={(e) => {
                                            this.handleClick(null);
                                        }}
                                    >
                                        {this.renderMenuItem("Sign out", "/signout")}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {/*{this.renderMenuItem("Sign in", "/signin")}*/}
                                    {/*{this.renderMenuItem("Sign up", "/signup")}*/}
                                </div>
                            )}
                        </MenuWrapper>
                    );
                }}
            </AppContextConsumer>
        );
    }
}

export default withRouter(Menu);
