import React, { useContext, useEffect, useState } from "react";
import { Alert } from "grommet-icons";
import styled from "styled-components";
import Loader from "../components/Loader";
import { isUserTypeModerator } from "../utils";
import { CheckMark, Theme } from "../theme";
import { Mutations, Queries } from "../apollo";
import { BULK_UPLOAD_JOB_ID } from "./../constants";
import { getUserId, UploadFileToS3 } from "../helpers";
import { createJobRecordInput } from "../apollo/mutations/Variables";
import DragAndDropComponent from "../components/DragAndDropComponent";
import { Anchor, Box, Heading, ResponsiveContext, Text } from "grommet";
import { useMutation, useQuery as useQueryApollo } from "@apollo/react-hooks";
import ExampleUpload from "../assets/documents/Example_Upload.xlsx"
import AppContext from "../appContext";

const BulkRecordPageRootContainer = styled(Box)`
  background: ${Theme.global.colors.white};
`;

const RecordForumHeading = styled(Heading)`
  font-size: 2.125rem;
  color: ${Theme.global.colors.textBlack};
  @media screen and (max-width: 1280px) {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 786px) {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
`;

const InfoDetailWrapper = styled(Box)`
  font-size: 1.0625rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 1280px) {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 786px) {
    margin-bottom: 1rem;
  }
`;


const BulkUpload = (props) => {
    const context = useContext(AppContext);
    const [status, setStatus] = useState({
        type: "IN_PROGRESS",
        message: "Checking status...",
    });
    const [isFirstTime, setIsFirstTime] = useState(true);

    const [isFile, setIsFile] = useState(false);
    const [uploadedFileData, setUploadedFileData] = useState({
        key: "",
        url: "",
    });

    const [jobRecordData, setJobRecordData] = useState({
        id: localStorage.getItem(BULK_UPLOAD_JOB_ID),
        status: "IN_PROGRESS",
    });

    const useQuery = (query, options = {}) => {
        if (options.pollInterval) {
            options.pollInterval = options.skip ? 0 : options.pollInterval;
        }

        return useQueryApollo(query, options);
    };
    const { data: getJobsResponseData, error: getJobsResponseError } = useQuery(
        Queries.GET_MY_JOBS,
        {
            pollInterval: 10000,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only",
            skip: !isFirstTime && !jobRecordData.id,
        }
    );

    const [
        createJobRecord,
        { data: createJobRecordResponseData, error: createJobRecordErrorResponse },
    ] = useMutation(
        Mutations.CREATE_JOB_RECORD,
        createJobRecordInput(uploadedFileData.url)
    );
    useEffect(() => {
        if (getJobsResponseData) {
            if (isFirstTime) setIsFirstTime(false);
            if (getJobsResponseData?.me?.jobRecordToUser?.length) {
                decideActionOnResponse(
                    getJobsResponseData?.me?.jobRecordToUser[0].id,
                    getJobsResponseData?.me?.jobRecordToUser[0].status
                );
            } else {
                decideActionOnResponse(
                    jobRecordData.id ? jobRecordData.id : "",
                    jobRecordData.id ? "SUCCESS" : "IN_PROGRESS"
                );
            }
        }

        if (createJobRecordResponseData) {
            decideActionOnResponse(
                createJobRecordResponseData.createJobRecord.id,
                createJobRecordResponseData.createJobRecord.status
            );
        }
        if (createJobRecordErrorResponse || getJobsResponseError) {
            decideActionOnResponse("", "FAILED");
        }
        // eslint-disable-next-line
    }, [
        createJobRecordResponseData,
        createJobRecordErrorResponse,
        getJobsResponseData,
        getJobsResponseError,
    ]);

    const decideActionOnResponse = (jobId, jobStatus) => {
        localStorage.setItem(BULK_UPLOAD_JOB_ID, jobId);
        setJobRecordData({
            id: jobId,
            status: jobStatus,
        });
        setIsFile(jobStatus === "IN_PRGRESS" ? true : false);
        let statusMsg = "";
        switch (jobStatus) {
            case "IN_PROGRESS":
                statusMsg = `Uploading Records. Your Job Id is: ${jobId}`;
                break;
            case "SUCCESS":
                bulkUploadSuccess();
                break;
            case "FAILED":
                statusMsg = "Failed to Upload Records.";
                break;
            default:
                jobStatus = "";
                statusMsg = "";
        }
        setStatus({
            type: jobStatus,
            message: statusMsg,
        });
    };

    const bulkUploadSuccess = () => {
        setIsFile(false);
        let path = `/success`;
        props.history.push(path);
    };

    const renameFile = (file) => {
        const newName = `bulk/${getUserId()}/${new Date().getTime()}.csv`;
        return new File([file], newName, { type: file.type });
    };

    const onBrowseClick = async (file) => {
        if (file) {
            setIsFile(true);
            setStatus({
                type: "IN_PROGRESS",
                message: "Uploading file...",
            });
            try {
                const callbackData = await UploadFileToS3(renameFile(file));
                setUploadedFileData({
                    key: callbackData.key,
                    url: callbackData.location,
                });
                createJobRecord();
            } catch (error) {
                setStatus({
                    type: "FAILED",
                    message: "Failed to upload file.",
                });
            }
        }
    };

    const _isDragNDrop = () => {
        return isFile || jobRecordData.id ? (
            <Box direction="column" align="center" gap={"small"}>
                {status.type === "IN_PROGRESS" && <Loader/>}
                {status.type === "SUCCESS" && (
                    <CheckMark color={Theme.global.colors.green}/>
                )}
                {status.type === "FAILED" && <Alert color={Theme.global.colors.red}/>}
                <Text color={Theme.global.colors.textBlack} size={"medium"}>
                    {status.message}
                </Text>

                {status.type !== "IN_PROGRESS" && (
                    <Text
                        color={Theme.global.colors.active}
                        onClick={() => {
                            setIsFile(false);
                        }}
                        style={{ cursor: "pointer" }}
                        size={"medium"}
                    >
                        Back
                    </Text>
                )}
            </Box>
        ) : (
            <DragAndDropComponent onBrowse={onBrowseClick} fileType="csv"/>
        );
    };
    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <BulkRecordPageRootContainer
                    elevation="medium"
                    round="0.25rem"
                    margin={{
                        top: size === "small" ? "small" : "medium",
                        bottom: size === "small" ? "small" : "medium",
                    }}
                    pad={size === "small" ? "1.5rem" : "3rem"}
                    direction="column"
                    gap="small"
                    size={size}
                >
                    <RecordForumHeading>Bulk upload</RecordForumHeading>
                    {isUserTypeModerator() ? (
                        <div>
                            <InfoDetailWrapper>
                                <Text color={Theme.global.colors.grey}>
                                    Bulk uploads must be in the specific format. For reference, please
                                    download the <Anchor href={ExampleUpload}
                                                         download label="example csv"/>
                                </Text>
                            </InfoDetailWrapper>
                            <Text
                                color={Theme.global.colors.textBlack}
                                margin={{ bottom: "0.75rem" }}
                            >
                                CSV file
                            </Text>
                            {_isDragNDrop()}
                        </div>) : (
                        <Text weight={"normal"} style={{ display: "block", fontSize: "18px" }}>Have a lot of
                            data to
                            share with {context.isMammals ? "NYMS" : "NYARS"}? Contact our project manager <Anchor
                                href={context.isMammals ? "mailto:NYMS@esf.edu" : "mailto:nyherps@gmail.com"}
                                target="_blank" label="here"/> who can
                            upload
                            bulk records from your spreadsheets (see format <Anchor href={ExampleUpload}
                                                                                    label="example"
                                                                                    download/>).</Text>)}

                </BulkRecordPageRootContainer>
            )}
        </ResponsiveContext.Consumer>
    );
};

export default BulkUpload;
