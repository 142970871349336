import React, { useEffect, useState } from "react";
import { Theme } from "../../theme";
import styled from "styled-components";
import { capitalizeFirstLetter, getFirstImageFromList } from "../../utils";
import { Box, Heading, Image as GrommetImage, Text } from "grommet";

const GuideItemWrapper = styled(Box)`
  min-height: 28rem;
  min-width: ${(props) => props.width};
  position: relative;
  cursor: pointer;
  display: flex;
  flex: 1;
`;

const ImageContainer = styled(Box)`
  max-height: 20rem;
  flex-direction: row;
  img {
    object-fit: ${(props) => (props.aspect > 1.75 ? "contain" : "cover")};
  }
`;

const TagText = styled(Text)`
  position: absolute;
  margin: 1rem 0 0 1rem;
  padding: 0rem 1rem 0rem 1rem;
  color: white;
  font-size: 0.7rem;
  text-align: center;
  border-radius: 0.3rem;
  background: ${Theme.global.colors.active};
`;

const GuideItem = (props) => {
    const [aspect, setAspect] = useState(1);
    const { gridArea, specieItem, onItemClick } = props;

    const url = getFirstImageFromList(specieItem?.images);

    useEffect(() => {
        if (!url) return;
        const img = new Image();
        img.onload = () => {
            setAspect(img.width / img.height);
        };
        img.src = url;
    }, [url]);

    return (
        <GuideItemWrapper
            ariaLabel={capitalizeFirstLetter(specieItem.commonName)}
            tabIndex="0"
            width={"100%"}
            role="link"
            onClick={onItemClick}
            round="0.25rem"
            elevation="medium"
            gridArea={gridArea}
            background={Theme.global.colors.white}
            style={props.minWidth ? { minWidth: props.minWidth } : null}
        >
            <TagText>
                {`${capitalizeFirstLetter(specieItem.order.commonName)} `}
                <b>:</b>
                {` ${capitalizeFirstLetter(specieItem.family.commonName)}`}
            </TagText>
            <ImageContainer aspect={aspect} round="small">
                <GrommetImage
                    style={{
                        borderTopLeftRadius: "0.25rem",
                        borderTopRightRadius: "0.25rem",
                        height: "20rem",
                    }}
                    fill="horizontal"
                    src={url}
                />
            </ImageContainer>

            <Box pad="medium">
                <Heading
                    style={{
                        fontSize: "1.375rem",
                        color: Theme.global.colors.textBlack,
                    }}
                    margin={{
                        top: "none",
                        bottom: "small",
                    }}
                    level={3}
                >
                    {specieItem.commonName}
                </Heading>
                <Text
                    color={Theme.global.colors.textBlack}
                    style={{ fontStyle: "italic" }}
                >
                    {capitalizeFirstLetter(specieItem.binomialName)}
                </Text>
            </Box>
        </GuideItemWrapper>
    );
};

export default GuideItem;
