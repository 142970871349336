import React, { Fragment, useContext } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { deleteSession, getToken } from "../../helpers";
import { Box, Button, Layer, Text } from "grommet";
import { clearClientCache } from "../../apollo";
import { isUserTypeModerator } from "../../utils";
import AppContext from "../../appContext";
import {
    Add,
    Brand1,
    Brand2,
    ChartIcon,
    CloseIcon,
    HomeIcon,
    InfoIcon,
    MenuIcon,
    SearchIcon,
    Theme,
    UploadIcon,
    UserIcon,
} from "../../theme";
import styled from "styled-components";

const MenuLayer = styled(Layer)`
  background-color: ${Theme.global.colors.lightWhite};
  min-width: 12.5rem;
  overflow-x: scroll;

  .close-link {
    cursor: pointer;
  }
  a {
    font-size: 1rem;
    display: flex;
    text-align: center;
    text-decoration: none;
    margin-bottom: 1em;
    width: 100%;
    color: ${Theme.global.colors.inactive};
    &.active {
      .icon {
        svg.home polygon,
        svg.guide path,
        svg.explore path,
        svg.signin path,
        svg.signup path,
        svg.about path {
          fill: ${Theme.global.colors.active};
        }
      }
    }
    &:hover,
    &:focus {
      .icon {
        svg.home polygon,
        svg.guide path,
        svg.signin path,
        svg.signup path,
        svg.explore path,
        svg.about path {
          fill: ${Theme.global.colors.active};
        }
      }
    }
    .icon {
      display: flex;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      svg.home polygon,
      svg.guide path,
      svg.signin path,
      svg.signup path,
      svg.explore path,
      svg.about path {
        fill: ${Theme.global.colors.inactive};
      }
    }
  }
`;
const Separator = styled.hr`
  display: block;
  height: 2px;
  width: 12.5rem;
  border: 0;
  border-top: 1px solid ${Theme.global.colors.inactive};
  margin: 2rem 2rem 2rem 0rem;
  opacity: 0.25;
`;

const MenuWrapper = styled.div`
  height: 3.7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .menu-toggle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }

  .brand-wrapper {
    svg {
      height: 3.5rem;
    }
  }
`;

const pickIcon = (item) => {
    let Icon;
    item = item.replace("/", "");
    switch (item) {
        case "profile":
            Icon = UserIcon;
            break;
        case "signup":
            Icon = UserIcon;
            break;
        case "home":
            Icon = HomeIcon;
            break;
        case "explore":
            Icon = ChartIcon;
            break;
        case "guide":
            Icon = SearchIcon;
            break;
        case "about":
            Icon = InfoIcon;
            break;
        case "signin":
            Icon = UserIcon;
            break;
        case "signout":
            Icon = UserIcon;
            break;
        case "record":
            Icon = "none";
            break;
        case "bulkrecord":
            Icon = "none";
            break;
        default:
            Icon = HomeIcon;
    }
    return (
        <Icon
            width={"18px"}
            height={"18px"}
            aria-label="item"
            className={item === "" ? "home" : item}
        />
    );
};

const renderMenuItem = (item, path, setOpen) => {
    return (
        <NavLink
            to={path}
            exact={true}
            activeClassName="active"
            onClick={() => setOpen(false)}
        >
            {<div className={"icon"}>{pickIcon(path)}</div>}
            <Text size={"1.1rem"} style={{ display: "flex", alignItems: "center" }}>
                {item}
            </Text>
        </NavLink>
    );
};

const renderSeparator = () => {
    return <Separator/>;
};

const MobileMenu = (props) => {
    const context = useContext(AppContext);
    const handleClick = (path) => {
        if (path) {
            props.history.push(path);
        } else {
            clearClientCache();
            deleteSession();
            props.history.push("/signin");
        }
    };
    const [open, setOpen] = React.useState(false);
    return (
        <Fragment>
            <MenuWrapper>
                <div className={"brand-wrapper"}>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => props.history.push("/")}
                    >
                        {context.isMammals ? (
                            <Brand1/>
                        ) : (
                            <Brand2 width={"201px"} height={"61"}/>
                        )}
                    </div>
                </div>
                <div
                    className={"menu-toggle"}
                    onClick={() => setOpen(true)}
                    role="link"
                >
                    <MenuIcon aria-label={"toggle menu"}/>
                </div>
            </MenuWrapper>
            {open && (
                <MenuLayer
                    position="right"
                    full="vertical"
                    modal
                    onClickOutside={() => setOpen(false)}
                    onEsc={() => setOpen(false)}
                    responsive={false}
                >
                    <Box
                        width={"4rem"}
                        height={"4rem"}
                        align={"end"}
                        pad={"medium"}
                        onClick={() => setOpen(false)}
                        role="link"
                        className={"close-link"}
                        justify={"center"}
                    >
                        <CloseIcon/>
                    </Box>
                    <Box pad={{ top: "2rem", left: "2rem", right: "4rem" }}>
                        {renderMenuItem("Home", "/", setOpen)}
                        {renderMenuItem("Explore", "/explore", setOpen)}
                        {renderMenuItem("Guide", "/guide", setOpen)}
                        {renderMenuItem("About", "/about", setOpen)}
                        {renderSeparator()}
                        {getToken() ? (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Button
                                    label="Add records"
                                    icon={<Add color={Theme.global.colors.active}/>}
                                    style={{
                                        color: Theme.global.colors.active,
                                        width: "12rem",
                                    }}
                                    margin={{ bottom: "1rem" }}
                                    onClick={(e) => {
                                        handleClick("/record");
                                        setOpen(false);
                                    }}
                                />
                                {isUserTypeModerator() && (
                                    <Button
                                        label="Bulk records"
                                        icon={<UploadIcon color={Theme.global.colors.active}/>}
                                        style={{
                                            color: Theme.global.colors.active,
                                            width: "12rem",
                                        }}
                                        margin={{ bottom: "1rem" }}
                                        onClick={(e) => {
                                            handleClick("/bulkrecord");
                                            setOpen(false);
                                        }}
                                    />
                                )}
                                {renderMenuItem("Profile", "/profile", setOpen)}
                                <div
                                    onClick={(e) => {
                                        handleClick(null);
                                    }}
                                >
                                    {renderMenuItem("Sign out", "/signout", setOpen)}
                                </div>
                            </div>
                        ) : (
                            <div>
                                {/*{renderMenuItem("Sign in", "/signin", setOpen)}*/}
                                {/*{renderMenuItem("Sign up", "/signup", setOpen)}*/}
                            </div>
                        )}
                    </Box>
                </MenuLayer>
            )}
        </Fragment>
    );
};

export default withRouter(MobileMenu);
