import { USER_SESSION_KEY, USER_TOKEN_KEY } from "../constants";

function saveToken(token) {
    localStorage.setItem(USER_TOKEN_KEY, token);
    return true;
}

function getToken() {
    return localStorage.getItem(USER_TOKEN_KEY);
}

function saveUser(user) {
    localStorage.setItem(USER_SESSION_KEY, JSON.stringify(user));
    return true;
}

function getUser() {
    const user = localStorage.getItem(USER_SESSION_KEY);
    return user ? JSON.parse(user) : undefined;
}

function getUserId() {
    const user = localStorage.getItem(USER_SESSION_KEY);
    return user ? JSON.parse(user).id : "";
}

function getUserName() {
    const user = localStorage.getItem(USER_SESSION_KEY);
    return user ? JSON.parse(user).name : "";
}

function getUserType() {
    const user = localStorage.getItem(USER_SESSION_KEY);
    return user ? JSON.parse(user).type : "";
}

function isUserLogIn() {
    const user = localStorage.getItem(USER_SESSION_KEY);
    return user ? true : false;
}

function deleteToken() {
    localStorage.removeItem(USER_TOKEN_KEY);
    return true;
}

function deleteUser() {
    localStorage.removeItem(USER_SESSION_KEY);
    return true;
}

function deleteSession() {
    localStorage.removeItem(USER_TOKEN_KEY);
    localStorage.removeItem(USER_SESSION_KEY);
    return true;
}

export {
    saveToken,
    getToken,
    saveUser,
    getUser,
    getUserId,
    getUserType,
    getUserName,
    isUserLogIn,
    deleteToken,
    deleteUser,
    deleteSession
};
