import React, { useEffect, useState } from "react";
import { Theme } from "../../theme";
import styled from "styled-components";
import { Queries } from "../../apollo";
import Loader from "../../components/Loader";
import { isNotUndefined } from "../../helpers";
import { useQuery } from "@apollo/react-hooks";
import { DUMMY_PIC_URL, INFO_TEXTS } from "../../constants/Constants";
import MapComponent from "../../components/MapComponent";
import PieChartGraph from "../../components/PieChartGraph";
import ObservationCard from "../../components/ObservationCard";
import { capitalizeFirstLetter, isUserTypeModerator, showItemLoader } from '../../utils';
import FieldTitleWithIconComponent from "../../components/FieldTitleWithIconComponent";
import { Box, Grid, Heading, Image, InfiniteScroll, ResponsiveContext, Text, } from "grommet";

const RootContainer = styled(Box)``;
const StatsDetailContainer = styled(Box)`
  width: 100%;
`;

const GraphDetailMapContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;

const GraphDetailContainer = styled(Box)`
  width: 100%;
  @media screen and (max-width: 1150px) {
    display: block;
    margin-bottom: 1rem;
  }
`;

const MapContainer = styled.div`
  // width: ${(props) => (props.isZoneData ? "80%" : "100%")};
  width: 100%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ObservationsListContainer = styled(Box)`
  width: 100%;
`;

const GraphAndDetailContainer = styled(Box)`
  display: inline-flex;
`;

const ImageContainer = styled(Image)`
  border-radius: 50%;
  height: 3.75rem;
  width: 3.75rem;
  min-height: 3.75rem;
  min-width: 3.75rem;
  max-height: 3.75rem;
  max-width: 3.75rem;
`;

const ObservationTabDetailView = (props) => {
    const { data, refetch } = useQuery(Queries.CLIENT_GET_SELECTED_ZONE_QUERY);

    const init = () => {
        return {
            climate: "",
            landscape: "",
            name: "",
            lat: 42.7926592,
            lng: -73.7862493,
        };
    };

    const [selectedZoneData, setSelectedZoneData] = useState(init());

    useEffect(() => {
        if (!props.zoneId) setSelectedZoneData(init());
    }, [props.zoneId]);

    useEffect(() => {
        if (data?.selectedZoneData) {
            setSelectedZoneData({
                ...selectedZoneData,
                climate: data.selectedZoneData.climate,
                landscape: data.selectedZoneData.landscape,
                name: data.selectedZoneData.name,
            });
        }
        // eslint-disable-next-line
    }, [data]);

    const isZoneData = () => {
        return selectedZoneData.climate &&
        selectedZoneData.landscape &&
        selectedZoneData.name
            ? true
            : false;
    };

    const EmptyView = () => (
        <Text size={"0.8rem"} color={Theme.global.colors.white}>
            No Observation found with given search.
        </Text>
    );

    const ObservationsInfo = (size) => (
        <ObservationsListContainer
            pad="small"
            elevation="medium"
            gap="medium"
            style={{ paddingLeft: "1.5rem" }}
        >
            {(props.isSpecieSelected || props.isOrderSelected) && (
                <Box direction="row" pad="xsmall" gap="small">
                    <Box gridArea="picture" alignSelf="center">
                        <ImageContainer
                            alignSelf="center"
                            fit="cover"
                            src={
                                props.observations[0].images.length > 0
                                    ? props.observations[0].images[0].url
                                    : DUMMY_PIC_URL
                            }
                        />
                    </Box>
                    <Box gridArea="info" direction="column" alignSelf={"center"}>
                        <Text size={"1rem"} weight={"bold"} margin={{ bottom: "0.2rem" }}>
                            {props.isSpecieSelected && isNotUndefined(props.observations)
                                ? capitalizeFirstLetter(props.observations[0].specie.commonName)
                                : ""}
                            {props.isOrderSelected && isNotUndefined(props.observations)
                                ? capitalizeFirstLetter(props.observations[0].order.commonName)
                                : ""}
                        </Text>
                        {props.isSpecieSelected && (
                            <Text
                                color={Theme.global.colors.textBlack}
                                size={"0.687rem"}
                                style={{ fontStyle: "italic" }}
                            >
                                {isNotUndefined(props.observations)
                                    ? capitalizeFirstLetter(
                                        props.observations[0].specie.binomialName
                                    )
                                    : ""}
                            </Text>
                        )}
                    </Box>
                </Box>
            )}

            <Box gap="xsmall" direction={"row"}>
                <Text size={"small"} weight={"bold"}>
                    Observations:
                </Text>
                <Text size={"small"}>{props.observationsMeta}</Text>
            </Box>

            <Box height="medium" overflow="auto">
                <Grid columns={size === "small" ? "18rem" : "20rem"} rows="xsmall"
                      gap={size === "small" ? "medium" : "small"}>
                    <InfiniteScroll
                        items={props.observations}
                        replace={false}
                        show={0}
                        onMore={() => {
                            props.handleLoadMoreObsevations(props.observations.length);
                        }}
                        step={10}
                    >
                        {(item, index) => (
                            <div key={item.id}>
                                {showItemLoader(
                                    props.observations.length,
                                    index,
                                    props.fetchMoreLoading
                                ) ? (
                                    <Loader/>
                                ) : (
                                    <ObservationCard
                                        image={
                                            item.images.length > 0
                                                ? item.images[0].url
                                                : DUMMY_PIC_URL
                                        }
                                        dateTime={item.dateTime}
                                        quantity={item.quantity}
                                        SpecieName={item.specie.commonName}
                                        CountryName={item.county.name}
                                        UserName={item.user.name}
                                        profileData={item}
                                        onItemClick={props.onItemClick}
                                        onFlagClick={props.onFlagClick}
                                        onEditClick={props.onEditClick}
                                        isShowMenu={isUserTypeModerator()}
                                    />
                                )}
                            </div>
                        )}
                    </InfiniteScroll>
                </Grid>
                {props.searchCallLoading && <Loader/>}
            </Box>
        </ObservationsListContainer>
    );

    const View = (size) => {
        return (
            <RootContainer
                direction="column"
                gap={size === "medium" ? "small" : "medium"}
            >
                <StatsDetailContainer
                    pad={size === "medium" ? "medium" : "medium"}
                    elevation="medium"
                >
                    <Box direction="column" gap="small">
                        <Box gridArea="title" direction="column">
                            <Heading level={5} margin={{ bottom: "4px", top: "4px" }}>
                                {props.zone}
                            </Heading>
                            {isZoneData() && (
                                <Text color={Theme.global.colors.grey} size={"0.687rem"}>
                                    {selectedZoneData.name}
                                </Text>
                            )}
                        </Box>
                        <GraphDetailMapContainer
                            direction={isZoneData() ? "column" : "row"}
                            gap="medium"
                            alignSelf="center"
                        >
                            {isZoneData() && (
                                <GraphDetailContainer direction="row" gap="medium">
                                    <Box align="center">
                                        <PieChartGraph
                                            size={"medium"}
                                            graphData={selectedZoneData.landscape}
                                        />
                                    </Box>

                                    <GraphAndDetailContainer
                                        direction="row"
                                        width="medium"
                                        justify={"between"}
                                        align="center"
                                    >
                                        <Box direction="column">
                                            <FieldTitleWithIconComponent
                                                title="Landscape"
                                                headingLevel={5}
                                                isShowIcon
                                                isRequiredField={false}
                                                InfoText={INFO_TEXTS.LANDSCAPE_OBSERVATION_INFO}
                                            />
                                            <Text color={Theme.global.colors.grey} size={"0.687rem"}>
                                                {parseFloat(
                                                    selectedZoneData.landscape.protected
                                                ).toFixed(2)}
                                                % Protected
                                            </Text>
                                            <Text
                                                color={Theme.global.colors.textBlack}
                                                size={"0.687rem"}
                                                margin={{ top: "1rem" }}
                                                style={{ lineHeight: "1rem" }}
                                            >
                                                {parseFloat(selectedZoneData.landscape.forest).toFixed(
                                                    2
                                                )}
                                                % Forest
                                                <br/>
                                                {parseFloat(
                                                    selectedZoneData.landscape.agriculture
                                                ).toFixed(2)}
                                                % Agriculture
                                                <br/>
                                                {parseFloat(selectedZoneData.landscape.urban).toFixed(
                                                    2
                                                )}
                                                % Developed
                                                <br/>
                                                {parseFloat(selectedZoneData.landscape.wetland).toFixed(
                                                    2
                                                )}
                                                % Wetland
                                                <br/>
                                                {parseFloat(selectedZoneData.landscape.water).toFixed(
                                                    2
                                                )}
                                                % Open water
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Box direction="column">
                                                <FieldTitleWithIconComponent
                                                    title="Climate"
                                                    headingLevel={5}
                                                    isShowIcon
                                                    isRequiredField={false}
                                                    InfoText={INFO_TEXTS.CLIMATE_OBSERVATION_INFO}
                                                />
                                                <Heading
                                                    level={3}
                                                    margin={{ top: "0.437rem", bottom: "0" }}
                                                >
                                                    {parseFloat(
                                                        selectedZoneData.climate.temperature
                                                    ).toFixed(2)}
                                                    <> &#8451; </>
                                                </Heading>
                                                <Text
                                                    color={Theme.global.colors.textBlack}
                                                    size={"0.687rem"}
                                                >
                                                    Annual mean temperature
                                                </Text>
                                                <Heading
                                                    level={3}
                                                    margin={{ top: "0.625rem", bottom: "0" }}
                                                >
                                                    {parseFloat(
                                                        selectedZoneData.climate.precipitation
                                                    ).toFixed(2)}
                                                    <> &#13213;</>
                                                </Heading>
                                                <Text
                                                    color={Theme.global.colors.textBlack}
                                                    size={"0.687rem"}
                                                >
                                                    Annual mean precipitation
                                                </Text>
                                            </Box>
                                        </Box>
                                    </GraphAndDetailContainer>
                                </GraphDetailContainer>
                            )}
                            <MapContainer isZoneData={isZoneData()}>
                                <MapComponent
                                    zone={props.zone}
                                    isMapView={props.isMapView}
                                    height={isZoneData() ? "12rem" : "20rem"}
                                    size={size}
                                    zoom={isZoneData() ? 5 : 6}
                                    lat={selectedZoneData.lat}
                                    lng={selectedZoneData.lng}
                                    zoneId={props.zoneId}
                                    fetchMoreLoading={props.fetchMoreLoading}
                                    isLayerClickable={props.isLayerClickable}
                                    handleZoneChanged={props.handleZoneChanged}
                                    heatMapByZoneData={props.heatMapByZoneData}
                                    isShowColorContainer={props.isShowColorContainer}
                                    regionChangedCallBack={refetch}
                                />
                            </MapContainer>
                        </GraphDetailMapContainer>
                    </Box>
                </StatsDetailContainer>

                {props.observations !== undefined ? (
                    props.observations.length !== 0 ? (
                        ObservationsInfo(size)
                    ) : (
                        <Loader/>
                    )
                ) : (
                    EmptyView()
                )}
            </RootContainer>
        );
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => <div>{View(size)}</div>}
        </ResponsiveContext.Consumer>
    );
};

export default ObservationTabDetailView;
