import React from "react";
import styled from "styled-components";
import { Box, Button, Text } from "grommet";
import Loader from "../../components/Loader";
import { CheckMark, FilterIcon, Theme, WarningIcon } from "../../theme";

const Root = styled.div`
  text-align: center;
  word-wrap: break-word;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

const Dialog = styled.div`
  background-color: ${Theme.global.colors.white};
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  input {
    font-size: 1rem;
    font-weight: 400;
  }
`;

const ActionButton = styled(Button)`
  text-align: center;
  color: ${Theme.global.colors.active};
  font-size: 1rem;
  padding: 3%;
  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

const ExploreErrorDialog = (props) => {
    return (
        <Root>
            <Overlay>
                <Dialog>
                    <Box
                        pad="small"
                        gap="small"
                        direction="column"
                        background={Theme.global.colors.white}
                    >
                        <Box margin={{ top: "1rem", bottom: "2rem" }} alignSelf="center">
                            <WarningIcon
                                color={Theme.global.colors.active}
                                style={{
                                    height: "1.85em",
                                    width: "1.85em",
                                    marginLeft: "5px",
                                }}
                            />
                        </Box>

                        <Text>{props.title}</Text>
                        <Box margin={{ top: "3rem", bottom: "3rem" }} alignSelf="center">
                            <ActionButton
                                style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                                icon={
                                    <FilterIcon
                                        width={"1rem"}
                                        height={"1rem"}
                                        color={Theme.global.colors.active}
                                    />
                                }
                                label="Change filters"
                                onClick={() => {
                                    props.onClose();
                                }}
                            />
                        </Box>
                    </Box>
                </Dialog>
            </Overlay>
        </Root>
    );
};

const OverlayDialog = (props) => {
    const isSuccess = () => {
        return props.type === "SUCCESS" ? (
            <CheckMark color={Theme.global.colors.green}/>
        ) : (
            <WarningIcon color={Theme.global.colors.red}/>
        );
    };
    return (
        <Root>
            <Overlay>
                <Dialog style={{ width: "20%" }}>
                    <Box
                        pad="small"
                        gap="small"
                        direction="column"
                        background={Theme.global.colors.white}
                    >
                        <Box alignSelf="center">{isSuccess()}</Box>
                        <Text>{props.title}</Text>
                        <ActionButton
                            label="Close"
                            onClick={() => {
                                props.onClose();
                            }}
                        />
                    </Box>
                </Dialog>
            </Overlay>
        </Root>
    );
};

const LoadingDialog = (title) => {
    return (
        <Root>
            <Overlay>
                <Dialog>
                    <Box pad="small" gap="small" background={Theme.global.colors.white}>
                        <Loader/>
                        <Text>{title}</Text>
                    </Box>
                </Dialog>
            </Overlay>
        </Root>
    );
};

const ShowDialog = (props) => {
    return props.type === "LOADING"
        ? LoadingDialog(props.title)
        : OverlayDialog(props);
};

export { ShowDialog, ExploreErrorDialog };
