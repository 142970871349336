import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom"
import { Theme } from "../../theme"


const FooterBox = styled.div`
  position: relative;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  flex-wrap: wrap;

  img {
    width: 140px;
  }

  a {
    margin-top: 10px;
    align-self: flex-start;
    color: ${Theme.global.colors.brand};
    font-size: 16px;
    font-family: "Uber Move";
    text-decoration: none;
    font-weight: 700;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    padding-bottom: 30px;
  }
`

const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;

  a {
    margin-top: 10px;
    align-self: flex-start;
    
  }

  @media (max-width: 480px) {
    flex-direction: column;
    a {
      align-self: center;
    }
  }
`

const GrayBar = styled.div`
  height: 1px;
  margin: 24px;
  border-radius: 0px;
  background: #EFEFEF;
`

const Footer = (props) => {
    return (
        <>
            <GrayBar/>
            <FooterBox>
                <LeftSideWrapper>
                    {props.leftImage && <img src={props.leftImage.valueOf()}/>
                    }
                    <Link to="/contact">Contact us</Link>
                </LeftSideWrapper>


                <RightSideWrapper>
                    {props.rightImages.map((img) => {
                        return <ImageWrapper><img src={img}/></ImageWrapper>
                    })}
                </RightSideWrapper>
            </FooterBox>
        </>
    )
}

export default Footer