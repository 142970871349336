import React, { Fragment, useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import { Theme } from "../../theme";
import styled from "styled-components";
import { Mutations } from "../../apollo";
import Control from "react-leaflet-control";
import { ResponsiveContext } from "grommet";
import { MAP_LAYERS, MapColorOpacities, MapLayerColors } from "../../constants";
import Loader from "../../components/Loader";
import ColorContainer from "./ColorContainer";
import State from "../../helpers/GeoJSONs/State";
import { useMutation } from "@apollo/react-hooks";
import DiversityColorContainer from "./DiversityColorContainer";
import { calculateColorRanges, getQuantityValue } from "../../utils";
import { GeoJSON, LayersControl, Map, TileLayer } from "react-leaflet";
import QuadsGeoJSON from "../../helpers/GeoJSONs/QuadsGeoJSON";
import GraphAndDetailContainer from "./GraphAndDetailContainer";
import ObservationListContainer from "./ObservationListContainer";
import CountiesGeoJSON from "../../helpers/GeoJSONs/CountiesGeoJSON";
import DecRegionGeoJSON from "../../helpers/GeoJSONs/DecRegionGeoJSON";
import WatershedGeoJSON from "../../helpers/GeoJSONs/WatershedGeoJSON";
import { setSelectedZoneInput } from "../../apollo/mutations/Variables";

const RootContainer = styled.div`
  .leaflet-interactive {
    cursor: ${(props) =>
    props.isLayerClickable ? "pointer" : "auto"} !important;
  }
`;

const ResponsiveControl = styled(Control)`
  @media (max-width: 550px) {
      top: 135px;
  }
`;

const MapComponent = (props) => {
    const [stateModel, setStateModel] = useState({
        zoneId: "",
        lat: 0,
        lng: 0,
        zoom: 5,
        name: "",
        data: {},
        zone: "",
        climate: "",
        landscape: "",
        heatMapByZone: [],
        zoneCount: "",
        view: props.isMapView,
        loader: true,
    });
    const minZoneCount = props?.heatMapByZoneData?.min || 0;
    const maxZoneCount = props?.heatMapByZoneData?.max || 0;

    const [dialogState, setDialogState] = useState({
        show: false,
        message: "",
        type: "",
    });
    const [isRegionSelected, setIsRegionSelected] = useState(false);
    const [location, setLocation] = useState({
        lat: 0,
        lng: 0,
        __typename: "location",
    });

    const [colorRanges, setColorRanges] = useState([]);

    const [setSelectedZone, { data: cacheReponse }] = useMutation(
        Mutations.CLIENT_SET_SELECTED_ZONE_MUTATION
    );
    useEffect(() => {
        if (cacheReponse && props.regionChangedCallBack)
            props.regionChangedCallBack();
        // eslint-disable-next-line
    }, [cacheReponse]);

    const [setSelectedZoneEmpty] = useMutation(
        Mutations.CLIENT_SET_SELECTED_ZONE_MUTATION,
        setSelectedZoneInput("", "", "", "")
    );

    useEffect(() => {
        if (
            stateModel.name &&
            stateModel.climate &&
            stateModel.landscape &&
            location
        ) {
            setSelectedZone(
                setSelectedZoneInput(
                    //updaing state model to cache
                    stateModel.name,
                    stateModel.climate,
                    stateModel.landscape,
                    location
                )
            );
        }
        // eslint-disable-next-line
    }, [
        stateModel.name,
        stateModel.climate,
        stateModel.landscape,
        location
    ]);

    useEffect(() => {
        if (
            stateModel.name &&
            stateModel.climate &&
            stateModel.landscape &&
            location
        ) {
            setSelectedZone(
                setSelectedZoneInput(
                    //updaing state model to cache
                    stateModel.name,
                    stateModel.climate,
                    stateModel.landscape,
                    location
                )
            );
        }
        // eslint-disable-next-line
    }, [
        stateModel.name,
        stateModel.climate,
        stateModel.landscape,
        location
    ]);

    useEffect(() => {
        if (props.zoneId === "" && stateModel.zoneId) {
            setSelectedZoneEmpty();
        }

        if (props.heatMapByZoneData) {
            const zone = props.zone;
            let data = getData(zone);
            let zoom, lat, lng;

            const zoneCount = getTotalZoneCount(props.heatMapByZoneData);
            let layerData = getLayerData(props.heatMapByZoneData);
            if (stateModel.view) {
                zoom = 7;
                lat = props.hideObservations ? 42.8007281 : 42.7926666;
                lng = props.hideObservations ? -76.1263376 : -74.5113474;
            } else {
                lat = props.lat;
                lng = props.lng;
                zoom = props.zoom;
            }

            if (data && layerData) {
                const response = drawLayers(data, layerData, props.zoneId, zoneCount);
                setIsRegionSelected(response.isSelectedRegion);
                setStateModel({
                    ...stateModel,
                    lat: lat,
                    lng: lng,
                    data: response.data,
                    zoom: zoom,
                    zone: zone,
                    zoneId: props.zoneId,
                    zoneCount: zoneCount,
                    name: response.name,
                    heatMapByZone: layerData,
                    climate: response.climate,
                    landscape: response.landscape,
                    loader: false,
                });
            } else {
                setStateModel({
                    ...stateModel,
                    loader: true,
                });
            }
        }
        // eslint-disable-next-line
    }, [
        props.zone,
        props.zoneId,
        props.heatMapByZoneData,
        colorRanges
    ]);

    useEffect(() => {
        if (stateModel.zoneId) {
            props.handleZoneChanged(stateModel.zone, stateModel.zoneId);
        }
        // eslint-disable-next-line
    }, [stateModel.zoneId]);

    useEffect(() => {
        if (!dialogState.show) {
            setDialogState({
                show: true,
                message: "Fetching observations...",
                type: "LOADING",
            });
        }
        // eslint-disable-next-line
    }, [stateModel.zone]);

    useEffect(() => {
        setColorRanges(calculateColorRanges(minZoneCount, maxZoneCount));
    }, [
        minZoneCount,
        maxZoneCount
    ]);

    const drawLayers = (data, layerData, zoneId, zoneCount) => {
        let name = "";
        let climate = "";
        let landscape = "";
        let isSelectedRegion = false;

        if (data.features && layerData) {
            data.features.forEach((x, i) => {
                const zone = layerData.find((item) => item.zoneId === x.properties.id);
                if (zone && zone.zoneId === zoneId && !props.isDiversityMap) {
                    data.features[i].properties.stroke = Theme.global.colors.lightGrey;
                    data.features[i].properties["fill-opacity"] = 1;
                    isSelectedRegion = true;
                    name = zone.zoneName;
                    climate = zone.climate;
                    landscape = zone.landscape;
                } else if (zone) {
                    data.features[i].properties.stroke = Theme.global.colors.mapLayer;
                    data.features[i].properties["fill-opacity"] = props.isDiversityMap
                        ? getColorByRichness(zone.observationsCount, zoneCount)
                        : getColorByQuantity(zone.observationsCount);
                } else {
                    data.features[i].properties.stroke = Theme.global.colors.white;
                    data.features[i].properties["fill-opacity"] = 0;
                }
            });
            return {
                name: name,
                data: data,
                climate: climate,
                landscape: landscape,
                isSelectedRegion: isSelectedRegion,
            };
        }
        return "";
    };

    const getData = (zone) => {
        switch (zone) {
            case MAP_LAYERS[0]:
                return QuadsGeoJSON;
            case MAP_LAYERS[1]:
                return CountiesGeoJSON;
            case MAP_LAYERS[2]:
                return WatershedGeoJSON;
            case MAP_LAYERS[3]:
                return DecRegionGeoJSON;
            default:
                return State;
        }
    };

    const getLayerData = (heatMapByZone) => {
        if (heatMapByZone) {
            return heatMapByZone.observationHeatMapItem;
        }
    };

    const getColorByRichness = (quantity, totalCount) => {
        if (quantity === getQuantityValue(totalCount, 0)) {
            return 0.1;
        } else if (quantity <= getQuantityValue(totalCount, 1)) {
            return 0.3;
        } else if (quantity <= getQuantityValue(totalCount, 2)) {
            return 0.55;
        } else if (quantity <= getQuantityValue(totalCount, 3)) {
            return 0.8;
        } else if (quantity < getQuantityValue(totalCount, 4)) {
            return 1.2;
        }
    };

    const getColorByQuantity = (quantity) => {
        const nRanges = colorRanges.length;
        if (quantity === 0 || nRanges === 0) {
            return 0;
        }
        let corrospondingColor = colorRanges[nRanges - 1].color;

        for (let i = nRanges - 1; i >= 0; i--) {
            if (quantity >= colorRanges[i].val) {
                break;
            }
            corrospondingColor = colorRanges[i].color;
        }
        const colorOpacity =
            MapColorOpacities[
                MapLayerColors.findIndex((item) => item === corrospondingColor)
                ];
        return colorOpacity;
    };

    const handleAreaClick = (feature) => {
        if (props.isLayerClickable) {
            highlight(feature);
        }
    };

    const highlight = (feature) => {
        let zoneId = feature.properties.id;
        let data = stateModel.data;
        const layerData = getLayerData(stateModel.zone, props.heatMapByZoneData);
        if (data.features && stateModel.heatMapByZone) {
            const response = drawLayers(
                data,
                layerData,
                zoneId,
                stateModel.zoneCount
            );
            setIsRegionSelected(response.isSelectedRegion);
            setStateModel({
                ...stateModel,
                data: response.data,
                zoneId: zoneId,
                name: response.name,
                climate: response.climate,
                landscape: response.landscape,
            });
        }
    };

    const getLatLng = (e) => {
        if (stateModel.view) {
            setLocation({
                ...location,
                lat: e.latlng.lat,
                lng: e.latlng.lng,
            });
        }
    };

    const getTotalZoneCount = (heatMapByZone) => {
        if (heatMapByZone) {
            return heatMapByZone.zoneTotalCount;
        }
    };

    const _observationsContainer = () => {
        function getListContainerHeight() {
            if (props.observations?.length === 1) {
                if (props.isOrderSelected || props.isSpecieSelected) {
                    return "12rem";
                }
                return "9rem";
            } else if (props.observations?.length === 2) {
                if (props.isOrderSelected || props.isSpecieSelected) {
                    return "18rem";
                }
                return "15rem";
            } else if (isRegionSelected) return "18rem";
            else return "25rem";
        }

        return (
            stateModel.view && (
                <ResponsiveControl position="topright">
                    <ObservationListContainer
                        height={getListContainerHeight()}
                        zoneName={props.zone}
                        observations={props.observations}
                        onItemClick={props.onItemClick}
                        onFlagClick={props.onFlagClick}
                        onEditClick={props.onEditClick}
                        CSVFileLoading={props.CSVFileLoading}
                        isOrderSelected={props.isOrderSelected}
                        fetchMoreLoading={props.fetchMoreLoading}
                        observationsMeta={props.observationsMeta}
                        isSpecieSelected={props.isSpecieSelected}
                        searchCallLoading={props.searchCallLoading}
                        handleDownloadCSV={props.handleDownloadCSV}
                        handleLoadMoreObsevations={props.handleLoadMoreObsevations}
                    />
                </ResponsiveControl>
            )
        );
    };

    const _diversityColorInfoContainer = () =>
        props.isShowColorContainer && (
            <Control position="bottomleft" style={{ marginBottom: "10rem" }}>
                <DiversityColorContainer
                    observationsCount={stateModel.zoneCount}
                    titleFlag={props.hideObservations}
                />
            </Control>
        );

    const _colorInfoContainer = () =>
        props.isShowColorContainer && (
            <Control position="bottomleft" style={{ marginBottom: "10rem" }}>
                <ColorContainer
                    observationsCount={stateModel.zoneCount}
                    titleFlag={props.hideObservations}
                    minZoneCount={minZoneCount}
                    maxZoneCount={maxZoneCount}
                    colorRanges={colorRanges}
                />
            </Control>
        );

    const _graphAndDetailContainer = () => {
        return (
            stateModel.view &&
            isRegionSelected && (
                <Control position="bottomright">
                    <GraphAndDetailContainer
                        climate={stateModel.climate}
                        landscape={stateModel.landscape}
                        name={stateModel.name}
                        id={stateModel.zoneId}
                    />
                </Control>
            )
        );
    };

    return (
        <RootContainer isLayerClickable={props.isLayerClickable}>
            <ResponsiveContext.Consumer>
                {(size) => (
                    <Fragment>
                        {stateModel.loader ? (
                            <Loader/>
                        ) : (
                            <Map
                                center={[
                                    stateModel.lat,
                                    stateModel.lng
                                ]}
                                zoom={stateModel.zoom}
                                style={{
                                    width: "100%",
                                    height: props.height,
                                    zIndex: 0,
                                    cursor: props.isLayerClickable ? "" : "default",
                                }}
                                scrollWheelZoom={
                                    props.isDiversityMap || !props.isShowColorContainer
                                }
                                dragging={true}
                                clickable={props.isLayerClickable}
                                touchZoom={true}
                                zoomControl={true}
                                onClick={getLatLng}
                                doubleClickZoom={true}
                            >
                                <LayersControl position="topleft">
                                    <LayersControl.BaseLayer name="Satellite">
                                        <TileLayer
                                            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                        />
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer checked name="Street">
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
                                            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                                        />
                                    </LayersControl.BaseLayer>
                                </LayersControl>
                                <GeoJSON
                                    key={stateModel.zone}
                                    data={stateModel.data}
                                    weight=".5"
                                    style={(feature) => {
                                        return {
                                            color: feature.properties.stroke,
                                            fillOpacity: feature.properties["fill-opacity"],
                                        };
                                    }}
                                    onEachFeature={(feature, layer) => {
                                        layer.bindTooltip(feature.properties.name, {
                                            sticky: true,
                                        });
                                        layer.on({
                                            click: () => {
                                                handleAreaClick(feature);
                                            },
                                        });
                                    }}
                                />
                                {!props.hideObservations && _observationsContainer()}
                                {!props.isDiversityMap && _colorInfoContainer()}
                                {props.isDiversityMap && _diversityColorInfoContainer()}
                                {!props.isDiversityMap && _graphAndDetailContainer()}
                            </Map>
                        )}
                    </Fragment>
                )}
            </ResponsiveContext.Consumer>
        </RootContainer>
    );
};

export default MapComponent;
