import React, { Component } from "react";

class NotFoundPage extends Component {
    render() {
        return (
            <div>
                <p className="errorMessage">404 Page not found </p>
            </div>
        );
    }
}

export default NotFoundPage;
