import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { isUserLogIn } from "../../helpers";
import Loader from "../../components/Loader";
import { BarGraph } from "../../components/BarChart";
import { Download, LocationLogo, Theme } from "../../theme";
import { capitalizeFirstLetter, downloadGraph } from "../../utils";
import { Box, Heading, ResponsiveContext, Select, Text } from "grommet";
import { InformationIcon } from "../../components/FieldTitleWithIconComponent";
import { Drawer } from 'grommet-icons/es6';
import { Bar, ComposedChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis, } from "recharts";
import { CLIMATE_OPTIONS, HABITAT_OPTIONS, INFO_TEXTS, } from "../../constants";

const RootContainer = styled(Box)`
  tspan {
    font-family: "Uber Move";
    font-size: 0.68rem;
  }
`;
const BottomGraphsContainer = styled(Box)`
  @media screen and (max-width: 1300px) {
    display: block;
    flex-direction: column;
  }
`;

const OccurrenceTab = (props) => {
    const [HabitatData, setHabitatData] = useState();
    const [ClimateData, setClimateDate] = useState();
    const [appType, setAppType] = useState("");
    const graphRef = {
        climate: React.createRef(),
        habitat: React.createRef(),
        penology: React.createRef(),
    };

    useEffect(() => {
        (process.env.REACT_APP_TYPE_CODE
            ? process.env.REACT_APP_TYPE_CODE
            : "2") === "2"
            ? setAppType("Amphibians & Reptiles")
            : setAppType("Mammals");
    }, []);

    useEffect(() => {
        if (
            props.graphDataofHabitat &&
            props.graphDataofHabitat.absent &&
            props.graphDataofHabitat.present
        ) {
            setHabitatData([
                {
                    name: "Recorded",
                    data:
                        props.graphDataofHabitat && props.graphDataofHabitat.present
                            ? props.graphDataofHabitat.present.value
                            : "",
                    error:
                        props.graphDataofHabitat && props.graphDataofHabitat.present
                            ? props.graphDataofHabitat.present.errorDeviation
                            : "",
                },
                {
                    name: "Not-recorded",
                    data:
                        props.graphDataofHabitat && props.graphDataofHabitat.absent
                            ? props.graphDataofHabitat.absent.value
                            : "",
                    error:
                        props.graphDataofHabitat && props.graphDataofHabitat.absent
                            ? props.graphDataofHabitat.absent.errorDeviation
                            : "",
                },
            ]);
        }
    }, [props.graphDataofHabitat]);

    useEffect(() => {
        if (
            props.graphDataOfClimate &&
            props.graphDataOfClimate.absent &&
            props.graphDataOfClimate.present
        ) {
            setClimateDate([
                {
                    name: "Recorded",
                    data:
                        props.graphDataOfClimate && props.graphDataOfClimate.present
                            ? props.graphDataOfClimate.present.value
                            : "",
                    error:
                        props.graphDataOfClimate && props.graphDataOfClimate.present
                            ? props.graphDataOfClimate.present.errorDeviation
                            : "",
                },
                {
                    name: "Not-recorded",
                    data:
                        props.graphDataOfClimate && props.graphDataOfClimate.absent
                            ? props.graphDataOfClimate.absent.value
                            : "",
                    error:
                        props.graphDataOfClimate && props.graphDataOfClimate.absent
                            ? props.graphDataOfClimate.absent.errorDeviation
                            : "",
                },
            ]);
        }
    }, [props.graphDataOfClimate]);

    const CustomTooltip = ({ active, payload }) => {
        if (active) {
            return (
                <Box direction="column" style={{ textAlign: "center" }}>
                    <p style={{ fontSize: "1.3rem", marginBottom: "0" }}>
                        {payload ? payload[0].value : ""}
                    </p>
                    <p style={{ fontSize: "0.6rem", marginTop: "0" }}>
                        Number of observations
                    </p>
                </Box>
            );
        }

        return null;
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <RootContainer
                    direction="column"
                    pad={{ top: "24px" }}
                    gap={size === "medium" ? "medium" : "small"}
                >
                    <Box direction="column">
                        <Box direction="row" align="center">
                            <Heading
                                level={4}
                                margin={{ top: "0rem", bottom: "0rem", right: "1rem" }}
                            >
                                Phenology
                            </Heading>
                            <InformationIcon InfoText={INFO_TEXTS.PHENOLOGY_INFO}/>
                        </Box>
                        <Box direction="row" align="center" margin={{ top: "0.2rem" }}>
                            <Box style={{ width: '16px', height: '15px' }} align={'center'} justify={'center'}><Drawer
                                size={'small'} color={'#A0A9BA'}/></Box>
                            <Text size={"0.9rem"} margin={{ left: "0.3rem" }}>
                                {props.speiceName ? capitalizeFirstLetter(props.speiceName) : "All " + appType}
                            </Text>
                        </Box>
                        <Box direction="row" align="center" margin={{ top: "0.2rem" }}>
                            <LocationLogo
                                width={"16px"}
                                height={"16px"}
                                color={Theme.global.colors.grey}
                            />
                            <Text size={"0.9rem"} margin={{ left: "0.3rem" }}>
                                {props.countyName ? props.countyName : "New York State"}
                            </Text>
                        </Box>
                    </Box>
                    <Box style={{ cursor: "pointer" }} align="end">
                        {isUserLogIn() && (
                            <div onClick={() => downloadGraph(graphRef.penology)}>
                                <Download color={Theme.global.colors.active}/>
                            </div>
                        )}
                    </Box>
                    <Box style={{ width: "100%", height: "23.687rem" }}>
                        {props.Loading ? (
                            <Loader/>
                        ) : (
                            <Box>
                                <Box style={{ display: "flex", flexDirection: "row" }}>
                                    <Box style={{ minHeight: "23.687rem", minWidth: "90%" }}>
                                        <ResponsiveContainer>
                                            <ComposedChart
                                                data={props.occuranceGraphData}
                                                width={500}
                                                height={1400}
                                                margin={{
                                                    top: 5,
                                                    bottom: 15,
                                                }}
                                                ref={graphRef.penology}
                                            >
                                                <defs>
                                                    <linearGradient
                                                        id="colorUv"
                                                        x1="0%"
                                                        y1="0%"
                                                        x2="0%"
                                                        y2="100%"
                                                    >
                                                        <stop stopColor={Theme.global.colors.barShade}/>
                                                        <stop
                                                            offset="100%"
                                                            stopColor={Theme.global.colors.barShade1}
                                                        />
                                                    </linearGradient>
                                                </defs>
                                                <XAxis dataKey="name" interval={1}>
                                                    <Label value="Month" offset={-7} position="insideBottom"/>
                                                </XAxis>
                                                <YAxis padding={{ top: 10 }} label={{
                                                    offset: 4,
                                                    value: 'Recorded observations',
                                                    angle: -90,
                                                    position: 'insideLeft'
                                                }}>
                                                </YAxis>
                                                <Tooltip
                                                    cursor={{
                                                        stroke: Theme.global.colors.lightActive,
                                                        strokeWidth: "2.8px",
                                                    }}
                                                    content={<CustomTooltip/>}
                                                />
                                                <Bar
                                                    barSize={50}
                                                    dataKey="count"
                                                    fill="url(#colorUv)"
                                                />
                                            </ComposedChart>
                                        </ResponsiveContainer>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Box direction="column" margin={{ bottom: "2rem" }}>
                        <Heading
                            level={4}
                            margin={{ top: "0rem", bottom: "0rem", right: "1rem" }}
                        >
                            Environmental relationships
                        </Heading>

                        <Box direction="row" align="center" margin={{ top: "0.2rem" }}>
                            <Box style={{ width: '16px', height: '15px' }} align={'center'} justify={'center'}><Drawer
                                size={'small'} color={'#A0A9BA'}/></Box>
                            <Text size={"0.9rem"} margin={{ left: "0.3rem" }}>
                                {props.speiceName ? capitalizeFirstLetter(props.speiceName) : "All " + appType}
                            </Text>
                        </Box>
                        <Box direction="row" align="center" margin={{ top: "0.2rem" }}>
                            <LocationLogo
                                width={"16px"}
                                height={"16px"}
                                color={Theme.global.colors.grey}
                            />
                            <Text size={"0.9rem"} margin={{ left: "0.3rem" }}>
                                {props.countyName ? props.countyName : "New York State"}
                            </Text>
                        </Box>
                    </Box>
                    <BottomGraphsContainer direction="row" justify="between">
                        <Box direction="column" gap="small" margin={{ bottom: "1.2rem" }}>
                            <Box
                                direction="column"
                                width="12.562rem"
                                gap="small"
                                margin={{ bottom: "1rem" }}
                            >
                                <Box
                                    justify="between"
                                    direction="row"
                                    align="center"
                                    alignSelf="start"
                                >
                                    <Heading
                                        level={4}
                                        margin={{ top: "0rem", bottom: "0rem", right: "1rem" }}
                                    >
                                        Habitat
                                    </Heading>
                                    <InformationIcon
                                        InfoText=""
                                        content={() => INFO_TEXTS.HABITAT_TOOLTIP_INFO}
                                    />
                                </Box>
                                <Select
                                    options={HABITAT_OPTIONS}
                                    placeholder={"Select"}
                                    value={capitalizeFirstLetter(
                                        props.occurranceTabHabitatDefaultValue
                                    )}
                                    style={{ fontWeight: "100" }}
                                    onChange={({ option }) => {
                                        props.onHabitatClick(option);
                                    }}
                                />
                            </Box>
                            <Box style={{ cursor: "pointer" }} align="end">
                                {isUserLogIn() && (
                                    <div onClick={() => downloadGraph(graphRef.habitat)}>
                                        <Download color={Theme.global.colors.active}/>
                                    </div>
                                )}
                            </Box>
                            <Box style={{ width: "20rem", height: "19.187rem" }}>
                                {props.habitatLoadingResponse ? (
                                    <Loader/>
                                ) : (
                                    <BarGraph
                                        data={HabitatData}
                                        topMargin={5}
                                        bottomMargin={5}
                                        yAxisDataType="%"
                                        YAxisLabel={`Landcover: % ${capitalizeFirstLetter(
                                            props.occurranceTabHabitatDefaultValue
                                        )}`}
                                        xAxisDatakey={"name"}
                                        yAxisDataKey={"present"}
                                        yAxisSecondDataKey={"absent"}
                                        errorY={"errorY"}
                                        errorX={"errorX"}
                                        forwardRef={graphRef.habitat}
                                    />
                                )}
                            </Box>
                        </Box>

                        <Box direction="column" gap="small" margin={{ bottom: "1rem" }}>
                            <Box
                                direction="column"
                                width="12.562rem"
                                gap="small"
                                margin={{ bottom: "1rem" }}
                            >
                                <Box
                                    justify="between"
                                    direction="row"
                                    align="center"
                                    alignSelf="start"
                                >
                                    <Heading
                                        level={4}
                                        margin={{ top: "0rem", bottom: "0rem", right: "1rem" }}
                                    >
                                        Climate
                                    </Heading>
                                    <InformationIcon
                                        content={() => INFO_TEXTS.CLIMATE_TOOLTIP_INFO}
                                        InfoText={INFO_TEXTS.CLIMATE_TOOLTIP_INFO}
                                    />
                                </Box>
                                <Select
                                    options={CLIMATE_OPTIONS}
                                    placeholder={"Select"}
                                    value={capitalizeFirstLetter(
                                        props.occurranceTabClimateDefaultValue
                                    )}
                                    style={{ fontWeight: "100" }}
                                    onChange={({ option }) => {
                                        props.onClimateClick(option);
                                    }}
                                />
                            </Box>
                            <Box align="end" style={{ cursor: "pointer" }}>
                                {isUserLogIn() && (
                                    <div onClick={() => downloadGraph(graphRef.climate)}>
                                        <Download color={Theme.global.colors.active}/>
                                    </div>
                                )}
                            </Box>
                            <Box style={{ width: "20rem", height: "19.187rem" }}>
                                {props.climateLoadingResponse ? (
                                    <Loader/>
                                ) : (
                                    <BarGraph
                                        data={ClimateData}
                                        topMargin={5}
                                        YAxisLabel={capitalizeFirstLetter(
                                            props.occurranceTabClimateDefaultValue
                                        )}
                                        yAxisDataType={
                                            props.occurranceTabClimateDefaultValue === "temperature"
                                                ? "°C"
                                                : "cm"
                                        }
                                        bottomMargin={5}
                                        xAxisDatakey={"name"}
                                        yAxisDataKey={"present"}
                                        yAxisSecondDataKey={"absent"}
                                        errorY={"errorY"}
                                        errorX={"errorX"}
                                        forwardRef={graphRef.climate}
                                    />
                                )}
                            </Box>
                        </Box>
                    </BottomGraphsContainer>
                </RootContainer>
            )}
        </ResponsiveContext.Consumer>
    );
};

export default OccurrenceTab;
