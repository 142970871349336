import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Mutation from "../apollo/mutations";
import { Theme, UserCustom } from "../theme";
import { saveToken, saveUser } from "../helpers";
import { useMutation } from "@apollo/react-hooks";
import { ShowDialog } from "../components/DialogsComponent";
import { getLoginInput } from "../apollo/mutations/Variables";
import { EmailField, PasswordField } from "../components/InputFields";
import { Anchor, Box, Button, Heading, ResponsiveContext, Text } from "grommet";

const SigninForm = styled(Box)`
  background-color: ${Theme.global.colors["white"]};
  padding: ${(props) => (props.size === "small" ? "1rem" : "5.6875rem")};
  display: block !important;
  @media (max-width: 1280px) {
    padding: 2rem;
  }
`;
const InfoFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled(Box)`
  width: 100%;
  @media (min-width: 1280px) {
    width: 32.8rem;
  }
  ::placeholder {
    font-weight: lighter;
  }
`;
const SigninButton = styled(Button)`
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1.2rem;
  padding-left: 1.3rem;
  border-radius: 10rem;
  background-color: ${Theme.global.colors["brand"]};
  color: ${Theme.global.colors["white"]};
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const LoaderWrpper = styled(Box)`
  width: 10rem;
`;
const Signin = (props) => {
    const getInitialState = () => {
        return {
            email: "",
            password: "",
        };
    };
    const [userInfo, setUserInfo] = useState(getInitialState());
    const [dialogState, setDialogState] = useState({
        show: false,
        message: "",
        type: "",
    });
    const [
        userSignin,
        { data: networkCallResponseData, error: networkCallErrorResponse },
    ] = useMutation(Mutation.USER_LOGIN_MUTATION, getLoginInput(userInfo));

    const navigateToProfile = () => {
        let path;
        path = `/profile`;
        props.history.push(path);
    };
    const resetPassword = () => {
        let path;
        path = `/verify-email`;
        props.history.push(path);
    };
    const handleCloseAlertDialog = () => {
        setDialogState({
            ...dialogState,
            show: false,
            message: "",
        });
        if (dialogState.type === "SUCCESS") {
            navigateToProfile();
        }
    };

    const onSubmit = () => {
        if (userInfo.email && userInfo.password) {
            setDialogState({
                show: true,
                message: "Please wait...",
                type: "LOADING",
            });
            userSignin();
        } else {
            setDialogState({
                show: true,
                message: "Please fill all require fields.",
                type: "FAILED",
            });
        }
    };
    useEffect(() => {
        if (networkCallResponseData) {
            setDialogState({
                show: true,
                message: "User successfully logged in.",
                type: "SUCCESS",
            });
            saveToken(networkCallResponseData.login.token);
            saveUser(networkCallResponseData.login.user);
        }
        if (networkCallErrorResponse) {
            setDialogState({
                show: true,
                message: "Failed to login.",
                type: "FAILED",
            });
        }
    }, [
        networkCallResponseData,
        networkCallErrorResponse
    ]);

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <React.Fragment>
                    {dialogState.show && (
                        <ShowDialog
                            title={dialogState.message}
                            onClose={handleCloseAlertDialog}
                            type={dialogState.type}
                        />
                    )}

                    <SigninForm size={size} elevation={"medium"}>
                        <Heading
                            color={Theme.global.colors.textBlack}
                            level={2}
                            margin={{
                                top: "none",
                                bottom: "xsmall",
                                right: "none",
                                left: "none",
                            }}
                        >
                            Sign in
                        </Heading>
                        <Text color={Theme.global.colors.textBlack}>
                            Sign in to your contributor account to manage your profile and
                            record
                        </Text>
                        <Box>
                            <InfoFieldsWrapper size={size}>
                                <InputContainer margin={{ top: size }} size={size}>
                                    <EmailField
                                        size={size}
                                        value={userInfo.email}
                                        onValueChange={(event) => {
                                            setUserInfo({ ...userInfo, email: event });
                                        }}
                                    />
                                </InputContainer>
                                <InputContainer margin={{ top: size }} size={size}>
                                    <PasswordField
                                        size={size}
                                        title={"Password"}
                                        value={userInfo.password}
                                        onValueChange={(event) =>
                                            setUserInfo({ ...userInfo, password: event })
                                        }
                                    />
                                </InputContainer>
                            </InfoFieldsWrapper>
                            <Text
                                style={{ fontSize: "17px" }}
                                color={Theme.global.colors.grey}
                                margin={{
                                    top: "0.9rem",
                                    bottom: "xsmall",
                                }}
                            >
                                Forgot your password? Send a
                                <Anchor
                                    label=" Reset  "
                                    onClick={() => {
                                        resetPassword();
                                    }}
                                />
                                link to your email.
                            </Text>
                            <LoaderWrpper>
                                <SigninButton
                                    ariaLabel="click on sign button"
                                    tabIndex="0"
                                    role="link"
                                    icon={<UserCustom color={Theme.global.colors.white}/>}
                                    alignSelf={"start"}
                                    label="Sign in"
                                    onClick={() => {
                                        onSubmit();
                                    }}
                                />
                            </LoaderWrpper>
                        </Box>
                    </SigninForm>
                </React.Fragment>
            )}
        </ResponsiveContext.Consumer>
    );
};
export default Signin;
