import React from "react";
import { Box, Heading, Text } from "grommet";
import styled from "styled-components";
import { InfoIcon, Theme } from "../../theme";
import ReactTooltip from "react-tooltip";
import Fade from "react-reveal/Fade";

const FieldsTitleWrapper = styled(Box)`
  height: 1.4375rem;
  margin-bottom: 0.625rem;
  @media screen and (max-width: 786px) {
    margin-bottom: 0.4rem;
  }
`;

const FieldsTitle = styled(Text)`
  text-align: start;
  margin-right: 0.2rem;
  color: ${Theme.global.colors.textBlack};
`;

const Wrapper = styled.span`
  display: inline-block;
  align-self: center;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  .tip {
    max-width: 350px;
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
  }
  .extra {
    pointer-events: auto !important;
  }
`;
const Dot = styled.div`
  min-height: 12px;
  min-width: 12px;
  height: 12px;
  width: 12px;
  margin-right: 4px;
  border-radius: 50%;
`;

export const ShowExplanatoryOrder = (props) => {
    return (
        <div>
            {(props.habitatSelect || props.climateSelect) && (
                <Box
                    style={{ alignItems: "center" }}
                    direction={"row"}
                    margin={{ right: "8px" }}
                >
                    <Dot style={{ background: Theme.global.colors.barShade1 }}/>
                    <Text style={{ fontSize: "0.5875rem", marginLeft: "0.2rem" }}>
                        {props.order ? props.order : "All"}
                    </Text>
                </Box>
            )}
        </div>
    );
};
export const ShowCompareOrder = (props) => {
    return (
        <div>
            {props.compareTo && (
                <Box
                    style={{ alignItems: "center" }}
                    direction={"row"}
                    margin={{ right: "8px" }}
                >
                    <Dot style={{ background: Theme.global.colors.barShade }}/>
                    <Text style={{ fontSize: "0.5875rem", marginLeft: "0.2rem" }}>
                        {props.selectedCompareOrder ? props.selectedCompareOrder : "All"}
                    </Text>
                </Box>
            )}
        </div>
    );
};
const FieldTitleWithIcon = (props) => {
    var id = Math.random();
    const HoverDetail = () => {
        return (
            props.isShowIcon && (
                <Wrapper>
                    <Fade top distance="10px">
                        <ReactTooltip
                            id={id.toString()}
                            getContent={props.content}
                            className="tip extra"
                            clickable={true}
                            scrollHide={true}
                        />
                        <InfoIcon
                            data-for={id.toString()}
                            data-tip={props.InfoText}
                            data-effect="solid"
                            data-scroll-hide={true}
                            aria-label={"helpText"}
                            style={{
                                height: "1.15em",
                                width: "1.15em",
                                marginLeft: "5px",
                            }}
                        />
                    </Fade>
                </Wrapper>
            )
        );
    };

    return (
        <FieldsTitleWrapper direction="row" style={props.style ? props.style : {}}>
            {props.showLeft && HoverDetail()}
            {!props.headingLevel ? (
                <FieldsTitle>{props.title}</FieldsTitle>
            ) : (
                <Heading
                    margin={
                        props.headingMargin
                            ? props.headingMargin
                            : { top: "0rem", bottom: "0rem" }
                    }
                    level={props.headingLevel}
                >
                    {props.title}
                </Heading>
            )}
            {props.isRequiredField && <span style={{ color: "red" }}>*</span>}
            {!props.showLeft && HoverDetail()}
        </FieldsTitleWrapper>
    );
};

export const InformationIcon = (props) => {
    var id = Math.random();

    const HoverDetail = () => {
        return (
            <Wrapper>
                <Fade>
                    <ReactTooltip
                        id={id.toString()}
                        getContent={props.content}
                        className="tip extra"
                        clickable={true}
                        scrollHide={true}
                    />
                    <InfoIcon
                        style={{
                            width: "1.15em",
                            marginLeft: "5px",
                        }}
                        data-for={id.toString()}
                        data-effect="solid"
                        data-scroll-hide={true}
                        data-tip={props.InfoText}
                        aria-label={"helpText"}
                    />
                </Fade>
            </Wrapper>
        );
    };

    return <div>{HoverDetail()}</div>;
};

export default FieldTitleWithIcon;
