import { gql } from "apollo-boost";

const AmphRepWhereObj = "{ OR: [{ class: { id: 2 } }, { class: { id: 3 } }] }";
const MammalsWhereObj = "{ class: { id: 1 } }";

let whereObj = "";
let observationCount = "";

if (process.env.REACT_APP_TYPE_CODE === "2") {
    whereObj = AmphRepWhereObj;
    observationCount = "amphRepObsCount";
} else {
    whereObj = MammalsWhereObj;
    observationCount = "mammalsObsCount";
}

//SERVER SIDE QUERIES BELOW
const GET_ALL_GUIDE_LIST_QUERY = gql`
  query {
    species(where: ${whereObj}, orderBy:  commonName_ASC ) {
      id
      commonName
      binomialName
      mammalsObsCount
      amphRepObsCount
      family {
        commonName
        binomialName
        id
      }
      order {
        id
        commonName
        binomialName
      }
      images {
        url
        id
        caption
        credits
      }
      genus {
        id
        commonName
        binomialName
      }
      class {
        id
        commonName
        binomialName
      }
      endangered
      shortDescription
    }
  }
`;

const GET_ALL_ORDERS_QUERY = gql`
  query {
    orders(where: ${whereObj}) {
      id
      commonName
      binomialName
    }
  }
`;
const GET_USER_PROFILE_QUERY = gql`
  query getProfileData {
    me {
      id
      type
      name
      email
      password
      education
      city
      state
      image
      bio
      privacy
    }
  }
`;
const GET_MY_OBSERVATION_DOWNLOAD = gql`
  query getObservationsDownload {
    downloadMyObservationsAsCSV
  }
`;

const GET_MY_OBSERVATION_QUERY = gql`
  query getObservations($offset: Int, $limit: Int) {
    myObservations(offset: $offset, limit: $limit, orderBy:  dateTime_DESC,) {
      id
      dateTime
      gpsCoordinates
      age
      sex
      recordingMethod
      notes
      flagged
      privacy
      morphs
      quantity
      vocalization
      specie {
        id
        commonName
        binomialName
        mammalsObsCount
        amphRepObsCount
        endangered
        family {
          commonName
          binomialName
          id
        }
        order {
          id
          commonName
          binomialName
        }
        images {
          url
          id
          caption
          credits
        }
        genus {
          id
          commonName
          binomialName
        }
        class {
          id
          commonName
          binomialName
        }
        endangered
        shortDescription
      }
      order {
        id
        commonName
        binomialName
      }
      decRegion {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      watershed {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      county {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      quad {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }

      images {
        url
        id
        caption
        credits
      }
      user {
        id
        name
        city
        state
        image
      }
    }
    myObservationsCount
  }
`;

const GET_HOME_LIST_QUERY = gql`
  query {
    recentSpecies {
      id
      commonName
      binomialName
      mammalsObsCount
      amphRepObsCount
      family {
        commonName
        binomialName
        id
      }
      order {
        id
        commonName
        binomialName
      }
      images {
        url
        id
        sensitveImage
        caption
        credits
      }
      genus {
        id
        commonName
        binomialName
      }
      class {
        id
        commonName
        binomialName
      }
      endangered
      shortDescription
    }
    recentObservations {
      id
      age
      sex
      notes
      morphs
      dateTime
      quantity
      privacy
      flagged
      source
      recordingMethod
      gpsCoordinates
      vocalization
      decRegion {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      watershed {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      specie {
        id
        commonName
        binomialName
        mammalsObsCount
        amphRepObsCount
        endangered
        family {
          commonName
          binomialName
          id
        }
        order {
          id
          commonName
          binomialName
        }
        images {
          url
          id
          caption
          credits
        }
        genus {
          id
          commonName
          binomialName
        }
        class {
          id
          commonName
          binomialName
        }
        endangered
        shortDescription
      }
      county {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      order {
        id
        commonName
        binomialName
      }
      images {
        url
        id
        caption
        credits
        sensitveImage
      }
      user {
        id
        name
        city
        state
        image
      }
    }
  }
`;

const GET_TOP_CONTRIBUTORS = gql`
  query {
    topContributors {
      user {
        id
        name
        image
        city
      }
      currMonthObservations
      prevMonthObservations
      totalObservations
    }
  }
`;

const GET_DATA_TO_GENERATE_GRAPH = gql`
  query generateOccuranceGraph(
    $observationWhereInput: ObservationWhereInput
    $searchQuery: String
  ) {
    generateOccuranceGraph(
      where: $observationWhereInput
      searchQuery: $searchQuery
    ) {
      occuranceGraphData {
        name
        count
      }
      selectedZoneDetails {
        id
        name
        climate {
          id
        }
      }
      selectedTaxonomyDetails {
        id
        commonName
        binomialName
        shortDescription
      }
    }
  }
`;

const GET_ALL_ORDERS_SPECIES_QUERY = gql`
  query {
    orders(where: ${whereObj}, orderBy:  commonName_ASC) {
      id
      commonName
      binomialName
      genera {
        id
        commonName
        binomialName
      }
    }
    species(where: ${whereObj}, orderBy:  commonName_ASC ) {
      id
      commonName
      binomialName
      order {
        id
        commonName
        binomialName
      }
      genus {
        id
        commonName
      }
      morphs
    }
    genera {
      id
      commonName
      binomialName
      order {
        id
        commonName
      }
    }
  }
`;

const GET_SOURCE_IN_OBSERVATION = gql`
  query {
    sourceInObservations
  }
`;
const GET_OCCURRENCE_ENVIRONMENT_GRAPH_QUERY = gql`
  query generateOccuranceEnvironmentalRelationalGraph(
    $environmentType: EnvironmentType!
    $observationWhereInput: ObservationWhereInput
    $searchQuery: String
  ) {
    generateOccuranceEnvironmentalRelationalGraph(
      environmentType: $environmentType
      where: $observationWhereInput
      searchQuery: $searchQuery
    ) {
      absent {
        value
        errorDeviation
      }
      present {
        value
        errorDeviation
      }
    }
  }
`;
const GET_DIVERSITY_GRAPH_QUERY = gql`
  query generateDiversityGraphWithQuadData(
    $environmentType: EnvironmentType!
    $observationWhereInput: ObservationWhereInput
    $searchQuery: String
  ) {
    generateDiversityGraphWithQuadData(
      environmentType: $environmentType
      where: $observationWhereInput
      searchQuery: $searchQuery
    ) {
      diversityGraphData {
        id
        name
        specieCount
        environmentPercentage
        specie {
          id
        }
      }
      orderRichness
      selectedTaxonomyDetails {
        commonName
      }
      selectedEnvironmentFilter
      selectedTaxonomyType
      selectedZoneType
      selectedZoneDetails {
        id
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
    }
  }
`;
const GET_TOKEN_VERIFICATION_QUERY = gql`
  query tokenVarification($token: String!) {
    passwordTokenVerification(token: $token)
  }
`;

const SEARCH_AND_FILTER_OBSERVATION_QUERY = gql`
  query searchAndFilterObservations(
    $observationWhereInput: ObservationWhereInput!
    $searchQuery: String
    $offset: Int
    $limit: Int
  ) {
    observationsMeta(where: $observationWhereInput, searchQuery: $searchQuery)
    usersInObservations(
      where: $observationWhereInput
      searchQuery: $searchQuery
    ) {
      id
      type
      name
      email
      image
      privacy
      observationCount:${observationCount}
    }
    observations(
      where: $observationWhereInput
      searchQuery: $searchQuery
      offset: $offset
      limit: $limit
      orderBy:  dateTime_DESC,
    ) {
      id
      age
      sex
      notes
      morphs
      dateTime
      quantity
      privacy
      flagged
      source
      recordingMethod
      gpsCoordinates
      vocalization
      decRegion {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      watershed {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      county {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      quad {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      images {
        url
        id
        caption
        credits
      }
      user {
        id
        name
        type
        email
        privacy
      }
      specie {
        id
        mammalsObsCount
        amphRepObsCount
        commonName
        binomialName
        endangered
        family {
          commonName
          binomialName
          id
        }
        order {
          id
          commonName
          binomialName
        }
        images {
          url
          id
          caption
          credits
        }
        genus {
          id
          commonName
          binomialName
        }
        class {
          id
          commonName
          binomialName
        }
        endangered
        shortDescription
      }
      order {
        id
        commonName
        binomialName
      }
    }
  }
`;

const GET_OBSERVATIONS_HEAT_MAP_BY_ZONE_QUERY = gql`
  query observationsHeatMapByZone(
    $zoneTypes: [ZoneType!]
    $where: ObservationWhereInput
    $searchQuery: String
  ) {
    observationsHeatMapByZone(
      zoneTypes: $zoneTypes
      where: $where
      searchQuery: $searchQuery
    ) {
      min
      max
      zoneType
      zoneTotalCount
      observationHeatMapItem {
        observationsCount
        zoneId
        zoneName
        areaId
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
    }
  }
`;

const GET_OBSERVATION_QUERY = gql`
  query GET_OBSERVATION_QUERY($observationId: ID!) {
    observation(id: $observationId) {
      id
      age
      sex
      notes
      morphs
      dateTime
      quantity
      privacy
      flagged
      source
      recordingMethod
      gpsCoordinates
      vocalization
      decRegion {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      watershed {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      county {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      quad {
        id
        name
        climate {
          id
          temperature
          precipitation
        }
        landscape {
          id
          water
          urban
          forest
          shrub
          grass
          agriculture
          wetland
          protected
        }
      }
      images {
        url
        id
        caption
        credits
      }
      user {
        id
        name
        type
        email
        privacy
      }
      specie {
        id
        commonName
        binomialName
        endangered
        mammalsObsCount
        amphRepObsCount
        family {
          commonName
          binomialName
          id
        }
        order {
          id
          commonName
          binomialName
        }
        images {
          url
          id
          caption
          credits
        }
        genus {
          id
          commonName
          binomialName
        }
        class {
          id
          commonName
          binomialName
        }
        endangered
        shortDescription
      }
      order {
        id
        commonName
        binomialName
      }
    }
  }
`;

const GET_SPECIE_QUERY = gql`
  query GET_SPECIE_QUERY($specieId: ID!) {
    specie(id: $specieId) {
      id
      commonName
      binomialName
      mammalsObsCount
      amphRepObsCount
      family {
        commonName
        binomialName
        id
      }
      order {
        id
        commonName
        binomialName
      }
      images {
        url
        id
        caption
        credits
      }
      genus {
        id
        commonName
        binomialName
      }
      class {
        id
        commonName
        binomialName
      }
      endangered
      shortDescription
    }
  }
`;
const GET_FILE_DOWNLOAD_QUERY = gql`
  query downloardObservations(
    $observationWhereInput: ObservationWhereInput!
    $searchQuery: String
  ) {
    downloadObservationsAsCSV(
      where: $observationWhereInput
      searchQuery: $searchQuery
    )
  }
`;

const GET_JOB_RECORD_QUERY = gql`
  query getJobRecord($jobId: ID!) {
    jobRecord(id: $jobId) {
      id
      externalResLink
      status
    }
  }
`;

const GET_MY_JOBS = gql`
  query myJobs {
    me {
      id
      jobRecordToUser(where: { status: IN_PROGRESS }) {
        id
        status
      }
    }
  }
`;
//CLIENT SIDE QUERIES BELOW

const CLIENT_GET_SELECTED_ZONE_QUERY = gql`
  query {
    selectedZoneData @client(always: true) {
      name
      climate {
        id
        temperature
        precipitation
      }
      landscape {
        id
        water
        urban
        forest
        shrub
        grass
        agriculture
        wetland
        protected
      }
      location {
        lat
        lng
      }
    }
  }
`;

export default {
    //SERVER SIDE
    GET_SOURCE_IN_OBSERVATION,
    GET_ALL_GUIDE_LIST_QUERY,
    GET_ALL_ORDERS_QUERY,
    GET_USER_PROFILE_QUERY,
    GET_HOME_LIST_QUERY,
    GET_TOP_CONTRIBUTORS,
    SEARCH_AND_FILTER_OBSERVATION_QUERY,
    GET_ALL_ORDERS_SPECIES_QUERY,
    GET_OBSERVATIONS_HEAT_MAP_BY_ZONE_QUERY,
    GET_TOKEN_VERIFICATION_QUERY,
    GET_MY_OBSERVATION_QUERY,
    GET_OBSERVATION_QUERY,
    GET_SPECIE_QUERY,
    GET_DATA_TO_GENERATE_GRAPH,
    GET_FILE_DOWNLOAD_QUERY,
    GET_JOB_RECORD_QUERY,
    GET_DIVERSITY_GRAPH_QUERY,
    GET_MY_OBSERVATION_DOWNLOAD,
    GET_OCCURRENCE_ENVIRONMENT_GRAPH_QUERY,
    GET_MY_JOBS,
    //CLIENT SIDE
    CLIENT_GET_SELECTED_ZONE_QUERY,
};
