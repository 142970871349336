import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import styled from "styled-components";
import { Theme } from "../../theme";
import { getQuantityValue } from "../../utils";

const DesignColorContainer = styled.div`
  margin-top: 0.7rem;
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: row;
  }
`;

const ColorContainerStyle = styled(Box)`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const DiversityColorContainer = (props) => {
    const R = 211;
    const G = 146;
    const B = 60;
    const mapLayerColors = [
        `rgb(${R}, ${G}, ${B}, 0.1)`,
        `rgb(${R}, ${G}, ${B}, 0.3)`,
        `rgb(${R}, ${G}, ${B}, 0.55)`,
        `rgb(${R}, ${G}, ${B}, 0.8)`,
        `rgb(${R}, ${G}, ${B}, 1.2)`,
    ];
    const [colorsList, setColorsList] = useState([]);
    useEffect(() => {
        const list = [];
        let total = props.observationsCount > 3 ? 4 : props.observationsCount;
        for (let i = 0; i <= total; i++) {
            list.push(
                <ColorContainerStyle
                    key={i}
                    direction="row"
                    align="center"
                    margin={{ right: "0.5rem" }}
                >
                    <div
                        style={{
                            width: "0.8rem",
                            height: "0.8rem",
                            marginRight: "0.3rem",
                            backgroundColor: mapLayerColors[i],
                        }}
                    />
                    <Text size={"small"}>
                        {i !== 0 && <span>&#60; </span>}
                        {getQuantityValue(props.observationsCount, i)}
                    </Text>
                </ColorContainerStyle>
            );
        }
        setColorsList(list);
    }, [props.observationsCount]);

    const InfoView = () => (
        <Box
            elevation="medium"
            pad="small"
            margin={"small"}
            style={{ borderRadius: "0.5rem" }}
            background={Theme.global.colors.white}
            direction="column"
        >
            <Text
                size={"xsmall"}
                weight={"bold"}
                style={{
                    whiteSpace: "pre-line",
                    lineHeight: "1.2",
                    marginBottom: "0.5rem",
                }}
            >
                {props.titleFlag ? "Recorded species richnesss" : "Observations"}
            </Text>
            <DesignColorContainer direction="column" gap={"medium"}>
                {colorsList}
            </DesignColorContainer>
        </Box>
    );
    return <div>{props.observationsCount && <InfoView/>}</div>;
};
export default DiversityColorContainer;
