import React from "react";
import styled from "styled-components";
import ObservationTabMapView from "./ObservationTabMapView";
import ObservationTabDetailView from "./ObservationTabDetailView";

const RootContainer = styled.div`
  margin-top: 1rem;
  .leaflet-left{
    z-index: 10000;
  }
`;

const ObservationTab = (props) => {
    return (
        <RootContainer>
            {props.isMapView ? (
                <ObservationTabMapView
                    zone={props.zone}
                    zoneId={props.zoneId}
                    observations={props.observations}
                    observationsMeta={props.observationsMeta}
                    isMapView={props.isMapView}
                    isLayerClickable={true}
                    isShowColorContainer={true}
                    onItemClick={props.onItemClick}
                    onFlagClick={props.onFlagClick}
                    onEditClick={props.onEditClick}
                    CSVFileLoading={props.CSVFileLoading}
                    searchCallLoading={props.searchCallLoading}
                    isSpecieSelected={props.isSpecieSelected}
                    isOrderSelected={props.isOrderSelected}
                    heatMapByZoneData={props.heatMapByZoneData}
                    handleDownloadCSV={props.handleDownloadCSV}
                    handleZoneChanged={props.handleZoneChanged}
                    fetchMoreLoading={props.fetchMoreLoading}
                    handleLoadMoreObsevations={props.handleLoadMoreObsevations}
                />
            ) : (
                <ObservationTabDetailView
                    zone={props.zone}
                    zoneId={props.zoneId}
                    isLayerClickable={true}
                    isMapView={props.isMapView}
                    onItemClick={props.onItemClick}
                    onFlagClick={props.onFlagClick}
                    onEditClick={props.onEditClick}
                    observations={props.observations}
                    isShowColorContainer={false}
                    fetchMoreLoading={props.fetchMoreLoading}
                    isSpecieSelected={props.isSpecieSelected}
                    isOrderSelected={props.isOrderSelected}
                    searchCallLoading={props.searchCallLoading}
                    observationsMeta={props.observationsMeta}
                    handleZoneChanged={props.handleZoneChanged}
                    heatMapByZoneData={props.heatMapByZoneData}
                    handleLoadMoreObsevations={props.handleLoadMoreObsevations}
                />
            )}
        </RootContainer>
    );
};

export default ObservationTab;
