import React, { Fragment, useEffect, useState } from "react";
import { Queries } from "../apollo";
import styled from "styled-components";
import Loader from "../components/Loader";
import { SearchCustom, Theme } from "../theme";
import GuideItem from "../components/GuideCard";
import EmptyList from "../components/EmptyList";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Box, Heading, ResponsiveContext, Select, Text, TextInput, } from "grommet";
import { DEBOUNCE_TIME, INFO_TEXTS } from '../constants/Constants';
import { sortSpecieListAlphabetically, useDebounce } from "../utils";
import FieldTitleWithIconComponent from "../components/FieldTitleWithIconComponent";

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.size === "medium" ? "repeat(2,1fr)" : null};
  grid-template-columns: ${(props) =>
    props.size === "large" ? "repeat(3,1fr)" : null};
  @media (max-width: 1100px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
  grid-gap: 2rem;
  flex: 1;
  width: auto;
  flex-wrap: wrap;
`;
const FilterContainer = styled(Box)`
  width: 100%;
  margin-bottom: 3rem;
  margin-top: 3rem;

  @media only screen and (max-width: 1050px) {
    flex-direction: column;
    margin-bottom: 1em;
    margin-right: 0;
  }
`;

const CustomSelectItem = styled(Box)`
  background: ${(props) => props.bgColor};
  .select-text {
    fontweight: 100;
    color: ${(props) =>
    props.isSelected ? Theme.global.colors.white : Theme.global.colors.black};
  }
  .select-sub-text {
    font-style: italic;
    color: ${(props) =>
    props.isSelected ? Theme.global.colors.white : Theme.global.colors.grey};
    fontweight: 100;
    fontsize: 0.9rem;
    fontstyle: italic;
  }
`;

const SearchWrapper = styled.div`
  width: 34rem;
  @media only screen and (max-width: 1140px) {
    width: 100%;
  }
`;

const SearchBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 3rem;

  & > svg {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
  }

  input {
    padding-right: 2.5rem;
    font-size: 1rem;
    font-weight: 400;
    auto-focus: false;
  }
`;

const OrderWrapper = styled.div`
  width: 13rem;
  padding-top: 0;
  padding-right: 0;

  input {
    font-size: 1rem;
    font-weight: 400;
  }
  button {
    width: 13rem;
    height: 3rem;
  }

  @media only screen and (max-width: 1140px) {
    width: 100%;

    button {
      width: 100%;
      height: 3rem;
    }
  }
`;

const OrderAllObj = {
    id: -1,
    commonName: "All",
    binomialName: "All",
};

const allSuggestions = (species) => {
    let names = [];
    species.forEach((item) => {
        names.push(item);
    });
    return names;
};

const GuidePage = (props) => {
    const [orderList, setOrderList] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const debounceSearch = useDebounce(searchQuery, DEBOUNCE_TIME);
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(OrderAllObj);
    const [specieFilteredList, setSpecieFilteredList] = useState([]);
    const [suggestionOpen, setSuggestionOpen] = useState(false);

    const {} = useQuery(Queries.GET_ALL_ORDERS_QUERY, {
        onCompleted: (data) => {
            setOrderList([
                OrderAllObj,
                ...sortSpecieListAlphabetically(data.orders)
            ]);
        },
        fetchPolicy: "cache-first",
    });

    const [
        getSpeciesData,
        {
            data: speciesData,
            loading: speciesQueryLoading,
            error: speciesQueryError,
            refetch: speciesQueryRefetch,
        },
    ] = useLazyQuery(Queries.GET_ALL_GUIDE_LIST_QUERY, {
        fetchPolicy: "cache-first",
    });

    useEffect(() => {
        filter();
        if (!speciesData) {
            getSpeciesData();
        }
        // eslint-disable-next-line
    }, [
        speciesData,
        debounceSearch,
        selectedOrder
    ]);

    const onGuideListItemClick = (item) => {
        let path = `/guide/${item.id}`;
        props.history.push(path, { specieItem: item });
    };

    const onSearchItemClick = () => {
        setIsClicked(true);
    };

    const filter = () => {
        if (speciesData && speciesData.species) {
            let filtered = speciesData.species;

            // filter by order
            if (selectedOrder && selectedOrder.id >= 0) {
                filtered = filtered.filter((item) => {
                    return item.order.id === selectedOrder.id;
                });
            }

            // filter by query
            if (debounceSearch && debounceSearch.length > 0) {
                filtered = filtered.filter((item) => {
                    return (
                        item.commonName
                            .toLowerCase()
                            .includes(debounceSearch.toLowerCase()) ||
                        item.binomialName
                            .toLowerCase()
                            .includes(debounceSearch.toLowerCase())
                    );
                });
            }

            setSearchSuggestions(allSuggestions(filtered).sort());
            setSpecieFilteredList(
                filtered.sort(function (a, b) {
                    var textA = a.order.commonName.toUpperCase();
                    var textB = b.order.commonName.toUpperCase();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                })
            );
        }
    };

    const renderSuggestions = () => {
        return searchSuggestions
            ?.sort((a, b) => (a.commonName > b.commonName ? 1 : -1))
            .map((item) => ({
                label: (
                    <CustomSelectItem align="start" pad="small">
                        <Text className={"select-text"}>{item?.commonName}</Text>
                        <Text className={"select-sub-text"}>{item?.binomialName}</Text>
                    </CustomSelectItem>
                ),
                value: item.commonName,
            }));
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Fragment>
                    <Heading
                        color={Theme.global.colors.textBlack}
                        level={"1"}
                        margin={{
                            top: "small",
                            bottom: size === "small" ? "small" : "medium",
                        }}
                    >
                        Guide
                    </Heading>
                    <FilterContainer direction="row" justify="between" align="center">
                        <SearchWrapper>
                            <FieldTitleWithIconComponent
                                title={"Search"}
                                isShowIcon={false}
                            />
                            <SearchBarWrapper>
                                <TextInput
                                    placeholder={"Search species"}
                                    value={searchQuery}
                                    onChange={(event) => setSearchQuery(event.target.value)}
                                    onSelect={(event) => {
                                        setSearchQuery(event.suggestion.value);
                                    }}
                                    onClick={onSearchItemClick}
                                    suggestions={isClicked ? renderSuggestions() : []}
                                    dropHeight={"small"}
                                    size={"medium"}
                                    onSuggestionsOpen={() => setSuggestionOpen(true)}
                                    onSuggestionsClose={() => setSuggestionOpen(false)}
                                />

                                <SearchCustom color={Theme.global.colors.active}/>
                            </SearchBarWrapper>
                        </SearchWrapper>
                        <OrderWrapper>
                            <FieldTitleWithIconComponent title={"Order"} isShowIcon={true} InfoText={INFO_TEXTS.ORDER}/>
                            <Select
                                labelKey="commonName"
                                valueKey="commonName"
                                placeholder={"All"}
                                options={orderList}
                                value={selectedOrder}
                                onChange={({ option }) => {
                                    setSelectedOrder(option);
                                }}
                            />
                        </OrderWrapper>
                    </FilterContainer>

                    {speciesQueryLoading ? (
                        <Loader/>
                    ) : specieFilteredList.length > 0 ? (
                        <CardContainer size={size}>
                            {specieFilteredList.map((item) => {
                                return (
                                    <GuideItem
                                        key={"_SpeciesItem" + item.id}
                                        specieItem={item}
                                        onItemClick={() => {
                                            onGuideListItemClick(item);
                                        }}
                                    />
                                );
                            })}
                        </CardContainer>
                    ) : (
                        <EmptyList isError={false}/>
                    )}

                    {speciesQueryError && (
                        <EmptyList onRefetch={speciesQueryRefetch} isError={true}/>
                    )}
                </Fragment>
            )}
        </ResponsiveContext.Consumer>
    );
};
export default GuidePage;
