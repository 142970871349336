const DefaultConfig = {
    type: "Default",
    isMammals: true
};

const MammalsConfig = {
    type: "Mammals",
    isMammals: true
};

const AmphibianReptilesConfig = {
    type: "AmphibianReptiles",
    isMammals: false
};

export { DefaultConfig, MammalsConfig, AmphibianReptilesConfig };
