import React, { useMemo } from "react";
import { SearchCustom, Theme } from "../../theme";
import { Box, Text, TextInput } from "grommet";

const MultiLabelDropDown = ({
                                suggestedItems,
                                value,
                                placeholder,
                                onChange,
                                onSelect,
                                disabled
                            }) => {
    const suggestions = useMemo(() => {
        if (suggestedItems) {
            return suggestedItems
                .filter(
                    (option) =>
                        option.commonName.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                        (option.binomialName
                            ? option.binomialName
                            .toLowerCase()
                            ?.indexOf(value.toLowerCase()) >= 0
                            : false)
                )
                .map((option, index, list) => ({
                    label: (
                        <Box
                            align="start"
                            pad="small"
                            style={{
                                backgroundColor:
                                    option.commonName.toLowerCase() === value.toLowerCase()
                                        ? Theme.global.colors.brand
                                        : null,
                            }}
                        >
                            <Text style={{ fontWeight: "100" }}>{option.commonName}</Text>
                            {option.binomialName && (
                                <Text
                                    style={{
                                        color:
                                            value === option.commonName
                                                ? Theme.global.colors.white
                                                : Theme.global.colors.grey,
                                        fontWeight: "100",
                                        fontSize: "0.9rem",
                                        fontStyle: "italic",
                                    }}
                                >
                                    {option.binomialName}
                                </Text>
                            )}
                        </Box>
                    ),
                    value: option.commonName,
                }));
        }
    }, [
        suggestedItems,
        value
    ]);
    return (
        <TextInput
            placeholder={placeholder}
            style={{ fontSize: "0.9rem", fontWeight: "100" }}
            dropHeight={"small"}
            size={"medium"}
            value={value}
            disabled={disabled}
            suggestions={suggestions}
            onChange={onChange}
            onSelect={onSelect}
            icon={<SearchCustom color={Theme.global.colors.active}/>}
            reverse
        />
    );
};
export default MultiLabelDropDown;
