import { Anchor, Text } from "grommet";
import React from "react";

const FAQ = (props) => {
    const answerType = props.answerType ? props.answerType : "text";
    return (
        <div style={{ marginBottom: "1rem", ...props.style }}>
            <Text style={{ fontWeight: "bold", ...props.questionStyle }}>
                {props.question}
            </Text>
            <Text
                weight={"normal"}
                style={{ display: "block", fontSize: "18px", ...props.answerStyle }}
            >
                {answerType === "text" && props.answer}
                {props.link && <Anchor
                    href={props.link} target="_blank"
                    label={props.linkText}
                    download={props.download}/>}
                {props.secondPartOfText && props.secondPartOfText}
                {props.link2 && <Anchor href={props.link2} target="_blank" label={props.linkText2}/>}
                {props.thirdPartOfText && props.thirdPartOfText}
                {answerType === "html" && (
                    <p dangerouslySetInnerHTML={{ __html: props.answer }}/>
                )}
            </Text>
        </div>
    );
};
export default FAQ;
