function getUserInput(userInfo) {
    return {
        variables: {
            createUserInput: {
                ...userInfo,
            },
        },
    };
}

function getCsvFileToDownload(filterObj, searchString, skipQuery) {
    let jsonObject = {
        variables: {
            observationWhereInput: {
                ...filterObj,
            },
            searchQuery: searchString,
        },
        skip: skipQuery,
        fetchPolicy: "network-only",
    };
    return jsonObject;
}

function getOccuranceGraph(filterObj, searchString) {
    let jsonObject = {
        variables: {
            observationWhereInput: {
                ...filterObj,
            },
            searchQuery: searchString,
            fetchPolicy: "cache-first",
        },
    };
    return jsonObject;
}

function getDiversityGraph(environmentType, filterObj, searchString, skip) {
    let jsonObject = {
        variables: {
            observationWhereInput: {
                ...filterObj,
            },
            searchQuery: searchString,
            environmentType: environmentType,
        },
        skip: skip,
        fetchPolicy: "cache-first",
    };
    return jsonObject;
}

function getOccuranceEnvironmentalRelationalGraph(
    environmentType,
    filterObj,
    searchString,
    skip
) {
    let jsonObject = {
        variables: {
            observationWhereInput: {
                ...filterObj,
            },
            searchQuery: searchString,
            environmentType: environmentType,
        },
        skip: skip,
        fetchPolicy: "cache-first",
    };
    return jsonObject;
}

function getUserProfileInput(userInfo) {
    return {
        variables: {
            updateUserInput: {
                type: userInfo.type,
                name: userInfo.name,
                education: userInfo.education,
                city: userInfo.city,
                state: userInfo.state,
                image: userInfo.image,
                bio: userInfo.bio,
                privacy: userInfo.privacy,
            },
        },
    };
}

function getObservationOffset(offset) {
    let jsonObject = {
        variables: {
            offset: offset,
            limit: 15,
        },
        fetchPolicy: "cache-and-network",
    };
    return jsonObject;
}

function getLoginInput(userInfo) {
    let jsonObject = {
        variables: {
            loginUserInput: {
                ...userInfo,
            },
        },
    };
    return jsonObject;
}

export function getEmailForReset(userInfo) {
    let jsonObject = {
        variables: {
            email: userInfo,
        },
    };
    return jsonObject;
}

export function getTokenForResetPassword(token) {
    let jsonObject = {
        variables: {
            token: token,
        },
    };
    return jsonObject;
}

export function ResetPassword(userInfo) {
    let jsonObject = {
        variables: {
            token: userInfo.token,
            password: userInfo.password,
        },
    };
    return jsonObject;
}

function getObservationCreateInput(record) {
    return {
        variables: {
            observationCreateInput: {
                ...record,
            },
        },
    };
}

function getObservationWhereUniqueInput(id) {
    return {
        variables: {
            observationWhereUniqueInput: {
                id: id,
            },
        },
    };
}

function getObservationUpdateInput(record, observationId) {
    let jsonObject = {
        variables: {
            observationUpdateInput: {
                ...record,
            },
            observationId: {
                id: observationId,
            },
        },
    };
    return jsonObject;
}

function getSearchQueryObject(filterObj, searchString, length, skip) {
    return {
        variables: {
            observationWhereInput: {
                ...filterObj,
            },
            searchQuery: searchString,
            offset: length,
            limit: 30,
        },
        skip: skip,
        fetchPolicy: "network-only",
    };
}

function observationsHeatMapByZoneInput(zoneTypes, where, searchQuery, skip) {
    return {
        variables: {
            zoneTypes: zoneTypes,
            where: {
                startingDateLimit: where.startingDateLimit,
                endingDateLimit: where.endingDateLimit,
                specie: where.specie,
                order: where.order,
                user: where.user,
                source: where.source,
                genus: where.genus,
                showFlaggedEnteries: where.showFlaggedEnteries,
                showPrivateEnteries: where.showPrivateEnteries,
            },
            searchQuery: searchQuery,
        },
        skip: skip,
        fetchPolicy: "cache-and-network",
    };
}

function getObservationInput(id) {
    return {
        variables: {
            observationId: id,
        },
    };
}

function getSpecieInput(id) {
    return {
        variables: {
            specieId: id,
        },
        fetchPolicy: "network-only",
    };
}

function createJobRecordInput(url) {
    return {
        variables: {
            jobRecordCreateInput: {
                url: url,
            },
        },
    };
}

function getJobRecordInput(jobId) {
    return {
        variables: {
            jobId: jobId,
        },
    };
}

/////CLIENT SIDE CALL OBJECTS BELOW

function setSelectedZoneInput(name, climate, landscape, location) {
    const json = {
        variables: {
            selectedZoneData: {
                name: name,
                climate: climate,
                landscape: landscape,
                location: location,
            },
        },
    };
    return json;
}

export {
    //SERVER SIDE
    getUserInput,
    getLoginInput,
    getSpecieInput,
    getJobRecordInput,
    getUserProfileInput,
    getSearchQueryObject,
    getObservationOffset,
    getCsvFileToDownload,
    getObservationInput,
    createJobRecordInput,
    getObservationCreateInput,
    getObservationWhereUniqueInput,
    getObservationUpdateInput,
    getOccuranceGraph,
    observationsHeatMapByZoneInput,
    getDiversityGraph,
    getOccuranceEnvironmentalRelationalGraph,
    //CLIENT SIDE
    setSelectedZoneInput,
};
