import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Box, Image, Text } from "grommet";
import { LocationLogo, Theme, UserIcon } from "../../theme";

const TextWrapperLatestCard = styled(Box)`
  color: ${Theme.global.colors.textBlack};
  padding: 1rem;
`;

const GuideItemWrapper = styled(Box)`
  min-height: 10rem;
  min-width: ${(props) => (props.width ? props.width : "")};
  margin-bottom: 0.5rem;
  position: relative;
  cursor: pointer;
  display: flex;
  flex: 1;
  width: auto;
  min-width: 200px;
`;

const ImageContainer = styled(Box)`
  height: 11.5rem;
  flex-direction: row;
`;
const LatestObservationCard = (props) => {
    const [data] = useState(props);
    const [observationTimeInput, setObservationTimeInput] = useState(
        props.dateTime
    );
    useEffect(() => {
        var indexToSplit = observationTimeInput.indexOf("T");
        var first = observationTimeInput.slice(0, indexToSplit);
        const time = moment(first, "YYYY-MM-DD");
        setObservationTimeInput(time.format("MMM, Do YYYY"));
        // eslint-disable-next-line
    }, [data]);

    const getEllipsisText = (str, len) => {
        const stringLenght = str.length;
        if (stringLenght > len) {
            return str.substring(0, len) + "...";
        } else {
            return str;
        }
    };

    return (
        <GuideItemWrapper
            ariaLabel={"guideItem"}
            tabIndex="0"
            role="link"
            width={props.width}
            onClick={props.onItemClick}
            round="0.25rem"
            elevation="medium"
            background={Theme.global.colors.white}
        >
            <ImageContainer round="small">
                <Image
                    style={{
                        borderTopLeftRadius: "0.25rem",
                        borderTopRightRadius: "0.25rem",
                    }}
                    fit="cover"
                    src={props.image}
                />
            </ImageContainer>
            <Box>
                <TextWrapperLatestCard>
                    <Text
                        style={{
                            color: Theme.global.colors.textBlack,
                            fontSize: ".85rem",
                        }}
                    >
                        {observationTimeInput}
                    </Text>
                    <Text
                        style={{
                            fontWeight: "bold",
                            color: Theme.global.colors.textBlack,
                            fontSize: "1rem",
                        }}
                    >
                        {getEllipsisText(props.specieName, 20)}
                    </Text>

                    <Box style={{ display: "flex", flexDirection: "row" }}>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginRight: "1rem",
                            }}
                        >
                            <UserIcon width={".9rem"} color={Theme.global.colors.grey}/>
                            <Text
                                style={{
                                    color: Theme.global.colors.grey,
                                    fontSize: "0.8rem",
                                    marginLeft: "0.2rem",
                                }}
                            >
                                {getEllipsisText(props.userName, 15)}
                            </Text>
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <LocationLogo width={"0.9rem"} color={Theme.global.colors.grey}/>
                            <Text
                                style={{
                                    color: Theme.global.colors.grey,
                                    fontSize: "0.8rem",
                                    marginLeft: "0.2rem",
                                }}
                            >
                                {getEllipsisText(props.countryName, 10)}
                            </Text>
                        </Box>
                    </Box>
                </TextWrapperLatestCard>
            </Box>
        </GuideItemWrapper>
    );
};

export default LatestObservationCard;
