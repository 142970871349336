const Errors = {
    REQUIRED_FIELD: 'This field is required.',
    REQUIRED_FIELDS: 'Fields with * are required.',
    QUANTITY_FIELDS: 'Quantity should be grater then zero.',
    FILL_REQUIRE_FIELDS_FIRST: "Please fill require fields first.",
    IMAGE_REQUIRE: 'Please upload image.',
    INVALID_EMAIL: 'Email is invalid.',
    INVALID_PASSWORD:
        "Password must contain 1 uppercase, 1 lowercase letter, 1 special character, 1 number and must be at least 8 characters long.",
    EMPTY_FIELD: 'Field can\'t be empty.',
    USER_NOT_FOUND: 'Email does not exist. Please sign up.',
    CHECK_EMAIL: "Please check your email for passwod reset link.",
    ENTER_EMAIL: "Please enter your email.",
    PLEASE_WAIT: "Please wait...",
};

export { Errors };
