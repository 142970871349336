import React, { useState } from "react";
import styled from "styled-components";
import { Theme } from "../../theme";
import { Box, Heading, Text, TextArea, TextInput } from "grommet";
import FieldTitleWithIconComponent from "../FieldTitleWithIconComponent";
import { Errors } from "../../constants";
import { isEmpty, isValidEmail, isValidPassword } from "../../helpers";

const Root = styled(Box)``;

const TextInputFiledWrapper = styled.div`
  input {
    font-size: 1rem;
    font-weight: 400;
  }
`;

const TextAreaCustom = styled(TextArea)`
  font-size: 1rem;
  font-weight: 400;
  ::placeholder {
    font-size: 1rem;
    font-weight: 400;
    font-weight: lighter;
  }
`;

const EmailField = (props) => {
    const [emailState, setEmailState] = useState({
        text: "",
        error: "",
    });

    const validateEmail = (value) => {
        if (isEmpty(value)) {
            setEmailState({
                text: value,
                error: Errors.EMPTY_FIELD,
            });
            props.onValueChange(null);
            return;
        } else if (isValidEmail(value)) {
            setEmailState({
                text: value,
                error: "",
            });
            props.onValueChange(value);
            return;
        } else {
            setEmailState({
                text: value,
                error: Errors.INVALID_EMAIL,
            });
            props.onValueChange(null);
            return;
        }
    };

    return (
        <Root>
            <Box direction="column">
                <FieldTitleWithIconComponent
                    title={"Email"}
                    isShowIcon={false}
                    isRequiredField={true}
                />
                <TextInputFiledWrapper>
                    <TextInput
                        size={props.size}
                        placeholder={"Your email here "}
                        type={"email"}
                        value={emailState.text}
                        onChange={(event) => {
                            validateEmail(event.target.value);
                        }}
                    />
                </TextInputFiledWrapper>

                <Box color={Theme.global.colors.lightRed}>
                    <Text color={Theme.global.colors.red} style={{ fontSize: "0.85rem" }}>
                        {emailState.error}
                    </Text>
                </Box>
            </Box>
        </Root>
    );
};

const PasswordField = (props) => {
    const [passwordState, setPasswordState] = useState({
        text: "",
        error: "",
    });

    const validatePassword = (value) => {
        if (isEmpty(value)) {
            setPasswordState({
                text: value,
                error: Errors.EMPTY_FIELD,
            });
            props.onValueChange(null);
        } else if (isValidPassword(value)) {
            setPasswordState({
                text: value,
                error: "",
            });
            props.onValueChange(value);
        } else {
            setPasswordState({
                text: value,
                error: Errors.INVALID_PASSWORD,
            });
            props.onValueChange(null);
        }
    };

    return (
        <Root>
            <Box direction="column">
                <FieldTitleWithIconComponent
                    title={props.title}
                    isShowIcon={false}
                    isRequiredField={true}
                />
                <TextInputFiledWrapper>
                    <TextInput
                        size={props.size}
                        placeholder="Your password here "
                        type={"password"}
                        value={passwordState.text}
                        onChange={(event) => validatePassword(event.target.value)}
                    />
                </TextInputFiledWrapper>

                <Box color={Theme.global.colors.lightRed}>
                    <Text color={Theme.global.colors.red} style={{ fontSize: "0.85rem" }}>
                        {passwordState.error}
                    </Text>
                </Box>
            </Box>
        </Root>
    );
};

const TextField = (props) => {
    const [fieldState, setFieldState] = useState({
        text: props.value,
        error: "",
    });

    const validateField = (value) => {
        if (isEmpty(value)) {
            setFieldState({
                text: value,
                error: Errors.EMPTY_FIELD,
            });
            props.onValueChange(null);
        } else {
            setFieldState({
                text: value,
                error: "",
            });
            props.onValueChange(value);
        }
    };
    return (
        <Root>
            <Box direction="column">
                <FieldTitleWithIconComponent
                    title={props.title}
                    isRequiredField={props.isRequired}
                    isShowIcon={false}
                />
                <TextInputFiledWrapper>
                    <TextInput
                        size={props.size}
                        placeholder={props.placeholder}
                        value={fieldState.text}
                        onChange={(event) => validateField(event.target.value)}
                    />
                </TextInputFiledWrapper>
                <Box color={Theme.global.colors.lightRed}>
                    <Text color={Theme.global.colors.red} style={{ fontSize: "0.85rem" }}>
                        {fieldState.error}
                    </Text>
                </Box>
            </Box>
        </Root>
    );
};

const TextBox = (props) => {
    const [fieldState, setFieldState] = useState({
        text: props.value,
        error: "",
    });

    const validateField = (value) => {
        // if (isEmpty(value)) {
        //   setFieldState({
        //     text: value,
        //     error: Errors.EMPTY_FIELD,
        //   });
        //   props.onValueChange(null);
        // } else {
        setFieldState({
            text: value,
            error: "",
        });
        props.onValueChange(value);
    };
    return (
        <Root>
            <Box direction="column">
                <Box direction="row" align="center">
                    <Heading
                        color={Theme.global.colors.textBlack}
                        level={2}
                        margin={{ bottom: "0.75rem", right: "0.3rem" }}
                    >
                        Short biography
                    </Heading>
                </Box>
                <TextInputFiledWrapper>
                    <TextAreaCustom
                        size={"large"}
                        resize={false}
                        placeholder={props.placeholder}
                        value={fieldState.text}
                        onChange={(event) => {
                            validateField(event.target.value);
                        }}
                    />
                </TextInputFiledWrapper>
                <Box color={Theme.global.colors.lightRed}>
                    <Text color={Theme.global.colors.red} style={{ fontSize: "0.85rem" }}>
                        {fieldState.error}
                    </Text>
                </Box>
            </Box>
        </Root>
    );
};
export { EmailField, PasswordField, TextField, TextBox };
