import React, { useState } from "react";
import styled from "styled-components";
import { INFO_TEXTS, MAP_LAYERS } from "../../constants";
import MapComponent from "../../components/MapComponent";
import { Box, RadioButtonGroup, ResponsiveContext } from "grommet";
import FieldTitleWithIconComponent from "../../components/FieldTitleWithIconComponent";

const RootContainer = styled(Box)``;
const MapLayerContainer = styled(Box)`
  @media screen and (max-width: 1280px) {
    display: block;
    flex-direction: column;
    align-self: start;
  }
`;

const RadioButtonGroupStyle = styled(RadioButtonGroup)`
  font-size: 1rem;
  @media screen and (max-width: 1280px) {
    display: block;
    flex-direction: column;
    margin-top: 0.5rem;
  }
`;

const MapContainer = styled(Box)`
  margin-top: 1.437rem;
  width: 100%;
`;

const ObservationTabMapView = (props) => {
    const [mapLayerSelected, setMapLayerSelected] = useState(props.zone);
    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <RootContainer direction="column">
                    <MapLayerContainer
                        direction="row-responsive"
                        alignSelf={"end"}
                        gap={size === "small" ? "small" : "large"}
                    >
                        <Box direction="row">
                            <FieldTitleWithIconComponent
                                title={"Map layer:"}
                                isShowIcon={true}
                                isRequiredField={false}
                                showLeft={true}
                                content={() => INFO_TEXTS.CLIMATE_DIVERITY_INFO}
                                InfoText={INFO_TEXTS.MAP_LAYER_INFO}
                            />
                        </Box>

                        <RadioButtonGroupStyle
                            name="Map layer"
                            direction="row"
                            gap={size === "small" ? "small" : "medium"}
                            options={MAP_LAYERS}
                            value={mapLayerSelected}
                            onChange={(event) => {
                                setMapLayerSelected(event.target.value);
                                props.handleZoneChanged(event.target.value, "");
                            }}
                        />
                    </MapLayerContainer>
                    <MapContainer>
                        <MapComponent
                            size={size}
                            zone={props.zone}
                            zoneId={props.zoneId}
                            isMapView={props.isMapView}
                            onItemClick={props.onItemClick}
                            onFlagClick={props.onFlagClick}
                            onEditClick={props.onEditClick}
                            observations={props.observations}
                            CSVFileLoading={props.CSVFileLoading}
                            isOrderSelected={props.isOrderSelected}
                            fetchMoreLoading={props.fetchMoreLoading}
                            observationsMeta={props.observationsMeta}
                            isLayerClickable={props.isLayerClickable}
                            isSpecieSelected={props.isSpecieSelected}
                            searchCallLoading={props.searchCallLoading}
                            handleZoneChanged={props.handleZoneChanged}
                            heatMapByZoneData={props.heatMapByZoneData}
                            handleDownloadCSV={props.handleDownloadCSV}
                            isShowColorContainer={props.isShowColorContainer}
                            handleLoadMoreObsevations={props.handleLoadMoreObsevations}
                            height={size === "small" ? "44rem" : "38rem"}
                        ></MapComponent>
                    </MapContainer>
                </RootContainer>
            )}
        </ResponsiveContext.Consumer>
    );
};

export default ObservationTabMapView;
