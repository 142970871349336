import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Theme } from "../../theme";
import styled from "styled-components";
import Avatar from "react-avatar-edit";
import { getImageURL, UploadFileToS3 } from "../../utils";
import { Box, Button, Text } from "grommet";

const RootContainer = styled(Box)`
  width: 100%;
  flex-direction: column;
`;

const ActionButton = styled(Button)`
  color: ${Theme.global.colors.active};
  font-size: 1.2rem;
`;

const ImageContainer = styled(Box)`
  width: 100%;
  height: 14rem;
  width: 22rem;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const CropContainer = styled.div`
  position: static;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const PreviewImageContainer = styled(Box)`
  position: relative;
  flex: 1;
`;
const PreviewImage = styled.img`
  position: absolute;
  margin: auto;
  height: 14rem;
`;

const CropAndUploadImageComponent = forwardRef((props, ref) => {
    const image = props.isShowingCropView
        ? URL.createObjectURL(props.imageFileToUpload)
        : getImageURL(props.imageFileToUpload);

    const [preview, setPreview] = useState(null);
    const [isCropped, setIsCropped] = useState(!props.isShowingCropView);

    const base64toFile = (base64, filename) => {
        var arr = base64.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    useImperativeHandle(ref, () => ({
        async UploadImage() {
            let userName = props.userName.replace(/[^A-Z0-9]/gi, "_");
            userName = userName.toLowerCase();
            const newName = `profile/${userName}/${userName}_${new Date().getTime()}.jpg`;
            var file = base64toFile(preview, newName);
            try {
                const data = await UploadFileToS3(file);
                props.onImageUploaded(data);
            } catch (err) {
                throw err;
            }
        },
    }));

    const onClose = () => {
        props.clearImage();
    };

    const onCrop = (data) => {
        setPreview(data);
    };

    const ShowPreview = () => {
        return (
            <PreviewImageContainer align="center">
                <PreviewImage src={props.isShowingCropView ? preview : image}/>
            </PreviewImageContainer>
        );
    };

    return (
        <RootContainer gap="xsmall" align="center">
            <ImageContainer>
                <CropContainer>
                    {isCropped ? (
                        <ShowPreview/>
                    ) : (
                        <Avatar
                            width={300}
                            height={220}
                            onCrop={(data) => onCrop(data)}
                            onClose={() => onClose()}
                            src={image}
                        />
                    )}
                </CropContainer>
            </ImageContainer>
            {!isCropped && (
                <Text size={"medium"} color={Theme.global.colors.textBlack}>
                    Adjust image by drag & scale.
                </Text>
            )}

            <ActionButton
                label={isCropped ? "Clear" : "Crop"}
                onClick={() => {
                    isCropped ? onClose() : setIsCropped(true);
                }}
            />
        </RootContainer>
    );
});

export default CropAndUploadImageComponent;
