import React from "react";
import { Anchor, Text } from "grommet";

const USER_SESSION_KEY = "USER_SESSION_KEY";
const USER_TOKEN_KEY = "USER_TOKEN_KEY";
const BULK_UPLOAD_JOB_ID = "BULK_UPLOAD_JOB_ID";
const DUMMY_PIC_URL = process.env.PUBLIC_URL + "/default_img.png";

const STATES = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Minor Outlying Islands",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "U.S. Virgin Islands",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
];

const CITIES = [
    "Accord",
    "Adams",
    "Adams Center",
    "Addison",
    "Afton",
    "Airmont",
    "Akron",
    "Albany",
    "Albertson",
    "Albion",
    "Alden",
    "Alexander",
    "Alexandria Bay",
    "Alfred",
    "Allegany",
    "Almond",
    "Altamont",
    "Altmar",
    "Altona",
    "Amagansett",
    "Amenia",
    "Ames",
    "Amityville",
    "Amsterdam",
    "Andes",
    "Andover",
    "Angelica",
    "Angola",
    "Angola on the Lake",
    "Antwerp",
    "Apalachin",
    "Aquebogue",
    "Arcade",
    "Ardsley",
    "Argyle",
    "Arkport",
    "Arlington",
    "Armonk",
    "Asharoken",
    "Athens",
    "Atlantic Beach",
    "Attica",
    "Au Sable Forks",
    "Auburn",
    "Aurora",
    "Averill Park",
    "Avoca",
    "Avon",
    "Babylon",
    "Bainbridge",
    "Baiting Hollow",
    "Baldwin",
    "Baldwin Harbor",
    "Baldwinsville",
    "Ballston Spa",
    "Balmville",
    "Bardonia",
    "Barker",
    "Barneveld",
    "Barnum Island",
    "Batavia",
    "Bath",
    "Baxter Estates",
    "Bay Park",
    "Bay Shore",
    "Bayport",
    "Bayville",
    "Baywood",
    "Beacon",
    "Beaver Dam Lake",
    "Bedford",
    "Bedford Hills",
    "Belfast",
    "Belle Terre",
    "Bellerose",
    "Bellerose Terrace",
    "Belleville",
    "Bellmore",
    "Bellport",
    "Belmont",
    "Bemus Point",
    "Bergen",
    "Bethpage",
    "Big Flats",
    "Billington Heights",
    "Binghamton",
    "Binghamton University",
    "Black River",
    "Blasdell",
    "Blauvelt",
    "Bliss",
    "Blodgett Mills",
    "Bloomfield",
    "Bloomingburg",
    "Bloomville",
    "Blue Point",
    "Bohemia",
    "Bolivar",
    "Bolton Landing",
    "Boonville",
    "Brasher Falls",
    "Breesport",
    "Brentwood",
    "Brewerton",
    "Brewster",
    "Brewster Hill",
    "Briarcliff Manor",
    "Bridgehampton",
    "Bridgeport",
    "Bridgewater",
    "Brighton",
    "Brightwaters",
    "Brinckerhoff",
    "Broadalbin",
    "Brockport",
    "Brocton",
    "Bronxville",
    "Brookhaven",
    "Brookville",
    "Brownville",
    "Brushton",
    "Buchanan",
    "Buffalo",
    "Burdett",
    "Burke",
    "Busti",
    "Byersville",
    "Cairo",
    "Calcium",
    "Caledonia",
    "Callicoon",
    "Calverton",
    "Cambridge",
    "Camden",
    "Camillus",
    "Campbell",
    "Canajoharie",
    "Canandaigua",
    "Canaseraga",
    "Canastota",
    "Candor",
    "Canisteo",
    "Canton",
    "Cape Vincent",
    "Carle Place",
    "Carmel Hamlet",
    "Caroga Lake",
    "Carthage",
    "Cassadaga",
    "Castile",
    "Castleton-on-Hudson",
    "Castorland",
    "Cato",
    "Catskill",
    "Cattaraugus",
    "Cayuga",
    "Cayuga Heights",
    "Cazenovia",
    "Cedarhurst",
    "Celoron",
    "Center Moriches",
    "Centereach",
    "Centerport",
    "Central Bridge",
    "Central Islip",
    "Central Square",
    "Centre Island",
    "Chadwicks",
    "Champlain",
    "Chappaqua",
    "Chateaugay",
    "Chatham",
    "Chaumont",
    "Chautauqua",
    "Chazy",
    "Cheektowaga",
    "Chenango Bridge",
    "Cherry Creek",
    "Cherry Valley",
    "Chester",
    "Chestertown",
    "Chestnut Ridge",
    "Chittenango",
    "Churchville",
    "Clarence",
    "Clarence Center",
    "Clark Mills",
    "Clarkson",
    "Claverack-Red Mills",
    "Clayton",
    "Clayville",
    "Cleveland",
    "Clifton Springs",
    "Clinton",
    "Clintondale",
    "Clyde",
    "Cobleskill",
    "Cohocton",
    "Cohoes",
    "Cold Brook",
    "Cold Spring",
    "Cold Spring Harbor",
    "Colonie",
    "Colton",
    "Commack",
    "Conesus Hamlet",
    "Conesus Lake",
    "Congers",
    "Constableville",
    "Constantia",
    "Coopers Plains",
    "Cooperstown",
    "Copake Falls",
    "Copake Hamlet",
    "Copake Lake",
    "Copenhagen",
    "Copiague",
    "Coram",
    "Corfu",
    "Corinth",
    "Corning",
    "Cornwall-on-Hudson",
    "Cortland",
    "Cortland West",
    "Country Knolls",
    "Cove Neck",
    "Coxsackie",
    "Cragsmoor",
    "Cranberry Lake",
    "Croghan",
    "Crompond",
    "Croton-on-Hudson",
    "Crown Heights",
    "Crugers",
    "Crystal Beach",
    "Cuba",
    "Cumberland Head",
    "Cumminsville",
    "Cutchogue",
    "Cuylerville",
    "Dalton",
    "Dannemora",
    "Dansville",
    "Davenport Center",
    "Deer Park",
    "Deferiet",
    "DeKalb Junction",
    "Delanson",
    "Delevan",
    "Delhi",
    "Depauville",
    "Depew",
    "Deposit",
    "Dering Harbor",
    "DeRuyter",
    "Dexter",
    "Dix Hills",
    "Dobbs Ferry",
    "Dolgeville",
    "Dover Plains",
    "Downsville",
    "Dresden",
    "Dryden",
    "Duane Lake",
    "Duanesburg",
    "Dundee",
    "Dunkirk",
    "Durhamville",
    "Earlville",
    "East Atlantic Beach",
    "East Aurora",
    "East Avon",
    "East Farmingdale",
    "East Glenville",
    "East Greenbush",
    "East Hampton",
    "East Hampton North",
    "East Hills",
    "East Islip",
    "East Ithaca",
    "East Kingston",
    "East Marion",
    "East Massapequa",
    "East Meadow",
    "East Moriches",
    "East Nassau",
    "East Northport",
    "East Norwich",
    "East Patchogue",
    "East Quogue",
    "East Randolph",
    "East Rochester",
    "East Rockaway",
    "East Shoreham",
    "East Syracuse",
    "East Williston",
    "Eastchester",
    "Eastport",
    "Eatons Neck",
    "Eden",
    "Edmeston",
    "Edwards",
    "Eggertsville",
    "Elba",
    "Elbridge",
    "Elizabethtown",
    "Ellenville",
    "Ellicottville",
    "Ellisburg",
    "Elma Center",
    "Elmira",
    "Elmira Heights",
    "Elmont",
    "Elmsford",
    "Elwood",
    "Endicott",
    "Endwell",
    "Erin",
    "Esperance",
    "Evans Mills",
    "Fabius",
    "Fair Haven",
    "Fairmount",
    "Fairport",
    "Fairview",
    "Fairview CDP (Westchester County)",
    "Falconer",
    "Farmingdale",
    "Farmingville",
    "Farnham",
    "Fayetteville",
    "Felts Mills",
    "Fillmore",
    "Fire Island",
    "Firthcliffe",
    "Fishers Island",
    "Fishers Landing",
    "Fishkill",
    "Flanders",
    "Fleischmanns",
    "Floral Park",
    "Florida",
    "Flower Hill",
    "Fonda",
    "Forest Home",
    "Forestville",
    "Fort Ann",
    "Fort Covington Hamlet",
    "Fort Drum",
    "Fort Edward",
    "Fort Johnson",
    "Fort Montgomery",
    "Fort Plain",
    "Fort Salonga",
    "Fowlerville",
    "Frankfort",
    "Franklin",
    "Franklin Square",
    "Franklinville",
    "Fredonia",
    "Freedom Plains",
    "Freeport",
    "Freeville",
    "Frewsburg",
    "Friendship",
    "Fulton",
    "Fultonville",
    "Gainesville",
    "Galeville",
    "Galway",
    "Gang Mills",
    "Garden City",
    "Garden City Park",
    "Garden City South",
    "Gardiner",
    "Gardnertown",
    "Gasport",
    "Gates",
    "Geneseo",
    "Geneva",
    "Germantown",
    "Ghent",
    "Gilbertsville",
    "Gilgo",
    "Glasco",
    "Glen Aubrey",
    "Glen Cove",
    "Glen Head",
    "Glen Park",
    "Glens Falls",
    "Glens Falls North",
    "Glenwood Landing",
    "Gloversville",
    "Golden's Bridge",
    "Gordon Heights",
    "Gorham",
    "Goshen",
    "Gouverneur",
    "Gowanda",
    "Grand View-on-Hudson",
    "Grandyle Village",
    "Granville",
    "Great Bend",
    "Great Neck",
    "Great Neck Estates",
    "Great Neck Gardens",
    "Great Neck Plaza",
    "Great River",
    "Greece",
    "Green Island",
    "Greene",
    "Greenlawn",
    "Greenport",
    "Greenport West",
    "Greenvale",
    "Greenville",
    "Greenville CDP (Greene County)",
    "Greenwich",
    "Greenwood Lake",
    "Greigsville",
    "Groton",
    "Groveland Station",
    "Guilford",
    "Hadley",
    "Hagaman",
    "Hailesboro",
    "Halesite",
    "Hall",
    "Hamburg",
    "Hamilton",
    "Hamlin",
    "Hammond",
    "Hammondsport",
    "Hampton Bays",
    "Hampton Manor",
    "Hancock",
    "Hannawa Falls",
    "Hannibal",
    "Harbor Hills",
    "Harbor Isle",
    "Harriman",
    "Harris Hill",
    "Harrison",
    "Harrisville",
    "Hartsdale",
    "Hartwick",
    "Hastings-on-Hudson",
    "Hauppauge",
    "Haverstraw",
    "Haviland",
    "Hawthorne",
    "Head of the Harbor",
    "Hemlock",
    "Hempstead",
    "Henderson",
    "Heritage Hills",
    "Herkimer",
    "Hermon",
    "Herricks",
    "Herrings",
    "Heuvelton",
    "Hewlett",
    "Hewlett Bay Park",
    "Hewlett Harbor",
    "Hewlett Neck",
    "Hicksville",
    "High Falls",
    "Highland",
    "Highland Falls",
    "Hillburn",
    "Hillcrest",
    "Hillside",
    "Hillside Lake",
    "Hilton",
    "Hobart",
    "Holbrook",
    "Holland",
    "Holland Patent",
    "Holley",
    "Holtsville",
    "Homer",
    "Honeoye",
    "Honeoye Falls",
    "Hoosick Falls",
    "Hopewell Junction",
    "Hornell",
    "Horseheads",
    "Horseheads North",
    "Hortonville",
    "Houghton",
    "Hudson",
    "Hudson Falls",
    "Hunt",
    "Hunter",
    "Huntington",
    "Huntington Bay",
    "Huntington Station",
    "Hurley",
    "Hyde Park",
    "Ilion",
    "Interlaken",
    "Inwood",
    "Irondequoit",
    "Irvington",
    "Island Park",
    "Islandia",
    "Islip",
    "Islip Terrace",
    "Ithaca",
    "Jamesport",
    "Jamestown",
    "Jamestown West",
    "Jefferson Heights",
    "Jefferson Valley-Yorktown",
    "Jeffersonville",
    "Jericho",
    "Johnson City",
    "Johnstown",
    "Jordan",
    "Kaser",
    "Katonah",
    "Keeseville",
    "Kenmore",
    "Kennedy",
    "Kensington",
    "Kerhonkson",
    "Keuka Park",
    "Kinderhook",
    "Kings Park",
    "Kings Point",
    "Kingston",
    "Kiryas Joel",
    "Kysorville",
    "La Fargeville",
    "Lackawanna",
    "Lacona",
    "Lake Carmel",
    "Lake Erie Beach",
    "Lake George",
    "Lake Grove",
    "Lake Katrine",
    "Lake Luzerne",
    "Lake Mohegan",
    "Lake Placid",
    "Lake Ronkonkoma",
    "Lake Success",
    "Lakeland",
    "Lakeview",
    "Lakeville",
    "Lakewood",
    "Lancaster",
    "Lansing",
    "Larchmont",
    "Lattingtown",
    "Laurel",
    "Laurel Hollow",
    "Laurens",
    "Lawrence",
    "Le Roy",
    "Leeds",
    "Leicester",
    "Levittown",
    "Lewiston",
    "Liberty",
    "Lido Beach",
    "Lima",
    "Lime Lake",
    "Limestone",
    "Lincoln Park",
    "Lincolndale",
    "Lindenhurst",
    "Linwood",
    "Lisle",
    "Little Falls",
    "Little Valley",
    "Liverpool",
    "Livingston Manor",
    "Livonia",
    "Livonia Center",
    "Lloyd Harbor",
    "Loch Sheldrake",
    "Lockport",
    "Locust Valley",
    "Lodi",
    "Long Beach",
    "Long Lake",
    "Lorenz Park",
    "Lorraine",
    "Lowville",
    "Lynbrook",
    "Lyncourt",
    "Lyndonville",
    "Lyon Mountain",
    "Lyons",
    "Lyons Falls",
    "Macedon",
    "Machias",
    "Madison",
    "Madrid",
    "Mahopac",
    "Malden-on-Hudson",
    "Malone",
    "Malverne",
    "Malverne Park Oaks",
    "Mamaroneck",
    "Manchester",
    "Manhasset",
    "Manhasset Hills",
    "Manlius",
    "Mannsville",
    "Manorhaven",
    "Manorville",
    "Marathon",
    "Marcellus",
    "Margaretville",
    "Mariaville Lake",
    "Marion",
    "Marlboro",
    "Massapequa",
    "Massapequa Park",
    "Massena",
    "Mastic",
    "Mastic Beach",
    "Matinecock",
    "Mattituck",
    "Mattydale",
    "Maybrook",
    "Mayfield",
    "Mayville",
    "McGraw",
    "Mechanicstown",
    "Mechanicville",
    "Medford",
    "Medina",
    "Melrose Park",
    "Melville",
    "Menands",
    "Meridian",
    "Merrick",
    "Merritt Park",
    "Mexico",
    "Middle Island",
    "Middleburgh",
    "Middleport",
    "Middletown",
    "Middleville",
    "Milford",
    "Mill Neck",
    "Millbrook",
    "Miller Place",
    "Millerton",
    "Millport",
    "Milton",
    "Milton CDP (Ulster County)",
    "Mineola",
    "Minetto",
    "Mineville",
    "Minoa",
    "Mohawk",
    "Monroe",
    "Monsey",
    "Montauk",
    "Montebello",
    "Montgomery",
    "Monticello",
    "Montour Falls",
    "Montrose",
    "Mooers",
    "Moravia",
    "Moriches",
    "Morris",
    "Morrisonville",
    "Morristown",
    "Morrisville",
    "Mount Ivy",
    "Mount Kisco",
    "Mount Morris",
    "Mount Sinai",
    "Mount Vernon",
    "Mountain Lodge Park",
    "Munnsville",
    "Munsey Park",
    "Munsons Corners",
    "Muttontown",
    "Myers Corner",
    "Nanuet",
    "Napanoch",
    "Napeague",
    "Naples",
    "Narrowsburg",
    "Nassau",
    "Natural Bridge",
    "Nedrow",
    "Nelliston",
    "Nelsonville",
    "Nesconset",
    "New Berlin",
    "New Cassel",
    "New City",
    "New Hartford",
    "New Hempstead",
    "New Hyde Park",
    "New Paltz",
    "New Rochelle",
    "New Square",
    "New Suffolk",
    "New Windsor",
    "New York",
    "New York Mills",
    "Newark",
    "Newark Valley",
    "Newburgh",
    "Newfane",
    "Newfield Hamlet",
    "Newport",
    "Niagara Falls",
    "Nichols",
    "Niskayuna",
    "Nissequogue",
    "Niverville",
    "Norfolk",
    "North Amityville",
    "North Babylon",
    "North Ballston Spa",
    "North Bay Shore",
    "North Bellmore",
    "North Bellport",
    "North Boston",
    "North Collins",
    "North Creek",
    "North Gates",
    "North Great River",
    "North Haven",
    "North Hills",
    "North Hornell",
    "North Lindenhurst",
    "North Lynbrook",
    "North Massapequa",
    "North Merrick",
    "North New Hyde Park",
    "North Patchogue",
    "North Rose",
    "North Sea",
    "North Syracuse",
    "North Tonawanda",
    "North Valley Stream",
    "North Wantagh",
    "Northampton",
    "Northeast Ithaca",
    "Northport",
    "Northville",
    "Northville village",
    "Northwest Harbor",
    "Northwest Ithaca",
    "Norwich",
    "Norwood",
    "Noyack",
    "Nunda",
    "Nyack",
    "Oak Beach-Captree",
    "Oakdale",
    "Oakfield",
    "Ocean Beach",
    "Oceanside",
    "Odessa",
    "Ogdensburg",
    "Olcott",
    "Old Bethpage",
    "Old Brookville",
    "Old Field",
    "Old Forge",
    "Old Westbury",
    "Olean",
    "Oneida",
    "Oneida Castle",
    "Oneonta",
    "Ontario",
    "Orange Lake",
    "Orangeburg",
    "Orchard Park",
    "Orient",
    "Oriskany",
    "Oriskany Falls",
    "Ossining",
    "Oswego",
    "Otego",
    "Otisville",
    "Ovid",
    "Owego",
    "Oxbow",
    "Oxford",
    "Oyster Bay",
    "Oyster Bay Cove",
    "Painted Post",
    "Palatine Bridge",
    "Palenville",
    "Palmyra",
    "Pamelia Center",
    "Panama",
    "Parc",
    "Parish",
    "Parishville",
    "Patchogue",
    "Paul Smiths",
    "Pavilion",
    "Pawling",
    "Peach Lake",
    "Pearl River",
    "Peconic",
    "Peekskill",
    "Pelham",
    "Pelham Manor",
    "Penn Yan",
    "Perry",
    "Perrysburg",
    "Peru",
    "Phelps",
    "Philadelphia",
    "Philmont",
    "Phoenicia",
    "Phoenix",
    "Piermont",
    "Pierrepont Manor",
    "Piffard",
    "Pike",
    "Pine Bush",
    "Pine Hill",
    "Pine Plains",
    "Pine Valley",
    "Pittsford",
    "Plainedge",
    "Plainview",
    "Plandome",
    "Plandome Heights",
    "Plandome Manor",
    "Plattekill",
    "Plattsburgh",
    "Plattsburgh West",
    "Pleasant Valley",
    "Pleasantville",
    "Plessis",
    "Poestenkill",
    "Point Lookout",
    "Poland",
    "Pomona",
    "Poquott",
    "Port Byron",
    "Port Chester",
    "Port Dickinson",
    "Port Ewen",
    "Port Gibson",
    "Port Henry",
    "Port Jefferson",
    "Port Jefferson Station",
    "Port Jervis",
    "Port Leyden",
    "Port Washington",
    "Port Washington North",
    "Portville",
    "Potsdam",
    "Pottersville",
    "Poughkeepsie",
    "Prattsburgh",
    "Prattsville",
    "Preston-Potter Hollow",
    "Prospect",
    "Pulaski",
    "Pultneyville",
    "Putnam Lake",
    "Quiogue",
    "Quogue",
    "Randolph",
    "Ransomville",
    "Rapids",
    "Ravena",
    "Red Creek",
    "Red Hook",
    "Red Oaks Mill",
    "Redford",
    "Redwood",
    "Remsen",
    "Remsenburg-Speonk",
    "Rensselaer",
    "Rensselaer Falls",
    "Retsof",
    "Rhinebeck",
    "Rhinecliff",
    "Richburg",
    "Richfield Springs",
    "Richmondville",
    "Richville",
    "Ridge",
    "Rifton",
    "Ripley",
    "Riverhead",
    "Riverside",
    "Riverside village",
    "Rochester",
    "Rock Hill",
    "Rockville Centre",
    "Rocky Point",
    "Rodman",
    "Rome",
    "Romulus",
    "Ronkonkoma",
    "Roosevelt",
    "Roscoe",
    "Rosendale Hamlet",
    "Roslyn",
    "Roslyn Estates",
    "Roslyn Harbor",
    "Roslyn Heights",
    "Rotterdam",
    "Round Lake",
    "Rouses Point",
    "Rushford",
    "Rushville",
    "Russell Gardens",
    "Rye",
    "Rye Brook",
    "Sackets Harbor",
    "Saddle Rock",
    "Saddle Rock Estates",
    "Sag Harbor",
    "Sagaponack",
    "Salamanca",
    "Salem",
    "Salisbury",
    "Salisbury Mills",
    "Salt Point",
    "Saltaire",
    "Sanborn",
    "Sand Ridge",
    "Sands Point",
    "Sandy Creek",
    "Saranac Lake",
    "Saratoga Springs",
    "Saugerties",
    "Saugerties South",
    "Savannah",
    "Savona",
    "Sayville",
    "Scarsdale",
    "Schaghticoke",
    "Schenectady",
    "Schenevus",
    "Schoharie",
    "Schroon Lake",
    "Schuylerville",
    "Scio",
    "Scotchtown",
    "Scotia",
    "Scotts Corners",
    "Scottsburg",
    "Scottsville",
    "Sea Cliff",
    "Seaford",
    "Searingtown",
    "Selden",
    "Seneca Falls",
    "Seneca Knolls",
    "Setauket-East Setauket",
    "Sharon Springs",
    "Shelter Island",
    "Shelter Island Heights",
    "Shenorock",
    "Sherburne",
    "Sherman",
    "Sherrill",
    "Shinnecock Hills",
    "Shirley",
    "Shokan",
    "Shoreham",
    "Shortsville",
    "Shrub Oak",
    "Sidney",
    "Silver Creek",
    "Silver Springs",
    "Sinclairville",
    "Skaneateles",
    "Sleepy Hollow",
    "Sloan",
    "Sloatsburg",
    "Smallwood",
    "Smithtown",
    "Smithville Flats",
    "Smyrna",
    "Sodus",
    "Sodus Point",
    "Solvay",
    "Sound Beach",
    "South Blooming Grove",
    "South Corning",
    "South Dayton",
    "South Fallsburg",
    "South Farmingdale",
    "South Floral Park",
    "South Glens Falls",
    "South Hempstead",
    "South Hill",
    "South Huntington",
    "South Lima",
    "South Lockport",
    "South Nyack",
    "South Valley Stream",
    "Southampton",
    "Southold",
    "Southport",
    "Spackenkill",
    "Sparkill",
    "Speculator",
    "Spencer",
    "Spencerport",
    "Spring Valley",
    "Springs",
    "Springville",
    "Springwater Hamlet",
    "St. Bonaventure",
    "St. James",
    "St. Johnsville",
    "St. Regis Falls",
    "Staatsburg",
    "Stamford",
    "Stannards",
    "Star Lake",
    "Stewart Manor",
    "Stillwater",
    "Stone Ridge",
    "Stony Brook",
    "Stony Brook University",
    "Stony Point",
    "Stottville",
    "Strykersville",
    "Suffern",
    "Sunset Bay",
    "SUNY Oswego",
    "Sylvan Beach",
    "Syosset",
    "Syracuse",
    "Taconic Shores",
    "Tannersville",
    "Tappan",
    "Tarrytown",
    "Terryville",
    "Thendara",
    "Theresa",
    "Thiells",
    "Thomaston",
    "Thornwood",
    "Thousand Island Park",
    "Three Mile Bay",
    "Ticonderoga",
    "Tillson",
    "Titusville",
    "Tivoli",
    "Tonawanda",
    "Tonawanda city",
    "Town Line",
    "Tribes Hill",
    "Troy",
    "Trumansburg",
    "Tuckahoe",
    "Tuckahoe CDP",
    "Tully",
    "Tupper Lake",
    "Turin",
    "Tuscarora",
    "Tuxedo Park",
    "Unadilla",
    "Union Springs",
    "Uniondale",
    "Unionville",
    "University at Buffalo",
    "University Gardens",
    "Upper Brookville",
    "Upper Nyack",
    "Utica",
    "Vails Gate",
    "Valatie",
    "Valhalla",
    "Valley Cottage",
    "Valley Falls",
    "Valley Stream",
    "Van Etten",
    "Vernon",
    "Verona",
    "Verplanck",
    "Victor",
    "Victory",
    "Village Green",
    "Village of the Branch",
    "Viola",
    "Virgil",
    "Voorheesville",
    "Waddington",
    "Wading River",
    "Wadsworth",
    "Wainscott",
    "Walden",
    "Walker Valley",
    "Wallkill",
    "Walton",
    "Walton Park",
    "Wampsville",
    "Wanakah",
    "Wantagh",
    "Wappingers Falls",
    "Warrensburg",
    "Warsaw",
    "Warwick",
    "Washington Heights",
    "Washington Mills",
    "Washingtonville",
    "Watchtower",
    "Water Mill",
    "Waterford",
    "Waterloo",
    "Watertown",
    "Waterville",
    "Watervliet",
    "Watkins Glen",
    "Waverly",
    "Wayland",
    "Webster",
    "Websters Crossing",
    "Weedsport",
    "Wells",
    "Wellsburg",
    "Wellsville",
    "Wesley Hills",
    "West Babylon",
    "West Bay Shore",
    "West Carthage",
    "West Chazy",
    "West Elmira",
    "West End",
    "West Glens Falls",
    "West Hampton Dunes",
    "West Haverstraw",
    "West Hempstead",
    "West Hills",
    "West Hurley",
    "West Islip",
    "West Nyack",
    "West Point",
    "West Sand Lake",
    "West Sayville",
    "West Seneca",
    "West Valley",
    "West Winfield",
    "Westbury",
    "Westfield",
    "Westhampton",
    "Westhampton Beach",
    "Westmere",
    "Westmoreland",
    "Weston Mills",
    "Westport",
    "Westvale",
    "Wheatley Heights",
    "White Plains",
    "Whitehall",
    "Whitesboro",
    "Whitney Point",
    "Williamson",
    "Williamsville",
    "Williston Park",
    "Willsboro",
    "Wilmington",
    "Wilson",
    "Windham",
    "Windsor",
    "Winthrop",
    "Witherbee",
    "Wolcott",
    "Woodbury",
    "Woodbury CDP",
    "Woodmere",
    "Woodridge",
    "Woodsburgh",
    "Woodstock",
    "Woodsville",
    "Worcester",
    "Wurtsboro",
    "Wyandanch",
    "Wynantskill",
    "Wyoming",
    "Yaphank",
    "Yonkers",
    "York Hamlet",
    "Yorkshire",
    "Yorktown Heights",
    "Yorkville",
    "Youngstown",
    "Zena",
];

const HABITAT_OPTIONS = [
    "Forest",
    "Urban",
    "Water",
    "Shrub",
    "Grass",
    "Agriculture",
    "Wetland",
    "Protected",
];
const CLIMATE_OPTIONS = [
    "Temperature",
    "Precipitation"
];

const EDUCATION = [
    { type: "GRAD", value: "Graduate" },
    { type: "HIGH_SCHOOL", value: "Undergraduate" },
];

const USER_TYPE = [
    "ADMIN",
    "MODERATOR",
    "CONTRIBUTOR"
];

const MAP_LAYERS = [
    "Quads",
    "Counties",
    "Watersheds",
    "DEC Regions"
];

const AGE_MAMMALS = [
    { type: "UNKNOWN", value: "Unknown" },
    { type: "JUVENILE", value: "Juvenile" },
    { type: "ADULT", value: "Adult" },
];
const AGE_REPTILES = [
    { type: "EGG", value: "Egg" },
    { type: "LARVA", value: "Larva" },
    { type: "ADULT", value: "Adult" },
    { type: "JUVENILE", value: "Juvenile" },
    { type: "UNKNOWN", value: "Unknown" },
];

const AGE =
    (process.env.REACT_APP_TYPE_CODE ? process.env.REACT_APP_TYPE_CODE : "2") ===
    "2"
        ? AGE_REPTILES
        : AGE_MAMMALS;

const SEX = [
    { type: "UNKNOWN", value: "Unknown" },
    { type: "MALE", value: "Male" },
    { type: "FEMALE", value: "Female" },
];

const RECORDING_METHOD_REPTILES = [
    { type: "CAPTURED", value: "Captured" },
    { type: "DEAD", value: "Dead" },
    { type: "SIGHTING", value: "Sighting" },
];

const RECORDING_METHOD_REPTILES_VOCAL = {
    type: "VOCALIZATION",
    value: "Vocalization",
};

const RECORDING_METHOD_MAMMALS = [
    { type: "SCAT_SIGN", value: "Animal sign (ex. scat, tracks)" },
    { type: "CAPTURED", value: "Captured" },
    { type: "DEAD", value: "Dead" },
    { type: "SIGHTING", value: "Sighting" },
    { type: "SOUND", value: "Sound" },
    { type: "TRAIL_CAMERA", value: "Trail camera" },
    { type: "OTHER", value: "Other" },
];

const RECORDING_METHOD =
    process.env.REACT_APP_TYPE_CODE === "2"
        ? RECORDING_METHOD_REPTILES
        : RECORDING_METHOD_MAMMALS;

const COUNTIES = [
    { id: "ALBA", name: "Albany" },
    { id: "ALLE", name: "Allegany" },
    { id: "BRON", name: "Bronx" },
    { id: "BROO", name: "Broome" },
    { id: "CATT", name: "Cattaraugus" },
    { id: "CAYU", name: "Cayuga" },
    { id: "CHAU", name: "Chautauqua" },
    { id: "CHEM", name: "Chemung" },
    { id: "CHEN", name: "Chenango" },
    { id: "CLIN", name: "Clinton" },
    { id: "COLU", name: "Columbia" },
    { id: "CORT", name: "Cortland" },
    { id: "DELA", name: "Delaware" },
    { id: "DUTC", name: "Dutchess" },
    { id: "ERIE", name: "Erie" },
    { id: "ESSE", name: "Essex" },
    { id: "FRAN", name: "Franklin" },
    { id: "FULT", name: "Fulton" },
    { id: "GENE", name: "Genesee" },
    { id: "GREE", name: "Greene" },
    { id: "HAMI", name: "Hamilton" },
    { id: "HERK", name: "Herkimer" },
    { id: "KING", name: "Kings" },
    { id: "LEWI", name: "Lewis" },
    { id: "LIVI", name: "Livingston" },
    { id: "MONR", name: "Monroe" },
    { id: "MONT", name: "Montgomery" },
    { id: "NASS", name: "Nassau" },
    { id: "NEWY", name: "New York" },
    { id: "NIAG", name: "Niagara" },
    { id: "ONON", name: "Onondaga" },
    { id: "ONTA", name: "Ontario" },
    { id: "ORAN", name: "Orange" },
    { id: "ORLE", name: "Orleans" },
    { id: "OSWE", name: "Oswego" },
    { id: "OTSE", name: "Otsego" },
    { id: "PUTN", name: "Putnam" },
    { id: "QUEE", name: "Queens" },
    { id: "RENS", name: "Rensselaer" },
    { id: "RICH", name: "Richmond" },
    { id: "ROCK", name: "Rockland" },
    { id: "SARA", name: "Saratoga" },
    { id: "SCHE", name: "Schenectady" },
    { id: "SCHO", name: "Schoharie" },
    { id: "SCHU", name: "Schuyler" },
    { id: "SENE", name: "Seneca" },
    { id: "STLA", name: "St Lawrence" },
    { id: "STEU", name: "Steuben" },
    { id: "SUFF", name: "Suffolk" },
    { id: "SULL", name: "Sullivan" },
    { id: "TIOG", name: "Tioga" },
    { id: "TOMP", name: "Tompkins" },
    { id: "ULST", name: "Ulster" },
    { id: "WARR", name: "Warren" },
    { id: "WASH", name: "Washington" },
    { id: "WAYN", name: "Wayne" },
    { id: "WEST", name: "Westchester" },
    { id: "WYOM", name: "Wyoming" },
    { id: "YATE", name: "Yates" },
    { id: "MADI", name: "Madison" },
    { id: "ONEI", name: "Oneida" },
    { id: "JEFF", name: "Jefferson" },
];

const QUADS = [
    { id: "J30", name: "Adams" },
    { id: "X21", name: "Addison" },
    { id: "W34", name: "Afton" },
    { id: "P11", name: "Akron" },
    { id: "S48", name: "Albany" },
    { id: "E39", name: "Albert marsh" },
    { id: "O13", name: "Albion" },
    { id: "U47", name: "Alcove" },
    { id: "D47", name: "Alder brook" },
    { id: "Q12", name: "Alexander" },
    { id: "F31", name: "Alexandria bay" },
    { id: "V16", name: "Alfred" },
    { id: "X14", name: "Allentown" },
    { id: "V25", name: "Alpine" },
    { id: "S46", name: "Altamont" },
    { id: "A49", name: "Altona" },
    { id: "Z50", name: "Amenia" },
    { id: "II51", name: "Amityville" },
    { id: "G45", name: "Ampersand lake" },
    { id: "Q45", name: "Amsterdam" },
    { id: "X49", name: "Ancram" },
    { id: "W40", name: "Andes" },
    { id: "W16", name: "Andover" },
    { id: "V14", name: "Angelica" },
    { id: "S6", name: "Angola" },
    { id: "G34", name: "Antwerp" },
    { id: "X29", name: "Apalachin" },
    { id: "T11", name: "Arcade" },
    { id: "X41", name: "Arena" },
    { id: "U17", name: "Arkport" },
    { id: "JJ45", name: "Arthur kill" },
    { id: "V10", name: "Ashford" },
    { id: "U9", name: "Ashford hollow" },
    { id: "V44", name: "Ashland" },
    { id: "Y45", name: "Ashokan" },
    { id: "N12", name: "Ashwood" },
    { id: "R12", name: "Attica" },
    { id: "E49", name: "Au sable forks" },
    { id: "Q26", name: "Auburn" },
    { id: "E42", name: "Augerhole falls" },
    { id: "S50", name: "Averill park" },
    { id: "U19", name: "Avoca" },
    { id: "J45", name: "Bad luck mountain" },
    { id: "L46", name: "Bakers mills" },
    { id: "O28", name: "Baldwinsville" },
    { id: "B43", name: "Bangor" },
    { id: "N10", name: "Barker" },
    { id: "J32", name: "Barnes corners" },
    { id: "X27", name: "Barton" },
    { id: "P13", name: "Batavia north" },
    { id: "Q13", name: "Batavia south" },
    { id: "V20", name: "Bath" },
    { id: "E43", name: "Bay pond" },
    { id: "II53", name: "Bay shore east" },
    { id: "II52", name: "Bay shore west" },
    { id: "GG50", name: "Bayville" },
    { id: "X45", name: "Bearsville" },
    { id: "V23", name: "Beaver dams" },
    { id: "I39", name: "Beaver river" },
    { id: "B51", name: "Beekmantown" },
    { id: "W33", name: "Belden" },
    { id: "I36", name: "Belfort" },
    { id: "HH55", name: "Bellport" },
    { id: "W14", name: "Belmont" },
    { id: "S52", name: "Berlin" },
    { id: "W23", name: "Big flats" },
    { id: "J39", name: "Big moose" },
    { id: "E36", name: "Bigelow" },
    { id: "X32", name: "Binghamton east" },
    { id: "X31", name: "Binghamton west" },
    { id: "U15", name: "Birdsall" },
    { id: "L39", name: "Bisby lakes" },
    { id: "V13", name: "Black creek" },
    { id: "M39", name: "Black creek lake" },
    { id: "H32", name: "Black river" },
    { id: "T12", name: "Bliss" },
    { id: "E46", name: "Bloomingdale" },
    { id: "V40", name: "Bloomville" },
    { id: "J43", name: "Blue mountain lake" },
    { id: "I48", name: "Blue ridge" },
    { id: "X13", name: "Bolivar" },
    { id: "L49", name: "Bolton landing" },
    { id: "A42", name: "Bombay" },
    { id: "M36", name: "Boonville" },
    { id: "X20", name: "Borden" },
    { id: "K31", name: "Boylston center" },
    { id: "N17", name: "Braddock heights" },
    { id: "V22", name: "Bradford" },
    { id: "B46", name: "Brainardsville" },
    { id: "I41", name: "Brandreth lake" },
    { id: "K49", name: "Brant lake" },
    { id: "K36", name: "Brantingham" },
    { id: "B40", name: "Brasher falls" },
    { id: "T43", name: "Breakabeen" },
    { id: "O29", name: "Brewerton" },
    { id: "CC50", name: "Brewster" },
    { id: "V33", name: "Brisben" },
    { id: "R19", name: "Bristol center" },
    { id: "S19", name: "Bristol springs" },
    { id: "P45", name: "Broadalbin" },
    { id: "O15", name: "Brockport" },
    { id: "U3", name: "Brocton" },
    { id: "R36", name: "Brookfield" },
    { id: "II47", name: "Brooklyn" },
    { id: "F40", name: "Brother ponds" },
    { id: "H31", name: "Brownville" },
    { id: "B42", name: "Brushton" },
    { id: "Q8", name: "Buffalo ne" },
    { id: "Q7", name: "Buffalo nw" },
    { id: "R8", name: "Buffalo se" },
    { id: "K45", name: "Bullhead mountain" },
    { id: "U24", name: "Burdett" },
    { id: "A45", name: "Burke" },
    { id: "Q47", name: "Burnt hills" },
    { id: "P14", name: "Byron" },
    { id: "Q16", name: "Caledonia" },
    { id: "Z38", name: "Callicoon" },
    { id: "O8", name: "Cambria" },
    { id: "P51", name: "Cambridge" },
    { id: "N33", name: "Camden east" },
    { id: "N32", name: "Camden west" },
    { id: "W19", name: "Cameron" },
    { id: "P28", name: "Camillus" },
    { id: "W21", name: "Campbell" },
    { id: "U51", name: "Canaan" },
    { id: "O42", name: "Canada lake" },
    { id: "Q42", name: "Canajoharie" },
    { id: "Q20", name: "Canandaigua" },
    { id: "R20", name: "Canandaigua lake" },
    { id: "U16", name: "Canaseraga" },
    { id: "P32", name: "Canastota" },
    { id: "W28", name: "Candor" },
    { id: "V18", name: "Canisteo" },
    { id: "X36", name: "Cannonsville reservoir" },
    { id: "D37", name: "Canton" },
    { id: "G28", name: "Cape vincent north" },
    { id: "H28", name: "Cape vincent south" },
    { id: "R43", name: "Carlisle" },
    { id: "O43", name: "Caroga lake" },
    { id: "E41", name: "Carry falls reservoir" },
    { id: "I34", name: "Carthage" },
    { id: "V4", name: "Cassadaga" },
    { id: "Q36", name: "Cassville" },
    { id: "S14", name: "Castile" },
    { id: "W31", name: "Castle creek" },
    { id: "N44", name: "Cathead mountain" },
    { id: "O26", name: "Cato" },
    { id: "X22", name: "Caton" },
    { id: "V8", name: "Cattaraugus" },
    { id: "Q25", name: "Cayuga" },
    { id: "Q32", name: "Cazenovia" },
    { id: "W47", name: "Cementon" },
    { id: "HH53", name: "Central islip" },
    { id: "HH47", name: "Central park" },
    { id: "N29", name: "Central square" },
    { id: "A51", name: "Champlain" },
    { id: "F52", name: "Charlotte" },
    { id: "T41", name: "Charlotteville" },
    { id: "B38", name: "Chase mills" },
    { id: "B45", name: "Chasm falls" },
    { id: "A46", name: "Chateaugay" },
    { id: "V50", name: "Chatham" },
    { id: "H29", name: "Chaumont" },
    { id: "W3", name: "Chautauqua" },
    { id: "W32", name: "Chenango forks" },
    { id: "I47", name: "Cheney pond" },
    { id: "V6", name: "Cherry creek" },
    { id: "K48", name: "Chestertown" },
    { id: "F41", name: "Childwold" },
    { id: "E32", name: "Chippewa bay" },
    { id: "P15", name: "Churchville" },
    { id: "A47", name: "Churubusco" },
    { id: "O30", name: "Cicero" },
    { id: "T31", name: "Cincinnatus" },
    { id: "Q10", name: "Clarence" },
    { id: "P9", name: "Clarence center" },
    { id: "T47", name: "Clarksville" },
    { id: "Y42", name: "Claryville" },
    { id: "W49", name: "Claverack" },
    { id: "G30", name: "Clayton" },
    { id: "X48", name: "Clermont" },
    { id: "O31", name: "Cleveland" },
    { id: "P16", name: "Clifton" },
    { id: "Q21", name: "Clifton springs" },
    { id: "P35", name: "Clinton" },
    { id: "AA46", name: "Clintondale" },
    { id: "E50", name: "Clintonville" },
    { id: "X1", name: "Clymer" },
    { id: "S43", name: "Cobleskill" },
    { id: "D52", name: "Colchester point" },
    { id: "S9", name: "Colden" },
    { id: "U8", name: "Collins center" },
    { id: "D39", name: "Colton" },
    { id: "S17", name: "Conesus" },
    { id: "JJ47", name: "Coney island" },
    { id: "N47", name: "Conklingville" },
    { id: "A44", name: "Constable" },
    { id: "L35", name: "Constableville" },
    { id: "S39", name: "Cooperstown" },
    { id: "X50", name: "Copake" },
    { id: "I33", name: "Copenhagen" },
    { id: "K37", name: "Copper lake" },
    { id: "X38", name: "Corbett" },
    { id: "Q11", name: "Corfu" },
    { id: "O48", name: "Corinth" },
    { id: "W22", name: "Corning" },
    { id: "CC46", name: "Cornwall-on-hudson" },
    { id: "T29", name: "Cortland" },
    { id: "O51", name: "Cossayuna" },
    { id: "R11", name: "Cowlesville" },
    { id: "G40", name: "Cranberry lake" },
    { id: "I35", name: "Croghan" },
    { id: "DD49", name: "Croton falls" },
    { id: "I51", name: "Crown point" },
    { id: "J36", name: "Crystal dale" },
    { id: "W12", name: "Cuba" },
    { id: "S31", name: "Cuyler" },
    { id: "R13", name: "Dale" },
    { id: "AA38", name: "Damascus" },
    { id: "C49", name: "Dannemora" },
    { id: "T17", name: "Dansville" },
    { id: "U40", name: "Davenport" },
    { id: "D45", name: "Debar mountain" },
    { id: "I43", name: "Deerland" },
    { id: "H33", name: "Deferiet" },
    { id: "F38", name: "Degrasse" },
    { id: "U11", name: "Delevan" },
    { id: "V39", name: "Delhi" },
    { id: "T48", name: "Delmar" },
    { id: "X35", name: "Deposit" },
    { id: "F43", name: "Derrick" },
    { id: "R31", name: "Deruyter" },
    { id: "H30", name: "Dexter" },
    { id: "H48", name: "Dix mountain" },
    { id: "AA50", name: "Dover plains" },
    { id: "X39", name: "Downsville" },
    { id: "S23", name: "Dresden" },
    { id: "U28", name: "Dryden" },
    { id: "R45", name: "Duanesburg" },
    { id: "M30", name: "Dugway" },
    { id: "I44", name: "Dun brook mountain" },
    { id: "T23", name: "Dundee" },
    { id: "U4", name: "Dunkirk" },
    { id: "U45", name: "Durham" },
    { id: "J46", name: "Dutton mountain" },
    { id: "J40", name: "Eagle bay" },
    { id: "Q51", name: "Eagle bridge" },
    { id: "I50", name: "Eagle lake" },
    { id: "S34", name: "Earlville" },
    { id: "R10", name: "East aurora" },
    { id: "U50", name: "East chatham" },
    { id: "T49", name: "East greenbush" },
    { id: "GG61", name: "East hampton" },
    { id: "T33", name: "East pharsalia" },
    { id: "R40", name: "East springfield" },
    { id: "HH57", name: "Eastport" },
    { id: "S7", name: "Eden" },
    { id: "O46", name: "Edinburg" },
    { id: "S37", name: "Edmeston" },
    { id: "F36", name: "Edwards" },
    { id: "D34", name: "Edwardsville" },
    { id: "W51", name: "Egremont" },
    { id: "BB39", name: "Eldred" },
    { id: "II45", name: "Elizabeth" },
    { id: "G50", name: "Elizabethtown" },
    { id: "B47", name: "Ellenburg center" },
    { id: "A48", name: "Ellenburg depot" },
    { id: "B48", name: "Ellenburg mountain" },
    { id: "AA43", name: "Ellenville" },
    { id: "W4", name: "Ellery center" },
    { id: "V9", name: "Ellicottville" },
    { id: "K29", name: "Ellisburg" },
    { id: "X24", name: "Elmira" },
    { id: "X30", name: "Endicott" },
    { id: "R32", name: "Erieville" },
    { id: "W25", name: "Erin" },
    { id: "R44", name: "Esperance" },
    { id: "N25", name: "Fair haven" },
    { id: "P19", name: "Fairport" },
    { id: "JJ48", name: "Far rockaway" },
    { id: "T6", name: "Farnham" },
    { id: "U14", name: "Fillmore" },
    { id: "G37", name: "Fine" },
    { id: "Y37", name: "Fishs eddy" },
    { id: "H39", name: "Five ponds" },
    { id: "W42", name: "Fleischmanns" },
    { id: "M33", name: "Florence" },
    { id: "HH48", name: "Flushing" },
    { id: "M37", name: "Forestport" },
    { id: "U5", name: "Forestville" },
    { id: "I42", name: "Forked lake" },
    { id: "M51", name: "Fort ann" },
    { id: "A43", name: "Fort covington" },
    { id: "O50", name: "Fort miller" },
    { id: "N6", name: "Fort niagara" },
    { id: "Q41", name: "Fort plain" },
    { id: "V37", name: "Franklin" },
    { id: "E47", name: "Franklin falls" },
    { id: "V11", name: "Franklinville" },
    { id: "U12", name: "Freedom" },
    { id: "V46", name: "Freehold" },
    { id: "II50", name: "Freeport" },
    { id: "W13", name: "Friendship" },
    { id: "N27", name: "Fulton" },
    { id: "N20", name: "Furnaceville" },
    { id: "E45", name: "Gabriels" },
    { id: "I27", name: "Galloo island" },
    { id: "S45", name: "Gallupville" },
    { id: "P46", name: "Galway" },
    { id: "F29", name: "Gananoque" },
    { id: "O49", name: "Gansevoort" },
    { id: "AA45", name: "Gardiner" },
    { id: "FF62", name: "Gardiners island east" },
    { id: "FF61", name: "Gardiners island west" },
    { id: "O10", name: "Gasport" },
    { id: "R16", name: "Geneseo" },
    { id: "Q23", name: "Geneva north" },
    { id: "R23", name: "Geneva south" },
    { id: "S26", name: "Genoa" },
    { id: "W5", name: "Gerry" },
    { id: "U36", name: "Gilbertsville" },
    { id: "U43", name: "Gilboa" },
    { id: "K35", name: "Glenfield" },
    { id: "N49", name: "Glens falls" },
    { id: "FF49", name: "Glenville" },
    { id: "P44", name: "Gloversville" },
    { id: "K46", name: "Gore mountain" },
    { id: "CC44", name: "Goshen" },
    { id: "F35", name: "Gouverneur" },
    { id: "U7", name: "Gowanda" },
    { id: "R51", name: "Grafton" },
    { id: "Z42", name: "Grahamsville" },
    { id: "H43", name: "Grampus lake" },
    { id: "M52", name: "Granville" },
    { id: "J50", name: "Graphite" },
    { id: "V32", name: "Greene" },
    { id: "HH52", name: "Greenlawn" },
    { id: "FF60", name: "Greenport" },
    { id: "U46", name: "Greenville" },
    { id: "W17", name: "Greenwood" },
    { id: "EE44", name: "Greenwood lake" },
    { id: "M45", name: "Griffin" },
    { id: "T28", name: "Groton" },
    { id: "U35", name: "Guilford" },
    { id: "X34", name: "Gulf summit" },
    { id: "S8", name: "Hamburg" },
    { id: "W39", name: "Hamden" },
    { id: "R34", name: "Hamilton" },
    { id: "V5", name: "Hamlet" },
    { id: "N15", name: "Hamlin" },
    { id: "E33", name: "Hammond" },
    { id: "U21", name: "Hammondsport" },
    { id: "T52", name: "Hancock ma-ny" },
    { id: "Y36", name: "Hancock ny-pa" },
    { id: "N26", name: "Hannibal" },
    { id: "U29", name: "Harford" },
    { id: "U41", name: "Harpersfield" },
    { id: "M46", name: "Harrisburg" },
    { id: "G36", name: "Harrisville" },
    { id: "V3", name: "Hartfield" },
    { id: "N51", name: "Hartford" },
    { id: "S38", name: "Hartwick" },
    { id: "BB41", name: "Hartwood" },
    { id: "U18", name: "Haskinville" },
    { id: "EE47", name: "Haverstraw" },
    { id: "J29", name: "Henderson" },
    { id: "I29", name: "Henderson bay" },
    { id: "V45", name: "Hensonville" },
    { id: "P39", name: "Herkimer" },
    { id: "E37", name: "Hermon" },
    { id: "D35", name: "Heuvelton" },
    { id: "HH50", name: "Hicksville" },
    { id: "L34", name: "High market" },
    { id: "BB40", name: "Highland lake" },
    { id: "W50", name: "Hillsdale" },
    { id: "N16", name: "Hilton" },
    { id: "N38", name: "Hinckley" },
    { id: "W11", name: "Hinsdale" },
    { id: "V41", name: "Hobart" },
    { id: "M41", name: "Hoffmeister" },
    { id: "A41", name: "Hogansburg" },
    { id: "S10", name: "Holland" },
    { id: "O14", name: "Holley" },
    { id: "T35", name: "Holmesville" },
    { id: "S29", name: "Homer" },
    { id: "R18", name: "Honeoye" },
    { id: "Q18", name: "Honeoye falls" },
    { id: "L40", name: "Honnedaga lake" },
    { id: "Q52", name: "Hoosick falls" },
    { id: "N45", name: "Hope falls" },
    { id: "BB48", name: "Hopewell junction" },
    { id: "V17", name: "Hornell" },
    { id: "W24", name: "Horseheads" },
    { id: "Y38", name: "Horton" },
    { id: "U13", name: "Houghton" },
    { id: "II55", name: "Howells point" },
    { id: "R35", name: "Hubbardsville" },
    { id: "N50", name: "Hudson falls" },
    { id: "V48", name: "Hudson north" },
    { id: "W48", name: "Hudson south" },
    { id: "W10", name: "Humphrey" },
    { id: "W45", name: "Hunter" },
    { id: "HH51", name: "Huntington" },
    { id: "Z47", name: "Hyde park" },
    { id: "P38", name: "Ilion" },
    { id: "K44", name: "Indian lake" },
    { id: "U27", name: "Ithaca east" },
    { id: "U26", name: "Ithaca west" },
    { id: "X6", name: "Ivory" },
    { id: "O44", name: "Jackson summit" },
    { id: "II48", name: "Jamaica" },
    { id: "X5", name: "Jamestown" },
    { id: "Q30", name: "Jamesville" },
    { id: "F49", name: "Jay mountain" },
    { id: "Z39", name: "Jeffersonville" },
    { id: "B49", name: "Jericho" },
    { id: "II46", name: "Jersey city" },
    { id: "N40", name: "Jerseyfield lake" },
    { id: "O32", name: "Jewell" },
    { id: "L47", name: "Johnsburg" },
    { id: "S12", name: "Johnsonburg" },
    { id: "JJ50", name: "Jones inlet" },
    { id: "P27", name: "Jordan" },
    { id: "Q39", name: "Jordanville" },
    { id: "E52", name: "Juniper island" },
    { id: "W46", name: "Kaaterskill clove" },
    { id: "F48", name: "Keene" },
    { id: "G48", name: "Keene valley" },
    { id: "D51", name: "Keeseville" },
    { id: "H44", name: "Kempshall mountain" },
    { id: "N14", name: "Kendall" },
    { id: "W6", name: "Kennedy" },
    { id: "N13", name: "Kent" },
    { id: "Z44", name: "Kerhonkson" },
    { id: "T22", name: "Keuka park" },
    { id: "U49", name: "Kinderhook" },
    { id: "Y47", name: "Kingston east" },
    { id: "Y46", name: "Kingston west" },
    { id: "X10", name: "Knapp creek" },
    { id: "O12", name: "Knowlesville" },
    { id: "L44", name: "Kunjamuk river" },
    { id: "G31", name: "La fargeville" },
    { id: "G35", name: "Lake bonaparte" },
    { id: "CC49", name: "Lake carmel" },
    { id: "M49", name: "Lake george" },
    { id: "AA39", name: "Lake huntington" },
    { id: "N48", name: "Lake luzerne" },
    { id: "D42", name: "Lake ozonia" },
    { id: "F47", name: "Lake placid" },
    { id: "M43", name: "Lake pleasant" },
    { id: "C44", name: "Lake titus" },
    { id: "X4", name: "Lakewood" },
    { id: "Q9", name: "Lancaster" },
    { id: "T8", name: "Langford" },
    { id: "P42", name: "Lassellsville" },
    { id: "JJ49", name: "Lawrence" },
    { id: "Q15", name: "Le roy" },
    { id: "N34", name: "Lee center" },
    { id: "V47", name: "Leeds" },
    { id: "R15", name: "Leicester" },
    { id: "X40", name: "Lewbeach" },
    { id: "F50", name: "Lewis" },
    { id: "O6", name: "Lewiston" },
    { id: "W44", name: "Lexington" },
    { id: "Z41", name: "Liberty east" },
    { id: "Z40", name: "Liberty west" },
    { id: "K40", name: "Limekiln lake" },
    { id: "X9", name: "Limestone" },
    { id: "C36", name: "Lisbon" },
    { id: "V30", name: "Lisle" },
    { id: "P40", name: "Little falls" },
    { id: "H42", name: "Little tupper lake" },
    { id: "W8", name: "Little valley" },
    { id: "Y40", name: "Livingston manor" },
    { id: "U44", name: "Livingstonville" },
    { id: "R17", name: "Livonia" },
    { id: "GG51", name: "Lloyd harbor" },
    { id: "O9", name: "Lockport" },
    { id: "T24", name: "Lodi" },
    { id: "Z37", name: "Long eddy" },
    { id: "G41", name: "Long tom mountain" },
    { id: "D46", name: "Loon lake" },
    { id: "A38", name: "Louisville" },
    { id: "J35", name: "Lowville" },
    { id: "T26", name: "Ludlowville" },
    { id: "II49", name: "Lynbrook" },
    { id: "N11", name: "Lyndonville" },
    { id: "C47", name: "Lyon mountain" },
    { id: "P23", name: "Lyons" },
    { id: "O27", name: "Lysander" },
    { id: "P20", name: "Macedon" },
    { id: "W30", name: "Maine" },
    { id: "N30", name: "Mallory" },
    { id: "B44", name: "Malone" },
    { id: "GG49", name: "Mamaroneck" },
    { id: "P31", name: "Manlius" },
    { id: "U30", name: "Marathon" },
    { id: "Q28", name: "Marcellus" },
    { id: "W41", name: "Margaretville" },
    { id: "A39", name: "Massena" },
    { id: "GG58", name: "Mattituck" },
    { id: "FF58", name: "Mattituck hills" },
    { id: "CC45", name: "Maybrook" },
    { id: "T30", name: "Mcgraw" },
    { id: "L38", name: "Mckeever" },
    { id: "F46", name: "Mckenzie mountain" },
    { id: "D44", name: "Meacham lake" },
    { id: "Q49", name: "Mechanicville" },
    { id: "U25", name: "Mecklenburg" },
    { id: "O11", name: "Medina" },
    { id: "D43", name: "Meno" },
    { id: "M29", name: "Mexico" },
    { id: "P47", name: "Middle grove" },
    { id: "GG55", name: "Middle island" },
    { id: "T44", name: "Middleburgh" },
    { id: "S20", name: "Middlesex" },
    { id: "CC43", name: "Middletown" },
    { id: "O39", name: "Middleville" },
    { id: "T39", name: "Milford" },
    { id: "Z49", name: "Millbrook" },
    { id: "Q38", name: "Millers mills" },
    { id: "Y50", name: "Millerton" },
    { id: "J47", name: "Minerva" },
    { id: "C48", name: "Moffitsville" },
    { id: "DD48", name: "Mohegan lake" },
    { id: "Z45", name: "Mohonk lake" },
    { id: "DD45", name: "Monroe" },
    { id: "FF63", name: "Montauk point" },
    { id: "P25", name: "Montezuma" },
    { id: "AA41", name: "Monticello" },
    { id: "V24", name: "Montour falls" },
    { id: "A50", name: "Mooers" },
    { id: "S27", name: "Moravia" },
    { id: "N41", name: "Morehouse lake" },
    { id: "M40", name: "Morehouseville" },
    { id: "HH56", name: "Moriches" },
    { id: "C37", name: "Morley" },
    { id: "T37", name: "Morris" },
    { id: "A37", name: "Morrisburg" },
    { id: "C50", name: "Morrisonville" },
    { id: "D33", name: "Morristown" },
    { id: "Q33", name: "Morrisville" },
    { id: "H46", name: "Mount adams" },
    { id: "EE49", name: "Mount kisco" },
    { id: "H47", name: "Mount marcy" },
    { id: "F42", name: "Mount matumbla" },
    { id: "S15", name: "Mount morris" },
    { id: "K41", name: "Mount tom" },
    { id: "GG48", name: "Mount vernon" },
    { id: "T38", name: "Mount vision" },
    { id: "Q34", name: "Munnsville" },
    { id: "F33", name: "Muskellunge lake" },
    { id: "DD63", name: "Mystic" },
    { id: "AA44", name: "Napanoch" },
    { id: "GG62", name: "Napeague beach" },
    { id: "T19", name: "Naples" },
    { id: "BB38", name: "Narrowsburg" },
    { id: "T50", name: "Nassau" },
    { id: "H35", name: "Natural bridge" },
    { id: "F34", name: "Natural dam" },
    { id: "I40", name: "Nehasane lake" },
    { id: "V7", name: "New albion" },
    { id: "S36", name: "New berlin north" },
    { id: "T36", name: "New berlin south" },
    { id: "J33", name: "New boston" },
    { id: "M28", name: "New haven" },
    { id: "DD62", name: "New london" },
    { id: "P22", name: "Newark" },
    { id: "W29", name: "Newark valley" },
    { id: "BB46", name: "Newburgh" },
    { id: "I45", name: "Newcomb" },
    { id: "N9", name: "Newfane" },
    { id: "O38", name: "Newport" },
    { id: "G39", name: "Newton falls" },
    { id: "P6", name: "Niagara falls" },
    { id: "C41", name: "Nicholville" },
    { id: "N19", name: "Ninemile point" },
    { id: "R48", name: "Niskayuna" },
    { id: "B39", name: "Norfolk" },
    { id: "X2", name: "North clymer" },
    { id: "T7", name: "North collins" },
    { id: "K47", name: "North creek" },
    { id: "G47", name: "North elba" },
    { id: "B52", name: "North hero" },
    { id: "B41", name: "North lawrence" },
    { id: "T4", name: "North of dunkirk" },
    { id: "L33", name: "North osceola" },
    { id: "R52", name: "North pownal" },
    { id: "W35", name: "North sanford" },
    { id: "N36", name: "North western" },
    { id: "M38", name: "North wilmurt" },
    { id: "H34", name: "North wilna" },
    { id: "N24", name: "North wolcott" },
    { id: "GG52", name: "Northport" },
    { id: "O45", name: "Northville" },
    { id: "T34", name: "Norwich" },
    { id: "J37", name: "Number four" },
    { id: "T15", name: "Nunda" },
    { id: "FF47", name: "Nyack" },
    { id: "P12", name: "Oakfield" },
    { id: "C35", name: "Ogdensburg east" },
    { id: "C34", name: "Ogdensburg west" },
    { id: "N39", name: "Ohio" },
    { id: "N46", name: "Ohmer mountain" },
    { id: "K39", name: "Old forge" },
    { id: "X11", name: "Olean" },
    { id: "P33", name: "Oneida" },
    { id: "U38", name: "Oneonta" },
    { id: "O20", name: "Ontario" },
    { id: "P41", name: "Oppenheim" },
    { id: "Q31", name: "Oran" },
    { id: "R9", name: "Orchard park" },
    { id: "EE60", name: "Orient" },
    { id: "O36", name: "Oriskany" },
    { id: "Q35", name: "Oriskany falls" },
    { id: "L31", name: "Orwell" },
    { id: "CC48", name: "Oscawana lake" },
    { id: "T16", name: "Ossian" },
    { id: "EE48", name: "Ossining" },
    { id: "G38", name: "Oswegatchie" },
    { id: "H38", name: "Oswegatchie se" },
    { id: "H37", name: "Oswegatchie sw" },
    { id: "M27", name: "Oswego east" },
    { id: "M26", name: "Oswego west" },
    { id: "U37", name: "Otego" },
    { id: "R29", name: "Otisco valley" },
    { id: "CC42", name: "Otisville" },
    { id: "S33", name: "Otselic" },
    { id: "S24", name: "Ovid" },
    { id: "R27", name: "Owasco" },
    { id: "X28", name: "Owego" },
    { id: "C45", name: "Owls head" },
    { id: "U34", name: "Oxford" },
    { id: "K34", name: "Page" },
    { id: "L43", name: "Page mountain" },
    { id: "P21", name: "Palmyra" },
    { id: "X3", name: "Panama" },
    { id: "N31", name: "Panther lake" },
    { id: "I49", name: "Paradox lake" },
    { id: "C40", name: "Parishville" },
    { id: "FF46", name: "Park ridge" },
    { id: "HH54", name: "Patchogue" },
    { id: "Q46", name: "Pattersonville" },
    { id: "II56", name: "Pattersquash island" },
    { id: "BB50", name: "Pawling" },
    { id: "DD50", name: "Peach lake" },
    { id: "D49", name: "Peasleeville" },
    { id: "P43", name: "Peck lake" },
    { id: "Y43", name: "Peekamoose mountain" },
    { id: "DD47", name: "Peekskill" },
    { id: "S22", name: "Penn yan" },
    { id: "N28", name: "Pennellville" },
    { id: "U6", name: "Perrysburg" },
    { id: "D50", name: "Peru" },
    { id: "J49", name: "Pharaoh mountain" },
    { id: "Q22", name: "Phelps" },
    { id: "G33", name: "Philadelphia" },
    { id: "X44", name: "Phoenicia" },
    { id: "G42", name: "Piercefield" },
    { id: "D38", name: "Pierrepont" },
    { id: "T13", name: "Pike" },
    { id: "BB44", name: "Pine bush" },
    { id: "DD43", name: "Pine island" },
    { id: "Y49", name: "Pine plains" },
    { id: "M42", name: "Piseco lake" },
    { id: "T32", name: "Pitcher" },
    { id: "P18", name: "Pittsford" },
    { id: "C51", name: "Plattsburgh" },
    { id: "AA48", name: "Pleasant valley" },
    { id: "EE61", name: "Plum island" },
    { id: "I28", name: "Point peninsula" },
    { id: "M34", name: "Point rock" },
    { id: "CC40", name: "Pond eddy" },
    { id: "E34", name: "Pope mills" },
    { id: "DD46", name: "Popolopen lake" },
    { id: "E51", name: "Port douglass" },
    { id: "H51", name: "Port henry" },
    { id: "GG54", name: "Port jefferson" },
    { id: "CC41", name: "Port jervis north" },
    { id: "DD41", name: "Port jervis south" },
    { id: "L36", name: "Port leyden" },
    { id: "T14", name: "Portageville" },
    { id: "O47", name: "Porter corners" },
    { id: "X12", name: "Portville" },
    { id: "C39", name: "Potsdam" },
    { id: "S21", name: "Potter" },
    { id: "AA47", name: "Poughkeepsie" },
    { id: "BB49", name: "Poughquag" },
    { id: "EE50", name: "Pound ridge" },
    { id: "T20", name: "Prattsburg" },
    { id: "V43", name: "Prattsville" },
    { id: "L29", name: "Pulaski" },
    { id: "T21", name: "Pulteney" },
    { id: "N21", name: "Pultneyville" },
    { id: "K51", name: "Putnam" },
    { id: "M50", name: "Putnam mountain" },
    { id: "P49", name: "Quaker springs" },
    { id: "HH58", name: "Quogue" },
    { id: "C46", name: "Ragged lake" },
    { id: "D40", name: "Rainbow falls" },
    { id: "FF45", name: "Ramsey" },
    { id: "Q43", name: "Randall" },
    { id: "W7", name: "Randolph" },
    { id: "O7", name: "Ransomville" },
    { id: "J41", name: "Raquette lake" },
    { id: "A40", name: "Raquette river" },
    { id: "W20", name: "Rathbone" },
    { id: "U48", name: "Ravena" },
    { id: "V12", name: "Rawson" },
    { id: "X37", name: "Readburn" },
    { id: "U23", name: "Reading center" },
    { id: "X8", name: "Red house" },
    { id: "B35", name: "Red mills" },
    { id: "L32", name: "Redfield" },
    { id: "D48", name: "Redford" },
    { id: "F32", name: "Redwood" },
    { id: "H36", name: "Remington corners" },
    { id: "N37", name: "Remsen" },
    { id: "D36", name: "Rensselaer falls" },
    { id: "T45", name: "Rensselaerville" },
    { id: "X17", name: "Rexville" },
    { id: "U20", name: "Rheims" },
    { id: "R39", name: "Richfield springs" },
    { id: "V29", name: "Richford" },
    { id: "L30", name: "Richland" },
    { id: "S42", name: "Richmondville" },
    { id: "E35", name: "Richville" },
    { id: "V1", name: "Ripley" },
    { id: "GG57", name: "Riverhead" },
    { id: "O18", name: "Rochester east" },
    { id: "O17", name: "Rochester west" },
    { id: "Y48", name: "Rock city" },
    { id: "J44", name: "Rock lake" },
    { id: "G49", name: "Rocky peak ridge" },
    { id: "J31", name: "Rodman" },
    { id: "O35", name: "Rome" },
    { id: "R24", name: "Romulus" },
    { id: "Z43", name: "Rondout reservoir" },
    { id: "Y39", name: "Roscoe" },
    { id: "O23", name: "Rose" },
    { id: "Z46", name: "Rosendale" },
    { id: "R46", name: "Rotterdam junction" },
    { id: "Q48", name: "Round lake" },
    { id: "A52", name: "Rouses point" },
    { id: "V42", name: "Roxbury" },
    { id: "Q17", name: "Rush" },
    { id: "R21", name: "Rushville" },
    { id: "I32", name: "Rutland center" },
    { id: "H41", name: "Sabattis" },
    { id: "I30", name: "Sackets harbor" },
    { id: "GG60", name: "Sag harbor" },
    { id: "GG53", name: "Saint james" },
    { id: "G29", name: "Saint lawrence" },
    { id: "C42", name: "Saint regis falls" },
    { id: "E44", name: "Saint regis mountain" },
    { id: "W9", name: "Salamanca" },
    { id: "O52", name: "Salem" },
    { id: "O40", name: "Salisbury" },
    { id: "N22", name: "Salmon creek" },
    { id: "Z48", name: "Salt point" },
    { id: "K30", name: "Sandy creek" },
    { id: "C43", name: "Santa clara" },
    { id: "H45", name: "Santanoni peak" },
    { id: "F45", name: "Saranac lake" },
    { id: "P48", name: "Saratoga springs" },
    { id: "T10", name: "Sardinia" },
    { id: "J42", name: "Sargent ponds" },
    { id: "X47", name: "Saugerties" },
    { id: "P24", name: "Savannah" },
    { id: "V21", name: "Savona" },
    { id: "II54", name: "Sayville" },
    { id: "Q50", name: "Schaghticoke" },
    { id: "R47", name: "Schenectady" },
    { id: "T40", name: "Schenevus" },
    { id: "S44", name: "Schoharie" },
    { id: "J48", name: "Schroon lake" },
    { id: "R38", name: "Schuyler lake" },
    { id: "P50", name: "Schuylerville" },
    { id: "R26", name: "Scipio center" },
    { id: "HH49", name: "Sea cliff" },
    { id: "X42", name: "Seager" },
    { id: "K33", name: "Sears pond" },
    { id: "X23", name: "Seeley creek" },
    { id: "S28", name: "Sempronius" },
    { id: "Q24", name: "Seneca falls" },
    { id: "X43", name: "Shandaken" },
    { id: "R42", name: "Sharon springs" },
    { id: "S25", name: "Sheldrake" },
    { id: "L50", name: "Shelving rock" },
    { id: "S35", name: "Sherburne" },
    { id: "W2", name: "Sherman" },
    { id: "HH59", name: "Shinnecock inlet" },
    { id: "CC39", name: "Shohola" },
    { id: "P52", name: "Shushan" },
    { id: "V35", name: "Sidney" },
    { id: "K50", name: "Silver bay" },
    { id: "T5", name: "Silver creek" },
    { id: "N7", name: "Sixmile creek" },
    { id: "Q27", name: "Skaneateles" },
    { id: "EE45", name: "Sloatsburg" },
    { id: "U32", name: "Smithville flats" },
    { id: "K43", name: "Snowy mountain" },
    { id: "O22", name: "Sodus" },
    { id: "N23", name: "Sodus point" },
    { id: "I37", name: "Soft maple reservoir" },
    { id: "S16", name: "Sonyea" },
    { id: "W18", name: "South canisteo" },
    { id: "F37", name: "South edwards" },
    { id: "Q29", name: "South onondaga" },
    { id: "S32", name: "South otselic" },
    { id: "L45", name: "South pond mountain" },
    { id: "W1", name: "South ripley" },
    { id: "O37", name: "South trenton" },
    { id: "S41", name: "South valley" },
    { id: "GG59", name: "Southampton" },
    { id: "FF59", name: "Southold" },
    { id: "R28", name: "Spafford" },
    { id: "B36", name: "Sparrowhawk point" },
    { id: "V28", name: "Speedsville" },
    { id: "W27", name: "Spencer" },
    { id: "O16", name: "Spencerport" },
    { id: "T9", name: "Springville" },
    { id: "S18", name: "Springwater" },
    { id: "R41", name: "Sprout brook" },
    { id: "L42", name: "Spruce lake" },
    { id: "L41", name: "Spruce lake mountain" },
    { id: "Q14", name: "Stafford" },
    { id: "U42", name: "Stamford" },
    { id: "R22", name: "Stanley" },
    { id: "E40", name: "Stark" },
    { id: "V51", name: "State line" },
    { id: "X7", name: "Steamburg" },
    { id: "T51", name: "Stephentown center" },
    { id: "I38", name: "Stillwater" },
    { id: "J38", name: "Stillwater mountain" },
    { id: "M47", name: "Stony creek" },
    { id: "G44", name: "Stony creek mountain" },
    { id: "J28", name: "Stony point" },
    { id: "V49", name: "Stottville" },
    { id: "O41", name: "Stratford" },
    { id: "G46", name: "Street mountain" },
    { id: "S11", name: "Strykersville" },
    { id: "T42", name: "Summit" },
    { id: "O33", name: "Sylvan beach" },
    { id: "D41", name: "Sylvan falls" },
    { id: "P30", name: "Syracuse east" },
    { id: "P29", name: "Syracuse west" },
    { id: "S51", name: "Taborton" },
    { id: "L28", name: "Texas" },
    { id: "L48", name: "The glen" },
    { id: "JJ46", name: "The narrows" },
    { id: "K38", name: "Thendara" },
    { id: "G32", name: "Theresa" },
    { id: "EE46", name: "Thiells" },
    { id: "L52", name: "Thorn hill" },
    { id: "F30", name: "Thousand island park" },
    { id: "J51", name: "Ticonderoga" },
    { id: "N42", name: "Tomany mountain" },
    { id: "R50", name: "Tomhannock" },
    { id: "P8", name: "Tonawanda east" },
    { id: "P7", name: "Tonawanda west" },
    { id: "F39", name: "Tooley pond" },
    { id: "V19", name: "Towlesville" },
    { id: "V38", name: "Treadwell" },
    { id: "Q44", name: "Tribes hill" },
    { id: "X18", name: "Troupsburg" },
    { id: "W36", name: "Trout creek" },
    { id: "R49", name: "Troy north" },
    { id: "S49", name: "Troy south" },
    { id: "T25", name: "Trumansburg" },
    { id: "S30", name: "Truxton" },
    { id: "R30", name: "Tully" },
    { id: "G43", name: "Tupper lake" },
    { id: "U33", name: "Tyner" },
    { id: "V36", name: "Unadilla" },
    { id: "R37", name: "Unadilla forks" },
    { id: "H49", name: "Underwood" },
    { id: "R25", name: "Union springs" },
    { id: "DD42", name: "Unionville" },
    { id: "F44", name: "Upper saranac lake" },
    { id: "P37", name: "Utica east" },
    { id: "P36", name: "Utica west" },
    { id: "W26", name: "Van etten" },
    { id: "Q40", name: "Van hornesville" },
    { id: "I46", name: "Vanderwhacker mountain" },
    { id: "AA49", name: "Verbank" },
    { id: "G52", name: "Vergennes west" },
    { id: "P34", name: "Vernon" },
    { id: "O34", name: "Verona" },
    { id: "Q19", name: "Victor" },
    { id: "O25", name: "Victory" },
    { id: "S47", name: "Voorheesville" },
    { id: "B37", name: "Waddington" },
    { id: "GG56", name: "Wading river" },
    { id: "K42", name: "Wakely mountain" },
    { id: "BB45", name: "Walden" },
    { id: "W38", name: "Walton east" },
    { id: "W37", name: "Walton west" },
    { id: "BB47", name: "Wappingers falls" },
    { id: "M48", name: "Warrensburg" },
    { id: "S13", name: "Warsaw" },
    { id: "DD44", name: "Warwick" },
    { id: "I31", name: "Watertown" },
    { id: "X26", name: "Waverly" },
    { id: "EE43", name: "Wawayanda" },
    { id: "T18", name: "Wayland" },
    { id: "U22", name: "Wayne" },
    { id: "O19", name: "Webster" },
    { id: "P26", name: "Weedsport" },
    { id: "M44", name: "Wells" },
    { id: "X25", name: "Wellsburg" },
    { id: "W15", name: "Wellsville north" },
    { id: "X15", name: "Wellsville south" },
    { id: "V15", name: "West almond" },
    { id: "V34", name: "West bainbridge" },
    { id: "B50", name: "West chazy" },
    { id: "V26", name: "West danby" },
    { id: "U39", name: "West davenport" },
    { id: "R33", name: "West eaton" },
    { id: "JJ51", name: "West gilgo beach" },
    { id: "T27", name: "West groton" },
    { id: "P17", name: "West henrietta" },
    { id: "W43", name: "West kill" },
    { id: "M35", name: "West leyden" },
    { id: "J34", name: "West lowville" },
    { id: "M25", name: "West ninemile point" },
    { id: "L27", name: "West of texas" },
    { id: "N52", name: "West pawlet" },
    { id: "E38", name: "West pierrepont" },
    { id: "CC47", name: "West point" },
    { id: "C38", name: "West potsdam" },
    { id: "Y44", name: "West shokan" },
    { id: "U10", name: "West valley" },
    { id: "Q37", name: "West winfield" },
    { id: "M32", name: "Westdale" },
    { id: "T46", name: "Westerlo" },
    { id: "N35", name: "Westernville" },
    { id: "V2", name: "Westfield" },
    { id: "S40", name: "Westford" },
    { id: "G51", name: "Westport" },
    { id: "AA40", name: "White lake" },
    { id: "FF48", name: "White plains" },
    { id: "L51", name: "Whitehall" },
    { id: "N43", name: "Whitehouse" },
    { id: "X16", name: "Whitesville" },
    { id: "V31", name: "Whitney point" },
    { id: "U31", name: "Willet" },
    { id: "O21", name: "Williamson" },
    { id: "M31", name: "Williamstown" },
    { id: "Y41", name: "Willowemoc" },
    { id: "F51", name: "Willsboro" },
    { id: "V27", name: "Willseyville" },
    { id: "E48", name: "Wilmington" },
    { id: "N8", name: "Wilson" },
    { id: "X33", name: "Windsor" },
    { id: "H50", name: "Witherbee" },
    { id: "O24", name: "Wolcott" },
    { id: "P10", name: "Wolcottsville" },
    { id: "H40", name: "Wolf mountain" },
    { id: "L37", name: "Woodgate" },
    { id: "X19", name: "Woodhull" },
    { id: "AA42", name: "Woodridge" },
    { id: "X46", name: "Woodstock" },
    { id: "K32", name: "Worth center" },
    { id: "BB43", name: "Wurtsboro" },
    { id: "R14", name: "Wyoming" },
    { id: "BB42", name: "Yankee lake" },
    { id: "GG47", name: "Yonkers" },
];

const WATERSHEDS = [
    { id: "2010004", name: "Ausable" },
    { id: "4150101", name: "Black" },
    { id: "2030102", name: "Bronx" },
    { id: "4120103", name: "Buffalo-Eighteenmile" },
    { id: "4120102", name: "Cattaraugus" },
    { id: "4150102", name: "Chaumont-Perch" },
    { id: "4150102", name: "Chaumont-Perch" },
    { id: "4120101", name: "Chautauqua-Conneaut" },
    { id: "2050105", name: "Chemung" },
    { id: "2050102", name: "Chenango" },
    { id: "5010002", name: "Conewango" },
    { id: "2040102", name: "East Branch Delaware" },
    { id: "4150307", name: "English-Salmon" },
    { id: "5010004", name: "French" },
    { id: "4150304", name: "Grass" },
    { id: "2010006", name: "Great Chazy-Saranac" },
    { id: "2030103", name: "Hackensack-Passaic" },
    { id: "1100005", name: "Housatonic" },
    { id: "2020003", name: "Hudson-Hoosic" },
    { id: "2020008", name: "Hudson-Wappinger" },
    { id: "4150303", name: "Indian" },
    { id: "4140101", name: "Irondequoit-Ninemile" },
    { id: "2010001", name: "Lake George" },
    { id: "4130003", name: "Lower Genesee" },
    { id: "2030101", name: "Lower Hudson" },
    { id: "2040104", name: "Middle Delaware-Mongaup-Brodhead" },
    { id: "2020006", name: "Middle Hudson" },
    { id: "2020004", name: "Mohawk" },
    { id: "4120104", name: "Niagara" },
    { id: "2030201", name: "Northern Long Island" },
    { id: "4130001", name: "Oak Orchard-Twelvemile" },
    { id: "4140202", name: "Oneida" },
    { id: "4150302", name: "Oswegatchie" },
    { id: "4140203", name: "Oswego" },
    { id: "2050103", name: "Owego-Wappasening" },
    { id: "4150305", name: "Raquette" },
    { id: "2020007", name: "Rondout" },
    { id: "2020002", name: "Sacandaga" },
    { id: "4140102", name: "Salmon-Sandy" },
    { id: "2030104", name: "Sandy Hook-Staten Island" },
    { id: "1100006", name: "Saugatuck" },
    { id: "2020005", name: "Schoharie" },
    { id: "4140201", name: "Seneca" },
    { id: "2030202", name: "Southern Long Island" },
    { id: "4150306", name: "St. Regis" },
    { id: "2050104", name: "Tioga" },
    { id: "5010001", name: "Upper Allegheny" },
    { id: "2040101", name: "Upper Delaware" },
    { id: "4130002", name: "Upper Genesee" },
    { id: "2020001", name: "Upper Hudson" },
    { id: "4150301", name: "Upper St. Lawrence" },
    { id: "2050101", name: "Upper Susquehanna" },
];

const DEC_REGION = [
    { id: "1", name: "Region 1" },
    { id: "2", name: "Region 2" },
    { id: "3", name: "Region 3" },
    { id: "4", name: "Region 4" },
    { id: "5", name: "Region 5" },
    { id: "6", name: "Region 6" },
    { id: "7", name: "Region 7" },
    { id: "8", name: "Region 8" },
    { id: "9", name: "Region 9" },
];

const ENUM_VALIDATION = "EMPTY" | "INVALID_FORMATE" | "VALID" | "INVALID";

const INFO_TEXTS = {
    ORDER: "An order refers to different groups of species",
    EMAIL_INFO: "Please enter your email address",
    PASSWORD_INFO: "Please enter your password",
    ORDER_INFO: "Please select Order. Not sure? Select a Species to automatically fill in Order",
    SPECIE_INFO: "Please select a Species. Note: Choose an Order to filter Species by the selected Order",
    MORPH_INFO: "Please select coloration",
    PRIVACY_INFO: "When toggled on your record will not be displayed publically. The NYMS research team will still have access to your record",
    LOCATION_INFO: "Search or zoom and click the map to select the location for your observation",
    MAP_LAYER_INFO: "Count of observations by geographic unit",
    REQUIRED_INFO: "Field is required",
    BIO_INFO: "Please enter your bio",
    PHENOLOGY_INFO: "Number of observations by month",
    LANDSCAPE_OBSERVATION_INFO:
        "Land area in protected status (source: GAP status 1 or 2 = managed for biodiversity) and by landcover class (source: NLCD 2011)",
    CLIMATE_OBSERVATION_INFO:
        "Source: Climate research group data (CRU TS v. 3.23 covering 1901-2014)",
    EXPLANATORY_INFO: "Relationships shown at US topo quad level",
    QUAD_INFO: "Add points for each quad in analysis",
    TREND_LINES_INFO: "Trend line calculated using polynomial regression with a 2nd degree polynomial",
    COMPARETO_INFO:
        "Choose another taxonomic group to compare species richness to the one already selected in the above graph)",
    DIVERSITY_INFO:
        "Species richness per US topo quad for identified taxonomic rank",
    DIVERSITY_RELATIONSHIPS_INFO:
        "Note: shown relationships reflect number of observations for selected taxon over the selected geographic area and are not corrected for sampling effort",
    HABITAT_TOOLTIP_INFO: (
        <Text>
            Land area (mean ± SE) by land cover or protected status where taxon was recorded and not
            recorded for selected geographic area. Data sources:
            <Anchor
                href="https://www.mrlc.gov/data/nlcd-2011-land-cover-conus-0"
                label=" NLCD 2011"
            ></Anchor>
            <Anchor
                href="https://www.usgs.gov/core-science-systems/science-analytics-and-synthesis/gap/science/protected-areas"
                label=" PAD-US "
            />. <br/>
            Relationships calculated at the quad level.
        </Text>
    ),
    CLIMATE_TOOLTIP_INFO: (
        <Text>
            Climate (mean ±SE) where taxon was recorded and not recorded for selected geographic area.
            Data source: {" "}
            <Anchor
                href="https://crudata.uea.ac.uk/cru/data/hrg/cru_ts_3.23/"
                label="CRU TS v. 3.23"
            ></Anchor>.
            <br/>
            Relationships calculated at the quad level.
        </Text>
    ),
    HABITAT_DIVERSITY_INFO: (
        <Text>
            Land area by landcover classes or protected status where taxon was present
            and absent. Data sources:
            <Anchor
                href="https://www.mrlc.gov/data/nlcd-2011-land-cover-conus-0"
                label=" NLCD 2011"
            ></Anchor>
            <Anchor
                href="https://www.usgs.gov/core-science-systems/science-analytics-and-synthesis/gap/science/protected-areas"
                label=" PAD-US "
            />
            (“protected” = GAP status 1 or 2, managed for biodiversity)
        </Text>
    ),
    CLIMATE_DIVERITY_INFO: (
        <Text>
            Data source:
            <Anchor
                href="https://crudata.uea.ac.uk/cru/data/hrg/cru_ts_3.23/"
                label=" CRU TS v. 3.23 "
            ></Anchor>
            covering 1901-2014)
        </Text>
    ),
};
const CONTACT_INFO_MAMMALS = {
    TWITTER: "https://twitter.com/NYMammals",
    FACEBOOK: "https://www.facebook.com/NYMammals",
    MAIL: "NYMS@esf.edu",
    INSTAGRAM: "https://www.instagram.com/NYMammals/",
};

const CONTACT_INFO_REPTILES = {
    TWITTER: "https://twitter.com/nyherps",
    FACEBOOK: "https://www.facebook.com/nyherps",
    MAIL: "nyherps@gmail.com",
    INSTAGRAM: "https://www.instagram.com/nyherps/",
};

const CONTACT_INFO =
    (process.env.REACT_APP_TYPE_CODE ? process.env.REACT_APP_TYPE_CODE : "2") ===
    "2"
        ? CONTACT_INFO_REPTILES
        : CONTACT_INFO_MAMMALS;

const APP_LINKS = {
    SIGN_UP: window.location.origin + "/signup",
    SIGN_IN: window.location.origin + "/signin",
    MOBILE_APP: "", //TODO: Add app link here
    GUIDE_PAGE: window.location.origin + "/guide",
};

const TIME_INPUT_MASK = {
    mask: [
        {
            length: [
                1,
                2
            ],
            options: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12"
            ],
            regexp: /^1[1-2]$|^[0-9]$/,
            placeholder: "hh",
        },
        { fixed: ":" },
        {
            length: 2,
            options: [
                "00",
                "15",
                "30",
                "45"
            ],
            regexp: /^[0-5][0-9]$|^[0-9]$/,
            placeholder: "mm",
        },
        { fixed: " " },
        {
            length: 2,
            options: [
                "am",
                "pm"
            ],
            regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
            placeholder: "ap",
        },
    ],
};

const VOCALIZATION = [
    {
        type: "DISTINGUISHABLE_NO_OVERLAP",
        value: "Individual calls distinguishable, no overlap",
    },
    {
        type: "DISTINGUISHABLE_SOME_OVERLAP",
        value: "Individual calls distinguishable, some overlap",
    },
    {
        type: "CONTINUOUS_OVERLAPPING_CALLING",
        value: "Full chorus with continuous, overlapping calling",
    },
    { type: "UNKNOWN", value: "Unknown" },
];

const MIN_DATE = new Date();
MIN_DATE.setFullYear(1700, 0, 1);

const DEBOUNCE_TIME = 500;

export {
    APP_LINKS,
    USER_SESSION_KEY,
    USER_TOKEN_KEY,
    MAP_LAYERS,
    STATES,
    CITIES,
    AGE,
    SEX,
    DUMMY_PIC_URL,
    EDUCATION,
    USER_TYPE,
    COUNTIES,
    QUADS,
    WATERSHEDS,
    DEC_REGION,
    INFO_TEXTS,
    VOCALIZATION,
    RECORDING_METHOD,
    ENUM_VALIDATION,
    BULK_UPLOAD_JOB_ID,
    HABITAT_OPTIONS,
    CLIMATE_OPTIONS,
    CONTACT_INFO,
    TIME_INPUT_MASK,
    RECORDING_METHOD_MAMMALS,
    RECORDING_METHOD_REPTILES,
    RECORDING_METHOD_REPTILES_VOCAL,
    MIN_DATE,
    DEBOUNCE_TIME,
};
