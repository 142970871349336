import React, { useEffect, useRef, useState } from "react";
import { Mutations } from "../apollo";
import styled from "styled-components";
import { CheckMark, Theme } from "../theme";
import { useMutation } from "@apollo/react-hooks";
import { ShowDialog } from "../components/DialogsComponent";
import DragAndDropComponent from "../components/DragAndDropComponent";
import CropAndUploadImage from "../components/CropAndUploadImageComponent";
import { CITIES, EDUCATION, Errors, STATES, USER_TYPE } from "../constants";
import FieldTitleWithIconComponent from "../components/FieldTitleWithIconComponent";
import { getEnumKey, getEnumValue, getEnumValues } from "../utils";
import { Box, Button, CheckBox, Heading, ResponsiveContext, Select, TextInput, } from "grommet";
import { getUserInput, getUserProfileInput, } from "../apollo/mutations/Variables";
import { EmailField, PasswordField, TextBox, TextField, } from "../components/InputFields";

const RootContainer = styled(Box)`
  @media (max-width: 1280px) {
    padding: 2rem;
  }
  background-color: ${Theme.global.colors["white"]};
`;

const ItemContainer = styled(Box)`
  margin-top: ${(props) => (props.size === "small" ? "1rem" : "")};
  margin-right: ${(props) => (props.size === "small" ? "0rem" : "4rem")};
  @media (max-width: 1280px) {
    margin-top: 1rem;
    margin-right: 0rem;
  }
  button {
    height: 3rem;
  }
`;
const InputContainer = styled(Box)`
  width: 20rem;
  margin-top: ${(props) => (props.size === "small" ? "1rem" : "")};
  margin-right: ${(props) => (props.size === "small" ? "0rem" : "4rem")};
  @media (max-width: 1280px) {
    width: 100%;
    margin-top: 1rem;
    margin-right: 0rem;
  }
`;
const InfoFieldsWrapper = styled.div`
  padding: 0.3rem;
  flex-direction: ${(props) => (props.size === "small" ? "column" : "")};
  display: flex;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;
const DropDowns = styled(Box)`
  padding-top: 0.3rem;
  flex-direction: ${(props) => (props.size === "small" ? "column" : "")};
  display: flex;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
  button {
    height: 3rem;
  }
`;
const SpaceBetweenBox = styled(Box)`
  margin-right: ${(props) => (props.size === "small" ? "0rem" : "3rem")};
  @media (max-width: 1280px) {
    margin-right: 0rem;
  }
`;
const SignUpButton = styled(Button)`
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1.2rem;
  padding-left: 1.3rem;
  border-radius: 10rem;
  background-color: ${Theme.global.colors["brand"]};
  color: ${Theme.global.colors.white};
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const DropdownContainer = styled(Select)`
  width: 10rem;
  color: ${Theme.global.colors.textBlack};
  font-size: 1rem;
  font-weight: 100;
  ::placeholder {
    color: ${Theme.global.colors.textBlack};
  }
`;

const SignUp = (props) => {
    const cropComponentRef = useRef();
    const isEditMode = () => {
        return props.location.state ? true : false;
    };

    const getInitialState = () => {
        const s = {
            type: USER_TYPE[2],
            name: "",
            email: "",
            password: "",
            education: "",
            city: "",
            state: "",
            image: "",
            bio: "",
            privacy: true,
        };
        return isEditMode()
            ? {
                ...props.location.state.userInfo,
            }
            : s;
    };
    const [userInfo, setUserInfo] = useState(() => getInitialState());
    const [imageFile, setImageFile] = useState("");
    const [citySuggestions, setCitySuggestion] = useState([...CITIES]);
    const [stateSuggestions, setStateSuggestion] = useState([...STATES]);
    const [dialogState, setDialogState] = useState({
        show: false,
        message: "",
        type: "",
    });

    const [
        createUser,
        { data: createUserResponse, error: createUserErrorResponse },
    ] = useMutation(Mutations.CREATE_USER_MUTATION, getUserInput(userInfo));

    const [
        updateUser,
        { data: updateUserResponse, error: updateUserErrorResponse },
    ] = useMutation(Mutations.UPDATE_USER_PROFILE, getUserProfileInput(userInfo));

    useEffect(() => {
        if (createUserResponse) {
            setDialogState({
                show: true,
                message: "Successfully signed up.",
                type: "SUCCESS",
            });
        }
        if (createUserErrorResponse) {
            setDialogState({
                show: true,
                message: "Signed up failed.",
                type: "FAILED",
            });
        }
    }, [
        createUserResponse,
        createUserErrorResponse
    ]);

    useEffect(() => {
        if (updateUserResponse) {
            setDialogState({
                show: true,
                message: "User successfully updated.",
                type: "SUCCESS",
            });
        }
        if (updateUserErrorResponse) {
            setDialogState({
                show: true,
                message: "Failed to update user.",
                type: "FAILED",
            });
        }
    }, [
        updateUserResponse,
        updateUserErrorResponse
    ]);

    useEffect(() => {
        if (userInfo.state.length > 0) {
            let filterStates = [];
            STATES.map((item) => {
                if (item.toLowerCase().includes(userInfo.state.toLowerCase())) {
                    filterStates.push(item);
                }
                return item;
            });
            setStateSuggestion(filterStates);
        }
    }, [userInfo.state]);

    useEffect(() => {
        if (userInfo.city.length > 0) {
            let filteredCities = [];
            CITIES.map((item) => {
                if (item.toLowerCase().includes(userInfo.city.toLowerCase())) {
                    filteredCities.push(item);
                }
                return item;
            });
            setCitySuggestion(filteredCities);
        }
    }, [userInfo.city]);

    const makeCreateUserCall = () => {
        setDialogState({
            ...dialogState,
            message: "Registering...",
        });
        createUser();
    };

    const makeUpdateUserCall = () => {
        setDialogState({
            show: true,
            message: "Updating details...",
            type: "LOADING",
        });
        updateUser();
    };

    const navigateToNextPage = () => {
        let path;
        if (isEditMode()) {
            path = `/profile`;
        } else {
            path = `/signin`;
        }
        props.history.push(path);
    };

    const handleCloseAlertDialog = () => {
        setDialogState({
            ...dialogState,
            show: false,
            message: "",
        });
        if (dialogState.type === "SUCCESS") {
            navigateToNextPage();
        }
    };

    const handleClearImage = () => {
        setUserInfo({ ...userInfo, image: "" });
        setImageFile("");
    };

    useEffect(() => {
        if (userInfo.image && dialogState.show) {
            if (isEditMode()) {
                makeUpdateUserCall();
            } else {
                makeCreateUserCall();
            }
        }
        // eslint-disable-next-line
    }, [userInfo.image]);

    const handleOnImageUploaded = (data) => {
        setUserInfo({ ...userInfo, image: data.key });
    };

    const onBrowseClick = (file) => {
        if (userInfo.name) {
            if (file !== undefined) {
                setImageFile(file);
            }
        } else {
            setDialogState({
                show: true,
                message: Errors.FILL_REQUIRE_FIELDS_FIRST,
                type: "FAILED",
            });
        }
    };

    const onSubmit = (isEditMode) => {
        if (
            userInfo.name &&
            userInfo.email &&
            userInfo.password &&
            userInfo.education &&
            userInfo.city &&
            userInfo.state
        ) {
            if (!userInfo.image && imageFile) {
                setDialogState({
                    show: true,
                    message: "",
                    type: "LOADING",
                });
                cropComponentRef.current.UploadImage();
            } else {
                isEditMode ? makeUpdateUserCall() : makeCreateUserCall();
            }
        } else {
            setDialogState({
                show: true,
                message: Errors.REQUIRED_FIELDS,
                type: "FAILED",
            });
        }
    };

    const _isShowEmailPassword = (size) => {
        return isEditMode() ? (
            <div></div>
        ) : (
            <InfoFieldsWrapper size={size}>
                <InputContainer size={size}>
                    <EmailField
                        size={size}
                        value={userInfo.email}
                        onValueChange={(value) => {
                            setUserInfo({ ...userInfo, email: value });
                        }}
                    />
                </InputContainer>
                <InputContainer size={size}>
                    <PasswordField
                        size={size}
                        title={"Password"}
                        value={userInfo.password}
                        onValueChange={(value) =>
                            setUserInfo({ ...userInfo, password: value })
                        }
                    />
                </InputContainer>
            </InfoFieldsWrapper>
        );
    };

    const _DragAndDrop = () => {
        return userInfo.image || imageFile ? (
            <CropAndUploadImage
                ref={cropComponentRef}
                userName={userInfo.name}
                imageFileToUpload={imageFile ? imageFile : userInfo.image}
                clearImage={handleClearImage}
                onImageUploaded={handleOnImageUploaded}
                isShowingCropView={imageFile ? true : false}
            />
        ) : (
            <DragAndDropComponent onBrowse={onBrowseClick} fileType="image"/>
        );
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <React.Fragment>
                    {dialogState.show && (
                        <ShowDialog
                            title={dialogState.message}
                            onClose={handleCloseAlertDialog}
                            type={dialogState.type}
                        />
                    )}
                    <RootContainer
                        elevation={"medium"}
                        margin={{
                            top: size === "small" ? "small" : "medium",
                        }}
                        pad={size === "small" ? "1.5rem" : "3rem"}
                    >
                        <Heading
                            color={Theme.global.colors.textBlack}
                            level={2}
                            margin={{ bottom: "0.4rem" }}
                        >
                            General
                        </Heading>
                        <InfoFieldsWrapper size={size}>
                            <InputContainer size={size}>
                                <TextField
                                    size={size}
                                    isRequired={true}
                                    value={userInfo.name}
                                    placeholder={"Your name"}
                                    title={"Name"}
                                    onValueChange={(value) =>
                                        setUserInfo({ ...userInfo, name: value })
                                    }
                                />
                            </InputContainer>
                            <ItemContainer size={size}>
                                <FieldTitleWithIconComponent
                                    title={"Education"}
                                    isRequiredField={true}
                                    isShowIcon={false}
                                />
                                <DropdownContainer
                                    placeholder="Select"
                                    options={getEnumValues(EDUCATION)}
                                    value={getEnumValue(EDUCATION, userInfo.education)}
                                    onChange={(option) => {
                                        setUserInfo({
                                            ...userInfo,
                                            education: getEnumKey(EDUCATION, option.value),
                                        });
                                    }}
                                />
                            </ItemContainer>
                        </InfoFieldsWrapper>

                        {_isShowEmailPassword(size)}

                        <Heading
                            color={Theme.global.colors.textBlack}
                            level={2}
                            margin={{ bottom: "0.4rem" }}
                        >
                            Location
                        </Heading>
                        <DropDowns size={size} direction="row">
                            <SpaceBetweenBox size={size}>
                                <FieldTitleWithIconComponent
                                    title={"State"}
                                    isRequiredField={true}
                                    isShowIcon={false}
                                />
                                <TextInput
                                    placeholder={"Your state"}
                                    style={{
                                        width: "14rem",
                                        fontSize: "1rem",
                                        fontWeight: "100",
                                    }}
                                    dropHeight={"small"}
                                    size={"medium"}
                                    value={userInfo.state}
                                    suggestions={stateSuggestions}
                                    onChange={(event) => {
                                        setUserInfo({ ...userInfo, state: event.target.value });
                                    }}
                                    onSelect={(event) => {
                                        setUserInfo({ ...userInfo, state: event.suggestion });
                                    }}
                                />
                            </SpaceBetweenBox>
                            <ItemContainer size={size}>
                                <FieldTitleWithIconComponent
                                    title={"City"}
                                    isRequiredField={true}
                                    isShowIcon={false}
                                />
                                <TextInput
                                    placeholder={"Your city"}
                                    style={{
                                        width: "14rem",
                                        fontSize: "1rem",
                                        fontWeight: "100",
                                    }}
                                    dropHeight={"small"}
                                    size={"medium"}
                                    value={userInfo.city}
                                    suggestions={citySuggestions}
                                    onChange={(event) => {
                                        setUserInfo({ ...userInfo, city: event.target.value });
                                    }}
                                    onSelect={(event) => {
                                        setUserInfo({ ...userInfo, city: event.suggestion });
                                    }}
                                />
                            </ItemContainer>
                        </DropDowns>

                        <TextBox
                            size={size}
                            value={userInfo.bio}
                            placeholder={"Add a short biography to your profile"}
                            title={"Short biography"}
                            onValueChange={(value) =>
                                setUserInfo({ ...userInfo, bio: value })
                            }
                        />

                        <Box direction="row" align="center">
                            <Heading
                                color={Theme.global.colors.textBlack}
                                level={2}
                                margin={{ bottom: "0.75rem", right: "0.3rem" }}
                            >
                                Profile image
                            </Heading>
                        </Box>
                        {_DragAndDrop()}
                        <Heading
                            color={Theme.global.colors.textBlack}
                            level={2}
                            margin={{ bottom: "0.75rem" }}
                        >
                            Privacy
                        </Heading>
                        <CheckBox
                            toggle
                            label={"Include on leaderboard"}
                            checked={userInfo.privacy}
                            onChange={(isChecked) => {
                                setUserInfo({
                                    ...userInfo,
                                    privacy: isChecked.target.checked,
                                });
                            }}
                        />

                        <ItemContainer>
                            <SignUpButton
                                ariaLabel="SignUp Button"
                                tabIndex="1"
                                role="link"
                                icon={<CheckMark color={Theme.global.colors.white}/>}
                                alignSelf={"center"}
                                label={isEditMode() ? "Update profile" : "Sign up"}
                                onClick={(event) => {
                                    onSubmit(isEditMode());
                                }}
                            />
                        </ItemContainer>
                    </RootContainer>
                </React.Fragment>
            )}
        </ResponsiveContext.Consumer>
    );
};
export default SignUp;
