import React, { useEffect } from "react";
import styled from "styled-components";
import { Add, SuccessIcon, Theme } from "../theme";
import { BULK_UPLOAD_JOB_ID } from "./../constants";
import { Box, Button, Heading, ResponsiveContext, Text } from "grommet";

const SuccessPageRootContainer = styled(Box)`
  background: ${Theme.global.colors.white};
  align-content: center;
`;
const RecordForumHeading = styled(Heading)`
  font-size: 2.125rem;
  color: ${Theme.global.colors.textBlack};
`;
const InfoDetailWrapper = styled(Box)`
  font-size: 1.0625rem;
  margin-bottom: 2.75rem;
  @media screen and (max-width: 1280px) {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 786px) {
    margin-bottom: 1rem;
  }
`;
const ActionButtonsContainer = styled(Box)`
  flex-direction: row;
  @media screen and (max-width: 480px) {
    display: block;
    flex-direction: column;
  }
`;

const ButtonAnotherUpload = styled(Button)`
  font-size: 1rem;
  color: ${Theme.global.colors.active};
  height: 2.3125rem;
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const AddRecordSuccess = (props) => {
    useEffect(() => {
        localStorage.setItem(BULK_UPLOAD_JOB_ID, "");
    });
    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <SuccessPageRootContainer
                    elevation="medium"
                    round="0.25rem"
                    margin={{
                        top: size === "small" ? "small" : "medium",
                        bottom: size === "small" ? "small" : "medium",
                    }}
                    pad={size === "small" ? "1.5rem" : "3rem"}
                    direction="column"
                    gap="small"
                    size={size}
                >
                    <Box direction="row" gap="small" alignContent="center" align="center">
                        <SuccessIcon width="2.125rem" height="2.125rem"/>
                        <RecordForumHeading margin="none">Success</RecordForumHeading>
                    </Box>
                    <InfoDetailWrapper>
                        <Text color={Theme.global.colors.grey}>
                            Bulk uploads successfully added to the database.
                        </Text>
                    </InfoDetailWrapper>
                    <ActionButtonsContainer direction="row" gap={"medium"}>
                        <ButtonAnotherUpload
                            label="Add another upload"
                            icon={<Add color={Theme.global.colors.active}/>}
                            ariaLabel="Add another upload"
                            tabIndex="0"
                            role="link"
                            onClick={() => props.history.goBack()}
                        />
                        <Button
                            plain
                            ariaLabel="View records"
                            tabIndex="1"
                            role="link"
                            style={{ fontSize: "1.1rem", alignSelf: "center" }}
                            label="View records"
                            color={Theme.global.colors.active}
                            alignSelf="end"
                            onClick={() => props.history.push("/profile")}
                        />
                    </ActionButtonsContainer>
                </SuccessPageRootContainer>
            )}
        </ResponsiveContext.Consumer>
    );
};

export default AddRecordSuccess;
