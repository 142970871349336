import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import { clearClientCache, Mutations, Queries } from "../apollo";
import {
    capitalizeFirstLetter,
    getEnumKey,
    getEnumValue,
    getEnumValues,
    getImageURL,
    isUserTypeModerator,
    sortSpecieListAlphabetically
} from "../utils";
import { ShowDialog } from "../components/DialogsComponent";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { getUserId, getUserName, unCamelCase } from "../helpers";
import PickLocationDialog from "../components/MapForPickLocation";
import ImageCaptionDialog from "../components/ImageCaptionDialog";
import DragAndDropComponent from "../components/DragAndDropComponent";
import { DateComponent, TimeComponent } from "../components/DateTimeComponent";
import FieldTitleWithIconComponent, { InformationIcon } from "../components/FieldTitleWithIconComponent";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from "react-places-autocomplete";
import {
    AGE,
    Errors,
    INFO_TEXTS,
    RECORDING_METHOD_MAMMALS,
    RECORDING_METHOD_REPTILES,
    RECORDING_METHOD_REPTILES_VOCAL,
    SEX,
    VOCALIZATION,
} from "../constants";
import {
    Anchor,
    Box,
    Button,
    CheckBox,
    Grommet,
    Heading,
    ResponsiveContext,
    Select,
    Text,
    TextArea,
    TextInput
} from "grommet";
import {
    getObservationCreateInput,
    getObservationUpdateInput,
    getObservationWhereUniqueInput,
} from "../apollo/mutations/Variables";
import { Add, CheckMark, CloseCustom, SearchCustom, Theme, Trash, } from "../theme";
import MultiLabelDropDown from "../components/MultiLabelDropDown";
import AppContext from "../appContext";
import NYMSWalkthrough from "../assets/documents/NYMS_walkthrough_06142021.pdf"

const RecordPageRootContainer = styled(Box)`
  background: ${Theme.global.colors.white};
`;
const RecordForumGrid = styled.div`
  display: grid;
  @media only screen and (max-width: 1280px) {
    display: block;
  }
  grid-template-columns: repeat(2, 1fr);

  .GridClassification {
    grid-column: 1 / span 1;
  }

  .GridTimeNDate {
    grid-column: 2 / span 1;
  }

  .GridLocation {
    grid-column: 1 / span 1;
  }

  .GridObservation {
    grid-column: 1 / span 2;
  }

  .GridBottom {
    grid-column: 1 / span 2;
  }
`;

const ItemWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 2rem;
  @media screen and (max-width: 786px) {
    margin-bottom: 1.5rem;
  }
`;

const RecordForumHeading = styled(Heading)`
  font-size: 2.125rem;
  color: ${Theme.global.colors.textBlack};
  @media screen and (max-width: 786px) {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
`;
const SearchPlacesFieldWrapper = styled.div`
  position: relative;
  max-width: 30rem;

  & > svg {
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
  }

  input {
    padding-right: 2.5rem;
    padding-left: 3.5rem;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 18px;
    border-color: ${Theme.global.colors.active};
  }
`;
const InputFieldWrapper = styled.div`
  height: 3rem;

  .MediumInputField {
    position: relative;
    max-width: 21.4375rem;

    & > svg {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
    }

    input {
      padding-right: 2.5rem;
    }
  }

  .SmallInputField {
    max-width: 13.5rem;
    position: relative;

    & > svg {
      position: absolute;
      top: 0.8rem;
      left: 0.8rem;
    }

    input {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  .ExtraSmallInputField {
    max-width: 6.5rem;
  }

  input {
    font-size: 1rem;
    font-weight: 400;
  }
`;

const DateInput = styled.div`
  width: 18rem;
  height: 3rem;
  @media only screen and (max-width: 1398px) {
    width: 100%;
  }
`;

const ObservationItemsWrapper = styled(Box)`
  @media screen and (max-width: 1280px) {
    display: block;
    flex-direction: column;
  }
`;

const ObservationItemWrapper = styled(Box)`
  flex-direction: column;
  @media screen and (max-width: 1280px) {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 786px) {
    margin-bottom: 0.5rem;
  }
`;

const SelectWrapper = styled.div`
  max-width: 14.4375rem;
  min-width: 10rem;
  font-size: 1rem;
  font-weight: 100;
  color: ${Theme.global.colors.textBlack};
`;

const NotesTextInputArea = styled(TextArea)`
  fill: true;
  max-height: 8.125rem;
  min-height: 8.125rem;
  padding: 1rem;
  font-size: 1.0625rem;
  color: ${Theme.global.colors.textBlack};
  font-weight: 100;
  border-color: ${Theme.global.colors.inactive};
  border-style: solid;
  border-width: 1px;
`;

const ImagesSlider = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  overflow: auto;
`;

const ImageWrapper = styled.img`
  height: 12rem;
  width: auto;
  border-radius: 0.25rem;
  margin-right: 0.6rem;
  display: block;
  line-height: 100px;
  z-index: 1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  :hover {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25));
  }
`;

const SubmitRecordContainer = styled(Box)`
  display: flex;
  flex-direction: initial;
  justify-content: ${(props) =>
          props.isEditMode ? "space-between" : "center"};
  margin-top: ${(props) => (props.size === "small" ? "1rem" : "3rem")};
  @media (max-width: 786px) {
    margin-top: 1rem;
  }
`;

const SubmitButton = styled(Button)`
  width: 13rem;
  height: 3rem;
  border-radius: 8rem;
  background-color: ${(props) =>
          props.isEditMode
                  ? Theme.global.colors.white
                  : Theme.global.colors["brand"]};
  color: ${(props) =>
          !props.isEditMode
                  ? Theme.global.colors.white
                  : Theme.global.colors["brand"]};
  @media screen and (max-width: 786px) {
    width: 7.625rem;
    height: 2.4rem;
  }
`;

const Record = (props) => {
    const context = useContext(AppContext);
    let bounds;
    const isEditMode = () => {
        return props.location.state ? true : false;
    };
    const refToInputField = useRef({
        current: {
            clientWidth: "230px",
        },
    });
    const myCustomTheme = deepMerge(grommet, {
        ...Theme,
        select: {
            options: {
                container: {
                    width: refToInputField?.current?.clientWidth
                        ? refToInputField.current.clientWidth.toString() + "px"
                        : "280px",
                },
            },
        },
    });

    const isNotSpecieImage = (image) =>
        image.includes("https://ny-mammals-s3.s3.amazonaws.com/species")
            ? false
            : true;

    const getImagesWithoutSpecieImage = (images = []) => {
        const tempImages = [];
        images.forEach((item) => {
            if (isNotSpecieImage(item.url)) {
                tempImages.push(item);
            }
        });
        return tempImages;
    };

    const getInitialState = () => {
        const s = {
            dateTime: "",
            age: "UNKNOWN",
            sex: "UNKNOWN",
            recordingMethod: "SIGHTING",
            notes: "",
            privacy: false,
            flagged: false,
            quantity: 1,
            images: "",
            morphs: "",
            user: getUserId(),
            specie: "",
            order: "",
            vocalization: VOCALIZATION[3].type,
            gpsCoordinates: {
                lat: "",
                long: "",
            },
        };

        return isEditMode()
            ? {
                dateTime: props.location.state.observationItem.dateTime,
                age: props.location.state.observationItem.age,
                sex: props.location.state.observationItem.sex,
                recordingMethod: props.location.state.observationItem.recordingMethod,
                notes: props.location.state.observationItem.notes,
                flagged: props.location.state.observationItem.flagged,
                privacy: props.location.state.observationItem.privacy,
                quantity: props.location.state.observationItem.quantity,
                images: getImagesWithoutSpecieImage(
                    props.location.state.observationItem.images
                ),
                specie: props.location.state.observationItem.specie.id,
                order: props.location.state.observationItem.order.id,
                morphs: props.location.state.observationItem.morphs,
                vocalization: props.location.state.observationItem.vocalization,
                gpsCoordinates: {
                    lat: props.location.state.observationItem.gpsCoordinates[0],
                    long: props.location.state.observationItem.gpsCoordinates[1],
                },
            }
            : s;
    };
    const [address, setAddress] = useState("");
    const [record, setRecord] = useState(() => getInitialState());

    const [dialogState, setDialogState] = useState({
        show: false,
        message: "",
        type: "",
    });

    const getImages = (images) => (isEditMode() ? record.images : []);

    const getTime = () => moment(record.dateTime).utc().format("HH:mm");

    const getDate = () => moment(record.dateTime).format("YYYY-MM-DD");

    const getOrder = () =>
        isEditMode()
            ? capitalizeFirstLetter(
            props.location.state.observationItem.order.commonName
            )
            : "";

    const getSpecie = () =>
        isEditMode()
            ? capitalizeFirstLetter(
            props.location.state.observationItem.specie.commonName
            )
            : "";

    const [imageFile, setImageFile] = useState("");
    const [date, setDate] = useState(isEditMode() ? getDate() : "");
    const [time, setTime] = useState(isEditMode() ? getTime() : "");
    const [orderQuery, setOrderQuery] = useState(getOrder());
    const [speciesQuery, setSpeciesQuery] = useState(getSpecie());
    const [images, setImages] = useState(getImages(record.images));
    const [searchOrderSuggestions, setSearchOrderSuggestions] = useState([]);
    const [morphSuggestions, setMorphSuggestions] = useState([]);
    const [searchSpecieSuggestions, setSearchSpecieSuggestions] = useState([]);
    const [searchSpecieFiltered, setSearchSpecieFiltered] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [deleteImages, setImagesToDelete] = useState([]);
    const [isDelete, setIsDelete] = useState(false);
    const {
        data: queryCallResponseData,
        error: queryCallErrorResponse,
    } = useQuery(Queries.GET_ALL_ORDERS_SPECIES_QUERY);

    const [
        createObservationMutation,
        { data: createMutationDataResponse, error: createMutationErrorResponse },
    ] = useMutation(
        Mutations.CREATE_OBSERVATION_MUTATION,
        getObservationCreateInput(record)
    );

    const [
        deleteObservationMutation,
        { data: deleteMutationDataResponse, error: deleteMutationErrorResponse },
    ] = useMutation(
        Mutations.DELETE_OBSERVATION_MUTATION,
        getObservationWhereUniqueInput(props?.location?.state?.observationItem?.id)
    );
    const [
        updateObservationMutation,
        { data: updateMutationDataResponse, error: updateMutationErrorResponse },
    ] = useMutation(
        Mutations.UPDATE_OBSERVATION_MUTATION,
        getObservationUpdateInput(
            record,
            isEditMode() ? props.location.state.observationItem.id : ""
        )
    );

    useEffect(() => {
        const google = (window.google = window.google ? window.google : {});
        var southWest = new google.maps.LatLng({ lat: 40.4772, lng: -79.7624 });
        var northEast = new google.maps.LatLng({ lat: 45.0153, lng: -71.7517 });
        bounds = new google.maps.LatLngBounds();
        bounds.extend(southWest);
        bounds.extend(northEast);
    }, []);

    useEffect(() => {
        if (queryCallResponseData) {
            if (queryCallResponseData.orders) {
                setSearchOrderSuggestions(
                    setAllOrdersInSuggestion(
                        queryCallResponseData.orders.sort((a, b) =>
                            a.commonName > b.commonName ? 1 : -1
                        )
                    )
                );

                const species = spicesSuggestionsWithoutFilter();
                setSpeciesQuery(getSpecie());
                setOrderQuery(getOrder());
                setSearchSpecieFiltered(sortSpecieListAlphabetically(species));
                setSearchSpecieSuggestions(sortSpecieListAlphabetically(species));
            }
            if (queryCallResponseData?.species && isEditMode()) {
                let specieList = queryCallResponseData.species;
                specieList.map((item, index) => {
                    if (item.id === props.location.state.observationItem.specie.id) {
                        setMorphSuggestions(item.morphs);
                    }
                    return item;
                });
            }
        }
        // eslint-disable-next-line
    }, [
        queryCallResponseData,
        queryCallErrorResponse
    ]);

    useEffect(() => {
        if (createMutationDataResponse) {
            setDialogState({
                show: true,
                message: "Record submitted successfully.",
                type: "SUCCESS",
            });
        }
        if (createMutationErrorResponse) {
            setDialogState({
                show: true,
                message: "Error while submitting record.",
                type: "FAILED",
            });
        }
    }, [
        createMutationDataResponse,
        createMutationErrorResponse
    ]);

    useEffect(() => {
        if (deleteMutationDataResponse) {
            setDialogState({
                show: true,
                message: "Record deleted successfully.",
                type: "SUCCESS",
            });
        }
        if (deleteMutationErrorResponse) {
            setDialogState({
                show: true,
                message: "Error while deleting record.",
                type: "FAILED",
            });
        }
    }, [
        deleteMutationDataResponse,
        deleteMutationErrorResponse
    ]);

    useEffect(() => {
        if (updateMutationDataResponse) {
            setDialogState({
                show: true,
                message: "Record updated successfully.",
                type: "SUCCESS",
            });
        }
        if (updateMutationErrorResponse) {
            setDialogState({
                show: true,
                message: updateMutationErrorResponse.message,
                type: "FAILED",
            });
        }
    }, [
        updateMutationDataResponse,
        updateMutationErrorResponse
    ]);

    useEffect(() => {
        filterOrders();
        // eslint-disable-next-line
    }, [orderQuery]);

    useEffect(() => {
        if (!record.specie && morphSuggestions && morphSuggestions.length === 1) {
            setRecord({ ...record, morphs: morphSuggestions });
        }
        // eslint-disable-next-line
    }, [morphSuggestions]);

    useEffect(() => {
        filterMorps(record.specie);
        // eslint-disable-next-line
    }, [record.specie]);

    useEffect(() => {
        filterSpecie();
        // eslint-disable-next-line
    }, [speciesQuery]);

    useEffect(() => {
        if (isSubmit) {
            setIsSubmit(false);
            if (isEditMode()) {
                makeUpdateObservationCall();
            } else {
                makeCreateObservationCall();
            }
        }
        // eslint-disable-next-line
    }, [isSubmit]);

    const makeCreateObservationCall = () => {
        setDialogState({
            show: true,
            message: "Submitting record...",
            type: "LOADING",
        });
        createObservationMutation();
    };

    const makeDeleteObservationCall = () => {
        setDialogState({
            show: true,
            message: "Deleting record...",
            type: "LOADING",
        });
        deleteObservationMutation();
    };

    const makeUpdateObservationCall = () => {
        setDialogState({
            show: true,
            message: "Updating record...",
            type: "LOADING",
        });
        updateObservationMutation();
    };

    const setAllOrdersInSuggestion = (orders) => {
        let names = [];
        orders.forEach((item) => {
            names.push(unCamelCase(capitalizeFirstLetter(item.commonName)));
        });
        return names;
    };

    const filterOrders = () => {
        if (queryCallResponseData && queryCallResponseData.orders) {
            let filtered = queryCallResponseData.orders;
            filtered.sort((a, b) => (a.commonName > b.commonName ? 1 : -1));
            let specieList = spicesSuggestionsWithoutFilter();

            if (orderQuery && orderQuery.length > 0) {
                filtered = filtered.filter((item) => {
                    return item.commonName
                        .toLowerCase()
                        .includes(orderQuery.toLowerCase());
                });
            } else {
                setRecord({ ...record, order: "" });
            }

            let tempOrderId;
            filtered.map((item) => {
                if (item.commonName.toLowerCase() === orderQuery.toLowerCase()) {
                    tempOrderId = item.id;
                    setRecord({ ...record, order: item.id });
                }
                return item;
            });

            let newSuggestions = [];
            specieList.map((item) => {
                if (item.orderId === tempOrderId) {
                    newSuggestions.push(item);
                }
                return item;
            });
            setSearchOrderSuggestions(setAllOrdersInSuggestion(filtered));
            if (newSuggestions) {
                setSearchSpecieFiltered(
                    sortSpecieListAlphabetically(
                        tempOrderId ? newSuggestions : specieList
                    )
                );
                setSearchSpecieSuggestions(
                    sortSpecieListAlphabetically(
                        tempOrderId ? newSuggestions : specieList
                    )
                );
            }
        }
    };
    const filterMorps = (id) => {
        if (queryCallResponseData && queryCallResponseData.species) {
            let specieList = queryCallResponseData.species;
            specieList.map((item, index) => {
                if (item.id === id) {
                    setMorphSuggestions(item.morphs);
                }
                return item;
            });
        }
    };
    const filterSpecie = () => {
        if (searchSpecieFiltered) {
            let filtered = searchSpecieFiltered;
            if (speciesQuery && speciesQuery.length > 0) {
                filtered = filtered.filter((item) => {
                    return item.commonName
                        .toLowerCase()
                        .includes(speciesQuery.toLowerCase());
                });
                if (filtered.length === 1 && orderQuery.length < 1) {
                    setRecord({ ...record, specie: filtered[0].id });
                    setOrderQuery(filtered[0].orderCommonName);
                } else if (filtered.length === 1 && filtered[0].orderId === record.order) {
                    setRecord({ ...record, specie: filtered[0].id });
                }
            } else {
                setRecord({ ...record, specie: "" });
            }
            if (filtered) {
                if (record.order !== "") {
                    filtered = filtered.filter((item) => {
                        return (
                            item.commonName
                                .toLowerCase()
                                .includes(speciesQuery.toLowerCase()) &&
                            item.orderId === record.order
                        );
                    });
                }
                setSearchSpecieSuggestions(filtered);
            }
        }
    };

    const onBrowseClick = (file) => {
        if (file !== undefined) {
            setImageFile(file);
        }
    };

    const handleOnImageUploaded = (data, caption, credits, isSensitiveImage) => {
        const newImage = {
            url: data.location,
            caption: caption,
            credits: credits,
            sensitveImage: isSensitiveImage,
        };
        setImages([
            ...images,
            newImage
        ]);
        handleCancelDialog();
    };

    const spicesSuggestionsWithoutFilter = () => {
        if (queryCallResponseData && queryCallResponseData.species) {
            var species = [];
            queryCallResponseData.species.forEach((item) => {
                species.push({
                    id: item.id,
                    commonName: item.commonName,
                    binomialName: item.binomialName,
                    orderId: item.order.id,
                    orderCommonName: item.order.commonName,
                });
            });
            return species;
        }
    };

    const goToHomePage = () => {
        clearClientCache();
        let path = `/`;
        props.history.push(path);
    };

    const handleSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);

        setAddress(value);
        setRecord({
            ...record,
            gpsCoordinates: {
                ...record.gpsCoordinates,
                lat: Number.parseFloat(latLng.lat),
                long: Number.parseFloat(latLng.lng),
            },
        });
    };

    const handleAddMoreImages = () => {
        document.getElementById("addImage").click();
    };

    const handleCancelDialog = () => {
        setImageFile("");
    };

    const handleCloseAlertDialog = () => {
        setDialogState({
            ...dialogState,
            show: false,
            message: "",
        });
        if (dialogState.type === "SUCCESS") {
            goToHomePage();
        }
    };

    const handleLatLngPicked = (lat, lng) => {
        setRecord({
            ...record,
            gpsCoordinates: {
                lat: lat,
                long: lng,
            },
        });
        setAddress("");
    };

    const resetSpeciesFilter = () => {
        let specieList = spicesSuggestionsWithoutFilter();
        setSearchSpecieFiltered(specieList);
        setSearchSpecieSuggestions(specieList);
    };

    const onSubmit = () => {
        if (
            record.specie &&
            record.order &&
            record.gpsCoordinates.lat &&
            record.gpsCoordinates.long &&
            date &&
            time &&
            record.quantity > 0
        ) {
            const temp = `${date}T${moment(time, "hh:mm a").format("HH:mm:ss.000")}Z`;

            if (isEditMode()) {
                delete record.id;
                const newImages = [];
                images.map((imageItem) => {
                    if (!imageItem.id) {
                        newImages.push(imageItem);
                    }
                    return imageItem;
                });
                if (isDelete) {
                    setRecord({
                        ...record,
                        dateTime: temp,
                        images: { delete: deleteImages, create: newImages },
                    });
                } else {
                    setRecord({
                        ...record,
                        dateTime: temp,
                        images: { create: newImages },
                    });
                }
            } else {
                setRecord({ ...record, dateTime: temp, images: { create: images } });
            }
            setIsSubmit(true);
        } else if (record.quantity <= 0) {
            setDialogState({
                show: true,
                message: Errors.QUANTITY_FIELDS,
                type: "FAILED",
            });
        } else {
            setDialogState({
                show: true,
                message: Errors.REQUIRED_FIELDS,
                type: "FAILED",
            });
        }
    };
    const onDelete = () => {
        makeDeleteObservationCall();
    };
    const deleteImage = (data, index) => {
        setIsDelete(true);
        images.map((imageItem) => {
            if (imageItem.id === data.id && data.id !== undefined) {
                const newItem = [
                    ...deleteImages,
                    { id: data.id }
                ];
                setImagesToDelete(newItem);
            }
            return imageItem;
        });
        images.splice(index, 1);
        const newImages = images;
        setImages([...newImages]);
    };
    const renderImage = (data, index) => {
        return (
            <div key={index} direction="column" gap="xsmall" align="center">
                <ImageWrapper src={getImageURL(data.url)}/>
                {
                    <div
                        style={{ cursor: "pointer", margin: 12, display: "inline-block" }}
                        onClick={() => {
                            deleteImage(data, index);
                        }}
                    >
                        <CloseCustom color={Theme.global.colors.grey}/>
                    </div>
                }
            </div>
        );
    };
    const _isDragNDrop = () => {
        return imageFile || images.length > 0 ? (
            <ImagesSlider>
                {images.map((item, index) => renderImage(item, index))}
            </ImagesSlider>
        ) : (
            <DragAndDropComponent onBrowse={onBrowseClick} fileType="image"/>
        );
    };

    const _isShowImageDialog = () => {
        return imageFile ? (
            <ImageCaptionDialog
                Key={`${getUserName()}/${getUserId()}`}
                onCancel={handleCancelDialog}
                file={imageFile}
                onImageUploaded={handleOnImageUploaded}
            />
        ) : (
            ""
        );
    };

    const onOrderSelect = () => {
        setSpeciesQuery("");
        setRecord({ ...record, morphs: "", specie: "" });
    };

    const _isModeration = () => {
        return (
            isUserTypeModerator() && (
                <Box direction="column">
                    <RecordForumHeading margin={{ bottom: "0.75rem" }}>
                        Moderation
                    </RecordForumHeading>
                    <CheckBox
                        toggle
                        label={"Flagged"}
                        checked={record.flagged}
                        onChange={(isChecked) => {
                            setRecord({
                                ...record,
                                flagged: isChecked.target.checked,
                            });
                        }}
                    />
                </Box>
            )
        );
    };

    const RM =
        process.env.REACT_APP_TYPE_CODE === "2"
            ? [
                ...RECORDING_METHOD_REPTILES,
                orderQuery === "Frogs" && RECORDING_METHOD_REPTILES_VOCAL,
            ]
            : RECORDING_METHOD_MAMMALS;

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <RecordPageRootContainer
                    elevation="medium"
                    round="0.25rem"
                    margin={{
                        top: size === "small" ? "small" : "medium",
                        bottom: size === "small" ? "small" : "medium",
                    }}
                    pad={size === "small" ? "1.5rem" : "3rem"}
                    size={size}
                >
                    {dialogState.show && (
                        <ShowDialog
                            title={dialogState.message}
                            onClose={handleCloseAlertDialog}
                            type={dialogState.type}
                        />
                    )}
                    {_isShowImageDialog()}
                    <RecordForumGrid direction="column">
                        <Box direction="column">
                            <RecordForumHeading>Add Records</RecordForumHeading>
                            {context.isMammals ? (
                                <div>
                                    <Text weight={"normal"} style={{ display: "block", fontSize: "18px" }}>Use this form
                                        to
                                        submit
                                        your own mammal observations. These will be
                                        stored in your profile so you can track and view your NY mammal observations.
                                        Have
                                        questions? See our "<Anchor href={NYMSWalkthrough} download label="how to"/>"
                                        document for
                                        detailed information on submitting
                                        records.</Text>
                                </div>
                            ) : (
                                <div>
                                    <Text weight={"normal"} style={{ display: "block", fontSize: "18px" }}>Use this form
                                        to
                                        submit
                                        your own amphibian & reptile observations. These will be
                                        stored in your profile so you can track and view your NY amphibian & reptile
                                        observations.
                                    </Text>
                                </div>
                            )}
                        </Box>
                            <Box className="GridClassification">
                            <RecordForumHeading>Classification</RecordForumHeading>
                            <ItemWrapper>
                                <FieldTitleWithIconComponent
                                    title={"Species"}
                                    isShowIcon={true}
                                    isRequiredField={true}
                                    InfoText={INFO_TEXTS.SPECIE_INFO}
                                />
                                <InputFieldWrapper>
                                    <div className="MediumInputField">
                                        <MultiLabelDropDown
                                            placeholder={"Search species"}
                                            size={"medium"}
                                            value={speciesQuery}
                                            suggestedItems={searchSpecieSuggestions}
                                            dropHeight={"small"}
                                            onClick={spicesSuggestionsWithoutFilter}
                                            onChange={(event) => {
                                                setSpeciesQuery(event.target.value);
                                            }}
                                            onSelect={(event) => {
                                                setSpeciesQuery(event.suggestion.value);
                                            }}
                                        />
                                    </div>
                                </InputFieldWrapper>
                            </ItemWrapper>
                            <ItemWrapper>
                                <FieldTitleWithIconComponent
                                    title={"Order"}
                                    isShowIcon={true}
                                    isRequiredField={true}
                                    InfoText={INFO_TEXTS.ORDER_INFO}
                                />
                                <InputFieldWrapper>
                                    <div className="MediumInputField">
                                        <TextInput
                                            placeholder={"Search order"}
                                            size={"medium"}
                                            value={orderQuery}
                                            onSelect={(event) => {
                                                setOrderQuery(event.suggestion);
                                                onOrderSelect();
                                            }}
                                            suggestions={searchOrderSuggestions}
                                            dropHeight={"small"}
                                            onChange={(event) => {
                                                if (!event.target.value) {
                                                    setSpeciesQuery("");
                                                    resetSpeciesFilter();
                                                    setRecord({ ...record, order: "" })
                                                }
                                                setOrderQuery(event.target.value);
                                            }}
                                        />
                                        <SearchCustom color={Theme.global.colors.active}/>
                                    </div>
                                </InputFieldWrapper>
                            </ItemWrapper>
                            <ItemWrapper>
                                <FieldTitleWithIconComponent
                                    title={"Morph"}
                                    isShowIcon={true}
                                    isRequiredField={false}
                                    InfoText={INFO_TEXTS.MORPH_INFO}
                                />

                                <InputFieldWrapper>
                                    <div className="MediumInputField">
                                        <Select
                                            placeholder={"E.g. Typical (brown)"}
                                            value={record.morphs}
                                            options={morphSuggestions}
                                            onChange={(option) =>
                                                setRecord({ ...record, morphs: option.value })
                                            }
                                            disabled={speciesQuery.length > 0 ? false : true}
                                            dropHeight={"small"}
                                        />
                                    </div>
                                </InputFieldWrapper>
                            </ItemWrapper>
                        </Box>
                        <Box direction="column" className="GridTimeNDate">
                            <RecordForumHeading>Time and date</RecordForumHeading>
                            <ItemWrapper>
                                <FieldTitleWithIconComponent
                                    title={"Observation date"}
                                    isShowIcon={false}
                                    isRequiredField={true}
                                />
                                <DateInput>
                                    <DateComponent
                                        value={date}
                                        listenerOnDateChange={(value) => {
                                            setDate(value);
                                        }}
                                    />
                                </DateInput>
                            </ItemWrapper>

                            <ItemWrapper>
                                <FieldTitleWithIconComponent
                                    title={"Observation time"}
                                    isShowIcon={false}
                                    isRequiredField={true}
                                />
                                <DateInput>
                                    <TimeComponent
                                        value={time}
                                        listenerOnDateChange={(value) => {
                                            setTime(value);
                                        }}
                                    />
                                </DateInput>
                            </ItemWrapper>
                        </Box>

                        <Box direction="column" className="GridLocation">
                            <Box direction="row" align="center">
                                <RecordForumHeading>Location</RecordForumHeading>
                                <InformationIcon InfoText={INFO_TEXTS.LOCATION_INFO}/>
                            </Box>
                            <div>
                                <PlacesAutocomplete
                                    value={address}
                                    onChange={setAddress}
                                    onSelect={handleSelect}
                                    searchOptions={{
                                        componentRestrictions: { country: ["us"] },
                                        bounds: bounds,
                                        strictBounds: true,
                                    }}
                                >
                                    {({
                                          getInputProps,
                                          suggestions,
                                          getSuggestionItemProps,
                                          loading,
                                      }) => (
                                        <div style={{ marginBottom: "2rem" }}>
                                            <SearchPlacesFieldWrapper>
                                                <SearchCustom color={Theme.global.colors.active}/>
                                                <TextInput
                                                    placeholder="Search places"
                                                    onChange={setAddress}
                                                    dropHeight={"small"}
                                                    value={address}
                                                    size="medium"
                                                    {...getInputProps({ placeholder: "Search places" })}
                                                />
                                            </SearchPlacesFieldWrapper>
                                            <div
                                                style={{
                                                    maxWidth: "30rem",
                                                    padding: "0.08rem",
                                                    fontSize: "1rem",
                                                    marginTop: "0.1rem",
                                                    backgroundColor:
                                                        suggestions.length > 0
                                                            ? Theme.global.colors.brand
                                                            : Theme.global.colors.white,
                                                }}
                                            >
                                                {loading ? <div>...loading</div> : null}
                                                {suggestions.map((suggestion) => {
                                                    const style = {
                                                        padding: "0.4rem",
                                                        backgroundColor: suggestion.active
                                                            ? Theme.global.colors.brand
                                                            : Theme.global.colors.white,
                                                    };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                style,
                                                            })}
                                                        >
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <Fragment>
                                <PickLocationDialog
                                    height="25rem"
                                    lat={record.gpsCoordinates.lat}
                                    lng={record.gpsCoordinates.long}
                                    zoom={6}
                                    onClose={handleCloseAlertDialog}
                                    onLatLngPick={handleLatLngPicked}
                                    isViewOnly={false}
                                />
                            </Fragment>

                            <Box direction="row-responsive" gap={"medium"}>
                                <Box direction="column">
                                    <FieldTitleWithIconComponent
                                        title={"Latitude"}
                                        isShowIcon={false}
                                        isRequiredField={true}
                                    />
                                    <InputFieldWrapper>
                                        <div className="SmallInputField">
                                            <TextInput
                                                placeholder={"E.g. 42.23784"}
                                                type="Number"
                                                size={"medium"}
                                                pattern={"/^[+-]?(?:d*.)?d+$/"}
                                                value={record.gpsCoordinates.lat}
                                                onChange={(event) =>
                                                    setRecord({
                                                        ...record,
                                                        gpsCoordinates: {
                                                            ...record.gpsCoordinates,
                                                            lat: Number.parseFloat(event.target.value),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                    </InputFieldWrapper>
                                </Box>

                                <Box direction="column">
                                    <FieldTitleWithIconComponent
                                        title={"Longitude"}
                                        isShowIcon={false}
                                        isRequiredField={true}
                                    />
                                    <InputFieldWrapper>
                                        <div className="SmallInputField">
                                            <TextInput
                                                placeholder={"E.g. 42.23784"}
                                                type="Number"
                                                size={"medium"}
                                                pattern={"/^[+-]?(?:d*.)?d+$/"}
                                                value={record.gpsCoordinates.long}
                                                onChange={(event) =>
                                                    setRecord({
                                                        ...record,
                                                        gpsCoordinates: {
                                                            ...record.gpsCoordinates,
                                                            long: Number.parseFloat(event.target.value),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                    </InputFieldWrapper>
                                </Box>
                            </Box>
                        </Box>
                        <Box direction="column" className="GridObservation">
                            <Grommet theme={myCustomTheme}>
                                <RecordForumHeading>Observation</RecordForumHeading>
                                <ObservationItemsWrapper direction="row" gap="small">
                                    <ObservationItemWrapper>
                                        <FieldTitleWithIconComponent
                                            title={"Type"}
                                            isShowIcon={false}
                                            isRequiredField={false}
                                        />
                                        <SelectWrapper>
                                            <Select
                                                ref={refToInputField}
                                                name="type"
                                                style={{ fontWeight: "100" }}
                                                options={getEnumValues(RM)}
                                                value={getEnumValue(RM, record.recordingMethod)}
                                                dropHeight={"small"}
                                                onChange={(option) =>
                                                    setRecord({
                                                        ...record,
                                                        recordingMethod: getEnumKey(RM, option.value),
                                                    })
                                                }
                                            />
                                        </SelectWrapper>
                                    </ObservationItemWrapper>
                                    <ObservationItemWrapper>
                                        <FieldTitleWithIconComponent
                                            title={"Age"}
                                            isShowIcon={false}
                                            isRequiredField={false}
                                        />
                                        <SelectWrapper>
                                            <Select
                                                placeholder={"Select"}
                                                style={{ fontWeight: "100" }}
                                                options={getEnumValues(AGE)}
                                                value={getEnumValue(AGE, record.age)}
                                                onChange={(option) =>
                                                    setRecord({
                                                        ...record,
                                                        age: getEnumKey(AGE, option.value),
                                                    })
                                                }
                                            />
                                        </SelectWrapper>
                                    </ObservationItemWrapper>
                                    <ObservationItemWrapper>
                                        <FieldTitleWithIconComponent
                                            title={"Sex"}
                                            isShowIcon={false}
                                        />
                                        <SelectWrapper>
                                            <Select
                                                placeholder={"Select"}
                                                style={{ fontWeight: "100" }}
                                                options={getEnumValues(SEX)}
                                                value={getEnumValue(SEX, record.sex)}
                                                onChange={(option) =>
                                                    setRecord({
                                                        ...record,
                                                        sex: getEnumKey(SEX, option.value),
                                                    })
                                                }
                                            />
                                        </SelectWrapper>
                                    </ObservationItemWrapper>
                                    <ObservationItemWrapper>
                                        {process.env.REACT_APP_TYPE_CODE === "2" &&
                                        orderQuery === "Frogs" &&
                                        record.recordingMethod ===
                                        RECORDING_METHOD_REPTILES_VOCAL.type ? (
                                            <div>
                                                <FieldTitleWithIconComponent
                                                    title={"Call index"}
                                                    isShowIcon={false}
                                                    isRequiredField={false}
                                                />
                                                <InputFieldWrapper>
                                                    <div className="MediumInputField">
                                                        <Select
                                                            name="type"
                                                            style={{ fontWeight: "100" }}
                                                            options={getEnumValues(VOCALIZATION)}
                                                            value={getEnumValue(
                                                                VOCALIZATION,
                                                                record.vocalization
                                                            )}
                                                            dropHeight={"small"}
                                                            onChange={(option) =>
                                                                setRecord({
                                                                    ...record,
                                                                    vocalization: getEnumKey(
                                                                        VOCALIZATION,
                                                                        option.value
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </InputFieldWrapper>
                                            </div>
                                        ) : (
                                            <div>
                                                <FieldTitleWithIconComponent
                                                    title={"Quantity"}
                                                    isShowIcon={false}
                                                    isRequiredField={false}
                                                />

                                                <InputFieldWrapper>
                                                    <div className="ExtraSmallInputField">
                                                        <TextInput
                                                            style={{ height: "2.5rem" }}
                                                            placeholder={"1"}
                                                            size={"medium"}
                                                            type="Number"
                                                            value={record.quantity}
                                                            onChange={(event) => {
                                                                const value =
                                                                    event.target.value < 0
                                                                        ? 1
                                                                        : parseInt(event.target.value);
                                                                setRecord({
                                                                    ...record,
                                                                    quantity: value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </InputFieldWrapper>
                                            </div>
                                        )}
                                    </ObservationItemWrapper>
                                </ObservationItemsWrapper>
                            </Grommet>
                        </Box>
                        <Box direction="column" className="GridBottom">
                            <RecordForumHeading margin={{ bottom: "0.75rem" }}>
                                Notes
                            </RecordForumHeading>
                            <NotesTextInputArea
                                resize={false}
                                placeholder="Add details here"
                                value={record.notes}
                                onChange={(event) =>
                                    setRecord({
                                        ...record,
                                        notes: event.target.value,
                                    })
                                }
                            />
                            <Box
                                direction="row"
                                align="center"
                                justify="between"
                                margin={{ bottom: "-0.5rem" }}
                            >
                                <RecordForumHeading>Images</RecordForumHeading>
                                {images.length > 0 && (
                                    <div>
                                        <Button
                                            plain
                                            hoverIndicator={Theme.global.colors.white}
                                            icon={<Add color={Theme.global.colors.active}/>}
                                            onClick={() => {
                                                handleAddMoreImages();
                                            }}
                                        />
                                        <input
                                            id="addImage"
                                            type="file"
                                            multiple={false}
                                            value=""
                                            accept={[
                                                "image/x-png, image/png, image/jpg, image/jpeg, image/gif",
                                            ]}
                                            style={{ display: "none" }}
                                            onChange={(event) => {
                                                onBrowseClick(event.target.files[0]);
                                            }}
                                        />
                                    </div>
                                )}
                            </Box>
                            {_isDragNDrop()}

                            <Box direction={"row"} gap="xlarge">
                                <Box direction="column" align="start">
                                    <Box direction="row" align="center">
                                        <RecordForumHeading margin={{ bottom: "0.75rem" }}>
                                            Privacy
                                        </RecordForumHeading>
                                        <InformationIcon InfoText={INFO_TEXTS.PRIVACY_INFO}/>
                                    </Box>
                                    <CheckBox
                                        toggle
                                        label={"Private"}
                                        checked={record.privacy}
                                        onChange={(isChecked) => {
                                            setRecord({
                                                ...record,
                                                privacy: isChecked.target.checked,
                                            });
                                        }}
                                    />
                                </Box>
                                {_isModeration()}
                            </Box>

                            <SubmitRecordContainer isEditMode={isEditMode()}>
                                <SubmitButton
                                    tabIndex="2"
                                    role="link"
                                    isEditMode={isEditMode()}
                                    label={isEditMode() ? "Delete Record" : "Submit Record"}
                                    icon={
                                        isEditMode() ? (
                                            <Trash color={Theme.global.colors["brand"]}/>
                                        ) : (
                                            <CheckMark color={Theme.global.colors.white}/>
                                        )
                                    }
                                    onClick={() => {
                                        isEditMode() ? onDelete() : onSubmit();
                                    }}
                                />
                                {isEditMode() && (
                                    <SubmitButton
                                        ariaLabel="Single Record Submit"
                                        tabIndex="2"
                                        role="link"
                                        label={"Update Record"}
                                        icon={<CheckMark color={Theme.global.colors.white}/>}
                                        onClick={() => {
                                            onSubmit();
                                        }}
                                    />
                                )}
                            </SubmitRecordContainer>
                        </Box>
                    </RecordForumGrid>
                </RecordPageRootContainer>
            )}
        </ResponsiveContext.Consumer>
    );
};
export default Record;
