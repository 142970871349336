import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

let pathname = "";

function ScrollTop({ history, children, size }) {
    const frameEl = useRef(null);

    useEffect(() => {
        const unlisten = history.listen((e) => {
            if (pathname.split("/")[1] !== e.pathname.split("/")[1]) {
                (document.scrollingElement || document.documentElement).scrollTop = 0;
                frameEl.current.scrollTop = 0;
            }
            pathname = e.pathname;
        });
        return () => {
            unlisten();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div
            style={
                size === "small"
                    ? { overflowY: "auto" }
                    : { maxHeight: "100vh", overflowY: "auto" }
            }
            ref={frameEl}
        >
            {children}
        </div>
    );
}

export default withRouter(ScrollTop);
