import React from "react";
import AppRouter from "./router";
import { client } from "./apollo";
import "normalize.css/normalize.css";
import { ApolloProvider } from "@apollo/react-hooks";
import { AppContextProvider } from "./appContext";
import { AmphibianReptilesConfig, DefaultConfig, MammalsConfig, } from "./configrations";
import { Helmet } from "react-helmet";


function App() {
    const getContextValue = () => {
        let appIcon = document.getElementById("appIcon");
        switch (
            process.env.REACT_APP_TYPE_CODE ? process.env.REACT_APP_TYPE_CODE : "2"
            ) {
            case "0":
                document.title = "NY Atlas";
                return DefaultConfig;
            case "1":
                appIcon.href = "ny-mam.png";
                document.title = "NY Mammals";
                return MammalsConfig;
            case "2":
                appIcon.href = "ny-amprep.png";
                document.title = "NY Amphibians and Reptiles";
                return AmphibianReptilesConfig;
            default:
                document.title = "NY Atlas";
                return DefaultConfig;
        }
    };

    const isMammals = getContextValue().isMammals;

    return (
        <>
            <Helmet>
                <title>{isMammals ? "NY Mammals" : "NY Amphibians and Reptiles"}</title>
                <meta name="description"
                      content={isMammals ? "The New York Mammal Survey is the first statewide assessment of the distribution and status of New York’s terrestrial and freshwater mammals." : "The New York Amphibian & Reptile Survey is a statewide assessment of species distribution and status."}/>
                <meta property="og:image" itemProp="image"
                      content={isMammals ? window.location.hostname + "/ny-mam.png" : window.location.hostname + "/ny-amprep.png"}/>
            </Helmet>
            <ApolloProvider client={client}>
                <AppContextProvider value={getContextValue()}>
                    <AppRouter/>
                </AppContextProvider>
            </ApolloProvider>
        </>
    );
}

export default App;
