const R = 211;
const G = 146;
const B = 60;

const MapLayerColors = [
    `rgb(${R}, ${G}, ${B}, 0.1)`,
    `rgb(${R}, ${G}, ${B}, 0.3)`,
    `rgb(${R}, ${G}, ${B}, 0.55)`,
    `rgb(${R}, ${G}, ${B}, 0.8)`,
    `rgb(${R}, ${G}, ${B}, 1.2)`,
];
const MapColorOpacities = [
    0.1,
    0.3,
    0.55,
    0.8,
    1.2
];
export { MapLayerColors, MapColorOpacities };
