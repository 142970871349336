import React, { useContext } from "react"
import styled from "styled-components";
import { Box, Heading, ResponsiveContext } from "grommet";
import { Theme } from "../theme";
import AppContext from "../appContext";
import GettingStartedGuide from "../components/GettingStartedGuide";
import ContactComponent from "../components/ContactComponent";

const GettingStartedPageRootContainer = styled(Box)`
  background: ${Theme.global.colors.white};
  round: "0.25rem"
`;

const HeadingStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 1280px) {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 786px) {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
`

const PageHeading = styled(Heading)`
  font-size: 2.125rem;
  color: ${Theme.global.colors.textBlack};
  margin: 0;
`;

const GettingStarted = (props) => {
    const context = useContext(AppContext)
    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <GettingStartedPageRootContainer
                    elevation="medium"
                    margin={{
                        top: size === "small" ? "small" : "medium",
                        bottom: size === "small" ? "small" : "medium",
                    }}
                    pad={size === "small" ? "1.5rem" : "3rem"}
                    direction="column"
                    gap="small"
                    size={size}
                >
                    <HeadingStyle>
                    <PageHeading>Getting Started</PageHeading>
                    <ContactComponent/>
                    </HeadingStyle>
                    <GettingStartedGuide surveyType={context.isMammals ? "MAMMALS" : "REPTILES"} history={props.history}/>
                </GettingStartedPageRootContainer>)}
        </ResponsiveContext.Consumer>
    )
}

export default GettingStarted