import React, { useEffect, useLayoutEffect, useState } from "react";
import history from "./history";
import { Config, getUserType, isNotUndefined } from "../helpers";
import S3FileUpload from "react-s3";
import PolynomialRegression from "js-polynomial-regression";
import { DUMMY_PIC_URL, MapLayerColors, USER_SESSION_KEY, USER_TYPE, } from "../constants";
import { Theme } from "../theme";

const getImageURL = (imageKey) => {
    if (imageKey.includes("amazonaws.com")) {
        return imageKey;
    }
    return `https://ny-mammals-s3.s3.amazonaws.com/${imageKey}`;
};

const UploadFileToS3 = async (file) => {
    let payload = "";
    await S3FileUpload.uploadFile(file, Config.S3)
        .then((data) => {
            payload = data;
        })
        .catch((err) => {
            throw err;
        });
    return payload;
};
const downloadCsvFile = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export const svgToPng = (svg, width, height) => {
    return new Promise((resolve, reject) => {
        let canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.fillStyle = Theme.global.colors.white;
        ctx.fillRect(0, 0, width, height);

        let xml = new XMLSerializer().serializeToString(svg);
        let dataUrl = "data:image/svg+xml;utf8," + encodeURIComponent(xml);
        let img = new Image(width, height);

        img.onload = () => {
            ctx.drawImage(img, 10, 10);
            let imageData = canvas.toDataURL("image/png", 1.0);
            saveScreenshot(imageData);
        };
        img.src = dataUrl;
    });
};

const saveScreenshot = (imageData) => {
    var evt = new MouseEvent("click", {
        view: window,
        bubbles: false,
        cancelable: true,
    });

    var a = document.createElement("a");
    a.setAttribute("download", "Graph.png");
    a.setAttribute("href", imageData);
    a.setAttribute("target", "_blank");
    a.dispatchEvent(evt);
};
const convertChart = (ref) => {
    let refChild = ref.container.children[0];
    if (refChild) {
        svgToPng(
            refChild,
            refChild.width.baseVal.value + 50,
            refChild.height.baseVal.value + 50
        );
    }
};
const downloadGraph = (ref) => {
    if (ref.current) convertChart(ref.current);
};
const getEnumValues = (items) => {
    let tempArray = [];
    items.map((item) => item && tempArray.push(item.value));
    return tempArray;
};
const diversityGraphDataLinearReg = (diversity) => {
    if (diversity ? diversity : (diversity = [""])) {
        let diversityArray = [];
        diversity.forEach((x) => {
            diversityArray.push({
                x: x.environmentPercentage,
                y: x.specieCount,
                name: x.name,
                id: x.id,
            });
        });
        const model = PolynomialRegression.read(diversityArray, 2);
        const terms = model.getTerms();
        const graphArray = [];
        diversityArray.map((element, index) => {
            if (element.id !== undefined) {
                graphArray.push({
                    environmentPercentage: element.x,
                    specieCount: element.y,
                    reg: model.predictY(terms, element.x),
                    name: element.name,
                    id: element.id,
                });
            }
            return element;
        });

        if (diversity.length >= 0) {
            if (diversity.length === 0) {
                diversity.push("");
            }
            let maxLength = diversity[diversity.length - 1].environmentPercentage + 5;
            const regresstionData = [];
            for (let i = 0; i <= Math.floor(maxLength); i = i + 2) {
                regresstionData.push({
                    environmentPercentage: i,
                    reg: model.predictY(terms, i),
                });
            }
            let newArray = [];
            for (let i = 0; i < regresstionData.length; i++) {
                newArray.push(regresstionData[i]);
            }
            for (let i = 0; i < graphArray.length; i++) {
                newArray.push(graphArray[i]);
            }
            newArray = newArray.sort(
                (a, b) => a.environmentPercentage - b.environmentPercentage
            );
            return newArray;
        }
    }
};
const getEnumValue = (items, key) => {
    let obj = items.find((item) => item && item.type === key);
    return obj ? obj.value : "";
};

const getEnumValueById = (items, key) => {
    if (isNotUndefined(items) && isNotUndefined(key)) {
        let obj = items.find((item) => item.id === key);
        return obj ? obj : "";
    }
    return "";
};

const getEnumKey = (items, value) => {
    let obj = items.find((item) => item && item.value === value);
    return obj ? obj.type : "";
};
const getNameById = (items, value) => {
    value = capitalizeFirstLetter(value);
    let obj = items.find((item) => item.name === value);
    return obj ? obj.id : "";
};

const getEllipsisText = (str, len) => {
    const stringLenght = str.length;
    if (stringLenght > len) {
        return str.substring(0, len) + "..";
    } else {
        return str;
    }
};

const isUserTypeModerator = () => {
    return getUserType() === USER_TYPE[0] || getUserType() === USER_TYPE[1];
};

function isUserIdMatched(id) {
    const user = localStorage.getItem(USER_SESSION_KEY);
    return user ? JSON.parse(user).id === id : false;
}

function capitalizeFirstLetter(string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function capitalizeObjectFirstLetterSpecie(obj) {
    const specieArray = [];
    obj.map((item, index) => {
        specieArray.push({
            id: item.id,
            commonName: capitalizeFirstLetter(item.commonName),
            bioName: capitalizeFirstLetter(item.binomialName),
            order: item.order,
            genus: item.genus,
        });
        return item;
    });

    return specieArray;
}

function _objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

function capitalizeObjectFirstLetter(obj) {
    const orderArray = [];
    obj.map((item, index) => {
        orderArray.push({
            id: item.id,
            commonName: capitalizeFirstLetter(item.commonName),
            bioName: capitalizeFirstLetter(item.binomialName),
        });
        return item;
    });
    return orderArray;
}

const getPramsFromUrl = () => {
    const query = new URLSearchParams(window.location.search);
    const county = query.get("county");
    const start = query.get("start");
    const end = query.get("end");
    const watershed = query.get("watershed");
    const quad = query.get("quad");
    const decregion = query.get("decregion");
    const specie = query.get("species");
    const order = query.get("order");

    const prams = {
        county: county ? county : "",
        start: start ? start : "",
        end: end ? end : "",
        watershed: watershed ? watershed : "",
        quad: quad ? quad : "",
        decregion: decregion ? decregion : "",
        specie: specie ? specie : "",
        order: order ? order : "",
    };

    return prams;
};

const removeDuplicatesFromList = (list) => {
    return Array.from(new Set(list.map((a) => a.id))).map((id) => {
        return list.find((a) => a.id === id);
    });
};

const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `${parseInt(result[1], 16)},
        ${parseInt(result[2], 16)},
        ${parseInt(result[3], 16)}`
        : null;
};

const showItemLoader = (listLength, index, isLoading) =>
    listLength >= 15 && listLength - 1 === index && isLoading ? true : false;

const sortSpecieListAlphabetically = (list) =>
    list
        ? list.sort(function (a, b) {
            var textA = a.commonName.toUpperCase();
            var textB = b.commonName.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        : [];

const getFirstImageFromList = (images) => {
    if (images.length > 0) {
        let i = 0;
        for (i = 0; i < images.length; i++) {
            if (images[i].url.includes("01")) return images[i].url;
        }
        return images[0].url;
    }
    return DUMMY_PIC_URL;
};

const sortImageByCount = (images) => {
    if (images?.length > 0) {
        let i = 0;
        let imagesList = [];
        let temp = [];
        for (i = 0; i < images.length; i++) {
            images.forEach((item) =>
                item.url.includes(`0${i + 1}`)[0]
                    ? imagesList.push(item)
                    : temp.push(item)
            );
        }
        const distinct = [...new Set(temp.map((item) => item.url))];
        return imagesList.length === 0 ? images : imagesList.concat(distinct);
    }
    return [];
};

function useWindowSize() {
    const [size, setSize] = useState([
        0,
        0
    ]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([
                window.innerWidth,
                window.innerHeight
            ]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

const calculateColorRanges = (minZoneCount, maxZoneCount) => {
    const nbClass = 4;

    let a = Array();

    const tmpMin = minZoneCount;

    const tmpMax = maxZoneCount;

    const logMax = Math.log(tmpMax) / Math.LN10;

    const logMin = Math.log(tmpMin) / Math.LN10;

    const interval = (logMax - logMin) / nbClass;

    for (let i = 0; i < nbClass; i++) {
        if (i == 0) {
            a[i] = logMin;
        } else {
            a[i] = a[i - 1] + interval;
        }
    }

    a = a.map(function (x) {
        return Math.floor(Math.pow(10, x));
    });

    if (a.length > 0) {
        a[0] = minZoneCount;
    }

    a.push(tmpMax);

    a = a.filter((v, i) => a.indexOf(v) === i);

    let nColors = MapLayerColors.length;

    let _intervals = [];

    let nIntervals = a.length;
    for (
        let start_color = 0,
            start_interval = 0,
            end_color = nColors - 1,
            end_interval = nIntervals - 1;
        start_interval < nIntervals / 2 && start_color < nColors / 2;
        start_color += 1, start_interval += 1, end_color -= 1, end_interval -= 1
    ) {
        _intervals[start_interval] = {
            val: a[start_interval],
            color: MapLayerColors[start_color],
        };
        _intervals[end_interval] = {
            val: a[end_interval],
            color: MapLayerColors[end_color],
        };
    }

    return _intervals;
};

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [
        value,
        delay
    ]);
    return debouncedValue;
};

const getQuantityValue = (count, id) => {
    if (count < 4 && id === count) return count;
    else if (id !== 0 && count < 4 && id !== count) {
        id++;
    }
    if (id === 0) {
        return 0;
    } else if (id === 1) {
        return (count * 0.1).toFixed(0);
    } else if (id === 2) {
        return (count * 0.3).toFixed(0);
    } else if (id === 3) {
        return (count * 0.6).toFixed(0);
    } else if (id === 4) {
        return count;
    }
};

export {
    hexToRgb,
    history,
    getEnumKey,
    getNameById,
    getImageURL,
    getEnumValue,
    getEnumValues,
    getEllipsisText,
    getEnumValueById,
    UploadFileToS3,
    getQuantityValue,
    isUserTypeModerator,
    downloadCsvFile,
    isUserIdMatched,
    showItemLoader,
    getFirstImageFromList,
    capitalizeFirstLetter,
    capitalizeObjectFirstLetter,
    convertChart,
    downloadGraph,
    removeDuplicatesFromList,
    diversityGraphDataLinearReg,
    capitalizeObjectFirstLetterSpecie,
    getPramsFromUrl,
    _objectWithoutProperties,
    sortSpecieListAlphabetically,
    sortImageByCount,
    useWindowSize,
    calculateColorRanges,
    useDebounce,
};
