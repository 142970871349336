import { Download, Edit, Theme } from "../theme";
import { downloadCsvFile, getImageURL, useWindowSize } from "../utils";
import styled from "styled-components";
import Loader from "../components/Loader";
import EmptyList from "../components/EmptyList";
import { useLazyQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { clearClientCache, Queries } from "../apollo";
import { DUMMY_PIC_URL } from "../constants/Constants";
import ObservationCard from "../components/ObservationCard";
import { getObservationOffset } from "../apollo/mutations/Variables";
import { Box, Button, Grid, Image, InfiniteScroll, ResponsiveContext, Text, } from "grommet";
import GrayCircle from "../assets/images/gray_circle.png"

const ProfileContainer = styled(Box)`
  background-color: ${Theme.global.colors["white"]};
`;
const UserInformationContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  @media (max-width: 786px) {
    flex-direction: column;
  }
`;
const UserInfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;
const UserProfileInformationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: ${(props) => (props.size === "small" ? "column" : "row")};
  @media (max-width: 840px) {
    align-items: center;
    flex-direction: column;
  }
`;
const UserImage = styled(Image)`
  border-radius: 50%;
  height: 50px;
  width: 170px;
  height: ${(props) => (props.size === "small" ? "50px" : "")};
  width: ${(props) => (props.size === "small" ? "170px" : "")};
`;
const Line = styled.div`
  background: ${Theme.global.colors["inactive"]};
  margin-top: 3rem;
  height: 0.09rem;
  opacity: 0.3;
`;
const ProfileButton = styled(Button)`
  color: ${Theme.global.colors["brand"]};
  background-color: ${Theme.global.colors["white"]};
  font-size: 1rem;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ButtonWrapper = styled(Button)`
  width: 15rem;
  @media only screen and (max-width: 786px) {
    margin-top: 1rem;
    width: 100%;
  }
`;
const ProfilePage = (props) => {
    const getInitialState = () => {
        return {
            myObservations: [],
            offset: 0,
        };
    };
    const [isUserHaveObservations, setIsUserHaveObservations] = useState(false);
    const [observationsData, setObservationsData] = useState(getInitialState());
    const [
        userProfileData,
        {
            data: UserData,
            loading: Loading,
            error: ErrorResponse,
            refetch: profileQueryRefetch,
        },
    ] = useLazyQuery(Queries.GET_USER_PROFILE_QUERY, {
        fetchPolicy: "cache-and-network",
    });

    const [
        userProfileObservationsData,
        { data: observations, loading: observationsLoading },
    ] = useLazyQuery(
        Queries.GET_MY_OBSERVATION_QUERY,
        getObservationOffset(observationsData.offset)
    );
    const [
        downLoadObservatios,
        { data: observationsDownload, loading: downloading },
    ] = useLazyQuery(Queries.GET_MY_OBSERVATION_DOWNLOAD);

    const isProfileDataAvailable = () => {
        return UserData ? true : false;
    };
    const handleDownloadCSV = () => {
        clearClientCache();
        downLoadObservatios();
    };

    useEffect(() => {
        if (!UserData) {
            userProfileData();
        }
        // eslint-disable-next-line
    }, [UserData]);

    useEffect(() => {
        if (observationsDownload) {
            downloadCsvFile(observationsDownload.downloadMyObservationsAsCSV);
        }
    }, [observationsDownload]);

    useEffect(() => {
        userProfileObservationsData();
        // eslint-disable-next-line
    }, [observationsData.myObservations.offset]);

    useEffect(() => {
        if (
            observations &&
            observations.myObservations &&
            observations.myObservations.length > 0
        ) {
            setIsUserHaveObservations(true);
            setObservationsData({
                ...observationsData,
                myObservations: [
                    ...observationsData.myObservations,
                    ...observations.myObservations,
                ],
            });
        }
        // eslint-disable-next-line
    }, [observations && observations.myObservations]);

    const editProfileButton = () => {
        let path = `/profile/${UserData.me.id}`;
        props.history.push(path, {
            userInfo: UserData.me,
            enableEditProfile: true,
        });
    };
    const onItemClick = (item) => {
        let path = `/observation/${item.id}`;
        props.history.push(path, {
            observationItem: item,
        });
    };

    const userProfilePicture = (size) => {
        return (
            isProfileDataAvailable() && (
                <UserImage
                    size={size}
                    alignSelf={"center"}
                    fit="cover"
                    src={UserData.me.image ? getImageURL(UserData.me.image) : GrayCircle}
                />
            )
        );
    };
    const userProfileInformation = () => {
        return (
            isProfileDataAvailable() && (
                <React.Fragment>
                    <Text style={{ display: "block" }} size={"large"} weight={"bold"}>
                        {UserData.me.name}
                    </Text>
                    <Text size={"small"} weight={"normal"}>
                        {UserData.me.email}
                    </Text>
                    <UserInfoContainer>
                        <Box>
                            <Text margin={{ top: "2rem" }} size={"medium"} weight={"bold"}>
                                Location
                            </Text>
                            <Text size={"small"} weight={"normal"}>
                                {UserData.me.city}
                            </Text>
                        </Box>
                        <Box margin={{ left: "2rem" }}>
                            <Text margin={{ top: "2rem" }} size={"medium"} weight={"bold"}>
                                Education
                            </Text>
                            <Text size={"small"} weight={"normal"}>
                                {UserData.me.education === "GRAD"
                                    ? "Graduate"
                                    : "Undergraduate"}
                            </Text>
                        </Box>
                    </UserInfoContainer>
                </React.Fragment>
            )
        );
    };
    const handleLoadMoreObsevations = (length) => {
        setObservationsData({ ...observationsData, offset: length });
    };
    const userProfileBioInformation = () => {
        return (
            isProfileDataAvailable() && (
                <React.Fragment>
                    <Text
                        color={Theme.global.colors["inactive"]}
                        size={"small"}
                        margin={{ top: "1rem" }}
                    >
                        {UserData.me.bio}
                    </Text>
                    <Line/>
                </React.Fragment>
            )
        );
    };

    const [width] = useWindowSize();

    const userObservationInformation = (size) => {
        return (
            isUserHaveObservations &&
            observations &&
            observations.myObservationsCount > 0 && (
                <Box>
                    <UserInformationContainer>
                        <Box>
                            <Text
                                margin={{
                                    bottom: size === "small" ? "0rem" : "1rem",
                                    top: size === "small" ? "1rem" : "4rem",
                                    right: size === "small" ? "2rem" : "2rem",
                                }}
                                size={"medium"}
                                weight={"bold"}
                            >
                                Observations :
                                <Text weight={"normal"}>
                                    &nbsp; {observations ? observations.myObservationsCount : ""}
                                </Text>
                            </Text>
                        </Box>

                        <Box
                            margin={{
                                bottom: "1rem",
                                top: size === "small" ? "1rem" : "3.5rem",
                            }}
                            size={"medium"}
                            weight={"bold"}
                            alignSelf={"center"}
                        >
                            {downloading ? (
                                <Text size={"1rem"} color={Theme.global.colors.active}>
                                    Please wait...
                                </Text>
                            ) : (
                                <ButtonWrapper
                                    ariaLabel="Tap on Download"
                                    tabIndex="1"
                                    role="link"
                                    style={{ color: Theme.global.colors.active }}
                                    icon={<Download color={Theme.global.colors.active}/>}
                                    label="Download csv"
                                    onClick={() => {
                                        handleDownloadCSV();
                                    }}
                                />
                            )}
                        </Box>
                    </UserInformationContainer>
                    <Box height="medium" overflow="auto">
                        <Grid
                            columns="15rem"
                            rows={width > 400 ? "xsmall" : "400rem"}
                            gap="small"
                            pad={"xsmall"}
                        >
                            <InfiniteScroll
                                items={observationsData.myObservations}
                                replace={false}
                                show={0}
                                onMore={() =>
                                    handleLoadMoreObsevations(
                                        observationsData.myObservations.length
                                    )
                                }
                                step={15}
                            >
                                {(item) => (
                                    <ObservationCard
                                        image={item.images[0] ? item.images[0].url : DUMMY_PIC_URL}
                                        key={item.id}
                                        dateTime={item.dateTime}
                                        SpecieName={item.specie.commonName}
                                        CountryName={item.county.name}
                                        quantity={item.quantity}
                                        UserName={item.user.name}
                                        onItemClick={() => {
                                            onItemClick(item);
                                        }}
                                        isShowMenu={false}
                                    />
                                )}
                            </InfiniteScroll>
                        </Grid>
                        {observationsLoading && <Loader/>}
                    </Box>
                </Box>
            )
        );
    };
    const editButton = () => {
        return (
            isProfileDataAvailable() && (
                <ProfileButton
                    ariaLabel="Tap on Edit"
                    tabIndex="0"
                    role="link"
                    plain={true}
                    gap={"none"}
                    icon={<Edit color={Theme.global.colors.active}/>}
                    alignSelf={"end"}
                    label="Edit"
                    onClick={() => {
                        editProfileButton();
                    }}
                />
            )
        );
    };

    const networkCallStatus = () => {
        if (ErrorResponse) {
            return <EmptyList onRefetch={profileQueryRefetch} isError={true}/>;
        }
    };
    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <ProfileContainer
                    elevation={size === "small" ? "none" : "medium"}
                    round="0.25rem"
                    margin={{
                        bottom: size === "small" ? "small" : "medium",
                    }}
                    pad={size === "small" ? "0px" : size === "medium" ? "1rem" : "3rem"}
                    size={size}
                >
                    {networkCallStatus()}
                    {Loading ? (
                        <Loader/>
                    ) : (
                        <Grid
                            rows={[
                                "auto",
                                "auto",
                                "auto",
                                "auto",
                                "auto"
                            ]}
                            columns={size === "small" ? ["fill"] : [
                                "fill",
                                "fill"
                            ]}
                            gap="small"
                            areas={
                                size === "small"
                                    ? [
                                        {
                                            name: "header",
                                            start: [
                                                0,
                                                0
                                            ],
                                            end: [
                                                0,
                                                0
                                            ]
                                        },
                                        {
                                            name: "PictureArea",
                                            start: [
                                                0,
                                                1
                                            ],
                                            end: [
                                                0,
                                                1
                                            ]
                                        },
                                        {
                                            name: "UserInformation",
                                            start: [
                                                0,
                                                2
                                            ],
                                            end: [
                                                0,
                                                2
                                            ]
                                        },
                                        {
                                            name: "UserBioInformation",
                                            start: [
                                                0,
                                                3
                                            ],
                                            end: [
                                                0,
                                                3
                                            ],
                                        },
                                        {
                                            name: "ObservationCard",
                                            start: [
                                                0,
                                                4
                                            ],
                                            end: [
                                                0,
                                                4
                                            ]
                                        },
                                    ]
                                    : [
                                        {
                                            name: "header",
                                            start: [
                                                0,
                                                0
                                            ],
                                            end: [
                                                1,
                                                0
                                            ]
                                        },
                                        {
                                            name: "PictureArea",
                                            start: [
                                                0,
                                                1
                                            ],
                                            end: [
                                                0,
                                                1
                                            ]
                                        },
                                        {
                                            name: "UserInformation",
                                            start: [
                                                1,
                                                1
                                            ],
                                            end: [
                                                1,
                                                1
                                            ]
                                        },
                                        {
                                            name: "UserBioInformation",
                                            start: [
                                                0,
                                                2
                                            ],
                                            end: [
                                                1,
                                                2
                                            ],
                                        },
                                        {
                                            name: "ObservationCard",
                                            start: [
                                                0,
                                                3
                                            ],
                                            end: [
                                                1,
                                                3
                                            ]
                                        },
                                    ]
                            }
                        >
                            <Box gridArea="header">{editButton()}</Box>
                            <UserProfileInformationContainer>
                                <Box gridArea="PictureArea" height={"11rem"}>
                                    {userProfilePicture()}
                                </Box>
                                <Box>
                                    <Box
                                        margin={{ left: "1rem", top: "1rem" }}
                                        gridArea="UserInformation"
                                    >
                                        {userProfileInformation(size)}
                                    </Box>
                                </Box>
                            </UserProfileInformationContainer>
                            <Box gridArea="UserBioInformation">
                                {userProfileBioInformation(size)}
                            </Box>
                            <Box gridArea="ObservationCard">
                                {observationsLoading && !observations && <Loader/>}
                                {userObservationInformation(size)}
                            </Box>
                        </Grid>
                    )}
                </ProfileContainer>
            )}
        </ResponsiveContext.Consumer>
    );
};

export default ProfilePage;
