const colorsForMammals = {
    brand: "#D3923C",
    lightActive: "#b1742a",
    active: "#D3923C",
    mapLayer: "#D3923C",
    inactive: "#A0A9BA",
    lightWhite: "#F0F1F4",
    //For Graph
    barShade: "#723926",
    barShade1: "#D3923C",
    //
    control: {
        dark: "#D3923C",
        light: "#D3923C"
    },
    focus: "#D3923C",
    status: {
        normal: "#D3923C",
        low: "#0077C8",
        high: "#E35205"
    }
};

const colorsForAmphibiansReptiles = {
    brand: "#37BB2A",
    lightActive: "#009BDF",
    active: "#37BB2A",
    mapLayer: "#D3923C",
    inactive: "#A0A9BA",
    //For Graph
    barShade: "#009BDF",
    barShade1: "#37BB2A",
    //
    control: {
        dark: "#37BB2A",
        light: "#37BB2A"
    },
    focus: "#37BB2A",
    status: {
        normal: "#37BB2A",
        low: "#009BDF",
        high: "#2A10B7"
    }
};

const colorScheme =
    (process.env.REACT_APP_TYPE_CODE ? process.env.REACT_APP_TYPE_CODE : "2") ===
    "2"
        ? colorsForAmphibiansReptiles
        : colorsForMammals;

const Theme = {
    global: {
        font: {
            family: "Uber Move",
            size: "0.8rem",
            height: "1.3rem"
        },
        colors: {
            white: "#FFFFFF",
            lightWhite: "#F0F1F4",
            green: "#008000",
            lightGreen: "#f0fff0",
            red: " #ff0000",
            lightRed: "#ffe4e1",
            black: "#343434",
            textBlack: "#2D2A27",
            grey: "#666c78",
            lightGrey: "#E1E3EA",
            ...colorScheme
        }
    }
};
export default Theme;
