import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Queries } from "../apollo";
import styled from "styled-components";
import Loader from "../components/Loader";
import { useLazyQuery } from "@apollo/react-hooks";
import PickLocationDialog from "../components/MapForPickLocation";
import { getObservationInput } from "../apollo/mutations/Variables";
import { AGE, DUMMY_PIC_URL, RECORDING_METHOD, SEX } from "../constants";
import { capitalizeFirstLetter, getEnumValue, getImageURL, isUserIdMatched, isUserTypeModerator, } from "../utils";
import { Box, Button, Carousel, Heading, Image, ResponsiveContext, Text, } from "grommet";
import { BackIcon, Edit, LocationLogo, SearchCustom, Theme, UserCustom, } from "../theme";

const BackLink = styled(Box)`
  cursor: pointer;
`;

const EditLink = styled(Box)`
  cursor: pointer;
`;

const DetailWrapper = styled(Box)`
  min-height: 37rem;

  @media only screen and (max-width: 1250px) {
    display: flex;
    flex-direction: column;
  }
`;

const TopDetailGrid = styled.div`
  display: grid;

  @media only screen and (max-width: 1250px) {
    display: block;
    .GridRight {
      margin-top: 3rem;
    }
  }
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  margin-bottom: 1em;

  .GridRight {
    grid-column: 2 / span 3;
  }
`;

const GridLeft = styled(Box)`
  height: 19rem;
  width: 23rem;
  @media only screen and (max-width: 1250px) {
    width: 100%;
    height: 30rem;
  }

  @media only screen and (max-width: 425px) {
    width: 100%;
    height: 15rem;
  }

  button {
    margin-bottom: -2.5rem;
  }
`;

const InfoSection = styled(Box)`
  background: ${Theme.global.colors.lightWhite};
  display: flex;
`;

const LeftInfoBox = styled(Box)`
  width: 100%;
`;

const RightInfoBox = styled(Box)`
  width: 100%;
`;

const MidInfoBox = styled(Box)`
  @media only screen and (max-width: 768px) {
    display: block;
    flex-direction: column;
  }
`;

const ButtonWrapper = styled(Button)`
  svg path {
    fill: ${Theme.global.colors.active};
  }

  color: ${Theme.global.colors.active};
  width: 12rem;

  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
    width: 100%;
  }
`;

const MapContainer = styled(Box)`
  @media screen and (max-width: 1600px) {
    width: 100%;
  }
`;

const Observation = (props) => {
    const isHavePropsData = () => {
        return props.location.state ? true : false;
    };

    const getObservationId = () => {
        return props.match.params.id;
    };

    const [getObservationById, { data: observationQueryResponse }] = useLazyQuery(
        Queries.GET_OBSERVATION_QUERY,
        getObservationInput(getObservationId())
    );

    const [observationItem, setObservationItem] = useState();

    useEffect(() => {
        if (isHavePropsData()) {
            setObservationItem(props.location.state.observationItem);
        } else {
            if (observationQueryResponse) {
                setObservationItem(observationQueryResponse.observation);
            } else {
                getObservationById();
            }
        }
        // eslint-disable-next-line
    }, [observationQueryResponse]);

    const isAllowedToEdit = () => {
        return isUserTypeModerator()
            ? true
            : observationItem && isUserIdMatched(observationItem.user.id)
                ? true
                : false;
    };

    const getFormatedDateTime = (dateTime) => {
        return moment(dateTime).utc().format("MMMM D, YYYY hh:mm a");
    };

    const isText = (text) => {
        return text ? text : "Unknown";
    };

    const handleViewGuide = () => {
        let path = `/guide/${observationItem.specie.id}`;
        props.history.push(path, {
            specieItem: observationItem.specie,
        });
    };

    const View = (size) => {
        return (
            <Fragment>
                <Box
                    direction="row"
                    pad={{
                        top: "1.5rem",
                        left: "0rem",
                        bottom: "1.5rem",
                        right: "0rem",
                    }}
                    justify={"between"}
                >
                    <BackLink
                        width={"5rem"}
                        direction="row"
                        onClick={() => {
                            props.history.goBack();
                        }}
                        ariaLabel="Back"
                        tabIndex="0"
                        role="link"
                        gap={"xsmall"}
                    >
                        <BackIcon color={Theme.global.colors.active}/>
                        <Text color={Theme.global.colors.active}>Back</Text>
                    </BackLink>

                    {isAllowedToEdit() && (
                        <EditLink
                            width={"5rem"}
                            direction="row"
                            ariaLabel="Back"
                            tabIndex="0"
                            role="link"
                            gap={"xsmall"}
                            onClick={() => {
                                let path = `/record`;
                                props.history.push(path, {
                                    observationItem: observationItem,
                                });
                            }}
                        >
                            <Edit color={Theme.global.colors.active}/>
                            <Text color={Theme.global.colors.active}>Edit</Text>
                        </EditLink>
                    )}
                </Box>
                <DetailWrapper
                    elevation={size === "small" ? "none" : "medium"}
                    round="0.25rem"
                    margin={{
                        bottom: size === "small" ? "small" : "medium",
                    }}
                    pad={size === "small" ? "0px" : size === "medium" ? "1rem" : "3rem"}
                    size={size}
                >
                    <TopDetailGrid size={size} direction="row">
                        <GridLeft pad={"0"}>
                            {observationItem.images.length > 0 ? (
                                <Carousel fill={true} controls={"selectors"}>
                                    {observationItem.images.map((item) => {
                                        return (
                                            <Image
                                                key={item.id}
                                                fit="cover"
                                                src={getImageURL(item.url)}
                                            />
                                        );
                                    })}
                                </Carousel>
                            ) : observationItem.specie.images.length > 0 ? (
                                <Carousel fill>
                                    {observationItem.specie.images.map((item) => {
                                        return (
                                            <Image
                                                key={item.id}
                                                fit="cover"
                                                src={getImageURL(item.url)}
                                            />
                                        );
                                    })}
                                </Carousel>
                            ) : observationItem.specie.images.length > 0 ? (
                                <Carousel fill>
                                    {observationItem.specie.images.map((item) => {
                                        return (
                                            <Image
                                                key={item.id}
                                                fit="cover"
                                                src={getImageURL(item.url)}
                                            />
                                        );
                                    })}
                                </Carousel>
                            ) : (
                                <Image fit="cover" src={DUMMY_PIC_URL}/>
                            )}
                        </GridLeft>

                        <div className="GridRight">
                            <Heading
                                color={Theme.global.colors.textBlack}
                                margin={{
                                    top: "small",
                                    bottom: "small",
                                    left: "0",
                                    right: "0",
                                }}
                                level={2}
                            >
                                {capitalizeFirstLetter(observationItem.specie.commonName)}
                            </Heading>
                            <Text
                                style={{ fontStyle: "italic" }}
                                margin={{
                                    top: "0",
                                    bottom: "medium",
                                    left: "0",
                                    right: "0",
                                }}
                                color={Theme.global.colors.textBlack}
                            >
                                {capitalizeFirstLetter(observationItem.specie.binomialName)}
                            </Text>

                            <InfoSection
                                pad={"small"}
                                margin={{
                                    top: "medium",
                                    bottom: "medium",
                                }}
                                direction={"column"}
                            >
                                <Box
                                    direction={size === "small" ? "column" : "row"}
                                    justify={size === "small" ? "center" : "between"}
                                >
                                    <LeftInfoBox>
                                        <Box
                                            margin={{
                                                top: size === "small" ? "small" : "",
                                            }}
                                            pad={"none"}
                                            direction={"row"}
                                        >
                                            <Heading
                                                level={4}
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0",
                                                    right: "0",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                Stage of life:
                                            </Heading>

                                            <Text
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0.2rem",
                                                    right: "0",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                {isText(getEnumValue(AGE, observationItem.age))}
                                            </Text>
                                        </Box>
                                        <Box
                                            margin={{
                                                top: size === "small" ? "small" : "",
                                            }}
                                            pad={"none"}
                                            direction="row"
                                        >
                                            <Heading
                                                level={4}
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0",
                                                    right: "0",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                Sex:
                                            </Heading>

                                            <Text
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0.2rem",
                                                    right: "0",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                {isText(getEnumValue(SEX, observationItem.sex))}
                                            </Text>
                                        </Box>
                                        <Box
                                            margin={{
                                                top: size === "small" ? "small" : "",
                                            }}
                                            pad={"none"}
                                            direction="row"
                                        >
                                            <Heading
                                                level={4}
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0",
                                                    right: "0",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                Quantity:
                                            </Heading>

                                            <Text
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0.2rem",
                                                    right: "0",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                {isText(observationItem.quantity)}
                                            </Text>
                                        </Box>
                                    </LeftInfoBox>
                                    <RightInfoBox>
                                        <Box
                                            margin={{
                                                top: size === "small" ? "small" : "",
                                                bottom: size === "small" ? "small" : "",
                                            }}
                                            pad={"none"}
                                            direction="row"
                                        >
                                            <Heading
                                                level={4}
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0",
                                                    right: "0",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                Order:
                                            </Heading>

                                            <Text
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0.2rem",
                                                    right: "medium",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                {isText(
                                                    capitalizeFirstLetter(
                                                        observationItem.order.commonName
                                                    )
                                                )}
                                            </Text>
                                        </Box>
                                        <Box
                                            margin={{
                                                top: size === "small" ? "small" : "",
                                                bottom: size === "small" ? "small" : "",
                                            }}
                                            pad={"none"}
                                            direction="row"
                                        >
                                            <Heading
                                                level={4}
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0",
                                                    right: "0",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                Morph:
                                            </Heading>

                                            <Text
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0.2rem",
                                                    right: "medium",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                {capitalizeFirstLetter(isText(observationItem.morphs))}
                                            </Text>
                                        </Box>

                                        <Box
                                            margin={{
                                                top: size === "small" ? "small" : "",
                                                bottom: size === "small" ? "small" : "",
                                            }}
                                            pad={"none"}
                                            direction="row"
                                        >
                                            <Heading
                                                level={4}
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0",
                                                    right: "0",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                Recording method:
                                            </Heading>

                                            <Text
                                                margin={{
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0.2rem",
                                                    right: "medium",
                                                }}
                                                color={Theme.global.colors.textBlack}
                                            >
                                                {isText(
                                                    getEnumValue(
                                                        RECORDING_METHOD,
                                                        observationItem.recordingMethod
                                                    )
                                                )}
                                            </Text>
                                        </Box>
                                    </RightInfoBox>
                                </Box>
                                {observationItem.source && (
                                    <Box
                                        margin={{
                                            top: size === "small" ? "small" : "",
                                            bottom: size === "small" ? "small" : "",
                                        }}
                                        pad={"none"}
                                        direction="row"
                                    >
                                        <Heading
                                            level={4}
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0",
                                                right: "0",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            Source:
                                        </Heading>

                                        <Text
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0.2rem",
                                                right: "medium",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            {isText(observationItem.source)}
                                        </Text>
                                    </Box>
                                )}
                            </InfoSection>

                            <MidInfoBox direction={"row"} gap={"large"}>
                                <Box direction={"column"}>
                                    <Text
                                        margin={{
                                            top: "0",
                                            bottom: "small",
                                            left: "0",
                                            right: "0",
                                        }}
                                        color={Theme.global.colors.textBlack}
                                    >
                                        {getFormatedDateTime(observationItem.dateTime)}
                                    </Text>
                                    <Box direction="row" gap="small">
                                        <UserCustom color={Theme.global.colors.grey}/>
                                        <Text
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0",
                                                right: "0",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            {observationItem.user.name}
                                        </Text>
                                    </Box>
                                </Box>
                                <ButtonWrapper
                                    alignSelf="center"
                                    icon={<SearchCustom color={Theme.global.colors.active}/>}
                                    label="View Guide"
                                    onClick={() => handleViewGuide(observationItem.specie)}
                                />
                            </MidInfoBox>
                        </div>
                    </TopDetailGrid>

                    <Box direction="column" margin={{
                        bottom: "2rem",
                    }}>
                        <Box direction="row" align={"center"}>
                            <LocationLogo
                                width={"16px"}
                                height={"16px"}
                                color={Theme.global.colors.grey}
                            />

                            <Box margin={{ left: "0.5rem" }}>
                                <Text size="medium">{observationItem.county.name}</Text>
                                <Text color={Theme.global.colors.textBlack} size={"0.8rem"}>
                                    {observationItem.watershed.name}
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                    <MapContainer>
                        <PickLocationDialog
                            height="30rem"
                            lat={observationItem.gpsCoordinates[0]}
                            lng={observationItem.gpsCoordinates[1]}
                            zoom={16}
                            isViewOnly={true}
                            isEndangered={observationItem.specie.endangered}
                        />
                    </MapContainer>
                </DetailWrapper>
            </Fragment>
        );
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => <div> {observationItem ? View(size) : <Loader/>}</div>}
        </ResponsiveContext.Consumer>
    );
};

export default Observation;
