import React, { useEffect, useState } from "react";
import { Box, Heading, Text } from "grommet";
import PieChartGraph from "../../components/PieChartGraph";
import FieldTitleWithIconComponent from "../../components/FieldTitleWithIconComponent";
import styled from "styled-components";
import { Theme } from "../../theme";
import { INFO_TEXTS } from "../../constants";

const Container = styled(Box)`
  @media screen and (max-width: 1200px) {
    height: 10rem;
    width: 16rem;
    display: inline-block;
    margin-bottom: 5rem;
  }
  &::-webkit-scrollbar {
      display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
const GraphAndDetailContainer = (props) => {
    const [name, setName] = useState("");
    const [climate, setClimate] = useState({});
    const [landscape, setLandscape] = useState({});

    useEffect(() => {
        if (props.name) setName(props.name);

        if (props.landscape) setLandscape(props.landscape);

        if (props.climate) setClimate(props.climate);
    }, [
        props.climate,
        props.landscape,
        props.id,
        props.name
    ]);

    return (
        <Box
            style={{
                borderRadius: "0.5rem",
                overflow: "auto",
            }}
            margin={"small"}
            elevation="medium"
            flex="grow"
        >
            <Container
                width="20rem"
                height="12rem"
                gap={"small"}
                pad="small"
                background={Theme.global.colors.white}
                direction="column"
                style={{
                    overflow: "auto",
                }}
            >
                <Box direction="column" margin={{ bottom: "0.3rem" }}>
                    <Heading level={5} margin={{ bottom: "0.2rem", top: "0" }}>
                        {name}
                    </Heading>
                </Box>

                <Box
                    direction="row"
                    justify={"around"}
                    align="center"
                    alignContent="center"
                    flex="grow"
                >
                    <PieChartGraph size={"small"} graphData={landscape}/>
                    <Box direction="column">
                        <FieldTitleWithIconComponent
                            title="Landscape"
                            headingLevel={6}
                            isShowIcon
                            isRequiredField={false}
                            InfoText={INFO_TEXTS.LANDSCAPE_OBSERVATION_INFO}
                        />
                        <Text color={Theme.global.colors.grey} size={"0.7rem"}>
                            {parseFloat(landscape.protected).toFixed(2)}% Protected
                        </Text>
                        <Text
                            color={Theme.global.colors.textBlack}
                            size={"0.7rem"}
                            margin={{ top: "0.5rem" }}
                            style={{ lineHeight: "1rem" }}
                        >
                            {parseFloat(landscape.forest).toFixed(2)}% Forest
                            <br/>
                            {parseFloat(landscape.agriculture).toFixed(2)}% Agriculture
                            <br/>
                            {parseFloat(landscape.urban).toFixed(2)}% Developed
                            <br/>
                            {parseFloat(landscape.wetland).toFixed(2)}% Wetland
                            <br/>
                            {parseFloat(landscape.water).toFixed(2)}% Open water
                        </Text>
                    </Box>
                </Box>

                <Box flex="grow" direction="column">
                    <FieldTitleWithIconComponent
                        title="Climate"
                        headingLevel={6}
                        isShowIcon
                        isRequiredField={false}
                        InfoText={INFO_TEXTS.CLIMATE_OBSERVATION_INFO}
                    />
                    <Heading
                        level={4}
                        margin={{ top: "0.3rem", bottom: "0" }}
                        size={"0.7rem"}
                    >
                        {parseFloat(climate.temperature).toFixed(2)}
                    </Heading>
                    <Text color={Theme.global.colors.textBlack} size={"0.7rem"}>
                        Annual mean temperature (C)
                    </Text>
                    <Heading
                        level={4}
                        margin={{ top: "0.3rem", bottom: "0" }}
                        size={"0.7rem"}
                    >
                        {parseFloat(climate.precipitation).toFixed(2)}
                    </Heading>
                    <Text color={Theme.global.colors.textBlack} size={"0.7rem"}>
                        Annual mean precipitation (cm)
                    </Text>
                    <br/>
                </Box>
            </Container>
        </Box>
    );
};
export default GraphAndDetailContainer;
