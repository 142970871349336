import React from "react";
import { Anchor, Box, Text } from "grommet";
import FAQ from "../FAQComponent";
import List from "../List";
import ESFLogo from "../../assets/images/esf_logo.png";
import HWSLogo from "../../assets/images/HWS_logo.png";
import { isUserLogIn } from "../../helpers/SessionManagement";
import { APP_LINKS, CONTACT_INFO } from "../../constants/Constants";
import NYMSWalkthrough from "../../assets/documents/NYMS_walkthrough_06142021.pdf"


const RegisterText = (props) => {
    return (
        <>
            <Text weight="bold" style={{ fontStyle: "italic" }}>
                The data
            </Text>
            {isUserLogIn() ?
                <Text
                    weight={"normal"}
                    style={{ display: "block", fontSize: "18px" }}
                >

                    Thanks for registering to join our team.
                </Text> :
                <Text
                    weight={"normal"}
                    style={{ display: "block", fontSize: "18px" }}
                >
                    {props.mammals ? "NYMS" : "NYARS"} data can be explored by anyone. Go to the 
                    {" "}<Anchor onClick={() => props.history.push("/explore")} label="Explore"/> {" "}
                    page to view all records in the database.
                </Text>
            }</>
    )
}

const AmphibiansGuide = (props) => {
    return (
        <>
            <div style={{ marginBottom: "1rem" }}>
                <RegisterText history={props.history} mammals={false}/>
            </div>
            <FAQ
                style={{ margin: 0 }}
                questionStyle={{ fontStyle: "italic" }}
                question="Which amphibians and reptiles?"
                answer="We welcome observations of any and all amphibians and reptiles in New York State, or “herps” as they are commonly referred to (short for herpetofauna, the term for amphibians and reptiles as a group). These include:"
            />
            <List
                itemStyle={{ fontSize: "18px" }}
                items={[
                    "Amphibians: frogs and salamanders",
                    "Reptiles: turtles, snakes, and lizards",
                ]}
            />
            <FAQ
                question=""
                answer="There are approximately 70 species of amphibians and reptiles in New York State. These occur in many habitats from the Adirondack Mountains to Niagara Falls, the Allegheny Mountains to the Finger Lakes, and Albany to Long Island Sound, including the cities and suburbs in between. You’ll find “herps” mostly in wetlands and areas near wetlands, but “herps” can be found nearly everywhere, from the Central Park in New York City to the top of Mount Marcy in the Adirondacks."
            />

            <FAQ
                style={{ margin: 0 }}
                questionStyle={{ fontStyle: "italic" }}
                question="What information?"
                answer="NYARS seeks key information for each amphibian and reptile observed in New York State. Minimally NYARS requires species, location, and date. A photo voucher for every record is preferred, but not required. Other information such as sex and life stage is helpful."
            />
            <FAQ
                question=""
                answer="In addition to the observations that you contribute, NYARS has compiled records from the New York Department of Environmental Conservation and its Amphibian and Reptile Atlas of New York State, originally conducted from 1990-1999. We will be including records from many other sources including iNaturalist. "
            />
            <>
                <Text
                    weight={"normal"}
                    style={{ display: "block", fontSize: "18px" }}
                >
                    <Text weight="bold" style={{ fontStyle: "italic" }}>
                        Why report?
                    </Text>
                    <br/>
                    Sound conservation requires a reliable information. There seems to
                    be a general decline in amphibians and reptiles. Long term monitoring
                    is the only way to address this with scientific accuracy. This
                    edition of the New York Amphibian and Reptile Survey will enable
                    re-mapping the State’s “herps” to measure changes that have
                    occurred since the original
                    <Anchor href="https://nyherpatlas.org/" label=" the original "/>
                    was completed 1990-1999. Your observations on the current status
                    of our “herp” populations will:
                </Text>
            </>
            <List
                isOrdered
                itemStyle={{ fontSize: "18px" }}
                items={[
                    "Guide environmental planning and conservation for these creatures, many of which are endangered or declining.",
                    "Monitor change in amphibian and reptile populations around the state due to habitat loss and climate change.",
                    "Let us all learn more about the natural history and habitat requirements of these fascinating creatures.",
                    "Encourage us to get outside and explore the natural wonders of New York State.",
                ]}
            />
            <div style={{ marginBottom: "1rem" }}>
                <Text
                    weight={"normal"}
                    style={{ display: "block", fontSize: "18px" }}
                >
                    <Text weight="bold" style={{ fontStyle: "italic" }}>
                        Is there a data collection “app” I can use in the field?
                    </Text>
                    <br/>
                    NYARS supports a mobile app for data collection using your smart
                    device. The
                    <Anchor href={APP_LINKS.MOBILE_APP} label=" NYARS app "/>
                    directly uploads your observations from the field to this online
                    data portal where you can participate in our leaderboards and
                    view, edit, and map your records.
                </Text>
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <Text
                    weight={"normal"}
                    style={{ display: "block", fontSize: "18px" }}
                >
                    <Text weight="bold" style={{ fontStyle: "italic" }}>
                        What if I can’t identify an amphibian or reptile?
                    </Text>
                    <br/>
                    Most New York amphibians and reptiles are easy to identify but
                    some are not. As a first step, consult the NYARS
                    <Anchor href={"/guide"} target={"_blank"} label=" guide. "/>
                    If you know the general kind of amphibian or reptile but not the
                    exact species please select the generic categories provided, such
                    as “frog,” “salamander,”, “turtle”, “snake”, or “lizard.” You can
                    also just enter “unknown” for any observation. All “unknown”
                    records will be flagged so we can help you definitely identify the
                    species (assuming an image is available).
                </Text>
            </div>
            <FAQ
                questionStyle={{ fontStyle: "italic" }}
                question="Who checks these data?"
                answer="The NYARS team of amphibian and reptile experts checks each record submitted and maintains data quality and integrity. Team members may contact you via email to clarify a particular record. For any species reported for the first time in a particular area, NYARS project personnel are alerted to review the record to assure it is a bona fide range expansion for that species."
            />
            <FAQ
                questionStyle={{ fontStyle: "italic" }}
                question="How are the NYARS data organized?"
                answer="All amphibian and reptile observations gathered are recorded at the finest resolution possible. In many cases, this means each record is anchored to a specific location’s latitude and longitude. (Some older records may only be available at the level of town or county.) We then attach to each observation all the taxonomic information associated with the species (Family, Order, Class). Based on the record’s location we also append environmental data (climate and habitat) and then add geographical data (town, county, watershed, and NYSDEC region). This enables you to explore New York’s amphibians and reptiles at many scales and contrast occurrences by environment."
            />
            <FAQ
                questionStyle={{ fontStyle: "italic" }}
                question="Who can see my “point data”?"
                answer="All occurrence records are shared publicly only at the topographic quad scale (rectangular areas between 137-161 km<sup>2</sup>) or greater. All data are recorded in the database at the finest resolution possible (specific latitude and longitude), but only you can access the locations of records that you contributed. The NYARS team can also access records by location for scientific analysis and species status evaluation. NYARS does this to protect locations of sensitive species, for example, rare and endangered turtles."
                answerType={"html"}
            />
            <div style={{ marginBottom: "1rem" }}>
                <Text
                    weight={"normal"}
                    style={{ display: "block", fontSize: "18px" }}
                >
                    <Text weight="bold" style={{ fontStyle: "italic" }}>
                        Bulk data upload
                    </Text>
                    <br/>
                    If you have many records to contribute, then instead of entering
                    records one-by-one via the project interface we can help “bulk
                    upload” your data for you. We are eager to work with you to upload
                    your bulk data (spreadsheets, text files, etc. and images). If you
                    have a large dataset you would like uploaded to your account
                    please contact NYARS
                    <Anchor href={"mailto:" + CONTACT_INFO.MAIL} label=" here. "/>
                </Text>
            </div>
            <FAQ
                questionStyle={{ fontStyle: "italic" }}
                question="Accessing your records"
                answer="Once you have established a project account and entered records, you can view, edit, and download your own records via your personal account. Nobody else can access your records. The NYARS database can be a helpful place to store and manage your records and associated images.."
            />
            <div style={{ marginBottom: "1rem" }}>
                <Text
                    weight={"normal"}
                    style={{ display: "block", fontSize: "18px" }}
                >
                    <Text weight="bold" style={{ fontStyle: "italic" }}>
                        Questions?
                    </Text>
                    <br/>
                    Get in touch. Contact the NYARS team with questions or suggestions
                    about the New York Amphibian and Reptile Survey
                    <Anchor href={"mailto:" + CONTACT_INFO.MAIL} label=" here. "/>
                </Text>
            </div>
            {renderOtherLogos()}
        </>
    );
};
const MammalsGuide = (props) => {
    return (
        <>
            <div style={{ marginBottom: "1rem" }}>
                <RegisterText history={props.history} mammals={true}/>

            </div>
            <FAQ
                style={{ margin: 0 }}
                questionStyle={{ fontStyle: "italic" }}
                question="Which mammals?"
                answer="The New York mammal survey focuses on non-marine species of
            New York State, meaning terrestrial and freshwater species including:"
            />
            <List
                itemStyle={{ fontSize: "18px" }}
                items={[
                    "bats",
                    "bears",
                    "beavers",
                    "deer (elk, deer, moose)",
                    "felids or cats (like bobcats)",
                    "mice and rats",
                    "moles",
                    "opossums",
                    "otters",
                    "porcupines",
                    "rabbits and hares",
                    "raccoons",
                    "shrews",
                    "skunks",
                    "squirrels",
                    "voles",
                    "weasels",
                    "wild canids or dogs (coyotes foxes)",
                ]}
            />
            <FAQ
                style={{ margin: 0 }}
                question=""
                answer="Observations of significant invasive species like wild hogs and feral cats and
            dogs are also compiled. Marine mammals, such as whales and seals, are not currently included."
            />
            <br/>
            <FAQ
                style={{ margin: 0 }}
                questionStyle={{ fontStyle: "italic" }}
                question="What information is collected?"
                answer="Minimally, NYMS requires species, location, and date for each observation. Other 
             information such as sex and age is sought as available. A photo voucher for every record is
             preferred, but not required. Trail camera images, for example, are helpful for documenting 
             mammals."
            />
            <br/>
            <FAQ
                question=""
                answer="In addition to the observations from iNaturalist, NYMS is compiling records from
            many other sources, including 1,300 publications from the 1700's to the present that have been
            reviewed so far as well as unpublished survey data from universities and state agencies.
            New records are being gathered from ongoing field surveys of mammals by NYSDEC staff using a
            combination of trapping, trail cameras, and environmental DNA (eDNA) analysis, particularly 
            on state wildlife management areas around New York State."
            />
            <FAQ
                questionStyle={{ fontStyle: "italic" }}
                question="Is there a data collection “app” I can use in the field?"
                answer="We work with the iNaturalist "
                linkText="app"
                link={navigator.userAgent.indexOf("Android") != -1 || navigator.userAgent.indexOf("Win") != -1 ?
                    "https://play.google.com/store/apps/details?id=org.inaturalist.android&hl=en_US&gl=US" :
                    "https://apps.apple.com/us/app/inaturalist/id421397028"}
                secondPartOfText=" which you can use to record data in the field from your cell
              phone or tablet. Through their site you can join the "
                linkText2="New York Mammal Survey Project"
                link2={"https://www.inaturalist.org/projects/new-york-mammal-survey"}
                thirdPartOfText=" to view your records."
            />
            <div style={{ marginBottom: "1rem" }}>
                <Text
                    weight={"normal"}
                    style={{ display: "block", fontSize: "18px" }}
                >
                    <Text weight="bold" style={{ fontStyle: "italic" }}>
                        What if I can’t identify a mammal?
                    </Text>
                    <br/>
                    Many New York mammals are easy to identify, but some are not.
                    As a first step, consult the NYMS {" "}
                    <Anchor href={"/guide"}  target={"_blank"} label="guide"/>.
                    If you know the general kind of mammal but not the exact species
                    please filter by one of the general categories provided, such as “shrew”, “mouse”,
                    or “bat”. If the animal is still uncertain we recommend asking us on {" "}
                    <Anchor label="Facebook" href="https://www.facebook.com/NYMammals" target="_blank"/>
                    {" "} or <Anchor label="Twitter" href="https://twitter.com/NYMammals" target="_blank"/>
                    , or contacting the site coordinator directly <Anchor label="here" href="mailto:gsilveira@esf.edu"/>.
                </Text>
            </div>
            <FAQ
                questionStyle={{ fontStyle: "italic" }}
                question="Who checks these data?"
                answer="The NYMS team of mammal experts checks each record submitted and maintains data quality and 
                    integrity. Team members may contact you via email to clarify a particular record. "
            />
            <FAQ
                questionStyle={{ fontStyle: "italic" }}
                question="How are the NYMS data organized?"
                answer="All mammal observations gathered are recorded at the finest resolution possible. In many 
                    cases, this means each record is anchored to a specific location determined by latitude and 
                    longitude. Some older records may only be available at the level of town or county. We then 
                    attach all the taxonomic information (Family, Order, Class) associated with the species to 
                    each observation. We also append environmental (climate and habitat) and geographical data 
                    (town, county, watershed, and NYSDEC region) to each observation based on the location. This 
                    enables us to explore New York’s mammals at many scales and contrast mammal occurrences by 
                    environmental conditions."
            />
            <FAQ
                questionStyle={{ fontStyle: "italic" }}
                question="Who can see my “point data”?"
                answer="While all data are recorded in the database at the finest resolution possible (specific 
                    latitude and longitude), you may choose to make a record private so it is not publicly 
                    displayed – The NYMS team and approved researchers will still have access to the record and 
                    the location data."
            />
            <FAQ
                questionStyle={{ fontStyle: "italic" }}
                question="Bulk data upload"
                answer="If you have many records (such as from trail cameras), instead of entering records
              one-by-one via iNaturalist we can help “bulk upload” your data for you.
              The project administrator is eager to work with you to upload your bulk data
              (spreadsheets, text files, etc.). If you have a large dataset you would like
              uploaded to your account please contact the NYMS project manager "
                linkText="here"
                link="mailto:gsilveira@esf.edu"
                secondPartOfText="."
            />
            <FAQ
                questionStyle={{ fontStyle: "italic" }}
                question="Questions?"
                answer="Get in touch. Contact the NYMS project coordinator with questions or suggestions about 
                    the New York Mammal Survey "
                link="mailto:NYMS@esf.edu"
                linkText="here"
                secondPartOfText="."
            />
        </>
    );
};
const renderOtherLogos = () => {
    return (
        <Box direction="row" gap={"small"} alignContent="center" alignSelf="center">
            <img height={"80px"} width={"200px"} src={ESFLogo} alt={"esfLogo"}/>
            <img height={"80px"} width={"200px"} src={HWSLogo} alt={"hwsLogo"}/>
        </Box>
    );
};
const navToGuidePage = (props) => {
    let path;
    path = `/guide`;
    props.history.push(path);
};

const GettingStartedGuide = (props) => {
    if (props.surveyType === "MAMMALS") return MammalsGuide(props);
    else return AmphibiansGuide(props);
};
export default GettingStartedGuide;
