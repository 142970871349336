const isValidEmail = (mail) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(mail);
};

const isValidPassword = (expression) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@+-_$!%*?&])[A-Za-z\d@+-_$!%*?&]{8,}$/;
    return regex.test(expression);
};
const isSamePassword = (expression, expressionTwo) => {
    if (expression === expressionTwo) {
        return true;
    }
    return false;
};

const isEmpty = (expression) => expression.trim().length === 0;

const isNotUndefinedAndEmpty = (expression) => expression !== undefined;

const unCamelCase = (str) => {
    str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, "$1 $2");
    return str;
};

const isNotUndefined = (expression) => {
    if (expression === undefined) {
        return "";
    } else {
        return expression;
    }
};

export {
    isValidEmail,
    isValidPassword,
    isSamePassword,
    isEmpty,
    isNotUndefinedAndEmpty,
    unCamelCase,
    isNotUndefined,
};
