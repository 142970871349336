import React from "react";
import { Box } from "grommet";
import { Theme } from "../../theme";
import { capitalizeFirstLetter } from "../../utils";
import {
    Bar,
    CartesianGrid,
    Cell,
    ComposedChart,
    ErrorBar,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import styled from "styled-components";

const RootContainer = styled(Box)`
  tspan {
    font-family: "Uber Move";
    font-size: 0.68rem;
  }
`;

export const BarGraph = (props) => {
    const getMaxValue = () => {
        if (props.data) {
            const presentValue = props.data[0].data;
            const absentValue = props.data[1].data;
            return (presentValue > absentValue ? presentValue : absentValue) + 30;
        }
        return 100;
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active) {
            const errorValue = parseFloat(payload[0].payload.error).toFixed(2);

            return (
                <div
                    style={{
                        background: Theme.global.colors["black"],
                        color: "white",
                        padding: "0.5rem",
                        fontSize: "0.8rem",
                        borderRadius: "2px",
                        marginTop: "0rem",
                    }}
                >
                    <p>
                        <b>
                            {`${capitalizeFirstLetter(payload[0].payload.name)} ${
                                props.yAxisDataType
                            } ${props.YAxisLabel.replace("Landcover: % ", "")}: `}
                        </b>
                        {payload &&
                        `${parseFloat(payload[0].payload.data).toFixed(2)} ${
                            props.yAxisDataType
                        } ±${errorValue}%`}
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <RootContainer>
            <Box style={{ display: "flex", flexDirection: "row" }}>
                <Box style={{ minHeight: "20rem", minWidth: "90%" }}>
                    <ResponsiveContainer>
                        <ComposedChart
                            barSize={50}
                            data={props.data}
                            margin={{
                                top: props.topMargin,
                                bottom: props.bottomMargin,
                            }}
                            ref={props.forwardRef}
                        >
                            <CartesianGrid
                                height={props.height}
                                vertical={false}
                                horizontal
                                strokeDasharray="3 4"
                            />
                            <XAxis dataKey={"name"}/>

                            <YAxis
                                domain={[
                                    0,
                                    100
                                ]}
                                tickFormatter={(tick) =>
                                    `${parseFloat(tick).toFixed(0)} ${props.yAxisDataType}`
                                }
                                type="number"
                                label={{
                                    offset: 4,
                                    value: props.YAxisLabel,
                                    angle: -90,
                                    position: 'insideLeft'
                                }}
                            />
                            <Tooltip
                                cursor={{
                                    stroke: Theme.global.colors.white,
                                    strokeWidth: "0.5",
                                }}
                                content={<CustomTooltip/>}
                            />
                            <Bar dataKey={"data"} data-tip data-for="sadFace">
                                {props.data?.map((entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={
                                            props.data[index].name === "Present"
                                                ? Theme.global.colors.barShade
                                                : Theme.global.colors.barShade1
                                        }
                                    />
                                ))}
                                <ErrorBar
                                    dataKey={"error"}
                                    width={4}
                                    strokeWidth={1}
                                    stroke={Theme.global.colors.textBlack}
                                    direction="y"
                                />
                            </Bar>
                        </ComposedChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
        </RootContainer>
    );
};
