import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const injectGA = () => {
    if (typeof window == "undefined") {
        return;
    }
    window.dataLayer = window.dataLayer || [];

    function gtag() {
        window.dataLayer.push(arguments);
    }

    gtag("js", new Date());

    gtag("config", process.env.REACT_APP_GTAG);
};
ReactDOM.render(
    <>
        <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
        ></script>
        <script>{injectGA()}</script>
        <App/>
    </>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
