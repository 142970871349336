import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isUserLogIn } from "../../helpers";
import Loader from "../../components/Loader";
import MapComponent from "../../components/MapComponent";
import { capitalizeFirstLetter, downloadGraph } from "../../utils";
import { ExploreErrorDialog } from "../../components/DialogsComponent";
import { Theme, CloseCustom, LocationLogo, Download } from "../../theme";
import {
  Box,
  Text,
  Heading,
  Select,
  CheckBox,
  ResponsiveContext,
  Button,
} from "grommet";
import {
  HABITAT_OPTIONS,
  CLIMATE_OPTIONS,
  INFO_TEXTS,
  MAP_LAYERS,
} from "../../constants/Constants";
import FieldTitleWithIconComponent, {
  InformationIcon,
  ShowExplanatoryOrder,
  ShowCompareOrder,
} from "../../components/FieldTitleWithIconComponent";
import {
  Line,
  XAxis,
  YAxis,
  Scatter,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ReferenceArea,
  ResponsiveContainer, Label,
} from "recharts";
import { Drawer } from "grommet-icons/es6";

const RootContainer = styled(Box)`
  line-height: 12px;
  margin-top: 1rem;
  .leaflet-left {
    z-index: 10000;
  }
  tspan {
    font-family: "Uber Move";
    font-size: 0.68rem;
    padding-top: 0.5rem;
  }
  .graph-wrapper {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
`;
const MapContainer = styled(Box)`
  margin-top: 1rem;
`;

const ExplanatorySelectorContainer = styled(Box)`
  @media screen and (max-width: 1400px) {
    display: block;
    flex-direction: column;
  }
`;

const DiversityTab = (props) => {
  const graphRef = React.createRef();
  const [flags, setFlags] = useState({
    isTrendLines: false,
    isClimateSelect: false,
    isQuadData: true,
  });

  const [presentAbleData, setpresentAbleData] = useState({
    habitatSelect: "Forest",
    climateSelect: "Temperature",
    compareTo: "",
  });

  const [dialogState, setDialogState] = useState({
    show: false,
    message: "",
    type: "",
  });

  const [appType, setAppType] = useState("");

  const [mapLayerData, setMapLayerData] = useState();

  const onTrendLines = () => {
    setFlags({
      ...flags,
      isTrendLines: !flags.isTrendLines,
    });
  };

  const onQuadData = () => {
    setFlags({
      ...flags,
      isQuadData: !flags.isQuadData,
    });
  };

  const handleCloseAlertDialog = () => {
    setDialogState({
      ...dialogState,
      show: false,
      message: "",
    });
  };

  const checkUnitOfPaylod = (arr, val) => {
    if (Array.isArray(arr)) {
      const obj = arr.find((o) => o.unit === val);
      if (obj) {
        return obj;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    zoomOut();
    // eslint-disable-next-line
  }, [
    props.selectedOrder,
    presentAbleData.compareTo,
    presentAbleData.habitatSelect,
    presentAbleData.climateSelect,
  ]);

  useEffect(() => {
    (process.env.REACT_APP_TYPE_CODE
      ? process.env.REACT_APP_TYPE_CODE
      : "2") === "2"
      ? setAppType("Amphibians & Reptiles")
      : setAppType("Mammals");
  }, []);

  useEffect(() => {
    if (props.diversityGraphErrorResponse) {
      setDialogState({
        show: true,
        message: props.diversityGraphErrorResponse.message,
        type: "FAILED",
      });
    }
  }, [props.diversityGraphErrorResponse]);

  useEffect(() => {
    if (flags.isClimateSelect) {
      props.explanatoryVariableClick(presentAbleData.climateSelect);
    } else {
      props.explanatoryVariableClick(presentAbleData.habitatSelect);
    }
    // eslint-disable-next-line
  }, [flags.isClimateSelect]);

  const toolTipViewForLineGraph = (lineGraphData, selectedOrder, LineColor) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
        }}
      >
        <Text
          style={{
            fontSize: "2.125rem",
            marginBottom: "0",
            marginRight: "0.2rem",
            color: LineColor
              ? Theme.global.colors.barShade
              : Theme.global.colors.barShade1,
          }}
        >
          {lineGraphData.value.toFixed(2)}
        </Text>
        <Text style={{ fontSize: "0.6rem", marginTop: "0" }}>
          {selectedOrder
            ? capitalizeFirstLetter(selectedOrder)
            : "All " + appType}
        </Text>
      </div>
    );
  };

  const toolTipViewForScatedGraph = (scatedGraph, selectedOrder) => {
    return (
      <div>
        <Text
          style={{
            fontSize: "0.6rem",
            fontWeight: "600",
            marginBottom: "0",
            marginTop: "0rem",
            marginRight: "0.2rem",
          }}
        >
          {selectedOrder ? selectedOrder : "All " + appType + ":"}
        </Text>
        <Text style={{ fontSize: "0.6rem", marginTop: "0" }}>
          {scatedGraph.value}
        </Text>
      </div>
    );
  };

  const scatedGraphTollTip = (graphData, scatedGraph, scatedCompareToGraph) => {
    return (
      <div
        style={{
          background: Theme.global.colors["black"],
          width: "7.1875rem",
          color: "white",
          padding: "0.5rem",
          borderRadius: "2px",
          marginTop: "0rem",
        }}
      >
        <div>
          <Text
            style={{
              fontSize: "0.6rem",
              marginBottom: "0",
              marginTop: "0rem",
              fontWeight: "600",
              marginRight: "0.2rem",
            }}
          >
            Quad No:
          </Text>

          <Text style={{ fontSize: "0.6rem", marginTop: "0" }}>
            {graphData.payload.id}
          </Text>
        </div>
        <div>
          <Text
            style={{
              fontSize: "0.6rem",
              marginBottom: "0",
              marginTop: "0rem",
              fontWeight: "600",
              marginRight: "0.2rem",
            }}
          >
            Quad Name:
          </Text>

          <Text style={{ fontSize: "0.6rem", marginTop: "0" }}>
            {capitalizeFirstLetter(graphData.payload.name)}
          </Text>
        </div>
        {scatedGraph &&
          toolTipViewForScatedGraph(scatedGraph, props.selectedOrder)}
        {scatedCompareToGraph &&
          toolTipViewForScatedGraph(
            scatedCompareToGraph,
            props.selectedOrderForCompareOrderData
          )}
        <div>
          <Text
            style={{
              fontSize: "0.6rem",
              marginBottom: "0",
              marginTop: "0rem",
              fontWeight: "600",
              marginRight: "0.2rem",
            }}
          >
            {capitalizeFirstLetter(
              props.selectedZone ? props.selectedZone : ""
            )}
          </Text>
          <Text style={{ fontSize: "0.6rem", marginTop: "0" }}>
            {Math.round(graphData.payload.environmentPercentage) + " %"}
          </Text>
        </div>
      </div>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      const lineGraph = checkUnitOfPaylod(payload, 2);
      const compareToGraph = checkUnitOfPaylod(payload, 4);
      const scatedGraph = checkUnitOfPaylod(payload, 1);
      const scatedCompareToGraph = checkUnitOfPaylod(payload, 3);
      if (scatedGraph || scatedCompareToGraph) {
        let graphData = "";
        if (scatedGraph) {
          graphData = scatedGraph;
        } else if (scatedCompareToGraph) {
          graphData = scatedCompareToGraph;
        }
        return scatedGraphTollTip(graphData, scatedGraph, scatedCompareToGraph);
      }

      if (compareToGraph && lineGraph) {
        return (
          <div>
            {toolTipViewForLineGraph(lineGraph, props.selectedOrder)}
            <div style={{ marginTop: "0.5rem" }}>
              {toolTipViewForLineGraph(
                compareToGraph,
                props.selectedCompareOrder,
                "barShade"
              )}
            </div>
          </div>
        );
      } else if (lineGraph) {
        return toolTipViewForLineGraph(lineGraph, props.selectedOrder);
      } else if (compareToGraph) {
        return toolTipViewForLineGraph(
          compareToGraph,
          props.selectedCompareOrder,
          "barShade"
        );
      }
    }
    return null;
  };

  const initialState = {
    data: props.diversityGraphData,
    left: "0",
    right: "auto",
    refAreaLeft: "",
    refAreaRight: "",
    top: "dataMax+1",
    bottom: "dataMin-1",
    animation: true,
  };
  const [myState, setMyState] = useState(initialState);

  function zoom() {
    let { refAreaLeft, refAreaRight, data } = myState;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setMyState({
        ...myState,
        refAreaLeft: "",
        refAreaRight: "",
      });
      return;
    }

    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    refAreaRight =
      myState.left !== "0" ? refAreaRight + 0.1 : refAreaRight + 0.5;
    setMyState({
      ...myState,
      refAreaLeft: "",
      refAreaRight: "",
      data: data.slice(),
      left: refAreaLeft,
      right: refAreaRight,
      top: "auto",
      bottom: "0",
    });
  }

  const zoomOut = () => {
    const { data } = myState;
    setMyState({
      ...setMyState,
      data: data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
      left: "0",
      right: "auto",
      top: "dataMax+1",
      bottom: "dataMin-1",
    });
  };

  useEffect(() => {
    if (props.diversityGraphData) {
      setMyState({
        ...myState,
        data: props.diversityGraphData,
      });

      let zones = [];
      props.diversityGraphData &&
        props.diversityGraphData.forEach((item) => {
          const zone = {
            zoneId: item.id,
            zoneName: item.name,
            observationsCount: item.specieCount,
          };
          zones.push(zone);
        });

      setMapLayerData({
        observationHeatMapItem: zones,
        zoneType: MAP_LAYERS[0],
        zoneTotalCount: props.orderRichness,
      });
    }
    // eslint-disable-next-line
  }, [props.diversityGraphData]);

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <RootContainer
          direction="column"
          pad={{ top: "24px" }}
          gap={size === "medium" ? "medium" : "small"}
        >
          {dialogState.show && (
            <ExploreErrorDialog
              title={dialogState.message}
              onClose={handleCloseAlertDialog}
              type={dialogState.type}
            />
          )}
          <Box direction="column">
            <Box direction="row" align="center">
            <Heading
              level={4}
              margin={{ top: "0rem", bottom: "0rem", right: "1rem" }}
            >
              Diversity relationships
            </Heading>
            <InformationIcon InfoText={INFO_TEXTS.DIVERSITY_RELATIONSHIPS_INFO}/>
            </Box>
            <Box direction="row" align="center" margin={{ top: "0.2rem" }}>
              <Box
                style={{ width: "16px", height: "15px" }}
                align={"center"}
                justify={"center"}
              >
                <Drawer size={"small"} color={"#A0A9BA"} />
              </Box>
              <Text size={"0.9rem"} margin={{ left: "0.3rem" }}>
                {props.selectedOrder
                  ? capitalizeFirstLetter(props.selectedOrder)
                  : "All " + appType}
              </Text>
            </Box>
            <Box direction="row" align="center" margin={{ top: "0.2rem" }}>
              <LocationLogo
                width={"16px"}
                height={"16px"}
                color={Theme.global.colors.grey}
              />
              <Text size={"0.9rem"} margin={{ left: "0.3rem" }}>
                {props.selectedCounty ? props.selectedCounty : "New York State"}
              </Text>
            </Box>
          </Box>
          <Box
            direction="row"
            align="center"
            alignContent="center"
            margin={{ top: "1rem" }}
          >
            <FieldTitleWithIconComponent
              title="Explanatory variable"
              headingLevel={5}
              isShowIcon
              isRequiredField={false}
              InfoText={INFO_TEXTS.EXPLANATORY_INFO}
              content={() => INFO_TEXTS.EXPLANATORY_INFO}
              headingMargin={{
                top: "0.3rem",
                right: "0.3rem",
              }}
            />
          </Box>
          <ExplanatorySelectorContainer direction="row" gap="xlarge">
            <Box
              direction="column"
              width="11.625rem"
              gap="small"
              margin={{ bottom: "0.8rem" }}
            >
              <FieldTitleWithIconComponent
                title="Habitat"
                headingLevel={6}
                isShowIcon
                isRequiredField={false}
                InfoText={INFO_TEXTS.HABITAT_TOOLTIP_INFO}
                content={() => INFO_TEXTS.HABITAT_TOOLTIP_INFO}
                headingMargin={{ top: "0rem", bottom: "0rem", right: "1rem" }}
                style={{ marginBottom: "0" }}
              />
              <div
                style={{
                  border: flags.isClimateSelect
                    ? "1px solid #D3D3D3"
                    : `2px solid ${Theme.global.colors.active}`,
                  borderRadius: "7px",
                }}
              >
                <Select
                  plain
                  id="habitatSelector"
                  options={HABITAT_OPTIONS}
                  placeholder={"Select"}
                  value={capitalizeFirstLetter(presentAbleData.habitatSelect)}
                  dropHeight={"medium"}
                  style={{
                    fontWeight: "100",
                    color: flags.isClimateSelect
                      ? "#D3D3D3"
                      : `${Theme.global.colors.textBlack}`,
                  }}
                  onChange={({ option }) => {
                    setFlags({
                      ...flags,
                      isClimateSelect: false,
                    });
                    setpresentAbleData({
                      ...presentAbleData,
                      habitatSelect: option,
                    });
                    props.explanatoryVariableClick(option);
                  }}
                />
              </div>
            </Box>
            <Box
              direction="column"
              width="11.625rem"
              gap="small"
              margin={{ bottom: "0.8rem" }}
            >
              <FieldTitleWithIconComponent
                title="Climate"
                headingLevel={6}
                isShowIcon
                isRequiredField={false}
                InfoText={INFO_TEXTS.CLIMATE_TOOLTIP_INFO}
                content={() => INFO_TEXTS.CLIMATE_TOOLTIP_INFO}
                headingMargin={{ top: "0rem", bottom: "0rem", right: "1rem" }}
                style={{ marginBottom: "0" }}
              />
              <div
                style={{
                  border: flags.isClimateSelect
                    ? `2px solid ${Theme.global.colors.active}`
                    : "1px solid #D3D3D3",
                  borderRadius: "7px",
                }}
              >
                <Select
                  plain
                  id="climateSelector"
                  options={CLIMATE_OPTIONS}
                  placeholder={"Select"}
                  value={presentAbleData.climateSelect}
                  dropHeight={"medium"}
                  style={{
                    fontWeight: "100",
                    color: flags.isClimateSelect
                      ? `${Theme.global.colors.textBlack}`
                      : "#D3D3D3",
                  }}
                  onChange={({ option }) => {
                    setFlags({
                      ...flags,
                      isClimateSelect: true,
                    });
                    setpresentAbleData({
                      ...presentAbleData,
                      climateSelect: option,
                    });
                    props.explanatoryVariableClimateClick(option);
                  }}
                />
              </div>
            </Box>
            <Box direction="column" gap="small" margin={{ bottom: "0.8rem" }}>
              <Text
                style={{ fontSize: "0.9rem", fontWeight: "bold" }}
                margin={{ top: "0rem", bottom: "0", right: "1rem" }}
              >
                View
              </Text>
              <Box direction="row" gap="small">
                <Box direction="row" align="center">
                  <CheckBox
                    label="Trend lines"
                    checked={flags.isTrendLines}
                    onChange={onTrendLines}
                  />
                  <InformationIcon InfoText={INFO_TEXTS.TREND_LINES_INFO} />
                </Box>
                <Box direction="row" align="center">
                  <CheckBox
                    label="Quad data"
                    checked={flags.isQuadData}
                    onChange={onQuadData}
                  />
                  <InformationIcon InfoText={INFO_TEXTS.QUAD_INFO} />
                </Box>
              </Box>
            </Box>
          </ExplanatorySelectorContainer>
          <Box style={{ height: "23.687rem" }}>
            {props.diversityGraphDataLoading ? (
              <Loader />
            ) : (
              <Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box style={{ minHeight: "23.687rem", minWidth: "95%" }}>
                    <Box
                      style={{ cursor: "pointer" }}
                      align="end"
                      margin={{ bottom: "0.3rem" }}
                    >
                      <Box direction="row" style={{ alignItems: "center" }}>
                        <Box margin={{ left: "0.3rem" }}>
                          {size === "small" && (
                            <ShowExplanatoryOrder
                              habitatSelect={presentAbleData.habitatSelect}
                              climateSelect={presentAbleData.climateSelect}
                              order={props.selectedOrder}
                            />
                          )}
                        </Box>
                        <Box margin={{ left: "0.3rem" }}>
                          {size === "small" && (
                            <ShowCompareOrder
                              compareTo={presentAbleData.compareTo}
                              selectedCompareOrder={props.selectedCompareOrder}
                            />
                          )}
                        </Box>
                        {isUserLogIn() && (
                          <div
                            onClick={() => {
                              downloadGraph(graphRef);
                            }}
                          >
                            <Download color={Theme.global.colors.active} />
                          </div>
                        )}
                      </Box>
                    </Box>
                    {myState.left !== "0" && (
                      <Button
                        style={{ height: "2rem", width: "8rem" }}
                        label="Zoom Out"
                        onClick={zoomOut}
                        size="small"
                        margin={{ left: "3rem", bottom: "1rem" }}
                      />
                    )}
                    <ResponsiveContainer
                      className="graph-wrapper"
                      width="100%"
                      height="90%"
                    >
                      <ComposedChart
                        data={myState.data}
                        margin={{
                          top: 5,
                        }}
                        onMouseDown={(e) =>
                          e?.activeLabel &&
                          setMyState({ ...myState, refAreaLeft: e.activeLabel })
                        }
                        onMouseMove={(e) =>
                          e?.activeLabel &&
                          myState.refAreaLeft &&
                          setMyState({
                            ...myState,
                            refAreaRight: e.activeLabel,
                          })
                        }
                        onMouseUp={zoom}
                        ref={graphRef}
                      >
                        <XAxis
                          allowDataOverflow
                          domain={[myState.left, myState.right]}
                          dataKey="environmentPercentage"
                          tickFormatter={(tick) =>
                            `${tick} ${
                              flags.isClimateSelect
                                ? presentAbleData.climateSelect ===
                                  "Temperature"
                                  ? "°C"
                                  : "cm"
                                : "% "
                            }`
                          }
                          type="number"
                        >
                          <Label position="insideBottom" offset={0}>
                            {(flags.isClimateSelect ? "Climate: " : "Landcover: ") +
                            (flags.isClimateSelect
                                ? presentAbleData.climateSelect === "Temperature"
                                    ? "°C " + presentAbleData.climateSelect
                                    : capitalizeFirstLetter(presentAbleData.climateSelect)
                                : "% " +
                                capitalizeFirstLetter(presentAbleData.habitatSelect))}
                          </Label>
                        </XAxis>
                        <YAxis
                          allowDataOverflow
                          domain={[
                            (dataMin) => parseFloat(dataMin.toFixed(2)) - 1,
                            (dataMax) => parseFloat(dataMax.toFixed(2)) + 1,
                          ]}
                          label={{
                            value: 'Recorded number of species',
                            angle: -90,
                            position: 'center',
                            offset: -4
                          }}
                        />
                        <CartesianGrid
                          height={props.height}
                          vertical={false}
                          horizontal
                        />
                        <Tooltip
                          cursor={{
                            stroke: flags.isQuadData
                              ? Theme.global.colors.barShade1
                              : Theme.global.colors.barShade1,
                            strokeWidth: "1.5",
                            strokeDasharray: "3 3",
                          }}
                          content={<CustomTooltip />}
                        />
                        {flags.isQuadData && (
                          <Scatter
                            unit={1}
                            dataKey="specieCount"
                            fill={Theme.global.colors.barShade1}
                          />
                        )}
                        {flags.isTrendLines && (
                          <Line
                            unit={2}
                            dataKey="reg"
                            stroke={Theme.global.colors.barShade1}
                            strokeWidth={1}
                            dot={false}
                          />
                        )}
                        {presentAbleData.compareTo && flags.isQuadData && (
                          <Scatter
                            unit={3}
                            dataKey="count"
                            fill={Theme.global.colors.barShade}
                          />
                        )}
                        {presentAbleData.compareTo && flags.isTrendLines && (
                          <Line
                            unit={4}
                            dataKey="reg2"
                            stroke={Theme.global.colors.barShade}
                            strokeWidth={1}
                            dot={false}
                          />
                        )}
                        {myState.refAreaLeft && myState.refAreaRight ? (
                          <ReferenceArea
                            x1={myState.refAreaLeft}
                            x2={myState.refAreaRight}
                            strokeOpacity={0.3}
                          />
                        ) : null}
                      </ComposedChart>
                    </ResponsiveContainer>
                  </Box>
                </Box>
                <Box
                  gap={"1rem"}
                  direction={"row"}
                  style={{ justifyContent: "flex-end" }}
                >
                  {size !== "small" && (
                    <ShowExplanatoryOrder
                      habitatSelect={presentAbleData.habitatSelect}
                      climateSelect={presentAbleData.climateSelect}
                      order={props.selectedOrder}
                    />
                  )}
                  {size !== "small" && (
                    <ShowCompareOrder
                      compareTo={presentAbleData.compareTo}
                      selectedCompareOrder={props.selectedCompareOrder}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
          <Box direction="column" width="11.625rem" gap="small">
            <FieldTitleWithIconComponent
              title="Compare to"
              headingLevel={5}
              isShowIcon
              isRequiredField={false}
              InfoText={INFO_TEXTS.COMPARETO_INFO}
              style={{ marginBottom: "0" }}
            />
            <div
              style={{
                border: presentAbleData.compareTo
                  ? `2px solid ${Theme.global.colors.active}`
                  : "",
                borderRadius: presentAbleData.compareTo ? "7px" : "",
              }}
            >
              <Select
                active={true}
                labelKey="commonName"
                valueKey="commonName"
                options={
                  props.options
                    ? props.options?.filter((e) => e.commonName !== "All")
                    : ""
                }
                placeholder={"Select"}
                value={presentAbleData.compareTo}
                dropHeight="small"
                style={{ fontWeight: "100" }}
                icon={
                  presentAbleData.compareTo ? (
                    <div
                      onClick={() => {
                        props.setComparetoSlectorValue("");
                        setpresentAbleData({
                          ...presentAbleData,
                          compareTo: "",
                        });
                      }}
                    >
                      <CloseCustom color={Theme.global.colors.active} />
                    </div>
                  ) : (
                    true
                  )
                }
                onChange={({ option }) => {
                  setpresentAbleData({
                    ...presentAbleData,
                    compareTo: option,
                  });
                  props.setComparetoSlectorValue(option.id);
                }}
              />
            </div>
          </Box>
          <div style={{ height: "2rem" }} />
          <Box direction="column">
            <FieldTitleWithIconComponent
              title="Diversity map"
              headingLevel={4}
              isShowIcon
              isRequiredField={false}
              InfoText={INFO_TEXTS.DIVERSITY_INFO}
              content={() => INFO_TEXTS.DIVERSITY_INFO}
            />
            <Box direction="row" align="center" margin={{ top: "0.2rem" }}>
              <Box
                style={{ width: "16px", height: "15px" }}
                align={"center"}
                justify={"center"}
              >
                <Drawer size={"small"} color={"#A0A9BA"} />
              </Box>
              <Text size={"0.9rem"} margin={{ left: "0.3rem" }}>
                {props.selectedOrder
                  ? capitalizeFirstLetter(props.selectedOrder)
                  : "All " + appType}
              </Text>
            </Box>
            <Box direction="row" align="center" margin={{ top: "0.2rem" }}>
              <LocationLogo
                width={"16px"}
                height={"16px"}
                color={Theme.global.colors.grey}
              />
              <Text size={"0.9rem"} margin={{ left: "0.3rem" }}>
                {props.selectedCounty ? props.selectedCounty : "New York State"}
              </Text>
            </Box>
          </Box>
          <MapContainer>
            <MapComponent
              zone={MAP_LAYERS[0]}
              isMapView={props.isMapView}
              isLayerClickable={false}
              height={size === "small" ? "44rem" : "38rem"}
              size={size}
              zoneId={props.zoneId}
              zoom={7}
              isDiversityMap={true}
              lat={42.8007281}
              lng={-76.1263376}
              isShowColorContainer={true}
              handleZoneChanged={() => {}}
              heatMapByZoneData={mapLayerData}
              hideObservations={true}
            />
          </MapContainer>
        </RootContainer>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default DiversityTab;