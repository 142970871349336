import React from "react";

export default (props) => {
    const listItems = props.items.map((item) => (
        <li key={item} style={props.itemStyle}>
            {item}
        </li>
    ));
    if (props.isOrdered)
        return (
            <ol style={{ marginBottom: "1rem", ...props.style }}>{listItems}</ol>
        );
    else
        return (
            <ul style={{ marginBottom: "1rem", ...props.style }}>{listItems}</ul>
        );
};
