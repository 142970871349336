import React, { useState } from "react";
import { Theme } from "../../theme";
import styled from "styled-components";
import Loader from "../../components/Loader";
import { isNotUndefined } from "../../helpers";
import { ShowDialog } from "../../components/DialogsComponent";
import ObservationCard from "../../components/ObservationCard";
import { DUMMY_PIC_URL, MAP_LAYERS } from "../../constants/Constants";
import { capitalizeFirstLetter, isUserTypeModerator } from "../../utils";
import { Box, Image, ResponsiveContext, Text } from "grommet";
import { Drawer } from "grommet-icons";
import InfiniteLoaderList from "../InfiniteLoaderList";

const ImageContainer = styled(Image)`
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  max-height: 3rem;
  max-width: 3rem;
`;

const ObservationCardContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  position: relative;
`;

const ObservationListContainer = (props) => {
    const [dialogState, setDialogState] = useState({
        show: false,
        message: "",
        type: "",
    });
    const getZonePointNameByType = (item) => {
        if (props.zoneName === MAP_LAYERS[0]) {
            return item.quad.name;
        } else if (props.zoneName === MAP_LAYERS[1]) {
            return item.county.name;
        } else if (props.zoneName === MAP_LAYERS[2]) {
            return item.watershed.name;
        } else if (props.zoneName === MAP_LAYERS[3]) {
            return item.decRegion.name;
        }
        return "";
    };
    const showItemLoader = (listLength, index, isLoading) =>
        listLength >= 15 && listLength - 1 === index && isLoading ? true : false;

    const EmptyView = () => (
        <Box
            width="20rem"
            elevation="medium"
            gap={"small"}
            margin={"small"}
            style={{ borderRadius: "0.5rem" }}
            background={Theme.global.colors.white}
            pad={{ top: "12px", bottom: "8px", left: "6px", right: "6px" }}
        >
            <div style={{ margin: "1rem 0 0 0", textAlign: "center" }}>
                <Drawer/>
            </div>
            <Text
                size={"1rem"}
                color={Theme.global.colors.textBlack}
                style={{ textAlign: "center", marginBottom: "2rem" }}
            >
                No observation found <br></br>
                Adjust filters or date range
            </Text>
        </Box>
    );
    const ObservationCardItem = ({ item, size, style }) => {
        return (
            <ObservationCardContainer style={style} size={size} key={item.id}>
                <ObservationCard
                    key={item.id}
                    id={item.id}
                    image={
                        item.images && item.images.length > 0
                            ? item.images[0].url
                            : DUMMY_PIC_URL
                    }
                    dateTime={item.dateTime}
                    SpecieName={item.specie.commonName}
                    CountryName={getZonePointNameByType(item)}
                    UserName={item.user.name}
                    profileData={item}
                    quantity={item.quantity}
                    onItemClick={props.onItemClick}
                    onFlagClick={props.onFlagClick}
                    onEditClick={props.onEditClick}
                    isShowMenu={isUserTypeModerator()}
                />
            </ObservationCardContainer>
        );
    };

    const InfoView = (size) => (
        <Box
            width="20rem"
            elevation="medium"
            height={props.height}
            gap={"small"}
            margin={"small"}
            style={{ borderRadius: "0.5rem" }}
            background={Theme.global.colors.white}
            pad={{ top: "12px", bottom: "8px", left: "6px", right: "6px" }}
        >
            {props.isSpecieSelected && (
                <Box direction="row" gap="small" pad="small">
                    <Box gridArea="picture" alignSelf="center">
                        <ImageContainer
                            alignSelf="center"
                            fit="cover"
                            src={
                                props.observations[0].images.length > 0
                                    ? props.observations[0].images[0].url
                                    : DUMMY_PIC_URL
                            }
                        />
                    </Box>
                    <Box gridArea="info" direction="column" alignSelf={"center"}>
                        <Text size={"1rem"} weight={"bold"} margin={{ bottom: "0.2rem" }}>
                            {props.isSpecieSelected && isNotUndefined(props.observations)
                                ? props.observations[0].specie.commonName
                                : ""}
                        </Text>
                        {props.isSpecieSelected && (
                            <Text
                                color={Theme.global.colors.textBlack}
                                size={"0.687rem"}
                                style={{ fontStyle: "italic" }}
                            >
                                {isNotUndefined(props.observations)
                                    ? capitalizeFirstLetter(
                                        props.observations[0].specie.binomialName
                                    )
                                    : ""}
                            </Text>
                        )}
                    </Box>
                </Box>
            )}
            <Box
                gridArea="listTitle"
                direction={"row"}
                justify={"between"}
                margin={{ left: "0.5rem", right: "0.5rem" }}
            >
                <Box direction="row" align={"center"}>
                    <Text weight={"bold"} style={{ fontSize: "0.75rem" }}>
                        {`Observations: ${
                            isNotUndefined(props.observationsMeta)
                                ? props.observationsMeta
                                : ""
                        }`}
                    </Text>
                </Box>
                <Box>
                    {props.CSVFileLoading ? (
                        <Text
                            color={Theme.global.colors.black}
                            style={{ fontSize: "0.75rem" }}
                        >
                            Please wait...
                        </Text>
                    ) : (
                        isUserTypeModerator() && (
                            <Text
                                color={Theme.global.colors.active}
                                style={{ fontSize: "0.75rem", cursor: "pointer" }}
                                onClick={() => props.handleDownloadCSV()}
                            >
                                Download CSV
                            </Text>
                        )
                    )}
                </Box>
            </Box>
            <Box gridArea="list" height="medium">
                <InfiniteLoaderList
                    totalCount={props.observationsMeta}
                    items={props.observations}
                    hasNextPage={
                        props.observations.length < (props.observationsMeta || 0)
                    }
                    isNextPageLoading={props.fetchMoreLoading}
                    loadNextPage={() =>
                        props.handleLoadMoreObsevations(props.observations.length)
                    }
                    renderItem={(item, style) => (
                        <ObservationCardItem item={item} style={style} size={size}/>
                    )}
                />
            </Box>
        </Box>
    );
    const handleCloseAlertDialog = () => {
        setDialogState({
            ...dialogState,
            show: false,
            message: "",
        });
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <div>
                    {dialogState.show && (
                        <ShowDialog
                            title={dialogState.message}
                            onClose={handleCloseAlertDialog}
                            type={dialogState.type}
                        />
                    )}

                    {props.observations !== undefined ? (
                        props.observations?.length !== 0 ? (
                            InfoView(size)
                        ) : (
                            <Loader/>
                        )
                    ) : (
                        EmptyView()
                    )}
                </div>
            )}
        </ResponsiveContext.Consumer>
    );
};
export default ObservationListContainer;
