import React from "react";
import { EmailIcon, FacebookIcon, TwitterIcon } from "../../theme";
import { Box } from "grommet";
import { CONTACT_INFO } from "../../constants/Constants";
import styled from "styled-components";
import { Theme } from "./../../theme";

const Wrapper = styled(Box)`
  svg path {
    fill: ${Theme.global.colors.active};
  }
`;

const ContactComponent = (props) => {
    return (
        <Wrapper
            direction="row"
            alignSelf={props.position}
            alignContent="center"
            pad="0.1rem"
            align="center"
        >
            <TwitterIcon
                width="3rem"
                style={{ cursor: "pointer" }}
                onClick={() => window.open(CONTACT_INFO.TWITTER)}
            />

            <FacebookIcon
                fill={"#3b5998"}
                width="3rem"
                style={{ cursor: "pointer" }}
                onClick={() => window.open(CONTACT_INFO.FACEBOOK)}
            />

            <EmailIcon
                width="3rem"
                style={{ cursor: "pointer" }}
                onClick={() => window.open("mailto:" + CONTACT_INFO.MAIL)}
            />
        </Wrapper>
    );
};
export default ContactComponent;
