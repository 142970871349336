import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Calendar from "react-calendar";
import "react-date-range/dist/styles.css";
import { DateUtils } from "react-day-picker";
import { DateRange } from "react-date-range";
import { CalendarIcon, Theme } from "../../theme";
import "react-date-range/dist/theme/default.css";
import { FormClock } from "grommet-icons";
import { MIN_DATE, TIME_INPUT_MASK } from "../../constants/Constants";
import { Box, MaskedInput, Text, TextInput } from "grommet";

const RangePickerContainer = styled.div`
  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    .react-calendar__tile--active:enabled {
      border-radius: 50% !important;
      height: 40px;
      width: 50px;
      color: ${Theme.global.colors.textBlack} !important;
      background-color: ${Theme.global.colors.active} !important;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 18rem;
  }
`;

const InputFieldWrapper = styled.div`
  height: 3rem;
  margin-bottom: 1rem;
  position: relative;
  & > svg {
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
  }

  input {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .time-input-style {
    padding-left: 3rem;
    font-size: 1rem;
    font-weight: 100;
  }
`;

const RangeInputFieldWrapper = styled.div`
  position: relative;
  & > svg {
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
  }

  input {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

export const TimeComponent = (props) => {
    const [observationTimeInput, setObservationTimeInput] = useState(
        props.value
            ? moment(props.value, "HH:mm").format("hh:mm a")
            : moment().format("hh:mm a")
    );

    useEffect(() => {
        props.listenerOnDateChange(observationTimeInput);
        // eslint-disable-next-line
    }, [observationTimeInput]);

    return (
        <InputFieldWrapper>
            <Box width="medium">
                <MaskedInput
                    icon={<FormClock color={Theme.global.colors.active}/>}
                    mask={TIME_INPUT_MASK.mask}
                    value={observationTimeInput}
                    style={{ fontSize: "1rem", fontWeight: 100, paddingLeft: "2.5rem" }}
                    onChange={(event) => setObservationTimeInput(event.target.value)}
                />
            </Box>
        </InputFieldWrapper>
    );
};

export const DateRangeComponent = (props) => {
    const getInitialValue = () => {
        return [
            {
                startDate: new Date(),
                endDate: new Date(moment().add(-1, "days").format("YYYY, MM, DD")),
                key: "selection",
            },
        ];
    };

    const [focusedRange, setFocusedRange] = useState([
        0,
        0
    ]);
    const [dateRange, setDateRange] = useState(getInitialValue());

    function isEndDateFocused(focusNumber) {
        return focusNumber === 1;
    }

    useEffect(() => {
        if (props.start && props.end) {
            setDateRange([
                {
                    startDate: new Date(
                        moment(props.start, "MM-DD-YYYY").format("YYYY, MM, DD")
                    ),
                    endDate: new Date(
                        moment(props.end, "MM-DD-YYYY").format("YYYY, MM, DD")
                    ),
                    key: "selection",
                },
            ]);
        }
        if (props.start === "" && props.end === "") {
            setDateRange(getInitialValue());
        }
    }, [
        props.start,
        props.end
    ]);

    const [isShow, setIsShow] = useState(false);

    const getValue = () => {
        if (props.start && props.end) return `${props.start} - ${props.end}`;
        else return "";
    };

    const setRange = (start, end) => {
        setDateRange([
            {
                startDate: new Date(moment(start, "MM-DD-YYYY").format("YYYY, MM, DD")),
                endDate: new Date(moment(end, "MM-DD-YYYY").format("YYYY, MM, DD")),
                key: "selection",
            },
        ]);
    };

    const handleOkClick = () => {
        if (dateRange) {
            props.handleDateRange(
                moment(dateRange[0].startDate, "ddd MMM DD YYYY").format("MM-DD-YYYY"),
                moment(dateRange[0].endDate, "ddd MMM DD YYYY").format("MM-DD-YYYY")
            );
        }
        setIsShow(false);
    };

    const handleCancelClick = () => {
        setIsShow(false);
    };

    const handleChange = (item) => {
        if (isEndDateFocused(focusedRange[1])) {
            setDateRange([item.selection]);
        } else {
            item.selection.startDate > new Date(props.end)
                ? setRange(props.end, item.selection.startDate)
                : setRange(item.selection.startDate, props.end);
        }
    };

    return (
        <Box>
            {isShow ? (
                <Box
                    elevation={"medium"}
                    style={{
                        position: "relative",
                        zIndex: 1,
                        borderRadius: "0.25rem",
                        background: Theme.global.colors.white,
                    }}
                >
                    <Box align={"center"}>
                        <DateRange
                            maxDate={new Date()}
                            editableDateInputs={true}
                            onChange={(item) => handleChange(item)}
                            minDate={MIN_DATE}
                            ranges={dateRange}
                            fixedHeight={true}
                            focusedRange={focusedRange}
                            onRangeFocusChange={setFocusedRange}
                            moveRangeOnFirstSelection={false}
                            startDatePlaceholder={"MM-dd-yyyy"}
                            endDatePlaceholder={"MM-dd-yyyy"}
                            dateDisplayFormat={"MM-dd-yyyy"}
                            rangeColors={[Theme.global.colors.active]}
                        />
                    </Box>
                    <Box direction="row" justify="between" pad="small">
                        <Text
                            color={Theme.global.colors.textBlack}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCancelClick()}
                        >
                            Cancel
                        </Text>
                        <Text
                            color={Theme.global.colors.active}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOkClick()}
                        >
                            OK
                        </Text>
                    </Box>
                </Box>
            ) : (
                <RangeInputFieldWrapper>
                    <CalendarIcon color={Theme.global.colors.active}/>
                    <TextInput
                        style={{
                            paddingLeft: "3rem",
                            fontSize: "1rem",
                            fontWeight: "100",
                            height: "3rem",
                        }}
                        placeholder={"Select a date range"}
                        value={getValue()}
                        onClick={() => {
                            setIsShow(!isShow);
                        }}
                    />
                </RangeInputFieldWrapper>
            )}
        </Box>
    );
};

export const DateComponent = (props) => {
    const getDate = () => {
        return moment().format("YYYY-MM-DD");
    };
    const [date, setDate] = useState(props.value ? props.value : getDate());
    const [isShow, setIsShow] = useState(false);

    const formatDate = (value) => {
        return moment(value, "ddd MMM DD YYYY").format("YYYY-MM-DD");
    };

    const handleDayClick = (day) => {
        const dateSelected = DateUtils.addDayToRange(day);
        setDate(formatDate(dateSelected.from));
    };

    const handleOkClick = () => {
        setIsShow(false);
    };

    const handleCancelClick = () => {
        setIsShow(false);
    };

    useEffect(() => {
        props.listenerOnDateChange(date);
        // eslint-disable-next-line
    }, [date]);

    return (
        <RangePickerContainer>
            <RangeInputFieldWrapper>
                <CalendarIcon color={Theme.global.colors.active}/>
                <TextInput
                    style={{
                        paddingLeft: "3rem",
                        fontSize: "1rem",
                        fontWeight: "100",
                        height: "3rem",
                    }}
                    placeholder={"01/15/1995"}
                    value={date}
                    onClick={() => {
                        setIsShow(!isShow);
                    }}
                />
            </RangeInputFieldWrapper>

            {isShow && (
                <RangePickerContainer>
                    <Box
                        elevation="medium"
                        direction={"column"}
                        background={Theme.global.colors.white}
                        style={{ position: "relative", zIndex: 1 }}
                    >
                        <div>
                            <Calendar
                                maxDate={new Date()}
                                className="react-calendar"
                                onChange={(day) => handleDayClick(day)}
                            />
                        </div>
                        <Box direction="row" justify="between" pad="small">
                            <Text
                                color={Theme.global.colors.textBlack}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleCancelClick()}
                            >
                                Cancel
                            </Text>

                            <Text
                                color={Theme.global.colors.active}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleOkClick()}
                            >
                                OK
                            </Text>
                        </Box>
                    </Box>
                </RangePickerContainer>
            )}
        </RangePickerContainer>
    );
};
