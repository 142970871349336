import React, { useState } from "react";
import { Text } from "grommet";
import styled from "styled-components";
import { RefreshIcon, Theme } from "../../theme";
import { Drawer } from "grommet-icons/es6";

const EmptyListViewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    &:hover {
      stroke: ${Theme.global.colors["focus"]};
    }
  }
`;

const EmptyList = (props) => {
    const [error] = useState(props.isError);

    return (
        <EmptyListViewContainer>
            <div style={{ margin: "1rem 0 0 0", textAlign: "center" }}>
                <Drawer/>
            </div>
            <Text
                margin={{
                    top: "small",
                    bottom: "medium",
                }}
                textAlign={"center"}
                color={Theme.global.colors["inactive"]}
            >
                {error
                    ? "Issue loading data. Please try again."
                    : "No results that match your search."}
            </Text>
            {error && (
                <RefreshIcon
                    onClick={() => {
                        props.onRefetch();
                    }}
                    size="large"
                    color={Theme.global.colors.grey}
                ></RefreshIcon>
            )}
        </EmptyListViewContainer>
    );
};

export default EmptyList;
