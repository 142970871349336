import React, { Fragment, useContext } from "react";
import { Theme } from "../theme";
import { Box, Grid, Grommet, ResponsiveContext } from "grommet";
import ScrollTop from "../components/ScrollTop";
import { Menu, MobileMenu } from "../components/Menu";
import styled, { createGlobalStyle } from "styled-components";
import { BrowserRouter, Switch } from "react-router-dom";
import { ProvideRoutes } from "../components/ProvideRoutes";
import { Route } from "../constants";
import Footer from "../components/Footer/index"
import NYMammalsLogo from "../assets/images/brand1_logo.svg"
import ESFLogo from "../assets/images/esf_logo.png"
import NYNHPLogo from "../assets/images/NYNHP_colorHiRes_resize.png"
import DECLogo from "../assets/images/dec_fat.png"
import AppContext from "../appContext";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    //overflow-y: auto;
    height: 100vh;
  }

  .grid {
    display: -ms-grid;


    -ms-grid-columns: auto 1fr;
    -ms-grid-rows: auto 1fr;

    > *:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    > *:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }
  }

`;

const FloatBox = styled(Box)`
  position: fixed;
  width: 100%;
  background: white;
  z-index: 10;
`;

const FooterImagesRight = [
    ESFLogo,
    NYNHPLogo,
    DECLogo
];

const AppRouter = props => {
    let history = props.history;
    const context = useContext(AppContext);
    return (
        <BrowserRouter history={history}>
            <Grommet theme={Theme}>
                <GlobalStyle/>
                <ResponsiveContext.Consumer>
                    {size => (
                        <Fragment>
                            <Grid
                                fill
                                rows={[
                                    "auto",
                                    "flex"
                                ]}
                                columns={size === "small" ? ["flex"] : [
                                    "auto",
                                    "flex"
                                ]}
                                areas={
                                    size === "small"
                                        ? [
                                            {
                                                name: "menu",
                                                start: [
                                                    0,
                                                    0
                                                ],
                                                end: [
                                                    1,
                                                    0
                                                ]
                                            },
                                            {
                                                name: "main",
                                                start: [
                                                    0,
                                                    1
                                                ],
                                                end: [
                                                    1,
                                                    1
                                                ]
                                            }
                                        ]
                                        : [
                                            {
                                                name: "sidebar",
                                                start: [
                                                    0,
                                                    0
                                                ],
                                                end: [
                                                    0,
                                                    1
                                                ]
                                            },
                                            {
                                                name: "main",
                                                start: [
                                                    1,
                                                    1
                                                ],
                                                end: [
                                                    1,
                                                    1
                                                ]
                                            }
                                        ]
                                }
                                className="grid"
                            >
                                {size === "small" ? (
                                    <FloatBox
                                        gridArea="menu"
                                        direction="row"
                                        pad={{ horizontal: "medium", vertical: "small" }}
                                        elevation="small"
                                    >
                                        <MobileMenu/>
                                    </FloatBox>
                                ) : (
                                    <Box
                                        style={{
                                            maxHeight: "100%",
                                            overflowY: "auto",
                                        }}
                                        gridArea="sidebar"
                                        background="lightWhite"
                                        width="18rem"
                                        animation={[
                                            { type: "fadeIn", duration: 300 },
                                            { type: "slideRight", size: "xlarge", duration: 150 }
                                        ]}
                                    >
                                        <Menu/>
                                    </Box>
                                )}

                                <ScrollTop size={size} history={history}>
                                    <Box
                                        gridArea="main"
                                        pad={{
                                            horizontal: size === "small" ? "medium" : size === "medium" ? "medium" : "large",
                                            vertical: size
                                        }}
                                        style={size === "small" ? { marginTop: "8rem" } : null}
                                    >
                                        <Switch>
                                            {Route.map(route => (
                                                <ProvideRoutes key={`route-${route.name}`} {...route} />
                                            ))}
                                        </Switch>
                                    </Box>
                                    {context.isMammals &&
                                    <Footer leftImage={NYMammalsLogo}
                                            rightImages={FooterImagesRight}/>
                                    }
                                </ScrollTop>
                            </Grid>
                        </Fragment>
                    )}
                </ResponsiveContext.Consumer>
            </Grommet>
        </BrowserRouter>
    );
};

export default AppRouter;
