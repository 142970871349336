import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BackIcon, DotsMenu, Edit, LocationLogo, Theme, UserIcon, } from "../../theme";
import { getEllipsisText, useWindowSize, } from "../../utils";
import { Box, Grid, Image, Text } from "grommet";
import { Flag } from "grommet-icons";
import Popup from "reactjs-popup";
import moment from "moment";

const CardImage = styled(Image)`
  border-radius: 50%;
  height: 54px;
  width: 54px;
`;
const TextWrapper = styled(Box)`
  width: 90%;
  color: ${Theme.global.colors.textBlack};
  margin-left: 1rem;
`;
const RootContainer = styled(Box)`
  display: flex;
  border-radius: 5px;
  border: 1px solid ${Theme.global.colors.inactive};
  border-color: ${Theme.global.colors.lightGrey};
  padding-top: 0.4rem;
  padding-left: 0.4rem;
  padding-bottom: 0.4rem;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
  flex-direction: row;
  background-color: white;
  margin-left: "1rem";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MenuDiv = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Theme.global.colors.white};
`;

const MenuItemDiv = styled(Box)`
  cursor: pointer;
  padding: 0.4rem;
  &:hover {
    background: ${Theme.global.colors.lightGrey};
  }
`;

const QuantityContainer = styled(Box)`
  border-radius: 50%;
  border: 1px solid ${Theme.global.colors.grey};
`;

const ObservationCard = (props) => {
    const [data] = useState(props);
    const [observationTimeInput, setObservationTimeInput] = useState(
        props.dateTime
    );

    const OptionMenu = () => (
        <Popup
            trigger={
                <div style={{ cursor: "pointer", align: "end" }}>
                    <DotsMenu color={Theme.global.colors.grey}/>
                </div>
            }
            position="left top"
            on="click"
            closeOnDocumentClick
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            contentStyle={{ padding: "0px", border: "none" }}
            arrow={false}
        >
            <MenuDiv>
                <MenuItemDiv
                    direction={"row"}
                    gap="small"
                    onClick={() => props.onItemClick(data)}
                    align="center"
                >
                    <BackIcon color={Theme.global.colors.active}/>
                    <Text color={Theme.global.colors.textBlack} size={"0.8rem"}>
                        View observation
                    </Text>
                </MenuItemDiv>
                <MenuItemDiv
                    direction={"row"}
                    gap="small"
                    onClick={() => props.onEditClick(data)}
                    align="center"
                >
                    <div
                        style={{
                            paddingLeft: "0.2rem",
                            paddingRight: "0.25rem",
                        }}
                    >
                        <Edit
                            width={"20px"}
                            height={"20px"}
                            color={Theme.global.colors.active}
                        />
                    </div>
                    <Text color={Theme.global.colors.textBlack} size={"0.8rem"}>
                        Edit
                    </Text>
                </MenuItemDiv>
                <MenuItemDiv
                    direction={"row"}
                    gap="small"
                    onClick={() =>
                        props.onFlagClick(props.id, !props.profileData.flagged)
                    }
                    align="center"
                >
                    <Flag
                        color={
                            props.profileData.flagged
                                ? Theme.global.colors.active
                                : Theme.global.colors.grey
                        }
                        style={{
                            width: "18px",
                            height: "18px",
                            paddingLeft: "0.2rem",
                            paddingRight: "0.25rem",
                        }}
                    />
                    <Text color={Theme.global.colors.textBlack} size={"0.8rem"}>
                        {props.profileData.flagged
                            ? "Unflag Observation"
                            : "Flag observation"}
                    </Text>
                </MenuItemDiv>
            </MenuDiv>
        </Popup>
    );

    useEffect(() => {
        var indexToSplit = observationTimeInput.indexOf("T");
        var first = observationTimeInput.slice(0, indexToSplit);
        const time = moment(first, "YYYY-MM-DD");
        setObservationTimeInput(time.format("MMM, Do YYYY"));
        // eslint-disable-next-line
    }, [data]);

    const [width] = useWindowSize();

    return (
        <RootContainer
            elevation={"xsmall"}
            onClick={(e) => {
                props.onItemClick(data);
            }}
            style={{
                cursor: !props.isShowMenu ? "pointer" : "",
            }}
            margin={{ right: "2px" }}
        >
            <div>
                <CardImage fit="cover" src={props.image}/>
            </div>
            <Box fill direction="row">
                <TextWrapper direction="column">
                    <Wrapper>
                        <Text
                            style={{
                                color: Theme.global.colors.textBlack,
                                fontSize: "0.78rem",
                            }}
                        >
                            {observationTimeInput}
                        </Text>
                        <QuantityContainer
                            width="1.2rem"
                            height="1.2rem"
                            align="center"
                            alignContent="end"
                            alignSelf="center"
                        >
                            <Text
                                style={{
                                    color: Theme.global.colors.grey,
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    lineHeight: "16px",
                                }}
                            >
                                {props.quantity}
                            </Text>
                        </QuantityContainer>
                    </Wrapper>
                    <Text
                        style={{
                            fontWeight: "bold",
                            color: Theme.global.colors.textBlack,
                            fontSize: "0.99rem",
                        }}
                    >
                        {getEllipsisText(props.SpecieName, 20)}
                    </Text>

                    <Grid
                        rows={
                            width > 400
                                ? ["1.6rem"]
                                : false
                                ? [
                                    "1.0rem",
                                    "1.0rem"
                                ]
                                : [
                                    "1.3rem",
                                    "1.3rem"
                                ]
                        }
                        columns={width > 400 ? [
                            "50%",
                            "50%"
                        ] : [
                            "100%",
                            "100%"
                        ]}
                        gap={width > 400 ? "0.5rem" : "0.2rem"}
                        areas={
                            width > 400
                                ? [
                                    {
                                        name: "location",
                                        start: [
                                            0,
                                            0
                                        ],
                                        end: [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        name: "user",
                                        start: [
                                            1,
                                            0
                                        ],
                                        end: [
                                            1,
                                            0
                                        ]
                                    },
                                ]
                                : [
                                    {
                                        name: "location",
                                        start: [
                                            0,
                                            0
                                        ],
                                        end: [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        name: "user",
                                        start: [
                                            0,
                                            1
                                        ],
                                        end: [
                                            0,
                                            1
                                        ]
                                    },
                                ]
                        }
                    >
                        <Box
                            direction="row"
                            gridArea="location"
                            align="center"
                            alignContent="start"
                            alignSelf="center"
                        >
                            <LocationLogo
                                width={"14px"}
                                height={"14px"}
                                color={Theme.global.colors.grey}
                            />
                            <Text
                                truncate
                                size={"0.68rem"}
                                margin={{ left: "0.2rem" }}
                                color={Theme.global.colors.textBlack}
                            >
                                {props.CountryName}
                            </Text>
                        </Box>
                        <Box
                            direction="row"
                            gridArea="user"
                            align="center"
                            alignContent="center"
                            alignSelf="center"
                        >
                            <UserIcon height={"0.8rem"} width={"0.8rem"}/>
                            <Text
                                truncate
                                size={"0.68rem"}
                                margin={{ left: "0.2rem" }}
                                color={Theme.global.colors.textBlack}
                            >
                                {props.UserName}
                            </Text>
                        </Box>
                    </Grid>
                </TextWrapper>
                {/*<Box style={{ width: "10%" }}>*/}
                {/*{props.isShowMenu && isUserTypeModerator() && <OptionMenu />}*/}
                {/*</Box>*/}
            </Box>
        </RootContainer>
    );
};
export default ObservationCard;
