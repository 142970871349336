import React, { Fragment, useEffect, useState } from "react";
import { Queries } from "../apollo";
import styled from "styled-components";
import Loader from "../components/Loader";
import { DUMMY_PIC_URL } from "../constants";
import { useLazyQuery } from "@apollo/react-hooks";
import { BackIcon, ChartIcon, Theme } from "../theme";
import { capitalizeFirstLetter, hexToRgb, sortImageByCount } from "../utils";
import { getSpecieInput } from "../apollo/mutations/Variables";
import { Anchor, Box, Button, Carousel, Heading, Image, Paragraph, ResponsiveContext, Text, } from "grommet";

const BackLink = styled(Box)`
  cursor: pointer;
`;

const DetailWrapper = styled(Box)`
  min-height: 37rem;

  @media only screen and (max-width: 1250px) {
    display: flex;
    flex-direction: column;
  }
`;

const TopDetailGrid = styled.div`
  display: grid;

  @media only screen and (max-width: 1250px) {
    display: block;
    .GridRight {
      margin-top: 3rem;
    }
  }
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  margin-bottom: 1em;

  .GridRight {
    grid-column: 2 / span 3;
  }
`;

const GridLeft = styled(Box)`
  height: auto;
  width: 30rem;
  @media only screen and (max-width: 1250px) {
    width: 100%;
  }

  @media only screen and (max-width: 425px) {
    width: 100%;
    height: 15rem;
  }

  button {
    margin-bottom: -2.5rem;
    :focus {
      box-shadow: none;
    }
  }
`;

const InfoSection = styled(Box)`
  background: ${Theme.global.colors.lightWhite};
  display: flex;
`;

const LeftInfoBox = styled(Box)`
  width: 100%;
`;

const RightInfoBox = styled(Box)`
  width: 100%;
`;

const ButtonWrapper = styled(Button)`
  svg path {
    fill: ${Theme.global.colors.active};
  }

  color: ${Theme.global.colors.active};
  width: 16rem;

  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
    width: 100%;
  }
`;

const DescriptionWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1250px) {
    margin-top: 1rem;
  }

  p {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CaptionCreditContainer = styled.div`
  padding: 12px;
  margin: 12px 0px;
  border-radius: 4px;
  background: ${`rgba(${hexToRgb(Theme.global.colors.active)}, 0.1)`};
  .caption-credit {
    color: ${Theme.global.colors.black};
  }
`;

const GuideDetailPage = (props) => {
    const isHavePropsData = () => {
        return props.location.state ? true : false;
    };

    const getSpecieId = () => {
        return props.match.params.id;
    };

    const [getSpecieById, { data: specieQueryResponse }] = useLazyQuery(
        Queries.GET_SPECIE_QUERY,
        getSpecieInput(getSpecieId())
    );

    const [specieItem, setSpecieItem] = useState();
    const [toNavigate, setToNavigate] = useState(false);

    useEffect(() => {
        if (isHavePropsData()) {
            setSpecieItem(props.location.state.specieItem);
        } else {
            if (specieQueryResponse) {
                setSpecieItem(specieQueryResponse.specie);
            } else {
                getSpecieById();
            }
        }
        // eslint-disable-next-line
    }, [specieQueryResponse]);

    useEffect(() => {
        if (toNavigate) {
            handleExploreGuide();
        }
        // eslint-disable-next-line
    }, [specieItem]);

    const handleExploreGuide = () => {
        let path = `/explore?species=${specieItem.commonName}&order=${specieItem.order.commonName}`;
        props.history.push(path);
    };

    const formateDescription = (description, index) => {
        const arr = description.split("/n");
        if (arr.length === 4) {
            if (arr.length > index) {
                return arr[index];
            } else return false;
        } else if (arr.length === 2) {
            if (index === 3) {
                return arr[1];
            }
            if (index === 0) {
                return arr[0];
            }
        } else if (arr.length === 1 && index === 0) return arr[0];
    };

    const CaptionCreditView = (caption, credit) => (
        <CaptionCreditContainer>
            <div>
                {caption && (
                    <>
                        <b style={{ marginRight: 4 }}>Caption:</b>
                        {caption}
                    </>
                )}
                {caption && credit && <br/>}
                {credit && (
                    <>
                        <b style={{ marginRight: 4 }}>Credit:</b>
                        {credit}
                    </>
                )}
            </div>
        </CaptionCreditContainer>
    );

    const View = (size) => {
        return (
            <Fragment>
                <BackLink
                    width={"5rem"}
                    direction="row"
                    align={"start"}
                    pad={{
                        top: "1.5rem",
                        left: "0rem",
                        bottom: "1.5rem",
                        right: "0rem",
                    }}
                    onClick={() => {
                        props.history.goBack();
                    }}
                    ariaLabel="Back"
                    tabIndex="0"
                    role="link"
                >
                    <BackIcon color={Theme.global.colors.active}/>
                    <Text color={Theme.global.colors.active}>Back</Text>
                </BackLink>

                <DetailWrapper
                    round="0.25rem"
                    elevation="medium"
                    background={Theme.global.colors.white}
                    pad="2rem"
                >
                    <TopDetailGrid size={size} direction="row">
                        <GridLeft pad={"0"}>
                            <Carousel fill={true} controls={"selectors"}>
                                {specieItem.images.length > 0 ? (
                                    sortImageByCount(specieItem.images).map((item) => (
                                        <div key={item.id}>
                                            <ImageContainer>
                                                <img
                                                    src={item.url}
                                                    style={{ objectFit: "contain", height: "16rem" }}
                                                />
                                            </ImageContainer>
                                            {(item.caption || item.credits) &&
                                            CaptionCreditView(item.caption, item.credits)}
                                        </div>
                                    ))
                                ) : (
                                    <Image fill={"horizontal"} src={DUMMY_PIC_URL}/>
                                )}
                            </Carousel>
                        </GridLeft>

                        <div className="GridRight">
                            <Heading
                                color={Theme.global.colors.textBlack}
                                margin={{
                                    top: "small",
                                    bottom: "small",
                                    left: "0",
                                    right: "0",
                                }}
                                level={2}
                            >
                                {capitalizeFirstLetter(specieItem.commonName)}
                            </Heading>
                            <Text
                                style={{ fontStyle: "italic" }}
                                margin={{
                                    top: "0",
                                    bottom: "medium",
                                    left: "0",
                                    right: "0",
                                }}
                                color={Theme.global.colors.textBlack}
                            >
                                {capitalizeFirstLetter(specieItem.binomialName)}
                            </Text>

                            <InfoSection
                                pad={"small"}
                                margin={{
                                    top: "small",
                                    bottom: "small",
                                }}
                                direction={size === "small" ? "column" : "row"}
                                justify={size === "small" ? "center" : "between"}
                            >
                                <LeftInfoBox>
                                    <Box
                                        margin={{
                                            top: size === "small" ? "small" : "",
                                        }}
                                        pad={"none"}
                                        direction={"row"}
                                    >
                                        <Heading
                                            level={4}
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0",
                                                right: "0",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            Genus:
                                        </Heading>

                                        <Text
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0.2rem",
                                                right: "0",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            {capitalizeFirstLetter(specieItem.genus.commonName)}
                                        </Text>
                                    </Box>
                                    <Box
                                        margin={{
                                            top: "xsmall",
                                        }}
                                        pad={"none"}
                                        direction="row"
                                    >
                                        <Heading
                                            level={4}
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0",
                                                right: "0",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            Family:
                                        </Heading>

                                        <Text
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0.2rem",
                                                right: "0",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            {capitalizeFirstLetter(specieItem.family.commonName)}
                                        </Text>
                                    </Box>
                                    <Box
                                        margin={{
                                            top: "xsmall",
                                        }}
                                        pad={"none"}
                                        direction="row"
                                    >
                                        <Heading
                                            level={4}
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0",
                                                right: "0",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            Order:
                                        </Heading>

                                        <Anchor
                                            onClick={() => {
                                                setToNavigate(true);
                                                setSpecieItem({
                                                    ...specieItem,
                                                    commonName: "All",
                                                });
                                            }}
                                            textDecorationLine="underline"
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0.2rem",
                                                right: "0",
                                            }}
                                            size="medium"
                                            color={Theme.global.colors.brand}
                                        >
                                            {capitalizeFirstLetter(specieItem.order.commonName)}
                                        </Anchor>
                                    </Box>
                                    <Box
                                        margin={{
                                            top: "xsmall",
                                        }}
                                        pad={"none"}
                                        direction="row"
                                    >
                                        <Heading
                                            level={4}
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0",
                                                right: "0",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            Class:
                                        </Heading>

                                        <Text
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0.2rem",
                                                right: "0",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            {capitalizeFirstLetter(specieItem.class.commonName)}
                                        </Text>
                                    </Box>
                                </LeftInfoBox>
                                <RightInfoBox>
                                    <Box
                                        margin={{
                                            top: size === "small" ? "small" : "",
                                            bottom: size === "small" ? "small" : "",
                                            left: size === "small" ? "small" : "1rem",
                                        }}
                                        pad={"none"}
                                        direction="row"
                                    >
                                        <Heading
                                            level={4}
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0",
                                                right: "0",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            Total observations:
                                        </Heading>

                                        <Text
                                            margin={{
                                                top: "0",
                                                bottom: "0",
                                                left: "0.2rem",
                                                right: "0.2rem",
                                            }}
                                            color={Theme.global.colors.textBlack}
                                        >
                                            {process.env.REACT_APP_TYPE_CODE === "2"
                                                ? specieItem.amphRepObsCount
                                                    ? specieItem.amphRepObsCount
                                                    : " -"
                                                : specieItem.mammalsObsCount
                                                    ? specieItem.mammalsObsCount
                                                    : " -"}
                                        </Text>
                                    </Box>
                                </RightInfoBox>
                            </InfoSection>

                            <ButtonWrapper
                                icon={<ChartIcon/>}
                                label="Explore Observations"
                                onClick={() => {
                                    handleExploreGuide();
                                }}
                            />
                        </div>
                    </TopDetailGrid>

                    <DescriptionWrapper>
                        <Heading
                            level={4}
                            margin={{
                                top: "0",
                                bottom: "small",
                                left: "0",
                                right: "0",
                            }}
                            color={Theme.global.colors.textBlack}
                        >
                            Description
                        </Heading>
                        <Paragraph
                            color={Theme.global.colors.textBlack}
                            fill={true}
                            responsive={true}
                            textAlign={"start"}
                            margin="none"
                            style={{ whiteSpace: "pre-line" }}
                        >
                            {specieItem.shortDescription.length > 0
                                ? formateDescription(specieItem.shortDescription, 0)
                                : "None"}
                        </Paragraph>
                        {formateDescription(specieItem.shortDescription, 1) && (
                            <Box direction="row" style={{ marginTop: "1rem" }}>
                                <Heading
                                    level={5}
                                    margin={{
                                        top: "0",
                                        bottom: "small",
                                        left: "0",
                                        right: "xsmall",
                                    }}
                                    color={Theme.global.colors.textBlack}
                                >
                                    Total length:
                                </Heading>
                                <Text>
                                    {formateDescription(specieItem.shortDescription, 1)}
                                </Text>
                            </Box>
                        )}
                        {formateDescription(specieItem.shortDescription, 2) && (
                            <Box direction="row" style={{ marginTop: "1rem" }}>
                                <Heading
                                    level={5}
                                    margin={{
                                        top: "0",
                                        bottom: "small",
                                        left: "0",
                                        right: "xsmall",
                                    }}
                                    color={Theme.global.colors.textBlack}
                                >
                                    Weight:
                                </Heading>
                                <Text>
                                    {formateDescription(specieItem.shortDescription, 2)}
                                </Text>
                            </Box>
                        )}
                        {formateDescription(specieItem.shortDescription, 3) && (
                            <Box direction="row" style={{ marginTop: "1rem" }}>
                                <Heading
                                    level={5}
                                    margin={{
                                        top: "0",
                                        bottom: "small",
                                        left: "0",
                                        right: "xsmall",
                                    }}
                                    color={Theme.global.colors.textBlack}
                                >
                                    Other names:
                                </Heading>
                                <Text>
                                    {formateDescription(specieItem.shortDescription, 3)}
                                </Text>
                            </Box>
                        )}
                    </DescriptionWrapper>
                </DetailWrapper>
            </Fragment>
        );
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => <div>{specieItem ? View(size) : <Loader/>}</div>}
        </ResponsiveContext.Consumer>
    );
};

export default GuideDetailPage;
