import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from "moment";
import styled from "styled-components";
import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import DiversityTab from "./DiversityTab";
import OccurrenceTab from "./OccurrenceTab";
import ObservationTab from "./ObservationTab";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Mutations, Queries } from "../../apollo";
import { clearClientCache } from "../../apollo/client";
import { ShowDialog } from "../../components/DialogsComponent";
import { getUserId, isNotUndefined, isUserLogIn } from "../../helpers";
import { DateRangeComponent } from "../../components/DateTimeComponent";
import {
    getCsvFileToDownload,
    getDiversityGraph,
    getObservationUpdateInput,
    getOccuranceEnvironmentalRelationalGraph,
    getOccuranceGraph,
    getSearchQueryObject,
    observationsHeatMapByZoneInput
} from "../../apollo/mutations/Variables";
import {
    Box,
    Button,
    CheckBox,
    Grommet,
    Heading,
    ResponsiveContext,
    Select,
    Tab,
    Tabs,
    Text,
    TextInput,
} from "grommet";
import { COUNTIES, DEC_REGION, MAP_LAYERS, QUADS, WATERSHEDS, } from "../../constants";
import { FilterIcon, GridIcon, LocationIcon, SearchCustom, Theme, } from "../../theme";
import {
    _objectWithoutProperties,
    capitalizeFirstLetter,
    capitalizeObjectFirstLetter,
    capitalizeObjectFirstLetterSpecie,
    diversityGraphDataLinearReg,
    downloadCsvFile,
    getEnumValueById,
    getNameById,
    getPramsFromUrl,
    isUserTypeModerator,
    useWindowSize,
} from "../../utils";
import MultiLabelDropDown from "../../components/MultiLabelDropDown";
import AppContext from '../../appContext';

const ExplorePageRootContainer = styled(Box)`
  width: 100%;
  height: 100%;
  background: ${Theme.global.colors.white};
`;

const RootGrid = styled.div`
  display: grid;
  @media only screen and (max-width: 1280px) {
    display: block;
  }

  @media only screen and (max-width: 1400px) {
    .GridTop {
      display: block;
    }
  }

  grid-template-columns: repeat(1, 1fr);
  .GridHeader {
    grid-column: 1 / span 1;
  }
  .GridTop {
    grid-column: 1 / span 1;
  }
  .GridTabLayout {
    grid-column: 1 / span 1;
  }
  .GridTabFragment {
    grid-column: 1 / span 1;
  }
`;

const InputFieldWrapper = styled.div`
  height: 3rem;
  margin-bottom: 1rem;
  display: flex;
  flex-grow: 1;
  .MediumInputField {
    width: 100%;
    position: relative;
    & > svg {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
    }

    input {
      padding-right: 2.5rem;
    }
    button {
      width: 100%;
    }
    @media only screen and (max-width: 1398px) {
      width: 100%;
    }
  }
  .SmallInputField {
    width: 100%;
    position: relative;
    & > svg {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
    }

    input {
      padding-right: 2.5rem;
    }

    @media only screen and (max-width: 1398px) {
      width: 100%;
    }
  }
`;

const DateInput = styled.div`
  width: 22rem;
  height: 3rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 1398px) {
    width: 100%;
  }
`;

const FilterButton = styled(Button)`
  width: 8rem;
  height: 3rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  color: ${(props) =>
    props.active ? "white" : Theme.global.colors["text-black"]};
  background-color: ${(props) =>
    props.active ? Theme.global.colors.active : "unset"};
  svg path {
    fill: ${(props) => (!props.active ? Theme.global.colors.active : "white")};
  }

  @media only screen and (max-width: 1398px) {
    width: 100%;
  }
`;

const TabViewContainer = styled(Box)`
  margin-top: 1rem;
  .active-tab {
    div {
      margin-left: 0px;
      margin-right: 24px;
      border-bottom: solid 2px ${Theme.global.colors.brand};
      span {
        color: ${Theme.global.colors.brand};
      }
    }
    :focus {
      box-shadow: none;
    }
  }
  .inactive-tab {
    div {
      margin-left: 0px;
      margin-right: 24px;
      border-bottom: solid 2px rgb(68, 68, 68);
      span {
        color: rgb(68, 68, 68);
      }
      :hover {
        border-bottom: solid 2px ${Theme.global.colors.brand};
        span {
          color: ${Theme.global.colors.brand};
        }
      }
    }
    :focus {
      box-shadow: none;
    }
  }
`;

const ObservationViewSwitchContainer = styled.div`
  min-width: 6.5rem;
  height: 3rem;
  align-self: center;
`;

const ObservationViewSwitchButton = styled(Button)`
  width: 3.25rem;
  height: 3rem;
  border-radius: 0rem;
  align-self: center;
`;

const FilterItemContainer = styled(Box)`
  @media only screen and (max-width: 1398px) {
    display: block;
    flex-direction: column;
  }
`;

const ZoneContainer = styled(Box)`
  @media only screen and (max-width: 1398px) {
    display: block;
    flex-direction: column;
  }
`;

const Expandable = styled(Box)`
  z-index: 0;
  background-color: ${Theme.global.colors.white};
  border-radius: 0.25rem;
`;

const CustomSelectItem = styled(Box)`
  background: ${(props) => props.bgColor};
  .select-text {
    fontweight: 100;
    color: ${(props) =>
    props.isSelected ? Theme.global.colors.white : Theme.global.colors.black};
  }
  .select-sub-text {
    font-style: italic;
    color: ${(props) =>
    props.isSelected ? Theme.global.colors.white : Theme.global.colors.grey};
    fontweight: 100;
    fontsize: 0.9rem;
    fontstyle: italic;
  }
`;

const AllObj = {
    id: -1,
    name: "All",
};

const AllObjForOrderSpecie = {
    id: -1,
    commonName: "All",
};

const haveUrlParams = (obj) =>
    obj?.county ||
    obj?.decregion ||
    obj?.end ||
    obj?.order ||
    obj?.quad ||
    obj?.specie ||
    obj?.start ||
    obj?.watershed
        ? true
        : false;

const isPramsExits = (items, value) => {
    if (items) {
        const obj = items.find((event) => event.commonName.toLowerCase() === value);
        if (obj) {
            return obj;
        }
        return false;
    }
};

const Explore = (props) => {
    const refToSmallInputField = useRef(null);
    const myCustomTheme = deepMerge(grommet, {
        ...Theme,
        textInput: {
            suggestions: {
                extend: {
                    maxWidth: refToSmallInputField?.current?.clientWidth
                        ? refToSmallInputField.current.clientWidth.toString() + "px"
                        : "200px",
                },
            },
        },
    });
    const getInitialState = () => {
        return {
            startingDateLimit: moment().subtract(50, "year").format(),
            endingDateLimit: moment().format(),
            watershed: "",
            decRegion: "",
            county: "",
            quad: "",
            specie: "",
            order: "",
            user: "",
            genus: "",
            source: "",
            showFlaggedEnteries: false,
            showPrivateEnteries: false,
        };
    };
    const activePage = {
        observationPage: 0,
        occurrancePage: 1,
        diversityPage: 2,
    };
    const [diversityFilter, setDiversityFilter] = useState(getInitialState());
    const [
        diversityFilterCompareSelectData,
        setDiversityFilterCompareSelectData,
    ] = useState(getInitialState());
    const [searchFilterData, setSearchFilterData] = useState(getInitialState());
    const [presentableData, setPresentableData] = useState({
        search: {
            suggestionList: [],
        },
        order: {
            orderData: [],
            selectedOrder: "",
        },
        specie: {
            specieData: [],
            selectedSpecie: "",
        },
        genus: {
            genusData: [],
            selectedGenus: "",
        },
        usersList: [],
        observationsMeta: 0,
    });

    const [flag, setFlag] = useState({
        isObservationMap: true,
        isShowObservationSwitch: true,
        isFilterOpen: false,
        isContributor: isUserLogIn(),
        isModerator: isUserTypeModerator(),
        isZoneSelected: false,
        isCampareOrder: false,
        isDownloadCSV: true,
        isFetchAll: false,
    });

    const [flaggedObservation, setFlaggedObservation] = useState({
        id: "",
        flagged: false,
    });

    const [dialogState, setDialogState] = useState({
        show: false,
        message: "",
        type: "",
    });

    const [observationsData, setObservationsData] = useState({
        observations: [],
        observationsMeta: 0,
    });
    const context = useContext(AppContext);
    const [search, setSearch] = useState("");
    const [zone, setZone] = useState(MAP_LAYERS[0]);
    const [sourceQuery, setSourceQuery] = useState("");
    const [previousZone, setPreviousZone] = useState("");
    const [activePageIndex, setActivePageIndex] = useState(0);
    const [sourceSuggestions, setSourceSuggestions] = useState([]);
    const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
    const [diversityGraphMergedData, setDiversityGraphMergedData] = useState();
    const [intailSateOfGraphs, setIntailSateOfGraphs] = useState({
        habitatSearchVariable: "forest",
        climateSearchVariable: "temperature",
        explanatoryVariable: "forest",
    });
    const [suggestedItems, setSuggestedItems] = useState([]);
    const [orderRichness, setOrderRichness] = useState(0);

    const [
        orderSpecieCallResponseData,
        setOrderSpecieCallResponseData,
    ] = useState(null);

    const [getHeatMapByZone, { data: heatMapByCallResponseData }] = useLazyQuery(
        Queries.GET_OBSERVATIONS_HEAT_MAP_BY_ZONE_QUERY,
        observationsHeatMapByZoneInput(
            zone?.replace(" ", ""),
            searchFilterData,
            "",
            !(activePageIndex === 0)
        )
    );
    const { data: downloadCSVResponse, loading: loadingCsvFile } = useQuery(
        Queries.GET_FILE_DOWNLOAD_QUERY,
        getCsvFileToDownload(searchFilterData, "", flag.isDownloadCSV)
    );

    const {} = useQuery(Queries.GET_ALL_ORDERS_SPECIES_QUERY, {
        onCompleted: (data) => setOrderSpecieCallResponseData(data),
        fetchPolicy: "cache-first",
    });
    const { data: sourceInObservations } = useQuery(
        Queries.GET_SOURCE_IN_OBSERVATION,
        {
            onCompleted: (data) =>
                setSourceSuggestions(data.sourceInObservations.sourceInObservations),
            fetchPolicy: "cache-and-network",
        }
    );

    const {
        data: searchCallResponseData,
        loading: seachLoadingResponse,
        fetchMore: fetchMoreObservations,
    } = useQuery(
        Queries.SEARCH_AND_FILTER_OBSERVATION_QUERY,
        getSearchQueryObject(
            searchFilterData,
            "",
            0,
            !(activePageIndex === 0) || haveUrlParams()
        )
    );

    const [
        flagObservationMutation,
        { data: updateMutationDataResponse },
    ] = useMutation(
        Mutations.UPDATE_OBSERVATION_MUTATION,
        getObservationUpdateInput(
            { flagged: flaggedObservation.flagged },
            flaggedObservation.id
        )
    );

    const [
        getOccurrranceGraphData,
        { data: graphData, loading: Loading },
    ] = useLazyQuery(
        Queries.GET_DATA_TO_GENERATE_GRAPH,
        getOccuranceGraph(searchFilterData, "")
    );
    const [
        getOccurrranceGraphDataForClimate,
        { data: graphDataOfClimate, loading: climateLoadingResponse },
    ] = useLazyQuery(
        Queries.GET_OCCURRENCE_ENVIRONMENT_GRAPH_QUERY,
        getOccuranceEnvironmentalRelationalGraph(
            intailSateOfGraphs.climateSearchVariable,
            searchFilterData,
            "",
            !(activePageIndex === 1)
        )
    );

    const [
        getOccurrranceGraphDataForHabitat,
        { data: graphDataofHabitat, loading: habitatLoadingResponse },
    ] = useLazyQuery(
        Queries.GET_OCCURRENCE_ENVIRONMENT_GRAPH_QUERY,
        getOccuranceEnvironmentalRelationalGraph(
            intailSateOfGraphs.habitatSearchVariable,
            searchFilterData,
            "",
            !(activePageIndex === 1)
        )
    );

    const [
        getDiversityGraphData,
        {
            data: diversityGraphData,
            loading: diversityGraphDataLoading,
            error: diversityGraphErrorResponse,
        },
    ] = useLazyQuery(
        Queries.GET_DIVERSITY_GRAPH_QUERY,
        getDiversityGraph(
            intailSateOfGraphs.explanatoryVariable,
            diversityFilter,
            "",
            !(activePageIndex === 2)
        )
    );
    const [
        getDiversityGraphDataForCompareOrder,
        {
            data: diversityGraphCompareOrderData,
            loading: diversityGraphCompareOrderDataLoading,
            error: diversityCompareOrderGraphErrorResponse,
        },
    ] = useLazyQuery(
        Queries.GET_DIVERSITY_GRAPH_QUERY,
        getDiversityGraph(
            intailSateOfGraphs.explanatoryVariable,
            diversityFilterCompareSelectData,
            "",
            !(activePageIndex === 2)
        )
    );

    const setZones = (obj) => {
        if (obj.watershed) {
            const waterShedId = getNameById(WATERSHEDS, obj.watershed);
            return handleZoneChanged("Watersheds", waterShedId);
        } else if (obj.decregion) {
            const decReginId = getNameById(DEC_REGION, obj.decregion);
            return handleZoneChanged("DEC Regions", decReginId);
        } else if (obj.quad) {
            const quadsId = getNameById(QUADS, obj.quad);
            return handleZoneChanged("Quads", quadsId);
        } else if (obj.county) {
            const CountyId = getNameById(COUNTIES, obj.county);
            return handleZoneChanged("Counties", CountyId);
        }
    };

    useEffect(() => {
        if (
            updateMutationDataResponse &&
            dialogState.show &&
            dialogState.type === "LOADING"
        ) {
            setDialogState({
                ...dialogState,
                show: false,
                message: "",
            });
        }
        // eslint-disable-next-line
    }, [updateMutationDataResponse]);

    useEffect(() => {
        if (
            orderSpecieCallResponseData?.orders &&
            orderSpecieCallResponseData?.species &&
            orderSpecieCallResponseData?.genera
        ) {
            const getPrams = getPramsFromUrl();
            const obj = isPramsExits(
                orderSpecieCallResponseData && orderSpecieCallResponseData.species,
                getPrams.specie.toLowerCase()
            );
            const orderObj = isPramsExits(
                orderSpecieCallResponseData && orderSpecieCallResponseData.orders,
                getPrams.order.toLowerCase()
            );

            if (getPrams.start || getPrams.end || obj.id || orderObj.id) {
                getPrams?.specie && setSearch(getPrams.specie);
                setSearchFilterData({
                    ...searchFilterData,
                    startingDateLimit: getPrams.start
                        ? getPrams.start
                        : searchFilterData.startingDateLimit,
                    endingDateLimit: getPrams.end
                        ? getPrams.end
                        : searchFilterData.endingDateLimit,
                    specie: obj ? obj.id : "",
                    order: orderObj ? orderObj.id : "",
                    genus: "",
                });
            }

            setPresentableData({
                ...presentableData,
                search: { suggestionList: getSearchSuggestions() },
                order: {
                    ...presentableData.order,
                    orderData: [
                        AllObjForOrderSpecie,
                        ...capitalizeObjectFirstLetter(
                            orderSpecieCallResponseData.orders.sort((a, b) =>
                                a.commonName > b.commonName ? 1 : -1
                            )
                        ),
                    ],
                    selectedOrder: orderObj
                        ? capitalizeFirstLetter(orderObj.commonName)
                        : "",
                },

                specie: {
                    ...presentableData.specie,
                    specieData: [
                        AllObjForOrderSpecie,
                        ...capitalizeObjectFirstLetterSpecie(
                            orderSpecieCallResponseData.species.sort((a, b) =>
                                a.commonName > b.commonName ? 1 : -1
                            )
                        ),
                    ],
                    selectedSpecie: obj.commonName
                        ? capitalizeFirstLetter(obj.commonName)
                        : "",
                },
                genus: {
                    ...presentableData.genus,
                    genusData: [
                        AllObjForOrderSpecie,
                        ...capitalizeObjectFirstLetter(
                            orderSpecieCallResponseData.genera.sort((a, b) =>
                                a.commonName > b.commonName ? 1 : -1
                            )
                        ),
                    ],
                },
            });
        }
        // eslint-disable-next-line
    }, [orderSpecieCallResponseData]);

    useEffect(() => {
        const getPrams = getPramsFromUrl();
        setZones(getPrams);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isNotUndefined(heatMapByCallResponseData)) {
            getHeatMapByZone();
        }
        // eslint-disable-next-line
    }, [heatMapByCallResponseData]);

    const [width] = useWindowSize();

    useEffect(() => {
        setFlag({ ...flag, isObservationMap: width < 769 ? false : true });
        // eslint-disable-next-line
    }, [width]);

    useEffect(() => {
        clearClientCache();
        const graphData = [];
        setOrderRichness(
            diversityGraphData?.generateDiversityGraphWithQuadData?.orderRichness
        );
        let diversityRegressionData = diversityGraphDataLinearReg(
            diversityGraphData &&
            diversityGraphData.generateDiversityGraphWithQuadData &&
            diversityGraphData.generateDiversityGraphWithQuadData.diversityGraphData
        );
        let diversityRegressionDataForCampare = diversityGraphDataLinearReg(
            diversityGraphCompareOrderData &&
            diversityGraphCompareOrderData.generateDiversityGraphWithQuadData &&
            diversityGraphCompareOrderData.generateDiversityGraphWithQuadData
                .diversityGraphData
        );

        if (diversityRegressionDataForCampare && diversityRegressionData) {
            diversityRegressionData.map((element, index) => {
                if (element && element.id !== undefined) {
                    graphData.push({
                        reg: element && element.reg !== undefined ? element.reg : "",
                        specieCount:
                            element && element.specieCount !== undefined
                                ? element.specieCount
                                : "",
                        count:
                            diversityRegressionDataForCampare[index] &&
                            diversityRegressionDataForCampare[index].specieCount !== undefined
                                ? diversityRegressionDataForCampare[index].specieCount
                                : "",
                        environmentPercentage:
                            element && element.environmentPercentage !== undefined
                                ? element.environmentPercentage
                                : "",
                        reg2:
                            diversityRegressionDataForCampare[index] &&
                            diversityRegressionDataForCampare[index].reg !== undefined
                                ? diversityRegressionDataForCampare[index].reg
                                : "",
                        id: element && element.id !== undefined ? element.id : "",
                        name: element && element.name !== undefined ? element.name : "",
                    });
                }
                return element;
            });

            setDiversityGraphMergedData([...graphData]);
        }
        // eslint-disable-next-line
    }, [
        diversityGraphCompareOrderData,
        diversityGraphData
    ]);

    useEffect(() => {
        if (activePageIndex === activePage.occurrancePage) {
            getOccurrranceGraphData();
            if (intailSateOfGraphs.climateSearchVariable) {
                getOccurrranceGraphDataForClimate();
            }
            if (intailSateOfGraphs.habitatSearchVariable) {
                getOccurrranceGraphDataForHabitat();
            }
        }
        if (activePageIndex === activePage.diversityPage) {
            getDiversityGraphData();
        }
        if (activePageIndex === activePage.diversityPage && flag.isCampareOrder) {
            getDiversityGraphDataForCompareOrder();
        }
        // eslint-disable-next-line
    }, [
        searchFilterData,
        activePageIndex,
        intailSateOfGraphs.climateSearchVariable,
        intailSateOfGraphs.habitatSearchVariable,
    ]);

    const getDataOFDiversityGraph = () => {
        getDiversityGraphDataForCompareOrder();
    };

    useEffect(() => {
        if (
            searchCallResponseData &&
            searchCallResponseData.observations.length === 0
        ) {
            setObservationsData({
                observations: undefined,
                observationsMeta: 0,
            });
        }

        if (
            searchCallResponseData &&
            searchCallResponseData.observations &&
            searchCallResponseData.observationsMeta
        ) {
            setObservationsData({
                observations: searchCallResponseData.observations,
                observationsMeta: searchCallResponseData.observationsMeta,
            });

            isUserTypeModerator() &&
            fetchUsersListFromResponse(searchCallResponseData.usersInObservations);
        }
        // eslint-disable-next-line
    }, [searchCallResponseData]);

    useEffect(() => {
        if (flaggedObservation.id) {
            setFlag({ ...flag });
            flagObservationMutation();
        }
        // eslint-disable-next-line
    }, [flaggedObservation]);

    useEffect(() => {
        if (downloadCSVResponse) {
            setFlag({ ...flag, isDownloadCSV: true });
            downloadCsvFile(downloadCSVResponse.downloadObservationsAsCSV);
        }
        // eslint-disable-next-line
    }, [downloadCSVResponse]);

    useEffect(() => {
        if (
            orderSpecieCallResponseData?.orders &&
            orderSpecieCallResponseData?.species
        ) {
            setSearchSuggestions(
                orderSpecieCallResponseData?.orders,
                orderSpecieCallResponseData?.species
            );
        }
        // eslint-disable-next-line
    }, [orderSpecieCallResponseData]);

    useEffect(() => {
        const sources = sourceInObservations?.sourceInObservations || [];
        if (sourceQuery) {
            setSourceSuggestions(
                sources?.filter((item) =>
                    item.toLowerCase().includes(sourceQuery.toLowerCase())
                )
            );
        } else {
            setSourceSuggestions(sources);
        }
        // eslint-disable-next-line
    }, [sourceQuery]);

    const setSearchSuggestions = (orders, species) => {
        var ordersWithoutBioNames = orders.map((item) =>
            _objectWithoutProperties(item, ["binomialName"])
        );
        setSuggestedItems(ordersWithoutBioNames.concat(species));
    };

    const getSearchSuggestions = () => {
        let searchSuggestions = [];
        orderSpecieCallResponseData.orders.forEach((item) => {
            searchSuggestions.push(capitalizeFirstLetter(item.commonName));
        });
        orderSpecieCallResponseData.species.forEach((item) => {
            searchSuggestions.push(capitalizeFirstLetter(item.commonName));
        });
        return searchSuggestions;
    };

    const setComparetoSlectorValue = (value) => {
        if (value) {
            setDiversityFilterCompareSelectData({
                ...diversityFilterCompareSelectData,
                order: value,
            });
            setFlag({ ...flag, isCampareOrder: true });
            getDataOFDiversityGraph();
        }
    };

    const handleOrderSelected = (selectedOrderName, selectedOrderId) => {
        if (selectedOrderId === -1) {
            setSearchFilterData({
                ...searchFilterData,
                order: setValue(selectedOrderId),
                specie: setValue(selectedOrderId),
            });
            setDiversityFilter({
                ...diversityFilter,
                order: "",
            });
        } else {
            setSearchFilterData({
                ...searchFilterData,
                order: setValue(selectedOrderId),
            });
            setDiversityFilter({
                ...diversityFilter,
                order: setValue(selectedOrderId),
            });
        }

        let filtered = orderSpecieCallResponseData.orders;
        let specieList = orderSpecieCallResponseData.species;

        let newSuggestions = [];
        specieList.map((item) => {
            if (item.order.id === selectedOrderId) {
                newSuggestions.push(item);
            }
            return item;
        });
        if (newSuggestions.length === 0) {
            newSuggestions = specieList;
        }
        var genusData = [];
        if (setValue(selectedOrderId)) {
            var orderObj = filtered.find((item) => item.id === selectedOrderId);
            genusData = orderObj.genera;
        } else {
            genusData = orderSpecieCallResponseData.genera;
        }

        setPresentableData({
            ...presentableData,
            order: {
                selectedOrder: setValue(selectedOrderName),
                orderData: [
                    AllObjForOrderSpecie,
                    ...capitalizeObjectFirstLetter(filtered),
                ],
            },
            specie: {
                selectedSpecie: "",
                specieData: [
                    AllObjForOrderSpecie,
                    ...capitalizeObjectFirstLetterSpecie(newSuggestions),
                ],
            },
            genus: {
                selectedGenus: setValue(""),
                genusData: [
                    AllObjForOrderSpecie,
                    ...capitalizeObjectFirstLetter(
                        genusData.sort((a, b) => (a.commonName > b.commonName ? 1 : -1))
                    ),
                ],
            },
        });
    };

    const fetchUsersListFromResponse = (usersInObservations) => {
        let usersMap = new Map();
        usersInObservations.forEach((item) => {
            usersMap.set(item.id, item.name);
        });
        let usersList = [];
        for (const [key, value] of usersMap.entries()) {
            usersList.push({ id: key, name: value });
        }
        setPresentableData({
            ...presentableData,
            usersList: [
                AllObj,
                ...usersList
            ],
        });
    };

    const handleDateRange = (start, end) => {
        setSearchFilterData({
            ...searchFilterData,
            startingDateLimit: moment(start).isValid() ? moment(start).format() : "",
            endingDateLimit: moment(end).isValid() ? moment(end).format() : "",
        });
    };

    const onClimateClick = (value) => {
        if (value) {
            setIntailSateOfGraphs({
                ...intailSateOfGraphs,
                climateSearchVariable: value.toLowerCase(),
            });
        }
    };

    const onHabitatClick = (value) => {
        if (value) {
            setIntailSateOfGraphs({
                ...intailSateOfGraphs,
                habitatSearchVariable: value.toLowerCase(),
            });
        }
    };

    const disableDropdown = (fromDropDown) => {
        if (activePageIndex === 2) {
            return true;
        }
        if (
            searchFilterData.county ||
            searchFilterData.quad ||
            searchFilterData.watershed ||
            searchFilterData.decRegion
        ) {
            if (searchFilterData.county && fromDropDown === MAP_LAYERS[1]) {
                return false;
            } else if (searchFilterData.quad && fromDropDown === MAP_LAYERS[0]) {
                return false;
            } else if (searchFilterData.watershed && fromDropDown === MAP_LAYERS[2]) {
                return false;
            } else if (searchFilterData.decRegion && fromDropDown === MAP_LAYERS[3]) {
                return false;
            }
            return true;
        }
        return false;
    };

    const getValue = (list, value) => {
        return value && value !== -1 ? getEnumValueById(list, value) : AllObj;
    };

    const setValue = (id) => {
        return id === -1 || id.toString() === "All" ? "" : id;
    };

    const getSelectedValue = (value, list) =>
        value === ""
            ? AllObjForOrderSpecie
            : list.filter((item) => item.commonName === value);

    const getZoneId = () => {
        if (searchFilterData.county) {
            return searchFilterData.county;
        } else if (searchFilterData.quad) {
            return searchFilterData.quad;
        } else if (searchFilterData.watershed) {
            return searchFilterData.watershed;
        } else if (searchFilterData.decRegion) {
            return searchFilterData.decRegion;
        }
        return "";
    };

    const handleShowFlaggedEnteries = (isShowFlagged) => {
        setSearchFilterData({
            ...searchFilterData,
            showFlaggedEnteries: isShowFlagged,
        });
    };

    const handleShowPrivateEnteries = (isShowPrivate) => {
        setSearchFilterData({
            ...searchFilterData,
            showPrivateEnteries: isShowPrivate,
        });
    };

    const handleShowCreatedByMe = (isChecked) => {
        if (isChecked) {
            setSearchFilterData({
                ...searchFilterData,
                user: getUserId(),
                showPrivateEnteries: true,
                showFlaggedEnteries: true,
            });
        } else {
            setSearchFilterData({
                ...searchFilterData,
                user: "",
            });
        }
    };

    const handleZoneChanged = (zone, id) => {
        setZone(zone);
        if (zone === MAP_LAYERS[0]) {
            setSearchFilterData({
                ...searchFilterData,
                quad: setValue(id),
                decRegion: "",
                watershed: "",
                county: "",
            });
        } else if (zone === MAP_LAYERS[1]) {
            setSearchFilterData({
                ...searchFilterData,
                county: setValue(id),
                decRegion: "",
                watershed: "",
                quad: "",
            });
        } else if (zone === MAP_LAYERS[2]) {
            setSearchFilterData({
                ...searchFilterData,
                watershed: setValue(id),
                decRegion: "",
                quad: "",
                county: "",
            });
        } else if (zone === MAP_LAYERS[3]) {
            setSearchFilterData({
                ...searchFilterData,
                decRegion: setValue(id),
                quad: "",
                watershed: "",
                county: "",
            });
        }
    };
    const getSpecieObj = (value) => {
        const speciesList = orderSpecieCallResponseData.species;
        return speciesList.find((item) => {
            return item.commonName.toLowerCase() === value.toLowerCase();
        });
    };
    const getOrderObj = (value) => {
        const ordersList = orderSpecieCallResponseData.orders;
        return ordersList.find((item) => {
            return item.commonName.toLowerCase() === value.toLowerCase();
        });
    };
    const handleSpecieSelected = (value, fromSearchBox = false) => {
        var genusValue = "";
        var specieObj = {
            id: -1,
            order: {
                id: -1,
                commonName: "",
            },
        };
        if (value) {
            specieObj = getSpecieObj(value);

            if (specieObj) {
                genusValue = specieObj.genus.commonName;
            } else
                specieObj = {
                    id: -1,
                    order: {
                        id: -1,
                        commonName: "",
                    },
                };
        }

        if (!fromSearchBox) {
            setPresentableData({
                ...presentableData,
                genus: {
                    ...presentableData.genus,
                    selectedGenus: capitalizeFirstLetter(genusValue),
                },
                specie: {
                    ...presentableData.specie,
                    selectedSpecie: capitalizeFirstLetter(value),
                },
                order: {
                    ...presentableData.order,
                    selectedOrder: capitalizeFirstLetter(specieObj.order.commonName)
                        ? specieObj.order.commonName
                        : presentableData.order.selectedOrder,
                },
            });
            setDiversityFilter({
                ...diversityFilter,
                order: specieObj.order.id,
            });
        } else {
            setPresentableData({
                ...presentableData,
                genus: {
                    selectedGenus: capitalizeFirstLetter(genusValue),
                    genusData: [
                        AllObjForOrderSpecie,
                        ...capitalizeObjectFirstLetter(
                            orderSpecieCallResponseData.genera.sort((a, b) =>
                                a.commonName > b.commonName ? 1 : -1
                            )
                        ),
                    ],
                },
                specie: {
                    selectedSpecie: capitalizeFirstLetter(value),
                    specieData: [
                        AllObjForOrderSpecie,
                        ...capitalizeObjectFirstLetter(
                            orderSpecieCallResponseData.species.sort((a, b) =>
                                a.commonName > b.commonName ? 1 : -1
                            )
                        ),
                    ],
                },
                order: {
                    ...presentableData.order,
                    selectedOrder: capitalizeFirstLetter(specieObj.order.commonName)
                        ? specieObj.order.commonName
                        : presentableData.order.selectedOrder,
                },
            });
            setDiversityFilter({
                ...diversityFilter,
                order: specieObj.order.id,
            });
        }
        setSearchFilterData({
            ...searchFilterData,
            specie: setValue(specieObj.id),
            order: setValue(specieObj.order.id),
        });
    };
    const handleGenusSelected = (value) => {
        const speciesList = orderSpecieCallResponseData.species;
        var genusList = orderSpecieCallResponseData.genera;
        var selectedSpecie = "";
        var specieData = [];
        var matchingOrder = "";
        var genusObj = {
            commonName: "",
        };

        if (setValue(value)) {
            specieData = speciesList.filter((item) => item.genus.id === value);
            genusObj = genusList.find((item) => item.id === value);
            if (genusObj) matchingOrder = genusObj.order.commonName;
        } else {
            specieData = speciesList;
        }
        specieData = [
            AllObjForOrderSpecie,
            ...capitalizeObjectFirstLetterSpecie(
                specieData.sort((a, b) => (a.commonName > b.commonName ? 1 : -1))
            ),
        ];

        if (specieData.length === 2) {
            selectedSpecie = specieData[1].commonName;
        }
        setPresentableData({
            ...presentableData,
            genus: {
                ...presentableData.genus,
                selectedGenus: setValue(genusObj.commonName),
            },
            order: {
                ...presentableData.order,
                selectedOrder: setValue(matchingOrder)
                    ? matchingOrder
                    : presentableData.order.selectedOrder,
            },
            specie: {
                ...presentableData.specie,
                selectedSpecie: selectedSpecie,
                specieData: specieData,
            },
        });
        setSearchFilterData({
            ...searchFilterData,
            genus: setValue(value),
        });
    };
    const handleCloseAlertDialog = () => {
        setDialogState({
            ...dialogState,
            show: false,
            message: "",
        });
    };

    const handleLoadMoreObsevations = (length) => {
        if (observationsData?.observations.length >= 30) {
            setFetchMoreLoading(true);
            return new Promise((resolve) =>
                fetchMoreObservations({
                    variables: {
                        observationWhereInput: {
                            ...searchFilterData,
                        },
                        searchQuery: "",
                        offset: length,
                        limit: 30,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        const om = fetchMoreResult.observationsMeta;
                        const ob = fetchMoreResult.observations;
                        const obu = fetchMoreResult.usersInObservations;
                        resolve();
                        return {
                            observationsMeta: om,
                            usersInObservations: obu,
                            observations: [
                                ...observationsData.observations,
                                ...ob
                            ],
                        };
                    },
                }).finally((e) => setFetchMoreLoading(false))
            );
        }
    };

    const onItemClick = (item) => {
        let path = `/observation/${item.profileData.id}`;
        props.history.push(path, {
            observationItem: item.profileData,
        });
    };

    const onFlagClick = (id, check) => {
        setDialogState({
            type: "LOADING",
            show: true,
            message: check ? "Flagging Observation" : "Unflagging Observation",
        });
        setFlaggedObservation({
            id: id,
            flagged: check,
        });
    };
    const explanatoryVariableClick = (value) => {
        if (value) {
            setIntailSateOfGraphs({
                ...intailSateOfGraphs,
                explanatoryVariable: value.toLowerCase(),
            });
        }
    };
    const explanatoryVariableClimateClick = (value) => {
        if (value) {
            setIntailSateOfGraphs({
                ...intailSateOfGraphs,
                explanatoryVariable: value.toLowerCase(),
            });
        }
    };
    const onEditClick = (item) => {
        let path = `/record`;
        props.history.push(path, {
            observationItem: item.profileData,
        });
    };

    const handleDownloadCSV = () => {
        setFlag({ ...flag, isDownloadCSV: false });
    };

    const getSearchValue = (search) => {
        if (activePageIndex === 2) {
            return presentableData.order.selectedOrder
                ? presentableData.order.selectedOrder
                : search;
        } else {
            return search;
        }
    };

    const CustomSelectOption = (props) => {
        var option = props.option;
        if (props.list) {
            option = props.list.find(
                (item) => item.commonName.toLowerCase() === option.toLowerCase()
            );
            option.bioName = option.binomialName;
        }

        var selectedValue = presentableData.specie.selectedSpecie.commonName;

        return (
            <CustomSelectItem
                align="start"
                pad="small"
                bgColor={
                    option.commonName === selectedValue ? Theme.global.colors.brand : null
                }
                isSelected={option.commonName === selectedValue}
            >
                <Text className={"select-text"}>{option.commonName}</Text>
                <Text className={"select-sub-text"}>{option.bioName}</Text>
            </CustomSelectItem>
        );
    };

    const FilterDialog = (
        <Expandable
            className={flag.isFilterOpen && "active"}
            elevation="medium"
            pad="large"
            direction="column"
            plain
        >
            <FilterItemContainer direction="row">
                <Box direction="column" gap="small" align="start" width="40%">
                    <Heading level={4}>{context.isMammals ? "Mammals" : "Amphibians & Reptiles"}</Heading>
                    <Box
                        direction="column"
                        width="12.5rem"
                        gap="small"
                        margin={{ bottom: "0.3rem" }}
                    >
                        <Text size={"1rem"}>Order</Text>
                        <Select
                            labelKey="commonName"
                            valueKey="commonName"
                            options={presentableData?.order?.orderData || []}
                            placeholder={"Select"}
                            style={{ fontWeight: "100" }}
                            value={getSelectedValue(
                                presentableData.order.selectedOrder,
                                presentableData.order.orderData
                            )}
                            dropHeight={"small"}
                            onChange={(option) => {
                                setSearch(setValue(option.option.commonName));
                                handleOrderSelected(option.option.commonName, option.option.id);
                            }}
                        />
                    </Box>
                    <Box
                        direction="column"
                        width="12.5rem"
                        gap="small"
                        margin={{ bottom: "0.8rem" }}
                    >
                        <Text size={"1rem"}>Species</Text>
                        <Select
                            labelKey="commonName"
                            valueKey="commonName"
                            options={presentableData ? presentableData.specie.specieData : ""}
                            placeholder={"Select"}
                            dropHeight="medium"
                            style={{ fontWeight: "100" }}
                            disabled={activePageIndex === 2 ? true : false}
                            value={getSelectedValue(
                                presentableData.specie.selectedSpecie,
                                presentableData.specie.specieData
                            )}
                            onChange={(option) => {
                                let strSearch = "";
                                if (option.option.id !== -1) {
                                    strSearch = option.option.commonName;
                                } else if (searchFilterData.order) {
                                    strSearch = presentableData.order.selectedOrder;
                                }
                                setSearch(setValue(strSearch));
                                handleSpecieSelected(option.option.commonName);
                            }}
                        >
                            {(option) => (
                                <CustomSelectOption
                                    selectedValue={presentableData.specie.selectedSpecie}
                                    option={option}
                                />
                            )}
                        </Select>
                    </Box>
                    <Box direction="column" width="12.5rem" gap="small">
                        <Text size={"1rem"}>Genus</Text>
                        <Select
                            labelKey="commonName"
                            valueKey="commonName"
                            options={
                                presentableData?.genus?.genusData
                                    ? presentableData.genus.genusData
                                    : ""
                            }
                            disabled={activePageIndex === 2 ? true : false}
                            placeholder={"Select"}
                            style={{ fontWeight: "100" }}
                            value={getSelectedValue(
                                presentableData.genus.selectedGenus,
                                presentableData.genus.genusData
                            )}
                            dropHeight={"small"}
                            onChange={(option) => {
                                handleGenusSelected(option.option.id);
                            }}
                        />
                    </Box>
                    <Box
                        direction="column"
                        width="12.5rem"
                        gap="small"
                        margin={{ bottom: "0.1rem" }}
                    >
                        <Text size={"1rem"}>Source</Text>
                        <Grommet theme={myCustomTheme}>
                            <InputFieldWrapper>
                                <div className="SmallInputField">
                                    <TextInput
                                        placeholder={"Select source"}
                                        style={{ fontSize: "0.9rem", fontWeight: "100" }}
                                        dropHeight={"medium"}
                                        size={"xsmall"}
                                        disabled={activePageIndex === 2 ? true : false}
                                        ref={refToSmallInputField}
                                        value={sourceQuery}
                                        suggestions={sourceSuggestions}
                                        onChange={(event) => {
                                            if (!event.target.value)
                                                setSearchFilterData({
                                                    ...searchFilterData,
                                                    source: "",
                                                });
                                            setSourceQuery(event.target.value);
                                        }}
                                        onSelect={(event) => {
                                            setSourceQuery(event.suggestion);
                                            setSearchFilterData({
                                                ...searchFilterData,
                                                source: event.suggestion,
                                            });
                                        }}
                                    />
                                    <SearchCustom
                                        height={20}
                                        width={20}
                                        color={Theme.global.colors.active}
                                    />
                                </div>
                            </InputFieldWrapper>
                        </Grommet>
                    </Box>
                </Box>

                <Box
                    direction="column"
                    gap="small"
                    align="start"
                    width={flag.isModerator || flag.isContributor ? "30%" : "60&"}
                >
                    <Heading level={4}>Zones</Heading>
                    <ZoneContainer
                        direction={
                            flag.isModerator || flag.isContributor ? "column" : "row"
                        }
                        gap={flag.isModerator || flag.isContributor ? "small" : "large"}
                    >
                        <Box direction="column">
                            <Box
                                direction="column"
                                width="12.5rem"
                                gap="small"
                                margin={{ bottom: "0.8rem" }}
                            >
                                <Text size={"1rem"}>County</Text>

                                <Select
                                    labelKey="name"
                                    valueKey="name"
                                    options={[
                                        AllObj,
                                        ...COUNTIES
                                    ]}
                                    dropHeight="small"
                                    disabled={disableDropdown(MAP_LAYERS[1])}
                                    value={getValue(COUNTIES, searchFilterData.county)}
                                    style={{ fontWeight: "100" }}
                                    onChange={(option, a) => {
                                        handleZoneChanged(MAP_LAYERS[1], option.option.id);
                                    }}
                                />
                            </Box>
                            <Box
                                direction="column"
                                width="12.5rem"
                                gap="small"
                                margin={{ bottom: "0.8rem" }}
                            >
                                <Text size={"1rem"}>DEC Region</Text>
                                <Select
                                    labelKey="name"
                                    valueKey="name"
                                    options={[
                                        AllObj,
                                        ...DEC_REGION
                                    ]}
                                    placeholder={"Select"}
                                    dropHeight="small"
                                    disabled={disableDropdown(MAP_LAYERS[3])}
                                    value={getValue(DEC_REGION, searchFilterData.decRegion)}
                                    style={{ fontWeight: "100" }}
                                    onChange={(option) => {
                                        handleZoneChanged(MAP_LAYERS[3], option.option.id);
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box direction="column">
                            <Box
                                direction="column"
                                width="12.5rem"
                                gap="small"
                                margin={{ bottom: "0.8rem" }}
                            >
                                <Text size={"1rem"}>Watershed</Text>

                                <Select
                                    labelKey="name"
                                    valueKey="name"
                                    options={[
                                        AllObj,
                                        ...WATERSHEDS
                                    ]}
                                    placeholder={"Select"}
                                    dropHeight="small"
                                    disabled={disableDropdown(MAP_LAYERS[2])}
                                    value={getValue(WATERSHEDS, searchFilterData.watershed)}
                                    style={{ fontWeight: "100" }}
                                    onChange={(option) => {
                                        handleZoneChanged(MAP_LAYERS[2], option.option.id);
                                    }}
                                />
                            </Box>
                            <Box
                                direction="column"
                                width="12.5rem"
                                gap="small"
                                margin={{ bottom: "0.8rem" }}
                            >
                                <Text size={"1rem"}>Quads</Text>

                                <Select
                                    labelKey="name"
                                    valueKey="name"
                                    options={[
                                        AllObj,
                                        ...QUADS
                                    ]}
                                    placeholder={"Select"}
                                    dropHeight="small"
                                    disabled={disableDropdown(MAP_LAYERS[0])}
                                    value={getValue(QUADS, searchFilterData.quad)}
                                    style={{ fontWeight: "100" }}
                                    onChange={(option) => {
                                        handleZoneChanged(MAP_LAYERS[0], option.option.id);
                                    }}
                                />
                            </Box>
                        </Box>
                    </ZoneContainer>
                </Box>

                {flag.isModerator ? (
                    <Box direction="column" gap="small" align="start" width="30%">
                        <Box direction="column" gap="small">
                            <Heading level={4}>Contributors</Heading>
                            <Box
                                direction="column"
                                width="12.5rem"
                                gap="small"
                                margin={{ bottom: "0.8rem" }}
                            >
                                <Text size={"1rem"}>Users</Text>
                                <Select
                                    labelKey="name"
                                    valueKey="name"
                                    disabled={activePageIndex === 2 ? true : false}
                                    options={presentableData.usersList}
                                    placeholder={"Select"}
                                    dropHeight="small"
                                    value={getValue(
                                        presentableData.usersList,
                                        searchFilterData.user
                                    )}
                                    style={{ fontWeight: "100" }}
                                    onChange={(option) => {
                                        setDiversityFilter({
                                            ...diversityFilter,
                                            user: setValue(option.option.id),
                                        });
                                        setSearchFilterData({
                                            ...searchFilterData,
                                            user: setValue(option.option.id),
                                        });
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box direction="column" gap="medium">
                            <Heading level={4}>Moderation</Heading>
                            <CheckBox
                                toggle
                                label={"Flagged"}
                                disabled={activePageIndex === 2 ? true : false}
                                checked={searchFilterData.showFlaggedEnteries}
                                onChange={(isChecked) =>
                                    handleShowFlaggedEnteries(isChecked.target.checked)
                                }
                            />
                            <CheckBox
                                toggle
                                label={"Private"}
                                disabled={activePageIndex === 2 ? true : false}
                                checked={searchFilterData.showPrivateEnteries}
                                onChange={(isChecked) =>
                                    handleShowPrivateEnteries(isChecked.target.checked)
                                }
                            />
                        </Box>
                    </Box>
                ) : (
                    flag.isContributor && (
                        <Box direction="column" gap="medium">
                            <Heading level={4}>Contributors</Heading>
                            <CheckBox
                                toggle
                                label={"Created by me"}
                                onChange={(isChecked) =>
                                    handleShowCreatedByMe(isChecked.target.checked)
                                }
                                disabled={activePageIndex === 2 ? true : false}
                            />
                        </Box>
                    )
                )}
            </FilterItemContainer>
        </Expandable>
    );

    function resetFilters() {
        setPresentableData({
            ...presentableData,
            specie: {
                selectedSpecie: "",
                specieData: [
                    AllObjForOrderSpecie,
                    ...capitalizeObjectFirstLetter(
                        orderSpecieCallResponseData.species.sort((a, b) =>
                            a.commonName > b.commonName ? 1 : -1
                        )
                    ),
                ],
            },
            order: {
                ...presentableData.order,
                selectedOrder: "",
            },
            genus: {
                ...presentableData.genus,
                selectedGenus: "",
            },
        });
        setSearchFilterData({
            ...searchFilterData,
            order: "",
            specie: "",
        });
    }

    return (
        <ResponsiveContext.Consumer>
            {(size) => {
                return (
                    <ExplorePageRootContainer
                        elevation={size === "small" ? "none" : "medium"}
                        round="0.25rem"
                        margin={{
                            bottom: size === "small" ? "small" : "medium",
                        }}
                        pad={size === "small" ? "0px" : size === "medium" ? "1rem" : "3rem"}
                        size={size}
                    >
                        {dialogState.show && (
                            <ShowDialog
                                title={dialogState.message}
                                onClose={handleCloseAlertDialog}
                                type={dialogState.type}
                            />
                        )}
                        <RootGrid>
                            <Box
                                direction="row"
                                className="GridHeader"
                                gap={size === "small" ? "1rem" : "medium"}
                                style={{ marginBottom: "1rem" }}
                                justify={"between"}
                            >
                                <Heading style={{ maxWidth: "8rem" }} level={2}>
                                    Explore
                                </Heading>
                                {flag.isShowObservationSwitch ? (
                                    <ObservationViewSwitchContainer>
                                        <ObservationViewSwitchButton
                                            primary
                                            icon={
                                                flag.isObservationMap ? (
                                                    <GridIcon color={Theme.global.colors.grey}/>
                                                ) : (
                                                    <GridIcon color={Theme.global.colors.white}/>
                                                )
                                            }
                                            onClick={() =>
                                                setFlag({ ...flag, isObservationMap: false })
                                            }
                                            color={
                                                flag.isObservationMap
                                                    ? Theme.global.colors.lightWhite
                                                    : Theme.global.colors.active
                                            }
                                        />
                                        <ObservationViewSwitchButton
                                            primary
                                            icon={
                                                flag.isObservationMap ? (
                                                    <LocationIcon color={Theme.global.colors.white}/>
                                                ) : (
                                                    <LocationIcon color={Theme.global.colors.grey}/>
                                                )
                                            }
                                            onClick={() =>
                                                setFlag({ ...flag, isObservationMap: true })
                                            }
                                            color={
                                                flag.isObservationMap
                                                    ? Theme.global.colors.active
                                                    : Theme.global.colors.lightWhite
                                            }
                                        />
                                    </ObservationViewSwitchContainer>
                                ) : (
                                    <div></div>
                                )}
                            </Box>

                            <Box direction="row-responsive" className="GridTop" gap="medium">
                                <InputFieldWrapper>
                                    <div
                                        style={{ flexDirection: "row" }}
                                        className="MediumInputField"
                                    >
                                        <MultiLabelDropDown
                                            placeholder={"Search order/species by common/bio names"}
                                            value={getSearchValue(search)}
                                            suggestedItems={
                                                activePageIndex === 2
                                                    ? presentableData?.order?.orderData
                                                    : suggestedItems
                                            }
                                            onChange={(event) => {
                                                if (!event.target.value) resetFilters();
                                                setSearch(event.target.value);
                                            }}
                                            onSelect={(event) => {
                                                var option = event.suggestion.value;
                                                setSearch(option);
                                                if (
                                                    orderSpecieCallResponseData?.species?.find(
                                                        (item) => item.commonName === option
                                                    )
                                                ) {
                                                    handleSpecieSelected(option, true);
                                                } else if (
                                                    orderSpecieCallResponseData?.orders?.find(
                                                        (item) => item.commonName === option
                                                    )
                                                ) {
                                                    var orderObj = getOrderObj(option);
                                                    handleOrderSelected(orderObj.commonName, orderObj.id);
                                                }
                                            }}
                                        />
                                    </div>
                                </InputFieldWrapper>
                                <DateInput>
                                    <DateRangeComponent
                                        start={
                                            moment(searchFilterData.startingDateLimit).isValid()
                                                ? moment(searchFilterData.startingDateLimit).format(
                                                "MM-DD-YYYY"
                                                )
                                                : ""
                                        }
                                        end={
                                            moment(searchFilterData.endingDateLimit).isValid()
                                                ? moment(searchFilterData.endingDateLimit).format(
                                                "MM-DD-YYYY"
                                                )
                                                : ""
                                        }
                                        handleDateRange={handleDateRange}
                                    />
                                </DateInput>

                                <FilterButton
                                    label="Filters"
                                    icon={
                                        <FilterIcon
                                            width={"1rem"}
                                            height={"1rem"}
                                            color={
                                                flag.isFilterOpen
                                                    ? Theme.global.colors.white
                                                    : Theme.global.colors.active
                                            }
                                        />
                                    }
                                    gap="small"
                                    aria-label={flag.isFilterOpen ? "Hide filter" : "Show filter"}
                                    active={flag.isFilterOpen}
                                    primary={flag.isFilterOpen}
                                    onClick={() => {
                                        setFlag({ ...flag, isFilterOpen: !flag.isFilterOpen });
                                    }}
                                />
                            </Box>
                            {flag.isFilterOpen && FilterDialog}
                            <TabViewContainer direction="column" className="GridTabLayout">
                                <Tabs
                                    width="100%"
                                    alignSelf="start"
                                    justify="start"
                                    onActive={(index) => {
                                        setActivePageIndex(index);
                                        index === 0
                                            ? setFlag({ ...flag, isShowObservationSwitch: true })
                                            : setFlag({ ...flag, isShowObservationSwitch: false });
                                        if (previousZone && MAP_LAYERS.includes(previousZone)) {
                                            handleZoneChanged(previousZone, "");
                                            setPreviousZone("");
                                        }
                                    }}
                                >
                                    <Tab
                                        title="Observations"
                                        className={
                                            activePageIndex === 0 ? "active-tab" : "inactive-tab"
                                        }
                                    >
                                        <Box>
                                            <ObservationTab
                                                isMapView={flag.isObservationMap}
                                                observations={
                                                    seachLoadingResponse
                                                        ? []
                                                        : observationsData?.observations
                                                }
                                                observationsMeta={
                                                    seachLoadingResponse
                                                        ? 0
                                                        : observationsData.observationsMeta
                                                }
                                                handleZoneChanged={handleZoneChanged}
                                                handleLoadMoreObsevations={handleLoadMoreObsevations}
                                                onItemClick={onItemClick}
                                                onFlagClick={onFlagClick}
                                                onEditClick={onEditClick}
                                                heatMapByZoneData={
                                                    heatMapByCallResponseData
                                                        ?.observationsHeatMapByZone?.[0]
                                                }
                                                isSpecieSelected={
                                                    searchFilterData.specie ? true : false
                                                }
                                                isOrderSelected={searchFilterData.order ? true : false}
                                                handleDownloadCSV={handleDownloadCSV}
                                                CSVFileLoading={loadingCsvFile}
                                                zone={zone}
                                                zoneId={getZoneId()}
                                                size={size}
                                                fetchMoreLoading={fetchMoreLoading}
                                                searchCallLoading={seachLoadingResponse}
                                            />
                                        </Box>
                                    </Tab>
                                    <Tab
                                        title="Occurrence"
                                        className={
                                            activePageIndex === 1 ? "active-tab" : "inactive-tab"
                                        }
                                    >
                                        <Box>
                                            <OccurrenceTab
                                                occuranceGraphData={
                                                    graphData &&
                                                    graphData.generateOccuranceGraph.occuranceGraphData
                                                }
                                                countyName={
                                                    graphData &&
                                                    graphData.generateOccuranceGraph &&
                                                    graphData.generateOccuranceGraph
                                                        .selectedZoneDetails &&
                                                    graphData.generateOccuranceGraph.selectedZoneDetails
                                                        .name
                                                }
                                                speiceName={
                                                    graphData &&
                                                    graphData.generateOccuranceGraph &&
                                                    graphData.generateOccuranceGraph
                                                        .selectedTaxonomyDetails &&
                                                    graphData.generateOccuranceGraph
                                                        .selectedTaxonomyDetails.commonName
                                                }
                                                Loading={Loading}
                                                onClimateClick={onClimateClick}
                                                onHabitatClick={onHabitatClick}
                                                graphDataOfClimate={
                                                    graphDataOfClimate &&
                                                    graphDataOfClimate.generateOccuranceEnvironmentalRelationalGraph
                                                }
                                                graphDataofHabitat={
                                                    graphDataofHabitat &&
                                                    graphDataofHabitat.generateOccuranceEnvironmentalRelationalGraph
                                                }
                                                occurranceTabClimateDefaultValue={
                                                    intailSateOfGraphs.climateSearchVariable
                                                }
                                                occurranceTabHabitatDefaultValue={
                                                    intailSateOfGraphs.habitatSearchVariable
                                                }
                                                habitatLoadingResponse={habitatLoadingResponse}
                                                climateLoadingResponse={climateLoadingResponse}
                                            />
                                        </Box>
                                    </Tab>
                                    <Tab
                                        title="Diversity"
                                        className={
                                            activePageIndex === 2 ? "active-tab" : "inactive-tab"
                                        }
                                    >
                                        <Box>
                                            <DiversityTab
                                                diversityGraphDataLoading={
                                                    diversityGraphDataLoading ||
                                                    diversityGraphCompareOrderDataLoading
                                                }
                                                explanatoryVariableClick={explanatoryVariableClick}
                                                options={
                                                    presentableData && presentableData.order
                                                        ? presentableData.order.orderData
                                                        : [""]
                                                }
                                                diversityGraphData={
                                                    diversityGraphMergedData
                                                        ? diversityGraphMergedData
                                                        : ""
                                                }
                                                explanatoryVariableClimateClick={
                                                    explanatoryVariableClimateClick
                                                }
                                                setComparetoSlectorValue={setComparetoSlectorValue}
                                                disabled={
                                                    presentableData &&
                                                    presentableData.order &&
                                                    presentableData.order.selectedOrder
                                                        ? false
                                                        : true
                                                }
                                                defaultSelectedExplanatoryVariable={
                                                    intailSateOfGraphs.explanatoryVariable
                                                }
                                                diversityGraphErrorResponse={
                                                    diversityGraphErrorResponse ||
                                                    diversityCompareOrderGraphErrorResponse
                                                }
                                                selectedOrder={
                                                    diversityGraphData &&
                                                    diversityGraphData.generateDiversityGraphWithQuadData &&
                                                    diversityGraphData.generateDiversityGraphWithQuadData
                                                        .selectedTaxonomyDetails &&
                                                    diversityGraphData.generateDiversityGraphWithQuadData
                                                        .selectedTaxonomyDetails.commonName
                                                }
                                                selectedZone={
                                                    diversityGraphData &&
                                                    diversityGraphData.generateDiversityGraphWithQuadData &&
                                                    diversityGraphData.generateDiversityGraphWithQuadData
                                                        .selectedEnvironmentFilter
                                                }
                                                selectedCounty={
                                                    diversityGraphData &&
                                                    diversityGraphData.generateDiversityGraphWithQuadData &&
                                                    diversityGraphData.generateDiversityGraphWithQuadData
                                                        .selectedZoneDetails &&
                                                    diversityGraphData.generateDiversityGraphWithQuadData
                                                        .selectedZoneDetails.name
                                                }
                                                selectedCompareOrder={
                                                    diversityGraphCompareOrderData &&
                                                    diversityGraphCompareOrderData.generateDiversityGraphWithQuadData &&
                                                    diversityGraphCompareOrderData
                                                        .generateDiversityGraphWithQuadData
                                                        .selectedTaxonomyDetails &&
                                                    diversityGraphCompareOrderData
                                                        .generateDiversityGraphWithQuadData
                                                        .selectedTaxonomyDetails.commonName
                                                }
                                                selectedOrderForCompareOrderData={
                                                    diversityGraphCompareOrderData &&
                                                    diversityGraphCompareOrderData.generateDiversityGraphWithQuadData &&
                                                    diversityGraphCompareOrderData
                                                        .generateDiversityGraphWithQuadData
                                                        .selectedTaxonomyDetails &&
                                                    diversityGraphCompareOrderData
                                                        .generateDiversityGraphWithQuadData
                                                        .selectedTaxonomyDetails.commonName
                                                }
                                                zoneId={getZoneId()}
                                                isMapView={flag.isObservationMap}
                                                zone={zone}
                                                heatMapByZoneData={heatMapByCallResponseData}
                                                handleZoneChanged={handleZoneChanged}
                                                setPreviousZone={setPreviousZone}
                                                orderRichness={orderRichness}
                                            />
                                        </Box>
                                    </Tab>
                                </Tabs>
                            </TabViewContainer>
                        </RootGrid>
                    </ExplorePageRootContainer>
                );
            }}
        </ResponsiveContext.Consumer>
    );
};
export default Explore;
