import React, { useContext, useState } from "react";
import ContributorsCard from "../components/ContributorsCard";
import GuideItem from "../components/GuideCard";
import Loader from "../components/Loader";
import AppContext from "./../appContext";
import { Queries } from "../apollo";
import EmptyList from "../components/EmptyList";
import { useQuery } from "@apollo/react-hooks";
import { capitalizeFirstLetter, getImageURL } from "../utils";
import ContactComponent from "../components/ContactComponent";
import LatestObservationCard from "../components/LatestObservationcard";
import { AmphibiansReptilesHomeData, DUMMY_PIC_URL, MammalsHomeData, MAP_LAYERS, } from "../constants";
import { Box, Button, Heading, InfiniteScroll, ResponsiveContext, Text, } from "grommet";
import styled from "styled-components";
import { Brand1Logo, Brand2Logo, ChartIcon, SearchCustom, Theme, } from "../theme";

const RootContainer = styled(Box)`
  background: ${Theme.global.colors.white};
`;
const GridLeft = styled.div`
  display: grid;
  @media only screen and (max-width: 1050px) {
    display: block;
  }
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2em;
`;
const ButtonWrapper = styled(Button)`
  margin-top: 1rem;
  font-size: 1rem;
  svg path {
    fill: ${Theme.global.colors.active};
  }
  color: ${Theme.global.colors.active};
  width: 15rem;
  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
    width: 100%;
  }
`;
const ObservationCardContainer = styled.div`
  display: grid;
  
  grid-template-columns: repeat(1, 1fr);
  
  @media (min-width: 900px) {
    grid-template-columns: repeat(2,1fr);
  }
  
  @media (min-width: 1150px) {
    grid-template-columns: repeat(3,1fr);
  }
  
  grid-gap: 2rem;
  flex: 1;
  width: auto;
  flex-wrap: wrap;
`;
const SurveyCardContainer = styled.div`
  display: grid;
  padding: 0.2rem;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
`;
const Description = styled(Text)`
  grid-column: 2 / span 3;
`;
const BrandLogoWrapper = styled.div`
  background-color: transparent;
  width: 17.1rem;
  margin: 0;
  svg {
    height: 105px;
    width: 235px;
  }
`;
const HomePage = (props) => {
    const context = useContext(AppContext);
    const [showGuideDialog, setShowGuideDialog] = useState(false);

    const getStaticData = () => {
        if (context.isMammals) return MammalsHomeData;
        else return AmphibiansReptilesHomeData;
    };
    const pageStaticData = getStaticData();
    const {
        data: networkCallResponseData,
        loading: networkCallLoading,
        error: networkCallErrorResponse,
        refetch: networkCallRefetch,
    } = useQuery(Queries.GET_HOME_LIST_QUERY, { fetchPolicy: "cache-first" });

    const { data: topContributors, loading: topContributorsLoading } = useQuery(
        Queries.GET_TOP_CONTRIBUTORS,
        {
            fetchPolicy: "cache-and-network",
            onError: (err) => {
                throw err;
            },
        }
    );

    const onGuideListItemClick = (item) => {
        let path = `/guide/${item.id}`;
        props.history.push(path, { specieItem: item });
    };
    const onViewClick = (item) => {
        let path = `/observation/${item.id}`;
        props.history.push(path, {
            observationItem: item,
            zone: MAP_LAYERS[1],
        });
    };
    const navToGuidePage = () => {
        let path;
        path = `/guide`;
        props.history.push(path);
    };
    const navToExplorePage = () => {
        let path;
        path = `/explore`;
        props.history.push(path);
    };

    const renderLatestObservations = (size) => {
        return (
            <Box>
                <Heading level={"3"}>
                    {pageStaticData.headingLatestObservations}
                </Heading>
                <Text weight={"normal"} style={{ display: "block", fontSize: "18px" }}>
                    {pageStaticData.latestObservationInfo}
                </Text>
                <Box margin={{ top: size }}>
                    <ObservationCardContainer size={size}>
                        {networkCallResponseData.recentObservations.map((item) => {
                            return (
                                <LatestObservationCard
                                    key={item.id}
                                    image={
                                        item.images.length > 0
                                            ? item.images[0].url
                                            : item.specie.images.length > 0
                                            ? item.specie.images[0].url
                                            : DUMMY_PIC_URL
                                    }
                                    dateTime={item.dateTime}
                                    specieName={item.specie.commonName}
                                    countryName={item.county.name}
                                    userName={item.user.name}
                                    profileData={item}
                                    onItemClick={() => {
                                        onViewClick(item);
                                    }}
                                />
                            );
                        })}
                    </ObservationCardContainer>
                </Box>
                <ButtonWrapper
                    icon={<ChartIcon/>}
                    ariaLabel={"ExploreButton"}
                    tabIndex="0"
                    role="link"
                    label="Explore Observations"
                    onClick={() => {
                        navToExplorePage();
                    }}
                />
            </Box>
        );
    };

    const renderGuideList = (size) => {
        return (
            <Box margin={{ top: "2rem" }}>
                <Heading level={"3"}>{pageStaticData.headingSurveyGuide}</Heading>
                <Text weight={"normal"} style={{ display: "block", fontSize: "18px" }}>
                    {pageStaticData.surveyGuideInfo}
                </Text>
                <Box margin={{ top: "medium" }}>
                    <ObservationCardContainer size={size}>
                        {networkCallResponseData.recentSpecies.map((item, index) => {
                            return (
                                <GuideItem
                                    key={"_SpeciesItem" + item.id}
                                    specieItem={item}
                                    onItemClick={() => {
                                        onGuideListItemClick(item);
                                    }}
                                />
                            );
                        })}
                    </ObservationCardContainer>
                </Box>
                <ButtonWrapper
                    icon={
                        <SearchCustom
                            color={Theme.global.colors.active}
                            style={{ marginRight: "3px" }}
                        />
                    }
                    label="Read the guide"
                    onClick={() => {
                        navToGuidePage();
                    }}
                />
            </Box>
        );
    };

    const networkCallStatus = () => {
        if (networkCallLoading) {
            return <Loader/>;
        }
        if (networkCallErrorResponse) {
            return <EmptyList onRefetch={networkCallRefetch} isError={true}/>;
        }
    };

    const renderContributorsCard = (size) => {
        return (
            <Box margin={{ top: "2rem" }}>
                <Heading level={"3"}>{pageStaticData.headingTopContributors}</Heading>
                <Text weight={"normal"} style={{ display: "block", fontSize: "18px" }}>
                    {pageStaticData.topContributorsDescription}
                </Text>
                {topContributorsLoading ? (
                    <Loader/>
                ) : (
                    <Box height="auto" overflow="auto">
                        <InfiniteScroll items={[0]}>
                            {(item) => (
                                <SurveyCardContainer size={size}>
                                    {topContributors?.topContributors?.map((item, i) => {
                                        return (
                                            <ContributorsCard
                                                key={i}
                                                size={size}
                                                city={item.user.city}
                                                image={
                                                    item.user.image
                                                        ? getImageURL(item.user.image)
                                                        : DUMMY_PIC_URL
                                                }
                                                name={capitalizeFirstLetter(item.user.name)}
                                                currMonthObservations={item.currMonthObservations}
                                                prevMonthObservations={item.prevMonthObservations}
                                                totalObservations={item.totalObservations}
                                            />
                                        );
                                    })}
                                </SurveyCardContainer>
                            )}
                        </InfiniteScroll>
                    </Box>
                )}
            </Box>
        );
    };

    const isDataAvaliable = (size) => {
        if (networkCallResponseData) {
            return true;
        }
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <React.Fragment>
                    <RootContainer
                        elevation={size === "small" ? "none" : "medium"}
                        round="0.25rem"
                        margin={{
                            bottom: size === "small" ? "small" : "medium",
                        }}
                        pad={size === "small" ? "0px" : size === "medium" ? "1rem" : "3rem"}
                        size={size}
                    >

                        <GridLeft>
                            <BrandLogoWrapper>
                                {context.isMammals ? (
                                    <Brand1Logo/>
                                ) : (
                                    <Brand2Logo width={"400px"} height={"124px"}/>
                                )}
                            </BrandLogoWrapper>

                            <Description>
                                <Text
                                    weight={"normal"}
                                    style={{ display: "block", fontSize: "18px" }}
                                >
                                    {pageStaticData.info}
                                </Text>
                                <Button
                                    plain
                                    ariaLabel={"guideButton"}
                                    tabIndex="0"
                                    role="link"
                                    style={{ fontSize: "18px" }}
                                    weight={"normal"}
                                    color={Theme.global.colors.brand}
                                    label={pageStaticData.infoButton1}
                                    margin={{ right: size, top: "0.5rem" }}
                                    onClick={() => {
                                        window.open(`${window.location}getting-started`, "_blank")
                                    }}
                                />
                                <Button
                                    plain
                                    ariaLabel={"mammalButton"}
                                    tabIndex="0"
                                    role="link"
                                    style={{ fontSize: "18px", marginBottom: 16 }}
                                    weight={"normal"}
                                    color={Theme.global.colors.brand}
                                    label={pageStaticData.infoButton2}
                                    onClick={() => {
                                        props.history.push("/about");
                                    }}
                                />
                                <Box alignSelf="end">
                                    <ContactComponent position="end" size="medium"/>
                                </Box>
                            </Description>
                        </GridLeft>
                        {networkCallStatus()}
                        {isDataAvaliable() && renderLatestObservations(size)}
                        {isDataAvaliable() && renderGuideList(size)}
                    </RootContainer>
                </React.Fragment>
            )}
        </ResponsiveContext.Consumer>
    );
};

export default HomePage;
