import React from "react";
import { Default } from "react-awesome-spinners";
import styled from "styled-components";
import { Theme } from "../../theme";

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-item: center;
`;

const Loader = () => {
    return (
        <LoaderContainer>
            <Default color={Theme.global.colors.active}/>
        </LoaderContainer>
    );
};
export default Loader;
